import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConfirmChanges1 from "./ConfirmChanges1";
import ConfirmChanges2 from "./ConfirmChanges2";
import loaderImage from "../../../../assets/images/loader-icon.png";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  DDPIStatusUpdateApiCall,
  AddNomineeApiCall,
  DDPIShameESign,
  AfterESignDeduction,
  modMailSend,
  modUpdateIscompleteSend,
  modification_profile
} from "../../../../helpers/fakebackend_helper";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MOD_EKYC_NEW } from "../../../../helpers/url_helper";
import Encrypted from "../../../../pages/Dashboard/Encripted";
// TODO Ui and design and functions.
const NomineeDetailsModelIndex = (props) => {

  const { ClientID } = useSelector((state) => ({
    ClientID: state.ClientID.ClientID
  }));
  // const history = useNavigate();
  const client_code = localStorage.getItem("client_id");
  // const client_token = localStorage.getItem("client_token");
  // const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  // const STATECODE = localStorage.getItem("STATECODE");
  // const BRCODE = localStorage.getItem("BRCODE");
  // const [modStatus, setModStatus] = useState("");
  const [eSign, setESign] = useState(false);
  const [eSignStart, setESignStart] = useState(false);
  const [eSignComplected, setESignComplected] = useState(false);
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [button_disable, setbuttonDisable] = useState(false);
  const [modalAdditional, setModelAdditional] = useState({
    CChange1: true,
    CChange2: false,
  });

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));

  // const sendData = (data) => {
  //   ////console.log(data);
  //   //e.preventDefault();
  //   setModelAdditional(data);
  // };

  // const tog_signUpModalsFinal = (data) => {
  //   // //console.log('submit')
  //   setmodal_signUpModals(!modal_signUpModals);
  // };

  const tog_signUpModals = () => {
    ////console.log("submdsdsdsdsdit");
    setmodal_signUpModals(!modal_signUpModals);
  };

  const submitHandler = () => {
    ////console.log("submit");
  };







  function onSubmitHandler2(e) {
    e.preventDefault();

    const nomini_data = props.data.nomini_val;

    if (nomini_data.length > 0) {
      // Create a Set to track unique identity numbers
      const identityNumbersSet = new Set();

      for (let i = 0; i < nomini_data.length; i++) {
        const identityNumber = nomini_data[i].proof_num_1;

        // Check if the identity number already exists in the Set
        if (identityNumbersSet.has(identityNumber)) {
          // Show alert and stop the form submission
          toast.error(`You have already enter identitynumber: ${identityNumber}`);
          return;
        } else {
          // Add the identity number to the Set
          identityNumbersSet.add(identityNumber);
        }
      }

      // If no duplicates found, proceed with form submission
      for (let i = 0; i < nomini_data.length; i++) {
        const payload = {
            client_code: client_code,
            entry_type: `nominee_${i + 1}`,
            new: JSON.stringify({
                add1: nomini_data[i].address_line_1_1,
                add2: nomini_data[i].address_line_2_1,
                // add3: nomini_data[i].address_line_3_1,
                mobile: nomini_data[i].nominee_mobile_number_1,
                email: nomini_data[i].nominee_email_1,
                relation: nomini_data[i].nominee_relation_1,
                percentage: nomini_data[i].percentage_1,
                name: nomini_data[i].name,
                pincode: nomini_data[i].area_code_1,
                city: nomini_data[i].city_1,
                state: nomini_data[i].state_1,
                country: nomini_data[i].country_1,
                doc_type: nomini_data[i].proof_type_1,
                doc_id: nomini_data[i].proof_num_1,
            }),
            old: JSON.stringify({
                add1: null,
                add2: null,
                // add3: null,
                mobile: null,
                email: null,
                relation: null,
                percentage: null,
                name: null,
                pincode: null,
                city: null,
                state: null,
                country: null,
                doc_type: null,
                doc_id: null,
            }),
            modify_key: JSON.stringify([
                'name',
                'email',
                'mobile',
                'relation',
                'doc_type',
                'add1',
                'add2',
                // 'add3',
                'percentage',
                'pincode',
                'city',
                'state',
                'country',
                'doc_id'
            ]),
        };
    
        axios.post(MOD_EKYC_NEW + '/update-modification', { 'enc': Encrypted([payload]) }, {
            headers: {
                'Authorization': 'Bearer your_token', // Replace with your actual token.
            },
        })
        .then(response => {
            if (response.status) {
                setmodal_signUpModals(false);
                props.onStateChange(1);
            } else {
                toast('Something went wrong');
            }
        })
        .catch(error => {
            toast('Something went wrong');
        });
    }
    
    } else {
      console.log('No nominee data available.');
    }
  }


  return (
    <React.Fragment>
      <button
        className="btn additional-page-button-save me-0 mt-4"
        type={props.type}
        id={props.id}
        onClick={(e) => {
          tog_signUpModals();
        }}
      >
        Save
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={(e) => {

          setmodal_signUpModals(false)
        }}
        centered
      >
        <ModalHeader
          className="px-4 pt-4 justify-content-center"
          toggle={(e) => {
            setmodal_signUpModals(false)

          }}
        >
          <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
            Confirm Changes
          </h4>
        </ModalHeader>
        {eSignStart === false ? (
          <ModalBody className="p-4">
            <form onSubmit={submitHandler()}>
             
              {eSign === false && eSignComplected === true ? (
                <div>
                  <h4 className="text-15 text-9 text-color5 text-center ">
                    {/* E-Sign completed successfully */}

                    E-sign successful! <br></br>Your modification request has been received. <br></br>Expect changes to reflect within the next 24 working hours following verification.
                  </h4>
                </div>
              ) : (
                <div>
                  <h4 className="text-9 text-color5 mt-3 text-center">
                    {/* You will be charged ₹50/- for account modification request. */}
                    'Confirm' finalises your details and leads to <br></br>e-sign which will charge Rs.25/- for modification.
                  </h4>
                </div>
              )}
              <div className="text-center">
                {/* {eSign === false && eSignComplected === false ? (
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={(e) => onSubmitHandler(e)}
                >
                  Confirm
                </button>
              ) : null} */}
                {eSign === false && eSignComplected === true ? (
                  <button
                    type="button"
                    className="btn btn-primary modal-content-Button my-3"
                    onClick={() => {

                      location.reload();
                      // props.tog(false);
                      // history("/profile");
                    }}
                  >
                    {" "}
                    Okay
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary modal-content-Button my-3"
                    disabled={button_disable}
                    onClick={(e) => onSubmitHandler2(e)}
                  >

                    {button_disable == false ? 'Confirm' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
                  </button>
                )}
              </div>
            </form>
          </ModalBody>
        ) : (
          <ModalBody className="p-4">
            <h5 className="text-9 text-color5 mt-3 text-center">We are currently processing your e-sign, please await confirmation.</h5>
            <Loader />
          </ModalBody>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default NomineeDetailsModelIndex;
