import {
  API_TRADE_REFER_SUMMARY_SUCCESS,
  API_TRADE_REFER_SUMMARY_FAIL,
  GET_TRADE_REFER_SUMMARY,
  GET_TRADE_REFER_SUM,
  START_LOADING,
  STOP_LOADING,
} from "./actionType";

const INIT_STATE = {
  referSummary: [],
  referSum: [],
  isLoading: false,
};

const TradeReferSummary = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case API_TRADE_REFER_SUMMARY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TRADE_REFER_SUMMARY:
          return {
            ...state,
            referSummary:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        case GET_TRADE_REFER_SUM:
          return {
            ...state,

            referSum:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_TRADE_REFER_SUMMARY_FAIL:
      switch (action.payload.actionType) {
        case GET_TRADE_REFER_SUMMARY:
          return {
            ...state,
            error: action.payload.error,
          };
        case GET_TRADE_REFER_SUM:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case STOP_LOADING:
      return { ...state, isLoading: false };
    default:
      return { ...state };
  }
};

export default TradeReferSummary;
