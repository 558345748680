import {
  GET_HIGHLIGHTS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
  highlightsDetails: [],
};

const HighlightDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_HIGHLIGHTS:
          return {
            ...state,
            highlightsDetails: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_HIGHLIGHTS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_HIGHLIGHTS: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default HighlightDetails;
