import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
//Import Breadcrumb
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";

const VideoContainer = (props) => {
  const { id } = props;
  // document.title = "Shopping Cart | Velzon - React Admin & Dashboard Template";
  ////console.log(id)
  var advancedFormat = require('dayjs/plugin/advancedFormat')
  dayjs.extend(advancedFormat)
  const { ELearningVideosList } = useSelector((state) => ({
    ELearningVideosList: state.ELearning.ELearningVideosList,
  }));
 // //console.log(ELearningVideosList)
  const selectedVideo = ELearningVideosList.find(el =>  el.id === +id);
  ////console.log(selectedVideo)
  let domain = !isEmpty(selectedVideo) ? selectedVideo.video_url.substring(selectedVideo.video_url.indexOf('https') + 0, selectedVideo.video_url.indexOf('title') - 2) : '';
  return (
    <React.Fragment>
      <Col lg={7}>
        <Col className="">
          <iframe
            width="100%"
            height="400px"
            src={domain}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowfullscreen"
          />
        </Col>
      </Col>
      <Col lg={5}>
        <div>
          <h4 className="text-2 text-color5 mb-4">{!isEmpty(selectedVideo) && selectedVideo.title}</h4>
          <div className="d-flex mb-3">
            <h5 className="me-3 text-15 text-color7">{!isEmpty(selectedVideo) && selectedVideo.category.category}</h5>
            <h5 className="text-15">{!isEmpty(selectedVideo) && dayjs(selectedVideo.created_at).format("DD MMM YYYY")}</h5>
          </div>
          <h6 className="text-1 text-color4 mb-4">
            {!isEmpty(selectedVideo) && selectedVideo.description}
          </h6>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default VideoContainer;
