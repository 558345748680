import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";

//Import Breadcrumb
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";

import need_Help from "../../assets/images/logo/need_Help.png";

const EcommerceCart = () => {
  return (
    <React.Fragment>
      <Col xxl={3} xl={3} lg={3}>
          <Card>
            <CardHeader>
              <h5 className="card-title mb-0 text-color5 text-9 text-center">
                Need Help?
              </h5>
            </CardHeader>
            <CardBody className="text-center">
              <img
                src={need_Help}
                style={{ width: "82px"}}
                alt='logo'
              />
              <p className="mt-4 mb-0 text-4 text-color1 ">Check out our</p>
              <p className="text-9 text-color7 mb-0"><a className="green" target="_blank" href="mailto:support@bigul.co">SUPPORT CENTER</a></p>
              <p className="text-4 text-color1 ">for quick and easy guide</p>
            </CardBody>
          </Card>
      </Col>
    </React.Fragment>
  );
};

export default EcommerceCart;
