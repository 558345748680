import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import loaderImage from "../../../../assets/images/loader-icon.png";
// actions
import {
  DDPIStatusUpdateApiCall,
  DDPIESign,
  AfterESignDeduction,
  modification_profile
} from "../../../../helpers/fakebackend_helper";
import Loader from "../../../Common/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { MOD_EKYC_NEW } from "../../../../helpers/url_helper";
import Encrypted from "../../../../pages/Dashboard/Encripted";
// TODO Ui and design and functions.
const ConfirmChanges2 = (props) => {
  const history = useNavigate();
  const [modStatus, setModStatus] = useState("");
  const [eSign, setESign] = useState(false);
  const [eSignStart, setESignStart] = useState(false);
  const [eSignComplected, setESignComplected] = useState(false);
  const [disableButton, setDisableButton] = useState(0);
  const { ClientID } = useSelector((state) => ({
    ClientID: state.ClientID.ClientID
  }));
  const validationSchema = Yup.object().shape({
    condition: Yup.bool().oneOf(
      [true],
      "Please accept the Terms & Conditions to proceed."
    ).required('This field is required'),
  });
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const initialPackage = {
    condition: false,
  };
  const [formValues, setFormValues] = useState(initialPackage);
  const modalMobile = {
    CUpdate: false,
    otp1: true,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: false,
  };
  ////console.log(props.data2);
  const client_code = localStorage.getItem("client_id");
  // function tog_signUpModals() {
  //   // //console.log('submit')
  //   setmodal_signUpModals(!modal_signUpModals);
  // }
  // //console.log(props.data[0]);
  const submitHandler = () => {
    ////console.log("submit");
  };
  function onSubmitHandler(e) {

    ////console.log(props.data);
    // const newValue = {...values, client_code: client_code}
    // //console.log(newValue);
    // const initialValues = {
    //   pan_no: "M9M90Y057",
    //   client_code: 'M9M90Y057',
    //   old_mobile_number: "8097379632",
    //   new_mobile_number: "9821662441",
    // };
   // e.preventDefault();
    const value = {
      ClientCode: client_code,
      DdpiOption: "",
    };
    if (props.data.length === 4) {
      value.DdpiOption =  props.data.toString();
    } else {
      value.DdpiOption = props.data.toString();
    }
    // setDisableButton(1);
    //  const formData = new FormData();
    // formData.append('client_code', client_code);
    // formData.append('entry_type', 'ddpi');
    // formData.append('new[All_Transaction]',value?.DdpiOption);
    
   
    // formData.append('old[All_Transaction]', null);

    // formData.append('modify_key[]', 'All_Transaction');
   
  
    const formData={
      client_code:client_code,
      entry_type:'ddpi',
      old: JSON.stringify({
        All_Transaction:null,
      }),
      new: JSON.stringify({
        All_Transaction: value?.DdpiOption,
      }),
      modify_key: JSON.stringify(['All_Transaction'])
    }

    axios.post(MOD_EKYC_NEW +'/update-modification',  {'enc':Encrypted([formData])}, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer your_token', 
      },
    })

      .then(response => {
       if(response.status){
        props.tog(false);
        props.handlechangeState1(true)
       }
       else[
        toast('something went wrong')
       ]

      })
      .catch(error => {

      });
  //   try {
  //     modification_profile(client_code)
  //     .then((response) => {
  //   // //console.log(props.data.length);
  //   // //console.log(value);
  //   DDPIStatusUpdateApiCall(value)
  //     .then((response) => {
  //       setDisableButton(0);
  //       ////console.log(response);
  //       if (
  //         response.success === true &&
  //         response.message === "Insert/Updated Successfully."
  //       ) {
  //         setModStatus(response.data.modification_id);
  //         setESign(true);
  //       } else {
  //         //history("/dashboard");
  //         props.tog(false);
  //       }
  //       //props.tog(false);
  //     })
  //     .catch((err) => {
  //       setDisableButton(0);
  //       ////console.log(err);
  //     });
  //   })
  //   .catch((err) => {
  //     setDisableButton(0);
  //     console.log('catch ERROR',err);
  //   });
  // }catch(error){
  //   setDisableButton(0);
  //   console.log('ERROR ',error);
  // }
    //dispatch(UpdatedPhone(newValue));
  }

  function onSubmitHandler2(e) {
    console.log('client_codeclient_code',client_code);
    const initialValues = {
      ClientCode: client_code,
      IsESign: "Yes",
      IsType: "equity",
      pan_number: props.data2.userProfile[0].PANGIR,
      full_address: `${props.data2.userProfile[0].ADDRESS1},${props.data2.userProfile[0].ADDRESS2},${props.data2.userProfile[0].ADDRESS3},${props.data2.userProfile[0].PINCODE},${props.data2.userProfile[0].CITY},${props.data2.userProfile[0].CSTATE},${props.data2.userProfile[0].COUNTRY}`,
      full_name: props.data2.userProfile[0].FIBSACCT,
      dpCode: props.data2.userProfile[0].DPCODE,
      email: props.data2.userProfile[0].EMAIL,
    };
    e.preventDefault();

    DDPIESign(initialValues)
      .then((response) => {
        ////console.log(response);
        if (
          response.success === true &&
          response.message === "URL Generated Successfully."
        ) {
          setESignStart(true);
          setESign(false);
          window.open(`${response.data.URLL}`, "_blank");
        } else {
          setESign(false);
          setESignComplected(true);
        }
      })
      .catch((err) => {
        ////console.log(err);
      });
    //dispatch(UpdatedPhone(newValue));
  }

  useEffect(() => {
    if (eSignStart === true) {
      const initialValues = {
        ClientCode: client_code,
        IsESign: "Yes",
        IsType: "equity",
        pan_number: props.data2.userProfile[0].PANGIR,
        full_address: `${props.data2.userProfile[0].ADDRESS1},${props.data2.userProfile[0].ADDRESS2},${props.data2.userProfile[0].ADDRESS3},${props.data2.userProfile[0].PINCODE},${props.data2.userProfile[0].CITY},${props.data2.userProfile[0].CSTATE},${props.data2.userProfile[0].COUNTRY}`,
        full_name: props.data2.userProfile[0].FIBSACCT,
        dpCode: props.data2.userProfile[0].DPCODE,
        email: props.data2.userProfile[0].EMAIL,
      };
      const current = setInterval(
        () =>
          DDPIESign(initialValues)
            .then((response) => {
              ////console.log(response);
              if (
                response.success === false &&
                response.message === "ESign Already Done."
              ) {
                setESignStart(false);
                setESignComplected(true);
                AfterESignDeduction({
                  client_code: ClientID,
                  pan_no: props.data2.userProfile[0].PANGIR,
                  payment_for: "Update DDPI",
                  modification_id: modStatus,
                })
                  .then((response) => {})
                  .catch((err) => {
                    ////console.log(err);
                  });
              }
            })
            .catch((err) => {
              ////console.log(err);
            }),
        10000
      );

      return () => {
        clearInterval(current);
      };
    }
  }, [eSignStart, eSignComplected]);

  // useEffect(() => {
  //   if ( eSignStart === false ) {
  //     clearInterval(currentTimer);
  //   }
  // }, [eSignStart]);
  //console.log("hello")
  const tog_signUpModals = () => {
    props.tog(false);
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4 justify-content-center"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
          Confirm Changes
        </h4>
      </ModalHeader>
      {eSignStart === false ? (
        <ModalBody className="p-4">

            {eSign === false && eSignComplected === false && (
                     <Formik
                     initialValues={{
                       condition: formValues.condition,
                     }}
                     validationSchema={validationSchema}
                     onSubmit={(values, { setSubmitting }) => {
                      //console.log("ff")
                        onSubmitHandler();
                       setSubmitting(false);
                     }}
                   >
                     {({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       setFieldValue,
                       isSubmitting,
                     }) => {
                       ////console.log(values);
           
                       return (
                         <Form
                           noValidate
                           autoComplete="off"
                           id="my-form"
                           className="flex flex-col justify-center w-full"
                         >
                           <div className="mb-3 form-check">
                             <Input
                               type="checkbox"
                               className="form-check-input"
                               id="checkTerms"
                               name="condition"
                               value={values.condition}
                               onBlur={handleBlur}
                               error={errors.condition && touched.condition}
                               helperText={touched.condition && errors.condition}
                               onChange={handleChange}
                               checked={values.condition}
                             />
                             <Label
                               className="form-check-label text-15 text-color1 px-3"
                               htmlFor="checkTerms"
                             >
                               I have read and understood the information and requirements of DDPI and hereby give authority to Bigul (Bonanza Portfolio Ltd.) to activate and use the authority provided by me for the purpose of Demat Debit and Pledge instructions.
                             </Label>
                             {errors.condition && (
                               <label
                                 className="mt-3 mx-3"
                                 style={{ color: "#f44336" }}
                               >
                                 <h6 className="fs-12 text-danger">{errors.condition}</h6>
                               </label>
                             )}
                           </div>
                           <div className="text-center">
                             <button
                               type="submit"
                               disabled = {disableButton == 0 ? false : true}
                               className="btn btn-primary modal-content-Button my-3"
                               //onClick={(e) => onClickHandler(e)}
                             >
                              {disableButton == 0 ? 'Confirm' : <img src={loaderImage} className="custom-loader" style={{height:"20px", marginRight:"5px"}} />}
                                
                             </button>
                           </div>
                         </Form>
                       );
                     }}
                   </Formik>
            )}
            {eSign === false && eSignComplected === false ? (
              // <div>
              //   <h4 className="text-15 text-color1 mt-3 text-center">
              //     We will notify you over email for the same.
              //   </h4>
              // </div>
              null
            ) : eSign === false && eSignComplected === true ? (
              <div>
                <h4 className="text-9 text-color5 ">E-Sign completed successfully</h4>
              </div>
            ) : (
              <div>
                <h4 className="text-9 text-color5  mt-3 text-center">
                You will be charged ₹50/- for account modification request.
                </h4>
              </div>
            )}
            <div className="text-center">
              {eSign === false && eSignComplected === false ? (
                // <button
                //   type="button"
                //   className="btn btn-primary modal-content-Button my-3"
                //   onClick={(e) => onSubmitHandler(e)}
                // >
                //   Confirm
                // </button>
                null
              ) : eSign === false && eSignComplected === true ? (
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={() => {
                    props.tog(false);
                    props.end(true);
                    history("/profile");
                  }}
                >
                  {" "}
                  Okay
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={(e) => onSubmitHandler2(e)}
                >
                  Proceed for E Sign
                </button>
              )}
            </div>
        </ModalBody>
      ) : (
        <ModalBody className="p-4">
          <Loader />
        </ModalBody>
      )}
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default ConfirmChanges2;
