import {
  GET_TRADE_BOOK_CASH,
  API_TRADE_BOOK_CASH_RESPONSE_SUCCESS,
  API_TRADE_BOOK_CASH_RESPONSE_ERROR,
  GET_TRADE_BOOK_DERIVATIVE,
  API_TRADE_BOOK_DERIVATIVE_RESPONSE_SUCCESS,
  API_TRADE_BOOK_DERIVATIVE_RESPONSE_ERROR,
  GET_LEDGER,
  API_LEDGER_RESPONSE_SUCCESS,
  API_LEDGER_RESPONSE_ERROR,
  GET_P_AND_L_CASH,
  API_P_AND_L_CASH_RESPONSE_ERROR,
  API_P_AND_L_CASH_RESPONSE_SUCCESS,
  GET_P_AND_L_DERIVATIVE,
  API_P_AND_L_DERIVATIVE_RESPONSE_SUCCESS,
  API_P_AND_L_DERIVATIVE_RESPONSE_ERROR,
  GET_HOLDING,
  API_HOLDING_RESPONSE_SUCCESS,
  API_HOLDING_RESPONSE_ERROR,
  GET_TAX_REPORT,
  API_TAX_REPORT_RESPONSE_SUCCESS,
  API_TAX_REPORT_RESPONSE_ERROR,
  GET_CONTRACT_NOTE,
  API_CONTRACT_NOTE_RESPONSE_SUCCESS,
  API_CONTRACT_NOTE_RESPONSE_ERROR,
  API_P_AND_L_DERIVATIVE_2_RESPONSE_SUCCESS,
  API_P_AND_L_DERIVATIVE_2_RESPONSE_ERROR,
  GET_P_AND_L_DERIVATIVE_2
} from "./actionType";

const INIT_STATE = {
    tradeBookCash : [],
    tradeBookDerivative: [],
    ledger: [],
    PAndLCash: [],
    PAndLDerivative: [],
    Holdings: [],
    TaxReport: [],
    PAndLDerivative2: []
};

const Statements = (state = INIT_STATE, action) => {
  // //console.log(action.payload);
  // Trade Book Cash
  switch (action.type) {
    case API_TRADE_BOOK_CASH_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TRADE_BOOK_CASH:
          return {
            ...state,
            tradeBookCash: action.payload.data.glcaptrResult,
          };
        default:
          return { ...state };
      }
    case API_TRADE_BOOK_CASH_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_TRADE_BOOK_CASH:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_TRADE_BOOK_CASH: {
      return {
        ...state,
      };
    }
    // Trade Book Derivative
    case API_TRADE_BOOK_DERIVATIVE_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TRADE_BOOK_DERIVATIVE:
          return {
            ...state,
            tradeBookDerivative: action.payload.data.gldertrResult,
          };
        default:
          return { ...state };
      }
    case API_TRADE_BOOK_DERIVATIVE_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_TRADE_BOOK_DERIVATIVE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_TRADE_BOOK_DERIVATIVE: {
      return {
        ...state,
      };
    }
    // Ledger
    case API_LEDGER_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_LEDGER:
          return {
            ...state,
            ledger: action.payload.data.finResult,
          };
        default:
          return { ...state };
      }
    case API_LEDGER_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_LEDGER:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_LEDGER: {
      return {
        ...state,
      };
    }
     // P&L Cash
     case API_P_AND_L_CASH_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_P_AND_L_CASH:
          return {
            ...state,
            PAndLCash: action.payload.data.glcapResult,
          };
        default:
          return { ...state };
      }
    case API_P_AND_L_CASH_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_P_AND_L_CASH:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_P_AND_L_CASH: {
      return {
        ...state,
      };
    }
     // P&L Derivative
     case API_P_AND_L_DERIVATIVE_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_P_AND_L_DERIVATIVE:
          return {
            ...state,
            PAndLDerivative: action.payload.data.glderResult,
          };
        default:
          return { ...state };
      }
    case API_P_AND_L_DERIVATIVE_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_P_AND_L_DERIVATIVE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_P_AND_L_DERIVATIVE: {
      return {
        ...state,
      };
    }
     // Holdings
     case API_HOLDING_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_HOLDING:
          return {
            ...state,
            Holdings: action.payload.data.holdwithavgResult,
          };
        default:
          return { ...state };
      }
    case API_HOLDING_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_HOLDING:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_HOLDING: {
      return {
        ...state,
      };
    }
     // Tax Report
     case API_TAX_REPORT_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TAX_REPORT:
          return {
            ...state,
            TaxReport: action.payload.data.ltstsummerizedvalueResult,
          };
        default:
          return { ...state };
      }
    case API_TAX_REPORT_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_TAX_REPORT:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_TAX_REPORT: {
      return {
        ...state,
      };
    }
    // P&L Derivative
    case API_P_AND_L_DERIVATIVE_2_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_P_AND_L_DERIVATIVE_2:
          return {
            ...state,
            PAndLDerivative2: action.payload.data.glderResult,
          };
        default:
          return { ...state };
      }
    case API_P_AND_L_DERIVATIVE_2_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_P_AND_L_DERIVATIVE_2:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_P_AND_L_DERIVATIVE_2: {
      return {
        ...state,
      };
    }
    // Contract Note
    //  case API_CONTRACT_NOTE_RESPONSE_SUCCESS:
    //   switch (action.payload.actionType) {
    //     case GET_CONTRACT_NOTE:
    //       return {
    //         ...state,
    //         ContractNote: action.payload.data.finResult,
    //       };
    //     default:
    //       return { ...state };
    //   }
    // case API_CONTRACT_NOTE_RESPONSE_ERROR:
    //   switch (action.payload.actionType) {
    //     case GET_CONTRACT_NOTE:
    //       return {
    //         ...state,
    //         error: action.payload.error,
    //       };
    //     default:
    //       return { ...state };
    //   }
    // case GET_CONTRACT_NOTE: {
    //   return {
    //     ...state,
    //   };
    // }
    // Default
    default:
      return { ...state };
  }
};

export default Statements;
