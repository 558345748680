import React from "react";

const FamilyHadder = ({ children }) => {
    return (
        <React.Fragment>
            <div className="main_body_heading pb-0">
                
            </div>
            <div className="main_body_heading pb-0">
                <div className="container refer-earn-container">
                    <div className="mx-3">
                {children}  {/* Render the children passed from Family */}
            </div>
            </div>
            </div>
        </React.Fragment>
    );
};

export default FamilyHadder;