import React, { useState, useEffect } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap";
import { isEmpty } from "lodash";
//SimpleBar
import SimpleBar from "simplebar-react";
//redux
import { useSelector, useDispatch } from "react-redux";

//action
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import { getCart } from "../../store/cart/action";
import Loader from "../../Components/Common/Loader";
import Select from "react-select";
import classnames from "classnames";
import { orderSummary } from "../../common/data/ecommerce";
import { Link } from "react-router-dom";
import active from "../../assets/images/logo/active.png";
import compleate from "../../assets/images/logo/compleate.png";
import PaymentInfo from "./PaymentInfo";
import { getPaymentDetails } from "../../store/checkOut/action";

const EcommerceCheckout = (props) => {
  const { generatedKey, encryptKey } = props;
  const dispatch = useDispatch();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getCart({ client_code: client_code, client_token: client_token }));
  }, [dispatch, client_code, client_token]);
 
  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));
  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));
 // //console.log(Cart);
  const { Checkout } = useSelector((state) => ({
    Checkout: state.PaymentDetails.PaymentDetails,
  }));
console.log(Cart,'Cart');
  const f =
    Cart !== null &&
    Cart.length > 0 &&
    Cart.findIndex((e) => e.feature_or_plan === "Plan");
  const g =
    Cart !== null &&
    Cart.length > 0 &&
    Cart.findIndex((e) => e.feature_or_plan === "Feature");
  const paymentData = {};

  // //console.log(f);
  // //console.log(g);
  if (Cart !== null && Cart.length > 0 && f !== -1) {
    paymentData.client_code = client_code;
    paymentData.client_token = client_token;
    paymentData.master_plan_or_feature_id = Cart[f].feature_or_plan_id;
    paymentData.duration_type = Cart[f].duration_type;
    paymentData.duration = Cart[f].duration;
    paymentData.type = Cart[f].feature_or_plan;
    paymentData.amount = Cart[f].discounted_price;
    ////console.log(paymentData);
  } else if (Cart !== null && Cart.length > 0 && f === -1 ) {
    paymentData.client_code = client_code;
    paymentData.client_token = client_token;
    paymentData.master_plan_or_feature_id = Cart[0].feature_or_plan_id;
    paymentData.duration_type = Cart[0].duration_type;
    paymentData.duration = Cart[0].duration;
    paymentData.type = Cart[0].feature_or_plan;
    paymentData.amount = Cart[0].amount;
    ////console.log(paymentData);
  }
  const cloneCart = Cart !== null && [...Cart];
 // //console.log(cloneCart);
  Cart !== null && f !== -1 ? cloneCart.splice(f, 1) : cloneCart.splice(g, 1);
 // //console.log(cloneCart);
  const addFeature = [];
  cloneCart.forEach((e) => {
    ////console.log(e);
    let fet = {
      id: e.feature_or_plan_id,
      amount: (e.features && e.amount) || (e.plans && e.discounted_price),
      duration: e.duration,
      duration_type: e.duration_type,
    };
    addFeature.push(fet);
  });
  //const t = [];
  // paymentData.additional_features = addFeature;
  ////console.log(paymentData);

  useEffect(() => {
    dispatch(getPaymentDetails(paymentData));
  },[Cart]);

  ////console.log(Checkout);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [activeTab, setactiveTab] = useState(3);
  const [passedSteps, setPassedSteps] = useState([3]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);

  const values = [];
  Cart.length > 0 &&
    Cart.forEach((element) => {
      if (
        element.feature_or_plan === "Plan" &&
        element.discounted_price !== ""
      ) {
        const value = +element.pre_discount;
        values.push(value);
      } else if (element.amount !== "") {
        const value = +element.amount;
        values.push(value);
      } else {
        const value = +element.amount;
        values.push(value);
      }
    });

    let discountValues = 0;
    Cart.length > 0 &&
    Cart.forEach((element) => {
      if (
        element.feature_or_plan === "Plan" &&
        element.discounted_price !== ""
      ) {
        // const value = +element.discount;
        // const value2 = +element.pre_discount - +element.discounted_price;
        // discountValues = value2
        discountValues+= parseFloat(element.pre_discount) - parseFloat(element.discounted_price);
      }
    });

  const subTotal = values.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  // //console.log(subTotal)
  // //console.log(discountValues);
  const total = (subTotal - discountValues);
  ////console.log(total);
  const taxCalculate = total * (18 / 100)
  ////console.log(taxCalculate);
  const finalTotal = total + taxCalculate;
  ////console.log(finalTotal)

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  function handleSelectCountry(selectedCountry) {
    setselectedCountry(selectedCountry);
  }

  function handleSelectState(selectedState) {
    setselectedState(selectedState);
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  //const total = Checkout !== null ? Checkout.gst + Checkout.amount : 0;

  if (Checkout.length === 0) {
    return <Loader />;
  }

 

  return (
    <React.Fragment>
      <Col xl="8">
      <Row className="align-items-center gy-3 mb-3">
          {/* <div className="col-sm">
            <div>
              <h5 className="text-15 text-color1 mb-0">
                Your Cart ({Cart.length} items)
              </h5>
            </div>
          </div> */}
          <div className="d-flex justify-content-end">
            <Link to="/cart" className="link-primary text-decoration-underline">
            Back to Cart
            </Link>
          </div>
        </Row>
      {/* <Link
          type="button"
          className="btn btn-success btn-label shopping-order-btn"
          to=""
        >
          <i className="ri-shopping-cart-line label-icon align-middle fs-16 me-2 shopping-order-i"></i>
          Back to Cart
        </Link> */}
        <Card className="m-0">
          <CardBody className="checkout-tab p-0">
            <Form action="#">
              <div className="step-arrow-nav mt-n3 mx-n3 p-3">
                <Nav
                  className="nav-pills nav-justified custom-nav"
                  role="tablist"
                >
                  {/* <NavItem role="presentation">
                          <NavLink href="#"
                            className={classnames({ active: activeTab === 1, done: (activeTab <= 4 && activeTab >= 0) }, "p-3 fs-15")}
                            onClick={() => { toggleTab(1); }}
                          >
                            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Personal Info
                          </NavLink>
                        </NavItem> */}
                  {/* <NavItem role="presentation">
                          <NavLink href="#"
                            className={classnames({ active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 }, "p-3 fs-15")}
                            onClick={() => { toggleTab(2); }}
                          >
                            <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Shipping Info
                          </NavLink>
                        </NavItem> */}
                  <NavItem role="presentation" style={{ textAlign: 'left'}}>
                    <NavLink
                      href="#"
                      className={classnames(
                        {
                          active: activeTab === 3,
                          done: activeTab <= 4 && activeTab > 2,
                        },
                        "p-3 text-9"
                      )}
                      onClick={() => {
                        toggleTab(3);
                      }}
                    >
                      <i className="ri-wallet-3-line p-2 text-color7 fs-5 text-4 align-middle me-2"></i>
                      Payment Details
                    </NavLink>
                  </NavItem>
                  {/* <NavItem role="presentation">
                    <NavLink
                      href="#"
                      className={classnames(
                        {
                          active: activeTab === 4,
                          done: activeTab <= 4 && activeTab > 3,
                        },
                        "p-3 text-4"
                      )}
                      onClick={() => {
                        toggleTab(4);
                      }}
                    >
                      <i className="ri-checkbox-circle-line fs-5 p-2 align-middle me-2"></i>
                      Purchase Complete
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1} id="pills-bill-info"></TabPane>

                <TabPane tabId={2}></TabPane>

                <TabPane tabId={3}>
                  <PaymentInfo data={availableMargin} total={subTotal.toFixed(2)} gst={taxCalculate.toFixed(2)}   />
                </TabPane>

                <TabPane tabId={4} id="pills-finish">
                  <div className="text-center pt-0 pb-3">
                    <div className="mb-4">
                      <img src={compleate} />
                    </div>
                    <h5>Thank you ! Your Order is Completed !</h5>
                    <p className="text-muted">
                      You will receive an order confirmation email with details
                      of your order.
                    </p>

                    <h3 className="fw-semibold mb-4">
                      Order ID:{" "}
                      <a
                        href="apps-ecommerce-order-details"
                        className="text-decoration-underline"
                      >
                        VZ2451
                      </a>
                    </h3>
                    <button
                      type="button"
                      className="btn btn-primary text-4 w-25 mb-3 margin-overview-button1"
                      style={{ borderRadius: "5px" }}
                    >
                      Back to My Plans
                    </button>
                  </div>
                </TabPane>
              </TabContent>
            </Form>
          </CardBody>
        </Card>
      </Col>

      <Col xl={4} className="margin-top-33">
        <Card>
          <CardHeader>
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">Order Summary</h5>
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <div
              className="d-flex align-items-center text-color7 mb-3 background-color10"
              style={{border: '1px solid #cecccc3d'}}
            >
              <p className="m-0 py-3 px-4 text-18">Item Info</p>
              {/* <p className="m-0 py-3 px-1 text-18">Item Info</p> */}
              <p className="m-0 py-3 px-4 text-18 ms-auto">Price</p>
            </div>

            <React.Fragment>
              <SimpleBar style={{ maxHeight: "380px" }}>
                {Cart.length > 0 &&
                  Cart.map((item, index) => {
                    return (
                      <div
                        className="d-flex px-3 align-items-center custom-padding"
                        key={index}
                      >
                        <div className="avatar-sm order-list-img flex-shrink-0 me-3">
                          <img
                            className="card-invest-logo align-middle"
                            src={
                              (item.features && item.features.logo) ||
                              (item.plans && item.plans.image) ||
                              active
                            }
                            alt=""
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div className="flex-grow-1">
                        <h5 className="text-4 text-color1">
                            {(item.features && item.features.title) ||
                              (item.plans && item.plans.plan_name)}
                          </h5>
                          <p className="text-21 text-color1 mb-0">
                            {item.duration_type}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="d-flex align-items-center gap-1">
                            <h5
                              className="text-color1 text-20 mb-0"
                              //onClick={() => {externalUrlRedirectHandler(item.url)}}
                            >
                              ₹ {" "}
                              {(item.features && item.amount) ||
                                (item.plans && item.pre_discount)}{" "}
                            
                            </h5>
                          </div>
                        </div>
                      </div>
                      // <tr key={index}>
                      //   <td>
                      //     <div className="avatar-sm order-list-img p-1">
                      //       <img
                      //         src={
                      //           (item.features && item.features.logo) ||
                      //           (item.plans && item.plans.image) ||
                      //           active
                      //         }
                      //         alt=""
                      //         className=""
                      //       />
                      //     </div>
                      //   </td>
                      //   <td>
                      //     <h5 className="text-4">
                      //       <a href="/#" className="text-color1">
                      //         {(item.features && item.features.title) ||
                      //           (item.plans && item.plans.plan_name)}
                      //       </a>
                      //     </h5>
                      //     <p className="text-21 text-color1 mb-0">
                      //       {item.duration_type}
                      //     </p>
                      //   </td>
                      //   <td className="text-4 text-color1 text-end">
                      //     Rs{" "}
                      //     {(item.features && item.amount) ||
                      //       (item.plans && item.discounted_price)}{" "}
                      //     /-
                      //   </td>
                      // </tr>
                    );
                  })}
              </SimpleBar>
              {/* <tr>
                      <td>
                        <div className="avatar-sm order-list-img p-1">
                          <img src={active} alt="" className="" />
                        </div>
                      </td>
                      <td>
                        <h5 className="text-4">
                          <a href="/#" className="text-color1">
                            Silver Plan
                          </a>
                        </h5>
                        <p className="text-21 text-color1 mb-0">Monthly plan</p>
                      </td>
                      <td className="text-4 text-color1 text-end">Rs 299/-</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid #E9E9EF" }}>
                      <td>
                        <div className="avatar-sm order-list-img p-1">
                          <img src={active} alt="" className="" />
                        </div>
                      </td>
                      <td>
                        <h5 className="text-4">
                          <a href="/#" className="text-color1">
                            Feature 1
                          </a>
                        </h5>
                        <p className="text-muted mb-0">Quarterly plan</p>
                      </td>
                      <td className="text-4 text-color1 text-end">Rs 399/-</td>
                    </tr> */}
            </React.Fragment>
            
            <div
              className="d-flex justify-content-between align-items-center text-color7 mt-2 pt-2"
              style={{ borderTop: '1px solid #80808042'}}
            >
              <p className="m-0 py-2 px-3 text-20 text-color1">Sub Total</p>
              <p className="m-0 p-2 px-3 text-20 text-color1">{`₹ ${
                      subTotal.toFixed(2)
                    } `}</p>
            </div>
            <div
              className="d-flex justify-content-between align-items-center text-color7 pb-2"
              style={{ borderBottom: '1px solid rgb(128 128 128 / 13%)'}}
            >
              <p className="m-0 p-2 px-3 text-21 text-color1">Discount (-)</p>
              <p className="m-0 p-2 px-3 text-color1 text-20">{`₹ ${
                     discountValues.toFixed(2)
                    } `}</p>
            </div>
            <div
              className="d-flex justify-content-between align-items-center text-color7 pt-2"
            >
              <p className="m-0 p-2 px-3 text-21 text-color1"> Amount</p>
              <p className="m-0 p-2 px-3 text-color1 text-20">{`₹ ${
                     total.toFixed(2)
                    } `}</p>
            </div>
            <div
              className="d-flex justify-content-between align-items-center text-color7 pb-2"
              style={{borderBottom: '1px solid #80808042'}}
            >
              <p className="m-0 p-2 px-3 text-21 text-color1">GST (+18%)</p>
              <p className="m-0 p-2 px-3 text-color1 text-20">{`₹ ${
                      taxCalculate.toFixed(2)
                    } `}</p>
            </div>
            <div
              className="d-flex justify-content-between align-items-center text-color7 mb-2 pt-2"
            >
              <p className="m-0 p-2 px-3 text-20 text-color7">Total Amount</p>
              <p className="m-0 p-2 px-3 text-20 text-color7">{`₹ ${
                      !isEmpty(Checkout) && finalTotal.toFixed(2)
                    } `}</p>
            </div>
            {/* <div className="table-responsive table-card">
              <table className="table table-borderless align-middle mb-0">
                <thead className="table-light text-color7">
                  <tr>
                    <th style={{ width: "90px" }} scope="col">
                      Item
                    </th>
                    <th scope="col">Item Info</th>
                    <th scope="col" className="text-end">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-20 text-color1" colSpan="2">
                      Sub Total :
                    </td>
                    <td className="text-20 text-color1 text-end">{`Rs ${
                      Checkout !== null ? Checkout.amount - Checkout.gst : 0
                    } /-`}</td>
                  </tr>
                  <tr>
                    <td className="text-color1 text-21" colSpan="2">
                      Discount <span className="text-color1 "></span>:{" "}
                    </td>
                    <td className="text-end text-color1  text-4">Rs - 0/-</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-color1 text-21 ">
                      Estimated Tax :
                    </td>
                    <td className="text-end text-4 text-color1 ">{`Rs ${
                      Checkout !== null
                        ? Math.round((Checkout.gst + Number.EPSILON) * 100) /
                          100
                        : 0
                    } /-`}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-20 text-color7">
                      Total (Rupee) :
                    </td>
                    <td className="text-end text-20 text-color7">{`Rs ${
                      !isEmpty(Checkout) && Checkout.amount.toFixed(2)
                    } /-`}</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default EcommerceCheckout;
