import { call, put, takeEvery, all, fork, retry } from "redux-saga/effects";

import { GET_REFERRAL_LINK, GET_REFERRAL_NUMBER, START_LOADING, STOP_LOADING } from "./actionType";

import {
  AddRefferlApiResponseError,
  AddRefferlApiResponseSuccess,
  RefferlLInkApiResponseError,
  RefferlLInkApiResponseSuccess,
} from "./action";

import { postAddReferlNumber } from "../../helpers/fakebackend_helper";

function* AddReferralNumber(data) {
  console.log(data);
  try {
    yield put({ type: START_LOADING });
    const response = yield call(postAddReferlNumber, data.payload);
    console.log("check the data add referral>>",response);
    yield put(AddRefferlApiResponseSuccess(GET_REFERRAL_NUMBER, response));
    yield put({ type: STOP_LOADING });
  } catch (error) {
    yield put({ type: STOP_LOADING });
    yield put(AddRefferlApiResponseError(GET_REFERRAL_NUMBER, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_REFERRAL_NUMBER, AddReferralNumber);
}

function* AddReferralNumberSaga() {
  yield all([fork(watchGetCart)]);
}

export default AddReferralNumberSaga;
