import React, { useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import avatar from "../../assets/images/users/avatar-1.jpg";
const InviteFriend2 = () => {
  return (
    <React.Fragment>
      <Col xxl={6} xl={6} lg={6} className="pe-3">
        <h4 className="text-9 text-color5 mb-3">Latest referrals</h4>
        <Card className="mb-2">
          <CardBody>
            <div
              //to="#"
              className="d-flex "
              //id={"leadInnerDiscovered" + props.index + subitemkey}
            >
              <div className="flex-shrink-0">
                <img
                  src={avatar}
                  alt=""
                  className="avatar-xs"
                  style={{ borderRadius: "5px", width: "48px", height: "48px" }}
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="text-4 text-color1 mb-1">Alexis Clarke</h6>
                <p className="mb-0 text-uppercase text-14 text-color2">
                  <span>
                    <i className="mdi mdi-clock-outline"></i> 4hrs AGO
                  </span>
                </p>
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="text-10 text-color6 mb-1 text-end">+₹300</h6>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardBody>
            <div
              //to="#"
              className="d-flex "
              //id={"leadInnerDiscovered" + props.index + subitemkey}
            >
              <div className="flex-shrink-0">
                <img
                  src={avatar}
                  alt=""
                  className="avatar-xs"
                  style={{ borderRadius: "5px", width: "48px", height: "48px" }}
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="text-4 text-color1 mb-1">Alexis Clarke</h6>
                <p className="mb-0 text-uppercase text-14 text-color2">
                  <span>
                    <i className="mdi mdi-clock-outline"></i> 4hrs AGO
                  </span>
                </p>
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="text-10 text-color6 mb-1 text-end">+₹300</h6>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardBody>
            <div
              //to="#"
              className="d-flex "
              //id={"leadInnerDiscovered" + props.index + subitemkey}
            >
              <div className="flex-shrink-0">
                <img
                  src={avatar}
                  alt=""
                  className="avatar-xs"
                  style={{ borderRadius: "5px", width: "48px", height: "48px" }}
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="text-4 text-color1 mb-1">Alexis Clarke</h6>
                <p className="mb-0 text-uppercase text-14 text-color2">
                  <span>
                    <i className="mdi mdi-clock-outline"></i> 4hrs AGO
                  </span>
                </p>
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="text-10 text-color6 mb-1 text-end">+₹300</h6>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default InviteFriend2;
