import { call, put, takeEvery, all, fork, retry } from "redux-saga/effects";

import { GET_ALL_UNCONVERTED_LEADS, START_LOADING, STOP_LOADING } from "./actionType";

import {
  unconvLeadsApiResponseError,
  unconvLeadsApiResponseSuccess,
} from "./action";

import { postAllUnconvertedLeadsApi } from "../../helpers/fakebackend_helper";

function* allUnconverted(data) {
  try {
    // yield put({ type: START_LOADING });
    const response = yield call(postAllUnconvertedLeadsApi, data.payload);
    yield put(
      unconvLeadsApiResponseSuccess(GET_ALL_UNCONVERTED_LEADS, response)
    );
    // yield put({ type: STOP_LOADING });
  } catch (error) {
    // yield put({ type: STOP_LOADING });
    yield put(unconvLeadsApiResponseError(GET_ALL_UNCONVERTED_LEADS, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_ALL_UNCONVERTED_LEADS, allUnconverted);
}

function* AllUnconvertedLeadsSaga() {
  yield all([fork(watchGetCart)]);
}

export default AllUnconvertedLeadsSaga;
