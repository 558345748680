import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";

//Import Breadcrumb
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";

const AvailableFeatureHeader = () => {
  // document.title = "Shopping Cart | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <Col xs={12} className="d-flex text-center justify-content-center mt-5">
        <div className="width-mobile-50">
          <h4 className="text-2 text-color5 mb-4">Connect with one of our other available plans.</h4>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default AvailableFeatureHeader;
