import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_ENCRYPT_KEY } from "./actionType";

import {
  EncryptKeyApiResponseSuccess,
  EncryptKeyApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getEncryptKeyApi } from "../../helpers/fakebackend_helper";

function* getEncryptKey() {
  try {
    const response = yield call(getEncryptKeyApi);
    yield put(EncryptKeyApiResponseSuccess(GET_ENCRYPT_KEY, response));
  } catch (error) {
    yield put(EncryptKeyApiResponseError(GET_ENCRYPT_KEY, error));
  }
}

export function* watchGetEncryptKey() {
  yield takeEvery(GET_ENCRYPT_KEY, getEncryptKey);
}

function* EncryptKeySaga() {
  yield all([fork(watchGetEncryptKey)]);
}

export default EncryptKeySaga;
