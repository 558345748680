import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  FormFeedback,
} from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";

const ConformUpdate = (props) => {
  const validationSchema = Yup.object().shape({
    condition: Yup.bool().oneOf(
      [true],
      "Please accept the Terms & Conditions to proceed."
    ).required('This field is required'),
  });

  const initialPackage = {
    condition: false,
  };
  const [formValues, setFormValues] = useState(initialPackage);

  const [modal_signUpModals, setmodal_signUpModals] = useState(false);

  const modalMobile = {
    CUpdate: false,
    otp1: false,
    vNumber1: true,
    otp2: false,
    vNumber2: false,
    done: false,
  };

  function onSubmit(values) {
    ////console.log(values);
   // e.preventDefault();
    props.sendData(modalMobile);
  }

  function tog_signUpModals() {
    // //console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(modalMobile);
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
        Mobile Number Modification
        </h4>
      </ModalHeader>
      <ModalBody className="p-4" >
        <Formik
          initialValues={{
            condition: formValues.condition,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values , "updateresponsevalues");
            onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => {
            ////console.log(values);

            return (
              <Form
                noValidate
                autoComplete="off"
                id="my-form"
                className="flex flex-col justify-center w-full "
                style={{flexDirection : "column"}}
              >
                <div className="mb-3 form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="checkTerms"
                    name="condition"
                    value={values.condition}
                    onBlur={handleBlur}
                    error={errors.condition && touched.condition}
                    helperText={touched.condition && errors.condition}
                    onChange={handleChange}
                    checked={values.condition}
                  />
                  <Label
                    className="form-check-label text-15 text-color1 px-3"
                    htmlFor="checkTerms"
                  >
                  I confirm my request to change my mobile number and authorize Bigul (Bonanza Portfolio Ltd.) to use the new number for messages, alerts, and communications as per KYC and client agreement terms. </Label>
                  {errors.condition && (
                    <label
                      className="mt-3 mx-3"
                      style={{ color: "#f44336" }}
                    >
                      <h6 className="fs-12 text-danger">{errors.condition}</h6>
                    </label>
                  )}
                </div>
                {/* <div>
                  <h4 className="text-11 text-color1">Receive OTP on</h4>
                </div> */}
                <div className="d-flex mb-2">
                  {/* <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      defaultChecked
                    />
                    <Label
                      className="form-check-label text-11 text-color5"
                      htmlFor="flexRadioDefault2"
                    >
                      Mobile
                    </Label>
                  </div> */}
                </div>
                <div>
                  <h4 className="text-11 text-color8 text-center">
                  Your new mobile number will take upto 48 hours to reflect on your profile.
                  </h4>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary modal-content-Button my-3"
                    //onClick={(e) => onClickHandler(e)}
                  >
                    Confirm
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default ConformUpdate;
