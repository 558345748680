

import React, { useEffect, useState } from "react";
import { CardBody, Card, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { getFeatureAndPlan } from "../../store/featureAndPlan/action";
import { addToCart } from "../../store/cart/action";
import MyPlan from "../../Components/CustomComponents/Models/myPlan/MyPlan";
import active from "../../assets/images/logo/active.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isMobile } from "react-device-detect";
import MediaQuery from "react-responsive";

const ActiveFeatures1 = () => {
  const dispatch = useDispatch();

  const { myPlan } = useSelector((state) => ({
    myPlan: state.MyPlan.myPlan,
  }));

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");

  useEffect(() => {
    dispatch(
      getFeatureAndPlan({
        client_code: client_code,
        client_token: client_token,
      })
    );
  }, [dispatch, client_token, client_code]);

  const charactersLimit = (data, limit) => {
    if (data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data;
  };
  // console.log("isMobile",isMobile)

  return (
    <React.Fragment>
       <MediaQuery minWidth={768}>
      <Col xl={3} lg={6} sm={6} className="dashboard-widget-bottom-margin ">
        <Card
          className="text-center"
          style={{
            backgroundColor: "#008490",
            margin: 0,
            height: "100%",
          }}
        >
          <CardBody className="p-4 card-height-101 text-center">
            <h2 className="text-12" style={{ color: "white", height: "108px" }}>
              My Active Plan
            </h2>
            <p
              className="card-text text-11"
              style={{ color: "white", height: "98px", paddingRight: "20px" }}
            >
              Unlock enhanced benefits. Choose your plan. Elevate your experience. Click to explore and manage your active subscription plans today!
            </p>
            <div className="mt-4">
              <Link
                to="/subscription/plans"
                className="btn btn-success text-4 active-feature-button"
              >
                Manage my Plan
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
      </MediaQuery>
      
      <MediaQuery maxWidth={767}> 
        <Col xl={3} lg={6} sm={6} className="dashboard-widget-bottom-margin">
        <Card
          className="text-center"
          style={{
            backgroundColor: "#008490",
            margin: 0,
            height: "100%",
          }}
        >
        <CardBody className="p-4 card-height-101 text-center">
          <div className="mt-auto">
            <h2
              className="text-12"
              style={{
                color: "white",
                height: "60px",
                marginBottom: "10px",
              }}
            >
              My Active Plan
            </h2>
            <div
              className="d-flex flex-column align-items-center"
              style={{
                color: "white",
                height: "30px",
                paddingRight: "20px",
              }}
              >
              <p className="card-text text-11 mb-3">
                Unlock enhanced benefits. Choose your plan. Elevate your experience.
              </p>
            </div>
          </div>
        </CardBody>
        </Card>
        </Col>
        <CardBody className="p-4 card-height-101 text-center">
          <div className="mt-auto">
            <Link
              to="/subscription/plans"
              className="btn btn-success text-4 active-feature-button"
              style={{ width: "70%" }}
            >
              Manage my Plan
            </Link>
          </div>
        </CardBody>
      </MediaQuery>

      <Col xl={9} lg={6} sm={6} className="dashboard-widget-bottom-margin">
        {myPlan?.plan && myPlan.plan.length === 1 ? (
            myPlan.plan.map((item, index) =>  (
              <div key={index} >
                <Col xl={4} lg={12} sm={12} className="dashboard-widget-bottom-margin">
                <Card
                  className="pricing-box ribbon-box ribbon-fill text-center"
                  style={{
                    margin: 0,
                    height: "100%",
                  }}
                >
                  {!isEmpty(item) && item.id !== 65 && item.message === "Will be activated within 24 hours" ? <div className="ribbon ribbon-secondary background-color13 shadow-none text-29">
                    Pending
                  </div> : <div className="ribbon ribbon-secondary background-color12 shadow-none text-29">
                    Active
                  </div>}
                  <CardBody className="p-4 card-height-101 text-center">
                    <div
                      className=" rounded-circle"
                      //style={{ backgroundColor: `${item.backGround}` }}
                    >
                      <img
                        className="card-invest-logo"
                        src={
                          (!isEmpty(item) &&
                            item.image) ||
                          active
                        }
                        style={{ width: "55px", height: "55px" }}
                      />
                    </div>
                    <h6 className="text-6 mt-4" style={{ height: "30px" }}>
                    
                      {!isEmpty(item) &&
                        item.plan_name}
                    </h6>

                    <p
                      className="card-text text-color1 text-11 mt-4"
                      style={{ height: "98px" }}
                    >
                      {!isEmpty(item) &&
                        charactersLimit(
                          item.description,
                          120
                        )}
                    </p>
                    {!isEmpty(item) && item.id === 65 ? <div className="">
                      <button
                        className="active-feature-button-normal"
                        style={{ width: "auto", background: "none", cursor: "text" }}
                      >
                        {!isEmpty(item) && item.message}
                      </button>
                    </div> : <div className="">
                      <button
                        className="active-feature-button-normal"
                        style={{ width: "auto", background: "none", cursor: "text" }}
                      >
                        {!isEmpty(item) && item.msg_title ? item.msg_title : ""} {!isEmpty(item) && item.message}
                      </button>
                    </div>}
                  
                  </CardBody>
                </Card>
                </Col>
              </div>
            ))
          ) : (
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={2000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          // infinite={!isMobile || (myPlan?.plan?.length == 1)}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 700,
              },
              items: 4,
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: {
                max: 700,
                min: 0,
              },
              items:1,
              partialVisibilityGutter: 30,
            },
          }}
          rewind
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
        {myPlan?.plan && myPlan.plan.length > 0 ? (
            myPlan.plan.map((item, index) =>  (
              <div key={index} >
                <Col xl={12} lg={12} sm={12} className="dashboard-widget-bottom-margin">
                <Card
                  className="pricing-box ribbon-box ribbon-fill text-center"
                  style={{
                    margin: 0,
                    height: "100%",
                  }}
                >
                  {!isEmpty(item) && item.id !== 65 && item.message === "Will be activated within 24 hours" ? <div className="ribbon ribbon-secondary background-color13 shadow-none text-29">
                    Pending
                  </div> : <div className="ribbon ribbon-secondary background-color12 shadow-none text-29">
                    Active
                  </div>}
                  <CardBody className="p-4 card-height-101 text-center">
                    <div
                      className=" rounded-circle"
                      //style={{ backgroundColor: `${item.backGround}` }}
                    >
                      <img
                        className="card-invest-logo"
                        src={
                          (!isEmpty(item) &&
                            item.image) ||
                          active
                        }
                        style={{ width: "55px", height: "55px" }}
                      />
                    </div>
                    <h6 className="text-6 mt-4" style={{ height: "30px" }}>
                    
                      {!isEmpty(item) &&
                        item.plan_name}
                    </h6>

                    <p
                      className="card-text text-color1 text-11 mt-4"
                      style={{ height: "98px" }}
                    >
                      {!isEmpty(item) &&
                        charactersLimit(
                          item.description,
                          120
                        )}
                    </p>
                    {!isEmpty(item) && item.id === 65 ? <div className="">
                      <button
                        className="active-feature-button-normal"
                        style={{ width: "auto", background: "none", cursor: "text" }}
                      >
                        {!isEmpty(item) && item.message}
                      </button>
                    </div> : <div className="">
                      <button
                        className="active-feature-button-normal"
                        style={{ width: "auto", background: "none", cursor: "text" }}
                      >
                        {!isEmpty(item) && item.msg_title ? item.msg_title : ""} {!isEmpty(item) && item.message}
                      </button>
                    </div>}
                  
                  </CardBody>
                </Card>
                </Col>
              </div>
            ))
          ) : (
            <div className="loading" style={{
              textAlign: "center",
              padding: "70px",
              fontSize: "larger",
            }}>Loading....</div>
          )}
        </Carousel>
        )}
        </Col>
    </React.Fragment>
  );
};

export default ActiveFeatures1;

