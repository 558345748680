import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_TRADE_REFER_SUMMARY,
  GET_TRADE_REFER_SUM,
  START_LOADING,
  STOP_LOADING,
} from "./actionType";

import {
  tradeReferApiResponseSuccess,
  tradeReferApiResponseError,
} from "./action";

import { getTradeReferSummaryApi } from "../../helpers/fakebackend_helper";

function* getReferDetail(data) {
  try {
    yield put({ type: START_LOADING });
    const response = yield call(getTradeReferSummaryApi, data.payload);
    yield put(tradeReferApiResponseSuccess(GET_TRADE_REFER_SUMMARY, response));
    yield put({ type: STOP_LOADING });
  } catch (error) {
    yield put(tradeReferApiResponseError(GET_TRADE_REFER_SUMMARY, error));
  }
}

function* getReferCount(data) {
  try {
    yield put({ type: START_LOADING });
    const response = yield call(getTradeReferSummaryApi, data.payload);

    yield put(tradeReferApiResponseSuccess(GET_TRADE_REFER_SUM, response));
    yield put({ type: STOP_LOADING });
  } catch (error) {
    yield put(tradeReferApiResponseError(GET_TRADE_REFER_SUM, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_TRADE_REFER_SUMMARY, getReferDetail);
  yield takeEvery(GET_TRADE_REFER_SUM, getReferCount);
}

function* TradeReferSummarySaga() {
  yield all([fork(watchGetCart)]);
}

export default TradeReferSummarySaga;
