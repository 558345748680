import React from "react";
import { Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";
import MyPlansHeader from "./MyPlansHeader";
import SubscriptionPlanHistoryTable from "./SubscriptionPlanHistory";

const MyPlanHistory = () => {
  document.title = "Bigul Admin & Dashboard";

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Plan History"
            titleMain="My Plan History"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />
          <Row>
            <MyPlansHeader />
            {/* <NeedHelp /> */}
          </Row>
          <Row>
            <SubscriptionPlanHistoryTable />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyPlanHistory;
