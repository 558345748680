import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConfirmChanges1 from './ConfirmChanges1';
import ConfirmChanges2 from './ConfirmChanges2';
// TODO Ui and design and functions.
const BankDetailsModelIndex = (props) => {
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [modalAdditional, setModelAdditional] = useState({
    CChange1: true,
    CChange2: false,
  });

  const sendData = (data) => {
    ////console.log(data);
    //e.preventDefault();
    setModelAdditional(data)
  }

  const tog_signUpModalsFinal = (data) => {
    // //console.log('submit')
    setmodal_signUpModals(!modal_signUpModals);
  }
 
  const tog_signUpModals = () => {
    // //console.log('submdsdsdsdsdit')
    setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        className="btn btn-primary btn-sm text-5 me-3 additional-page-button-save"
        type="button"
        onClick={() => tog_signUpModals()}
      >
        Save
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {modalAdditional.CChange1 && <ConfirmChanges1 sendData={sendData} tog={tog_signUpModals} />}
        {modalAdditional.CChange2 && <ConfirmChanges2 sendData={tog_signUpModalsFinal} tog={tog_signUpModals} />}
      </Modal>
    </React.Fragment>
  );
};

export default BankDetailsModelIndex;
