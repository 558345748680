import React, { useState, useEffect } from "react";
import { Col, Row, Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { SymphonyWithdrawalBalance, checkModificationStatus, esignPayment, mobileNumberUpdate_new, mobileNumberUpdate_new2, modificationEsign, modification_profile } from "../../helpers/fakebackend_helper";
import axios from "axios";
import { toast } from "react-toastify";
import { CHECKDIGIO_STATUS, MOD_EKYC_NEW, OPEN_DIGILOKCKER, redirectApp } from "../../helpers/url_helper";
import Encrypted from "../Dashboard/Encripted";
import Loader from "../../Components/Common/Loader";
import SelfieCapture from "./Nominee/SelfieCapture";

var amount = 0
const AddressDetails = (props) => {

    const { data, Balance } = props;
    const client_code = localStorage.getItem("client_id");
    const [check, setCheck] = useState(false);
    const [modal_signUpModals, setmodal_signUpModals] = useState(false);
    const [digioData, setDigioAddress] = useState('');
    const [digio, setDigio] = useState({});
    const [modificationAddress, setModificationAddress] = useState({});
    const [modificationAddress1, setModificationAddress1] = useState([]);
    const [EsignModal, setEsignModal] = useState(false);
    const [EsignModal1, setEsignModal1] = useState(false);
    const [deseble, setDeseble] = useState(false);
    const [balance, setBalance] = useState(false);
    const [shouldContinueApi, setShouldContinueApi] = useState(false);
    const [digiCheckStatus, setDigiocheckStatus] = useState(false);
    const client_token = localStorage.getItem("client_token");
    const [loading, setLoading] = useState(false)
    const [imageSrc, setImageSrc] = useState(null);
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const incomeammount = data[0]?.NINCOME

    const handleImageCapture = (capturedImage) => {
        setImageSrc(capturedImage);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setLong(capturedImage == null ? null : longitude)
                setLat(capturedImage == null ? null : latitude)


            },
            (error) => {
                console.error('Error getting geolocation: ', error);
                toast.error('Unable to get geolocation.');
            }
        );
    };
    useEffect(() => {
        setShouldContinueApi(false)
        setDigiocheckStatus(false)
    }, [props.activeTab === "6"])

    function getNewValueByModifyKey(key) {
        // Find the object where modify_key matches the provided key
        const item = modificationAddress1?.find(entry => entry?.modify_key === key);

        // Return the new_value if found, otherwise return null or a default value
        return item ? item?.new_value : null;
    }

    useEffect(() => {

        let intervalId;

        const callApi = () => {
            checkModificationStatus({
                client_code: client_code,
                entry_type: 'address',

            })
                .then((response) => {
                    if (response.status == true && response?.data?.modification?.length > 0) {
                        if (response?.data?.modification[0]?.is_esign == 1) {
                            setModificationAddress(response?.data?.modification[0])
                            setModificationAddress1(response?.data?.modification)
                            setShouldContinueApi(false)

                        }

                    } else {
                        toast('something went wrong')
                        // setShouldContinueApi(false)
                    }
                })
                .catch((err) => {
                    toast('something went wrong')

                    // setShouldContinueApi(false)
                });


        };

        if (props.activeTab === "6" && shouldContinueApi) {
            intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
        }

        return () => {
            clearInterval(intervalId); // Cleanup on unmount or condition change
        };
    }, [shouldContinueApi]);

    useEffect(() => {


        // setDigioAddress('')
        let intervalId;
        const checkStatus = () => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${symphony_token}`
            };

            const payload = {
                client_code: client_code
            }


            axios.post(CHECKDIGIO_STATUS, { 'enc': Encrypted([payload]) }, { headers: headers })
                .then(response => {
                    if (response.status == true) {
                        if (response?.digio_kyc_status == 1) {
                            setDigioAddress(response?.data.actions[0]?.details?.aadhaar?.permanent_address);
                            setDigio(response?.data);

                            setDigiocheckStatus(false)
                            setmodal_signUpModals(true)
                        }

                    }

                })
                .catch(error => {
                    console.error('Status Check Error:', error);
                });
        };

        if (props.activeTab === "6" && digiCheckStatus) {
            intervalId = setInterval(checkStatus, 20000); // Call API every 10 seconds
        }

        return () => {
            clearInterval(intervalId); // Cleanup on unmount or condition change
        };
    }, [digiCheckStatus, props.activeTab === "6"]);


    useEffect(() => {

        // setShouldContinueApi(false)
        setCheck(false);
        if (props.activeTab === "6") {
            checkModificationStatus({
                client_code: client_code,
                entry_type: "address",
            })
                .then((response) => {
                    if (response.status === true && response?.data?.modification?.length > 0) {
                        setModificationAddress(response?.data?.modification[0])
                        setModificationAddress1(response?.data?.modification)

                    } else {
                        setModificationAddress({})
                        setModificationAddress1([])

                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [modal_signUpModals, EsignModal]);
    useEffect(() => {
        setLoading(true)
        // setShouldContinueApi(false)
        setCheck(false);
        if (props.activeTab === "6") {
            checkModificationStatus({
                client_code: client_code,
                entry_type: "address",
            })
                .then((response) => {
                    setLoading(false)

                    if (response.status === true && response?.data?.modification?.length > 0) {
                        setModificationAddress(response?.data?.modification[0])
                        setModificationAddress1(response?.data?.modification)

                    } else {
                        setModificationAddress({})
                        setModificationAddress1([])

                    }
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false)

                });
        }
    }, [props.activeTab === "6"]);

    const tog_signUpModals = () => {
        setmodal_signUpModals(!modal_signUpModals);
        setDigiocheckStatus(false)
        setImageSrc(null)
    };

    const handleRefetchAddress = () => {
        setDigiocheckStatus(false)

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${symphony_token}` // Assuming token is stored in localStorage
        };

        const payload = {
            client_code: client_code,

        }


        axios.post(CHECKDIGIO_STATUS, { 'enc': Encrypted([payload]) }, { headers: headers })
            .then(response => {
                setEsignModal1(false)
                if (response?.status == true) {

                    if (response?.digio_kyc_status == 1) {
                        setmodal_signUpModals(true)
                        setDigioAddress(response?.data.actions[0]?.details?.aadhaar?.permanent_address);
                        setDigio(response?.data);
                    }
                    else {
                        axios.post(OPEN_DIGILOKCKER, { 'enc': Encrypted([payload]) }, { headers: headers })
                            .then(res => {
                                if (res?.status == true) {
                                    window.open(res?.link, '_blank');
                                    setDigiocheckStatus(true)
                                }
                                else {
                                    toast(res?.message)
                                }
                            })
                            .catch(error => {
                                toast(error?.message || 'something went wrong')

                            });
                    }

                } else {
                    toast(response?.message || 'something went wrong')
                }

            })
            .catch(error => {
                toast(error?.message || 'something went wrong')
            });
    };



    const UpdateAddressApi = () => {
        setDigiocheckStatus(false)
        var address = digioData;
        const pattern = /[^a-zA-Z0-9\/, ]/gu;
        const address1 = address?.replace(pattern, '');
        let remainingAddress = address1;
        const addressParts = [];
        while (remainingAddress?.length > 45) {
            let breakPoint = false;
            for (let i = 44; i >= 0; i--) {
                if (remainingAddress[i] === ',' || remainingAddress[i] === ' ') {
                    breakPoint = i;
                    break;
                }
            }
            if (breakPoint === false) {
                breakPoint = 45;
            }

            addressParts?.push(remainingAddress?.substring(0, breakPoint));
            remainingAddress = remainingAddress?.substring(breakPoint + 1);
        }
        const fullDetails = digio?.actions[0]?.details?.aadhaar?.current_address_details
        addressParts.push(remainingAddress);
        const payload = {
            client_code: client_code,
            entry_type: 'address',
            old: JSON.stringify({
                cr_addr_1: data[0]?.ADDRESS1.toUpperCase(),
                cr_addr_2: data[0]?.ADDRESS2.toUpperCase(),
                cr_addr_3: data[0]?.ADDRESS3.toUpperCase(),
                cr_state: data[0]?.CSTATE.toUpperCase(),
                cr_city: data[0]?.CITY.toUpperCase(),
                cr_pincode: data[0]?.PINCODE,
            }),
            new: JSON.stringify({
                cr_addr_1: addressParts[0].toUpperCase(),
                cr_addr_2: addressParts[1].toUpperCase(),
                cr_addr_3: addressParts[2].toUpperCase(),
                cr_state: fullDetails?.state.toUpperCase(),
                cr_city: fullDetails?.district_or_city.toUpperCase(),
                cr_pincode: fullDetails?.pincode,
            }),
            modify_key: JSON.stringify([
                'cr_addr_1',
                'cr_addr_2',
                'cr_addr_3',
                'cr_state',
                'cr_city',
                'cr_pincode',
            ]),
            doc_type: 'DIGIO',
            doc_json: JSON.stringify(digio),
            doc_id: digio?.id
        };

        axios.post(MOD_EKYC_NEW + '/update-modification', { 'enc': Encrypted([payload]) }, {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer your_token', // Add any custom headers here
            },
        })

            .then(response => {
                if (response.status) {
                    setmodal_signUpModals(false)
                    setEsignModal(true)


                } else {
                    toast('something went wrong')
                }

            })
            .catch(error => {

            });


    }
    const symphony_token = localStorage.getItem("symphony_token");

    const handleEsign = (e) => {
        setEsignModal(true)
        setBalance(false)
    }

    const toggle = () => {
        setEsignModal(!EsignModal)
    };
    const toggle1 = () => {
        setEsignModal1(!EsignModal1)
    };

    // const handlepay = () => {
    //     esignPayment({
    //         enc_data: amount > 0 ? `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent(25 - amount)}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}` : `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent((Math.abs(amount) + 25))}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}`

    //         // enc_data: amount > 0 ? `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent(50)}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}` : `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent((Math.abs(amount) + 50))}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}`
    //     })
    //         .then((response) => {
    //             if (response?.message == "Data Encrypted") {
    //                 window.location.href = 'https://payment.bigul.app/?enc=' + response?.enc_data;

    //             }
    //             else {
    //                 toast('data not found')
    //             }
    //         }).
    //         catch((error) => {
    //             toast('something went wrong')
    //         })
    // }

    const handlepay = () => {
        // Create the payload object with necessary parameters
        const payload = {
            ClientCode: client_code,
            amount: Balance > 0 ? (25 - Balance) : (Math.abs(Balance) + 25),
            redirectUrl: redirectApp,
        };

        // Encrypt the payload using the Encripted function
        const encryptedPayload = Encrypted([payload]);

        // Redirect to the payment URL with the encrypted payload
        window.location.href = 'https://payment.bigul.co/v1/?enc=' + encryptedPayload;
    };

    const handleEsignConfirm = () => {

        setBalance(false)
        if (isNaN(Balance)) {
            toast.error('Unable to fetch your ledger balance. Please try after some time.');
            return;
        }
        if (Balance < 25) {
            setBalance(true)
        }
        else {
            setDeseble(true)
            modificationEsign({
                client_code: client_code,
                entry_type: 'address',
            })
                .then((response) => {
                    if (response?.status) {
                        setDeseble(false)
                        window.open(`${response?.redirectUrl}`, "_blank");
                        setShouldContinueApi(true)
                        setEsignModal(false)
                    }
                    else {
                        toast(response.message)
                        setDeseble(false)
                    }
                })
                .catch((err) => {
                    setDeseble(false)

                    toast('something went wrong')
                });

        }


    }

    const handleCancel = async () => {
        setLoading(true)
        try {
            const payload = {
                client_code: client_code,
                entry_type: 'address'
            }; // Your POST data here
            const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', { 'enc': Encrypted([payload]) });
            if (response?.status == true) {


                setEsignModal(false)
                setBalance(false)
                setShouldContinueApi(false)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);

            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 1000);

                toast.error('something went wrong please try again')
            }
        } catch (error) {
            setLoading(false)

            toast.error('something went wrong please try again')
        }
    }
    const handleOpenModal = () => {
        if (incomeammount <= 0) {
            toast.error('To complete your requested modification, we need your most recent annual income information. Please update your profile with your current income details. Once your income is verified and updated in your account, you can proceed with your modification.')
            return
        }
        else {
            setEsignModal1(true)
        }

    }

    if (loading) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <Modal isOpen={EsignModal1} toggle={toggle1} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',

            }} >
                <ModalHeader style={{ textAlign: 'center', marginBottom: '20px' }} toggle={toggle1} >I hereby, provide my consent to change my permanent and current address.</ModalHeader>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle1}>No</Button>
                    <Button color="primary" onClick={() => handleRefetchAddress()}>Yes</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={EsignModal} toggle={toggle} >
                <ModalHeader toggle={toggle} >{balance ? 'Proceed to Pay' : 'Proceed to Esign'}</ModalHeader>
                <ModalBody>
                    {
                        balance ?
                            <h4 className="text-9 text-color5 mt-3 text-center">
                                The amount available in your ledger balance is not not sufficient for this transaction.
                            </h4>
                            :
                            <h4 className="text-9 text-color5 mt-3 text-center">
                                Please proceed to E-sign to confirm your request for modification. Rs. 25 (including GST) charge will be applicable for modification. The amount will be deducted from your ledger balance.
                            </h4>
                    }
                </ModalBody>
                <div className="d-flex justify-content-center">
                    {
                        balance ?
                            <>
                                <button
                                    type="button"
                                    className="btn btn-primary modal-content-Button my-3"
                                    onClick={handlepay}
                                >
                                    {" "}
                                    Proceed to pay
                                </button>
                                <button
                                    type="button"
                                    style={{
                                        marginLeft: '10px',
                                        backgroundColor: 'gray',
                                        color: 'white',
                                        border: '1px solid gray',
                                        padding: '10px 20px',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                    className="btn btn-primary modal-content-Button my-3"
                                    onClick={handleCancel}
                                >
                                    Cancel Request
                                </button>
                            </>
                            :
                            <>
                                <button
                                    type="button"
                                    style={{ marginLeft: '10px' }}
                                    className="btn btn-primary modal-content-Button my-3"
                                    onClick={handleEsignConfirm}
                                    disabled={deseble}

                                >
                                    {" "}
                                    {deseble ? 'please wait...' : 'Proceed to eSign'}

                                </button>
                                <button
                                    type="button"
                                    style={{
                                        marginLeft: '10px',
                                        backgroundColor: 'gray',
                                        color: 'white',
                                        border: '1px solid gray',
                                        padding: '10px 20px',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                    className="btn btn-primary modal-content-Button my-3"
                                    onClick={handleCancel}
                                >
                                    Cancel Request
                                </button>
                            </>
                    }
                </div>
            </Modal>
            <h4 className="text-9 text-color5 m-0">Address Details</h4>
            <Row className="nominee-details mt-3 mx-0 py-4">
                <Col lg={9}>
                    <h2 className="text-11 text-color9 mb-2">Address </h2>
                    <h4 className="text-4 text-color1">{getNewValueByModifyKey('cr_addr_1') || data[0]?.ADDRESS1}{getNewValueByModifyKey('cr_addr_2') || data[0]?.ADDRESS2}{getNewValueByModifyKey('cr_addr_3') || data[0]?.ADDRESS3}</h4>
                </Col>
                {/* <Col lg={3}>
                    <h2 className="text-11 text-color9">Address 2</h2>
                    <h4 className="text-4 text-color1">{getNewValueByModifyKey('cr_addr_2') || data[0]?.ADDRESS2}</h4>
                </Col> */}
                {/* <Col lg={3}>
                    <h2 className="text-11 text-color9">Address 3</h2>
                    <h4 className="text-4 text-color1">{getNewValueByModifyKey('cr_addr_3') || data[0]?.ADDRESS3}</h4>
                </Col> */}
                <Col lg={3}>
                    <h2 className="text-11 text-color9 mb-2">Status</h2>
                    {modificationAddress?.is_esign == 0 ? (
                        <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            className="btn btn-primary modal-content-Button"
                            onClick={() => handleEsign()}
                        >
                            {" "}
                            Esign Pending
                        </button>
                    ) :
                        modificationAddress?.is_send_to_ld == 1 ?
                            <p style={{ color: 'green' }}>In progress</p> :
                            modificationAddress?.is_esign == 1 ?
                                (
                                    <p className="text-5 text-color10 m-0"

                                    >Pending verification</p>
                                )
                                :

                                (

                                    <button
                                        className="text-11 text-color7 profile-edit-button1"
                                        // onClick={handleRefetchAddress}
                                        onClick={() => { handleOpenModal() }}

                                    >
                                        {
                                            digiCheckStatus ? null : 'Edit'
                                        }

                                    </button>
                                )}
                </Col>
            </Row>
            <Modal
                id="signupModals"
                tabIndex="-1"
                isOpen={modal_signUpModals}
                toggle={tog_signUpModals}
                centered
            >
                <ModalHeader toggle={tog_signUpModals}>
                    Are you sure update your address
                </ModalHeader>
                <ModalBody>

                    {
                        digioData?.length > 0 &&
                        <>
                            <p>{digioData}</p>
                            <SelfieCapture onCapture={handleImageCapture} />
                            {
                                imageSrc !== null &&
                                <Button style={{ marginTop: '10px', justifyContent: 'center' }} color="secondary" onClick={UpdateAddressApi}>
                                    Confirm
                                </Button>
                            }

                        </>

                    }

                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default AddressDetails;
