import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

//Dashboard components
import EcommerceCheckout from './EcommerceCheckout';
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";


const OrderSummary = () => {
  const navigate = useNavigate();
  const { VerifyOtpData } = useSelector((state) => ({
    VerifyOtpData: state.VerifyOTP.oderDetails,
  }));
  // useEffect(() => {
  //   if (VerifyOtpData.length === 0) {
  //     return navigate("/dashboard");
  //   }
  // },[VerifyOtpData]);
  ////console.log(VerifyOtpData);
  document.title = "Bigul Admin & Dashboard";
  const generatedKey  = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Payment Details"
            titleMain="ORDER SUMMARY"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />

          <Row>

            <EcommerceCheckout  generatedKey={generatedKey} encryptKey={encryptKey} data={VerifyOtpData} />
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderSummary;
