import React, { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { LOGOUT_APP } from "../helpers/url_helper";

const AuthProtected = (props) => {
  const [counter, setCounter] = useState(1);
  var client_code = localStorage.getItem('client_id');
  var client_token = localStorage.getItem('client_token');
  var client_type = localStorage.getItem('client_type');
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  const fullUrl = window.location.href;
const changeFavicon = (faviconUrl) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.rel = 'icon';
    link.href = faviconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  useEffect(() => {
    const faviconUrl = client_type == 'Bonanza'
      ? './bonanza/favicon.ico'
      : './favicon.ico';

    changeFavicon(faviconUrl);
  }, [client_type]);

  function Encryptclcode(strUserId) {
    let ch = '';
    for (let i = 0; i < strUserId.length; i++) {
      let baSourceData = strUserId.charCodeAt(i);
      let a = baSourceData;
      let b = 111;
      let orComputed = a ^ b;
      ch += ToHexadecimal(orComputed);
    }
    return ch;
  }

  function ToHexadecimal(n) {
    if (n === 0) {
      return '';
    } else {
      let r = n % 16;
      n = Math.floor(n / 16);
      let result = ToHexadecimal(n);
      switch (r) {
        case 10:
          result += 'A';
          break;
        case 11:
          result += 'B';
          break;
        case 12:
          result += 'C';
          break;
        case 13:
          result += 'D';
          break;
        case 14:
          result += 'E';
          break;
        case 15:
          result += 'F';
          break;
        default:
          result += r;
          break;
      }
      return result;
    }
  }

  const search = useLocation().search;
  var client_code_new = new URLSearchParams(search).get('client_id');

  console.log(search, 'search');

  ;

  if (client_code_new == null) {

  } else {
    localStorage.clear();
    let cleanedQueryString = search.slice(1);
    var client_code_new = new URLSearchParams(search).get('client_id');
    var client_token_new = new URLSearchParams(search).get('token');
    const symphony_token = new URLSearchParams(search).get('symphony_token');
    const expire = new URLSearchParams(search).get('expires');
    const refreshToken = new URLSearchParams(search).get('refreshToken');
    const terminal_code = new URLSearchParams(search).get('terminal_code');
    const client_type = new URLSearchParams(search).get('client_type');

    if (client_code_new == undefined || client_code_new == 'NULL' || client_code_new == "" || client_token_new == undefined || client_token_new == 'NULL' || client_token_new == "") {
      var ii = 0;
    }
    else {
      let decodedStringAtoB = atob(client_code_new);
      let decodedStringAtoc = atob(client_token_new);
      let decodedStringAtod = atob(symphony_token);
      let decodedStringAtotype = atob(client_type);
      // axios.get('https://client-dasboardapis.bigul.co/api/urldataenc?' + 'client_id=' + decodedStringAtoB + '&data=' + cleanedQueryString)
      localStorage.setItem('client_id', decodedStringAtoB);
      localStorage.setItem('client_token', decodedStringAtotype == 'Bonanza' ? client_token_new : decodedStringAtoc);
      localStorage.setItem('symphony_token', decodedStringAtod);
      localStorage.setItem('expires', expire);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('terminal_code', terminal_code);
      localStorage.setItem('client_type', decodedStringAtotype == "Bonanza" ? 'Bonanza' : 'Bigul');
      const encryptedClientId = Encryptclcode(decodedStringAtoB);
      localStorage.setItem('encryptKey', (JSON.stringify(encryptedClientId)));
      client_code = decodedStringAtoB;
      client_token = decodedStringAtoc;
    }
  }

  useEffect(() => {
    counter > 0 &&
      setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setTimeout(function () {
        if (profileDetails.length > 0) {
          var get_user_datat = profileDetails[0].OOWNCODE;
          if (client_code != get_user_datat) {
            localStorage.setItem('client_id', get_user_datat);
          }
        }
      }, 1500);
      if (client_token === null || client_token === "" || client_token === undefined || client_code === null || client_code === "" || client_code === undefined) {
        //app
        // const redirectUrl = fullUrl.includes('https://dashboard-bonanza.bigulapp.in')
        //   ? 'https://bigulapp.in/bonanza/sso-login'+`?redirect_back=${fullUrl}`
        // : `${LOGOUT_APP}?redirect_back=${fullUrl}`;
        //   console.log(redirectUrl,'redirectUrl');

        // Open the determined URL in the same window
//live
        const redirectUrl = fullUrl.includes('https://dashboard.bonanzaonline.com')
        ? 'https://bigul.co/bonanza/sso-login'+`?redirect_back=${fullUrl}`
        : `${LOGOUT_APP}?redirect_back=${fullUrl}`;
        console.log(redirectUrl,'redirectUrl');
        window.location.replace(redirectUrl);

      }
    }
  }, [counter]);

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };

