import {
  VERIFY_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_RESET
} from "./actionType";


// Add to Cart success
export const VerifyOtpApiResponseSuccess = (actionType, data) => {
  // //console.log(data)
  // //console.log(actionType)
  return ({
  type: VERIFY_OTP_SUCCESS,
  payload: {data},
})};
// Add to Cart error
export const VerifyOtpApiResponseError = (actionType, error) => ({
  type: VERIFY_OTP_FAIL,
  payload: { actionType, error },
});
//Add to Cart
export const VerifyOtp = (data,callback) => {
    ////console.log(data)
  return ({
  type: VERIFY_OTP,
  data,
  callback
})};

export const VerifyOtpStateReset = () => {
  ////console.log('HELLO');
  return ({
  type: VERIFY_OTP_RESET,
})};

