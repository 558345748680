import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";
import total_invested from "../../assets/images/logo/total_invested.png";

const Widget3 = (props) => {
  const { item, bgColor, bg, data, data2 } = props;
  ////console.log(data2)
  return (
    <Col xl={3} lg={6} md={6}>
      <Card className="card-invest-algo">
        <CardBody>
          <div className="d-flex align-items-center mb-2">
            <div className="flex-grow-1">
              <p className={"text-uppercase text-1 mb-0 text-color1"}>
                {item.label}
              </p>
            </div>
            {item.percentage && bgColor && data2.success === 1 && <div className="flex-shrink-0 align-self-end">
            <span className={`badge text-3 ${bgColor}`} style={{ borderRadius: '5px' }}>
                <i className={"align-middle me-1 " + item.badge}></i>{" "}
                {data2['Profif&loss_percentage']}
              </span>
            </div>}
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-grow-1">
              <h4 className="mb-0">
                {" "}
                <CountUp
                  start={0}
                  end={data}
                  decimals={item.decimal}
                  separator={item.separator}
                  prefix={item.prefix}
                  duration={3}
                  className="text-2 text-color1"
                />
              </h4>
            </div>
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title bg-light text-primary rounded-circle fs-1">
                {/* <i className={"align-middle " + item.icon}></i> */}
                <div
                  className={"card-invest-div-logo rounded-circle align-middle " + bg}
                  // style={{ backgroundColor: `${item.backGround}` }}
                >
                  <img
                    className="card-invest-logo align-middle"
                    src={item.img}
                  />
                </div>
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Widget3;
