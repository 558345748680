import {
  API_GET_CART_LIST_RESPONSE_SUCCESS,
  API_GET_CART_LIST_RESPONSE_ERROR,
  GET_GET_CART_LIST,
  ADD_ITEM_TO_CART_FAIL,
  ADD_ITEM_TO_CART_SUCCESS,
  REMOVE_ITEM_TO_CART_SUCCESS,
  REMOVE_ITEM_TO_CART_FAIL,
  UPDATE_ITEM_TO_CART,
  UPDATE_ITEM_TO_CART_SUCCESS,
  UPDATE_ITEM_TO_CART_FAIL,
} from "./actionType";

const INIT_STATE = {
  Cart: [],
};

const Cart = (state = INIT_STATE, action) => {
  ////console.log(action.payload);
  switch (action.type) {
    case API_GET_CART_LIST_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_GET_CART_LIST:
          return {
            ...state,
            Cart:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_GET_CART_LIST_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_GET_CART_LIST:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_GET_CART_LIST: {
      return {
        ...state,
      };
    }
    // add to cart
    case ADD_ITEM_TO_CART_SUCCESS:
      return {
        ...state,
        isAddToCart: true,
        Cart: [...state.Cart, action.payload.actionType.data],
      };

    case ADD_ITEM_TO_CART_FAIL:
      return {
        ...state,
        isAddToCart: false,
        error: action.payload,
      };
    // Remove to cart
    case REMOVE_ITEM_TO_CART_SUCCESS:
      return {
        ...state,
        isRemoveToCart: true,
        Cart: state.Cart.filter((Item) => {
          return Item.id !== action.payload.data.cart_id;
        }),
      };
    case REMOVE_ITEM_TO_CART_FAIL:
      return {
        ...state,
        isRemoveToCart: false,
        error: action.payload,
      };
    // Update to cart
    case UPDATE_ITEM_TO_CART_SUCCESS: {
      return {
        ...state,
        Cart: state.Cart.map((Item) => {
          // //console.log(Item)
          // //console.log(action.payload.data)
          if (
            Item.id === action.payload.data.data.id &&
            Item.feature_or_plan === "Plan"
          ) {
            Item["duration_type"] = action.payload.data.data.duration_type;
            Item["discounted_price"] =
              action.payload.data.data.discounted_price;
            Item["duration"] = action.payload.data.data.duration;
            Item["discount"] = action.payload.data.data.discount;
            Item["pre_discount"] = action.payload.data.data.pre_discount;
          } else if (
            Item.id === action.payload.data.data.id &&
            Item.feature_or_plan === "Feature"
          ) {
            ////console.log(action.payload.data)
            Item["duration_type"] = action.payload.data.data.duration_type;
            Item["amount"] = action.payload.data.data.amount;
            Item["duration"] = action.payload.data.data.duration;
          }
          return Item;
        }),
      };
    }
    case UPDATE_ITEM_TO_CART_FAIL:
      return {
        ...state,
        isRemoveToCart: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Cart;
