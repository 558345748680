import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_GET_CART_LIST, ADD_ITEM_TO_CART, REMOVE_ITEM_TO_CART, UPDATE_ITEM_TO_CART } from "./actionType";

import {
  cartApiResponseSuccess,
  cartApiResponseError,
  addToCartApiResponseSuccess,
  addToCartApiResponseError,
  removeToCartApiResponseSuccess,
  removeToCartApiResponseError,
  UpdateCartApiResponseSuccess,
  UpdateCartApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getCartApi, addItemToCartApi, removeItemToCartApi, UpdateItemToCartApi } from "../../helpers/fakebackend_helper";

function* getCart(data) {
    ////console.log(data);
  try {
    const response = yield call(getCartApi,data.data);
    yield put(cartApiResponseSuccess(GET_GET_CART_LIST, response));
  } catch (error) {
    yield put(cartApiResponseError(GET_GET_CART_LIST, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_GET_CART_LIST, getCart);
}

// add to cart
function* onAddItemToCart(data) {
  try {
      const response = yield call(addItemToCartApi,data.data);
      ////console.log(response)
      if(response.status === true) {
       yield put(addToCartApiResponseSuccess(response));
        toast.success("Item added successfully", { autoClose: 1500 });
      }
  } catch (error) {
      yield put(addToCartApiResponseError(error));
      //toast.error("Item added Failed", { autoClose: 1500 });
  }
}

export function* watchAddItemToCart() {
  yield takeEvery(ADD_ITEM_TO_CART, onAddItemToCart);
}

// Remove to cart
function* onRemoveItemToCart(data) {
  try {
      const response = yield call(removeItemToCartApi,data.data);
      yield put(removeToCartApiResponseSuccess(data.data));
      //toast.success("Item removed successfully", { autoClose: 1500 });
  } catch (error) {
      yield put(removeToCartApiResponseError(error));
      //toast.error("Item removed Failed", { autoClose: 1500 });
  }
}

export function* watchRemoveItemToCart() {
  yield takeEvery(REMOVE_ITEM_TO_CART, onRemoveItemToCart);
}

// Update to cart
function* UpdateItemToCart(data) {
  ////console.log(data)
  try {
      const response = yield call(UpdateItemToCartApi,data);
      ////console.log(response)
      yield put(UpdateCartApiResponseSuccess(response));
      //toast.success("Item updated successfully", { autoClose: 1500 });
  } catch (error) {
      ////console.log(error)
      yield put(UpdateCartApiResponseError(error));
     //toast.error("Item updated Failed", { autoClose: 1500 });
  }
}

export function* watchUpdateItemToCart() {
  yield takeEvery(UPDATE_ITEM_TO_CART, UpdateItemToCart);
}

function* CartSaga() {
  yield all([
    fork(watchGetCart),
    fork(watchAddItemToCart),
    fork(watchRemoveItemToCart),
    fork(watchUpdateItemToCart)
  ]);
}

export default CartSaga;
