import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_CODE_ENCRYPTION } from "./actionType";

import {
  codeEncryptionResponseError,
  codeEncryptionResponseSuccess,
} from "./action";

import { codeEncryptApi } from "../../helpers/fakebackend_helper";

function* codeEncryptionFunction(data) {
  try {
    const response = yield call(codeEncryptApi, data.payload);
    yield put(codeEncryptionResponseSuccess(GET_CODE_ENCRYPTION, response));
  } catch (error) {
    yield put(codeEncryptionResponseError(GET_CODE_ENCRYPTION, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_CODE_ENCRYPTION, codeEncryptionFunction);
}

function* CodeEncryptionSaga() {
  yield all([fork(watchGetCart)]);
}

export default CodeEncryptionSaga;
