import {
  API_PLAN_HISTORY_RESPONSE_SUCCESS,
  API_PLAN_HISTORY_RESPONSE_ERROR,
  GET_PLAN_HISTORY
} from "./actionType";

const INIT_STATE = {
  PlanHistoryData : [],
};

const PlanHistoryTable = (state = INIT_STATE, action) => {
  ////console.log(action.payload);
  switch (action.type) {
    case API_PLAN_HISTORY_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PLAN_HISTORY:
          return {
            ...state,
            PlanHistoryData: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_PLAN_HISTORY_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PLAN_HISTORY:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_PLAN_HISTORY: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default PlanHistoryTable;
