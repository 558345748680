import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { COLLATERAL_GET_AVAILABLE_MARGIN } from "./actionType";

import {
  CollateralApiResponseSuccess,
  CollateralApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getCollateralApi } from "../../helpers/fakebackend_helper";

function* getCollateral(data) {
  try {
    const response = yield call(getCollateralApi, data.data);
    yield put(CollateralApiResponseSuccess(COLLATERAL_GET_AVAILABLE_MARGIN, response));
  } catch (error) {
    yield put(CollateralApiResponseError(COLLATERAL_GET_AVAILABLE_MARGIN, error));
  }
}

export function* watchGetCollateral() {
  yield takeEvery(COLLATERAL_GET_AVAILABLE_MARGIN, getCollateral);
}

function* CollateralSaga() {
  yield all([
    fork(watchGetCollateral)
  ]);
}

export default CollateralSaga;
