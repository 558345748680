import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";
import Loader from "../../Components/Common/Loader";
//Dashboard components
import EcommerceCheckout from "./EcommerceCheckout";
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";

import { callBackApi } from '../../helpers/fakebackend_helper';
import { getCart } from "../../store/cart/action";
import Encripted from "../Dashboard/Encripted";

const OrderSummaryBank = () => {
  const dispatch = useDispatch();
  const [data, satData] = useState([]);
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const navigate = useNavigate();
  const { VerifyOtpData } = useSelector((state) => ({
    VerifyOtpData: state.VerifyOTP.oderDetails,
  }));
  // call_back?ClientCode=Y09057&amount=2358.82&insertId=25792&upiTransactionId=323087606719
  // useEffect(() => {
  //   if (VerifyOtpData.length === 0) {
  //     return navigate("/dashboard");
  //   }
  // },[VerifyOtpData]);
  ////console.log(VerifyOtpData);
  // const client_id = new URLSearchParams(search).get("ClientCode");
  const amount = new URLSearchParams(search).get("amount");
  const orderId = new URLSearchParams(search).get("insertId");
  const upiTransactionId = new URLSearchParams(search).get("upiTransactionId");
  const payment_type = new URLSearchParams(search).get("payment_type");
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem('client_token');
  const subscriber_ids = localStorage.getItem('subscriber_ids');
  const STATECODE = localStorage.getItem('STATECODE');
  const BRCODE = localStorage.getItem('BRCODE');
  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));

  const data2 = {
    client_code: client_code,
    amount: amount,
    orderId: orderId,
    transaction_id: upiTransactionId,
    payment_type: payment_type
  }

  useEffect(() => {
    getCart({enc:Encripted([])})
  }, [dispatch, client_code, client_token]);

  useEffect(() => {
    const val = {
      client_code: client_code,
      api_key: generatedKey,
      brcode:BRCODE,
      STATECODE: STATECODE,
      // client_token: client_token,
      subscriber_ids: subscriber_ids,
      transaction_id: upiTransactionId,
      payment_amount: +amount,
      payment_type: payment_type,
    };

    // console.log('val',val);
    // return false;

    callBackApi(val)
      .then((response) => {
        ////console.log(response);
        if(response.status === true) {
          satData(response.data)
          setLoading(false);

          // localStorage.removeItem('subscriber_ids');
          dispatch(getCart({ client_code: client_code, client_token: client_token }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  document.title = "Bigul Admin & Dashboard";

  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));
  if (loading === true) {
    return (
      <div style={{height: '50vh', marginTop: '20%'}}>
        <Loader />
      </div>
    )
    ;
  }

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Payment Details"
            titleMain="ORDER SUMMARY"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to="/dashboard"
          />

          <Row>
            <EcommerceCheckout
              generatedKey={generatedKey}
              encryptKey={encryptKey}
              data={VerifyOtpData}
              data2={data2}
              data3={data}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderSummaryBank;
