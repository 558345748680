import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import WithdrawFundsPopup from "./WithdrawFundsPopup";
import ConfirmWithdrawal from "./ConfirmWithdrawal";
import TransferredSuccessful from "./TransferredSuccessful";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { sendApi } from "../../../../helpers/fakebackend_helper";
//action

// TODO Ui and design and functions.
const WithdrawFunds = (props) => { 
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');
  const { data, openState } = props;
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);

  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));


  const [modalMobile, setModelMobile] = useState({
    CUpdate: true,
    otp1: false,
    done: false,
  });
  useEffect(() => {
    setModelMobile(openState)
  }, [])

  const sendData = (data, value) => {
    setAmount(value);
    setModelMobile(data);
  };

  const tog_signUpModalsFinal = (data) => {

    setmodal_signUpModals(!modal_signUpModals);
  };

  const tog_signUpModals = () => {
    sendApi(props.cartData)
      .then((response) => {
        if (response.status == true) {
          setModelMobile(openState)
          // setmodal_signUpModals(!modal_signUpModals);
        }
        else {
          toast(response.message)
        }

      })
      .catch((err) => {
        ////console.log(err);
      });


  };

  const submitHandler = () => {
    //  ////console.log("submit");
  };

  const tog_signUpModals1 = () => {
    setmodal_signUpModals(!modal_signUpModals);
  };
  return (
    <React.Fragment>
      {/* <button
        type="button"
        className="btn btn-success text-4 w-100 margin-overview-button2"
        style={{ borderRadius: "5px" }}
        onClick={() => tog_signUpModals()}
      >
        <i className={"align-middle me-2 fs-5 " + "ri-wallet-line"}></i>
        Withdraw
      </button> */}
      <button
        type="button"
        className="btn btn-success btn-label right ms-auto compleate-order-btn margin-start-auto"
        onClick={() =>setmodal_signUpModals(!modal_signUpModals)}
      >
        <i className=" ri-checkbox-circle-line label-icon align-middle fs-16 ms-2"></i>
        Proceed to Pay
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {modalMobile.CUpdate && (
          <WithdrawFundsPopup amount={props?.data} sendData={sendData} tog={tog_signUpModals1} cartData={props.cartData} />
        )}
        {modalMobile.done && (
          <TransferredSuccessful sendData={tog_signUpModalsFinal} />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default WithdrawFunds;
