// import React, { useRef, useState, useEffect } from 'react';
// // import './MediaPlayer.css'; // Ensure this file is imported for styling

// const MediaPlayer = ({ mediaUrl }) => {
//     const [isAudio, setIsAudio] = useState(false);
//     const mediaRef = useRef(null);

//     useEffect(() => {
//         if (mediaUrl) {
//             const extension = mediaUrl.split('.').pop().toLowerCase();
//             setIsAudio(['mp3', 'wav'].includes(extension));
//         }
//     }, [mediaUrl]);

//     // Auto-play the media when the component mounts
//     useEffect(() => {
//         if (mediaRef.current) {
//             mediaRef.current.play().catch(error => {
//                 console.error('Auto-play was prevented:', error);
//             });
//         }
//     }, [mediaUrl]);

//     return (
//         <div className="media-player">
//             {isAudio ? (
//                 <audio
//                     ref={mediaRef}
//                     src={mediaUrl}
//                     controls
//                 />
//             ) : (
//                 <video
//                     ref={mediaRef}
//                     src={mediaUrl}
//                     controls
//                     className="responsive-video"
//                 />
//             )}
//         </div>
//     );
// };

// export default MediaPlayer;
import React, { useRef, useState, useEffect } from 'react';
// import './MediaPlayer.css'; // Ensure this file is imported for styling

const MediaPlayer = ({ mediaUrl }) => {
    const [mediaType, setMediaType] = useState('');

    useEffect(() => {
        if (mediaUrl) {
            const extension = mediaUrl.split('.').pop().toLowerCase();
            if (['mp3', 'wav'].includes(extension)) {
                setMediaType('audio');
            } else if (['mp4', 'webm', 'ogg'].includes(extension)) {
                setMediaType('video');
            } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
                setMediaType('image');
            } else {
                setMediaType('unknown');
            }
        }
    }, [mediaUrl]);

    return (
        <div className="media-player">
            {mediaType === 'audio' && (
                <audio
                    src={mediaUrl}
                    controls
                    autoPlay
                />
            )}
            {mediaType === 'video' && (
                <video
                    src={mediaUrl}
                    controls
                    className="responsive-video"
                    autoPlay
                />
            )}
            {mediaType === 'image' && (
                <img
                    src={mediaUrl}
                    alt="Media content"
                    className="responsive-image"
                />
            )}
            {mediaType === 'unknown' && (
                <p>Unsupported media type</p>
            )}
        </div>
    );
};

export default MediaPlayer;
