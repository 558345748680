import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const Benefits = () => {
  return (
    <React.Fragment>
      <Col xxl={12} xl={12} lg={12} className="my-5">
        <h4 className="text-9 text-color11">Referral Benefits</h4>
        <h4 className="text-color5 text-12 mb-5">What Bigul has for you?</h4>
        <div className="d-flex justify-content-between">
          <Row>
            <Col xxl={4} xl={4} lg={4}>
              <Card className="card-body" style={{height: '170px'}}>
                    <i className="bx bxs-group mb-2" style={{ fontSize: '49px', color: 'rgba(255, 223, 53, 1)'}}></i>
                <p className="card-text text-9 text-color5 pe-3">
                You win, they win, we all win when you refer your friends.
                </p>
              </Card>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
            <Card className="card-body" style={{height: '170px'}}>
                    <i className="bx bxs-medal mb-2" style={{ fontSize: '49px', color: 'rgba(255, 223, 53, 1)'}}></i>
                <p className="card-text text-9 text-color5 pe-3">
                You get Rs 300* on every person you refer.
                </p>
              </Card>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
            <Card className="card-body" style={{height: '170px'}}>
                <i className="bx bxs-offer mb-2" style={{ fontSize: '49px', color: 'rgba(255, 223, 53, 1)'}}></i>
                <p className="card-text text-9 text-color5 pe-3">
                Plus 15% of all brokerage generated by your referrals
                </p>
              </Card>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <Card className="card-body" style={{height: '170px'}}>
                    <i className="bx bxs-group mb-2" style={{ fontSize: '49px', color: 'rgba(255, 223, 53, 1)'}}></i>
                <p className="card-text text-9 text-color5 pe-3">
                You win, they win, we all win when you refer your friends.
                </p>
              </Card>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
            <Card className="card-body" style={{height: '170px'}}>
                    <i className="bx bxs-medal mb-2" style={{ fontSize: '49px', color: 'rgba(255, 223, 53, 1)'}}></i>
                <p className="card-text text-9 text-color5 pe-3">
                You get Rs 300* on every person you refer.
                </p>
              </Card>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
            <Card className="card-body" style={{height: '170px'}}>
                <i className="bx bxs-offer mb-2" style={{ fontSize: '49px', color: 'rgba(255, 223, 53, 1)'}}></i>
                <p className="card-text text-9 text-color5 pe-3">
                Plus 15% of all brokerage generated by your referrals
                </p>
              </Card>
            </Col>
          </Row>
        </div>
        <h4 className="text-end text-10 text-color7"><a href="#" className="text-end text-10 text-color7">Terms & Conditions</a></h4>
      </Col>
    </React.Fragment>
  );
};

export default Benefits;
