import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_MF } from "./actionType";

import {
  MFApiResponseSuccess,
  MFApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { geCurrentOverviewMFApi } from "../../helpers/fakebackend_helper";

function* getMF(data) {
  try {
    const response = yield call(geCurrentOverviewMFApi, data.data);
    yield put(MFApiResponseSuccess(GET_MF, response));
  } catch (error) {
    yield put(MFApiResponseError(GET_MF, error));
  }
}

export function* watchGetMF() {
  yield takeEvery(GET_MF, getMF);
}

function* MfSaga() {
  yield all([
    fork(watchGetMF)
  ]);
}

export default MfSaga;
