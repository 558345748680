import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, CardHeader, Row } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getSuggestedAlgo } from "../../store/dashboardSuggestedAlgos/action";
import { addToCart } from "../../store/cart/action";

import active from "../../assets/images/logo/active.png";
import check_broken from "../../assets/images/logo/check_broken.png";

const SuggestedMyAlgos = () => {

  const dispatch = useDispatch();

  const client_code  = localStorage.getItem('client_id');
  const client_token = localStorage.getItem('client_token');

  const [info, setInfo] = useState([]);

  const { suggestedAlgo } = useSelector((state) => ({
    suggestedAlgo: state.SuggestedAlgos.suggestedAlgos,
  }));
  ////console.log(suggestedAlgo);
  const [sellerId, setSellerID] = useState(suggestedAlgo.length > 0 ? suggestedAlgo[0].id : null);

  useEffect(() => {
    dispatch(getSuggestedAlgo({ client_code: client_code, client_token: client_token }));
  }, [dispatch, client_code, client_token]);

  const selectHandler = (seller) => {
    setSellerID(seller.id);
    setInfo(seller);
  };
  const charactersLimit = (data, limit) => {
    //  ////console.log(data);
    if (data === undefined) {
      return data;
    }
    if (data !== null || data !== undefined) {
      if (data.length > limit) {
        return data.substring(0, limit).concat(" ...");
      }
    }
    return data;
  };

  const addToCartHandler = (data) => {
      ////console.log(data);
    const item = {
      client_code: client_code,
      client_token: client_token,
      feature_or_plan_id: data.id,
      feature_or_plan: "Feature",
      duration_type: "Monthly",
    };
    dispatch(addToCart(item));
  };

  const candidateListData = [
    {
      id: 1,
      img: active,
      name: "Algo Invest 1",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 2,
      img: active,
      name: "Algo Invest 2",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 3,
      img: active,
      name: "Algo Invest 3",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 4,
      img: active,
      name: "Algo Invest 4",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 5,
      img: active,
      name: "Algo Invest 5",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 6,
      img: active,
      name: "Algo Invest 6",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
  ];

  return (
    <React.Fragment>
      <Col xs={12} className="mb-2">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <h4 className="card-title mb-0 flex-grow-1 text-6 text-color1">
              Suggested Algos
            </h4>
          </div>
          {/* <div className="page-title-right">
            <button className="btn btn-primary btn-sm text-5 addons-button">
              View All Algos
            </button>
          </div> */}
        </div>
      </Col>
      <Col xxl={12}>
        <Row className="g-0 suggested-algos-col">
          <Col lg={9}>
            <Row>
              {suggestedAlgo.length > 0 && suggestedAlgo.map((seller, index) => {
                return(
                <React.Fragment key={index}>
                  <Col xl={4} lg={4} md={6}>
                    <Card
                      onClick={() => selectHandler(seller)}
                      className={`${
                        sellerId === seller.id && "selected-box-border"
                      } ${index > 2 && "item-hide"} suggested-algos-col-card ${sellerId !== seller.id && 'non-selected-box-border'}`}
                    >
                      <CardBody className="pt-3 pb-3 px-3" style={{height: '250px'}}>
                        <div className="d-flex mb-1">
                          <div className="flex-grow-1">
                            <h5 className="mt-2 text-6 text-color1" style={{height: '110px'}}>
                            {seller.title}
                            </h5>
                          </div>
                          <div className="flex-shrink-0">
                            <img src={seller.logo} alt="" height="40" />
                          </div>
                        </div>
                        <p className="text-color1 text-17 mt-2 pe-4 item-hide" style={{height: '100px'}}>
                        {charactersLimit(seller.short_description, 120)}
                        </p>
                        {/* <div className='item-hide'>
                          <button className="addons-feature-button-normal" onClick={() => {
                                addToCartHandler(seller);
                              }}>
                            Invest Now
                          </button>
                        </div> */}
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              )})}
            </Row>
          </Col>
          <Col lg={3} className="spacing-remove">
            <React.Fragment>
              <Card
                className="ms-3 card-height-100 background-color1 px-3 py-3 suggested-algo-bg"
                //style={{ borderRadius: "12px" }}
                style={{
                  height: "525px",
                  // backgroundImage: `url(${highlights_bg})`,
                  // backgroundSize: "cover",
                }}
              >
                <div>
                  <img
                    src={info.logo || suggestedAlgo.length > 0 && suggestedAlgo[0].logo}
                    alt={info.slug || suggestedAlgo.length > 0 && suggestedAlgo[0].slug}
                    id="candidate-img"
                    style={{ width: "48px", height: "48px" }}
                    className="img-thumbnail"
                  />
                </div>
                <h5 id="candidate-name" className="mt-4 text-6 text-color3 mb-3" style={{height: '100px'}}>
                  {info.title || suggestedAlgo.length > 0 && suggestedAlgo[0].title}
                </h5>
                <p id="candidate-name" className="mt-2 text-color3 text-11" style={{height: '200px'}}>
                  {charactersLimit(info.short_description, 300) || suggestedAlgo.length > 0 && charactersLimit(suggestedAlgo[0].short_description, 300)}
                </p>
                {/* <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="text-primary rounded-circle fs-1">
                      <div className="card-invest-div-logo rounded-circle align-middle">
                        <img
                          className="card-invest-logo align-middle"
                          src={check_broken}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-4 m-1 text-color3 text-16">
                      {info.points1 || candidateListData[0].points1}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="text-primary rounded-circle fs-1">
                      <div className="card-invest-div-logo rounded-circle align-middle">
                        <img
                          className="card-invest-logo align-middle"
                          src={check_broken}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-4 m-1 text-color3 text-16">
                      {info.points1 || candidateListData[0].points1}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="text-primary rounded-circle">
                      <div className="card-invest-div-logo rounded-circle align-middle">
                        <img
                          className="card-invest-logo align-middle"
                          src={check_broken}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-4 m-1 text-color3 text-16">
                      {info.points1 || candidateListData[0].points1}
                    </p>
                  </div>
                </div> */}
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    to="/apps-nft-create"
                    className="btn btn-success w-50 mt-3 mb-2 align-center"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      addToCartHandler(info);
                    }}
                  >
                    Invest Now
                  </button>
                </div> */}
              </Card>
            </React.Fragment>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default SuggestedMyAlgos;
