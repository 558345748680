// import React, { useState, useEffect } from "react";
// import {
//   Col,
//   Dropdown,
//   DropdownMenu,
//   DropdownToggle,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   TabContent,
//   TabPane,
// } from "reactstrap";
// import { Link } from "react-router-dom";
// import classnames from "classnames";
// import dayjs from "dayjs";

// //redux
// import { useSelector, useDispatch } from "react-redux";

// // actions
// import { getNotificationsList } from "../../store/notification/action";
// import "simplebar-react/dist/simplebar.min.css"; // Import SimpleBar styles

// import bell from "../../assets/images/svg/bell.svg";

// //SimpleBar
// import SimpleBar from "simplebar-react";
// import Encripted from "../../pages/Dashboard/Encripted";
// import "simplebar-react/dist/simplebar.min.css";
// import { Modal, ModalHeader, ModalBody } from "reactstrap";
// import MediaPlayer from "./MediaPlayer";

// const NotificationDropdown = () => {
//   //Dropdown Toggle
//   const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);

//   const dispatch = useDispatch();
//   const { notificationsList, NotificationsListCount } = useSelector((state) => ({
//     notificationsList: state.Notifications.NotificationsList,
//     NotificationsListCount: state.Notifications.NotificationsListCount,
//   }));

//   const client_code = localStorage.getItem("client_id");
//   const [modal, setModal] = useState(false)
//   const [media, setMedia] = useState('')

//   const messageNotifications = NotificationsListCount?.filter(notification => notification.type === "Message");
//   const messageNotifications1 = NotificationsListCount?.filter(notification => notification.type === "Alert");

//   /*
//   get data
//   */
//   useEffect(() => {
//     if (client_code) {
//       dispatch(
//         getNotificationsList({
//           enc: Encripted([])

//         })
//       );
//     }

//   }, [dispatch, client_code]);

//   const toggleNotificationDropdown = () => {
//     setIsNotificationDropdown(!isNotificationDropdown);
//   };

//   //Tab
//   const [activeTab, setActiveTab] = useState("2");
//   const toggleTab = (tab) => {
//     if (activeTab !== tab) {
//       setActiveTab(tab);
//     }
//   };

//   const toggle = () => setModal(!modal)

//   const modalOpen = (media) => {
//     setModal(true)
//     setMedia(media)
//   }
//   return (
//     <React.Fragment>
//       <Modal isOpen={modal} toggle={toggle} size="md">
//         <ModalHeader toggle={toggle}></ModalHeader>
//         <ModalBody>
//           {<MediaPlayer mediaUrl={media} />}
//         </ModalBody>
//       </Modal>
//       <Dropdown
//         isOpen={isNotificationDropdown}
//         toggle={toggleNotificationDropdown}
//         className="topbar-head-dropdown ms-1 header-item"
//         data-toggle="tooltip"
//         data-placement="bottom"
//         //data-class="tooltip-class-header"
//         title="Notifications"
//       >
//         <DropdownToggle
//           type="button"
//           tag="button"
//           className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle bx bx-bell fs-22"
//         >
//           {/* <i className="bx bx-bell fs-22"></i> */}
//           {NotificationsListCount.length > 0 && (
//             <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger center-padding">
//               {NotificationsListCount.length}
//               <span className="visually-hidden">unread messages</span>
//             </span>
//           )}
//         </DropdownToggle>
//         <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" >
//           <div className="dropdown-head bg-primary bg-pattern border-top">
//             <div className="p-3">
//               <Row className="align-items-center">
//                 <Col>
//                   <h6 className="m-0 text-color3 text-10"> Notifications </h6>
//                 </Col>
//                 <div className="col-auto dropdown-tabs">
//                   <span className="badge badge-soft-light text-3 text-color3">
//                     {" "}
//                     {NotificationsListCount.length} New
//                   </span>
//                 </div>
//               </Row>
//             </div>

//             <div className="px-2 pt-2">
//               <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">



//                 <NavItem>
//                   <NavLink
//                     href="#"
//                     className={classnames({ active: activeTab === "2" })}
//                     onClick={() => {
//                       toggleTab("2");
//                     }}
//                   >
//                     Alerts
//                   </NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink
//                     href="#"
//                     className={classnames({ active: activeTab === "3" })}
//                     onClick={() => {
//                       toggleTab("3");
//                     }}
//                   >
//                     Messages
//                   </NavLink>
//                 </NavItem>
//               </Nav>
//             </div>
//           </div>

//           <TabContent activeTab={activeTab}>
//             <TabPane tabId="2" className="py-2 ps-2">
//               <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
//                 {NotificationsListCount.length > 0 &&
//                   messageNotifications1?.map((notification, index) => {
//                     return (
//                       <div
//                         key={index}
//                         className="text-reset notification-item d-block dropdown-item position-relative"
//                       >
//                         <Link
//                           to="/notifications"
//                           onClick={() => { setIsNotificationDropdown(!isNotificationDropdown); }}
//                           type="button"

//                         >
//                           <div className="d-flex">
//                             <div className="avatar-xs me-3">
//                               <span className="avatar-title rounded-circle">
//                                 <i className="ri-message-line"></i>
//                               </span>
//                             </div>
//                             <div className="flex-1 pe-5">
//                               <h6 className="mt-0 mb-2 text-14">
//                                 {notification.title}
//                               </h6>
//                               <p className="mb-0 text-uppercase text-14">
//                                 <span>
//                                   <i className="mdi mdi-clock-outline me-2"></i>
//                                   {dayjs(notification.created_at).format(
//                                     "MMMM D, YYYY h:mm A"
//                                   )}
//                                 </span>
//                               </p>
//                             </div>
//                             {
//                           notification?.media_file !== null &&
//                           <div className="circle-container">
//                           <div className="circle" onClick={() => modalOpen(notification.media_file)}>
//                               <span className="circle-text">Pl</span>
//                           </div>
//                       </div>
//                         }
//                           </div>
//                         </Link>
//                       </div>
//                     );
//                   })}


//                 {notificationsList.length > 0 ? (
//                   <div className="my-3 text-center">

//                     <Link
//                       to="/notifications"
//                       onClick={() => { setIsNotificationDropdown(!isNotificationDropdown); }}
//                       type="button"
//                       className="btn btn-soft-success waves-effect waves-light"
//                       style={{ borderRadius: "5px" }}
//                     >
//                       See All Notifications{" "}
//                       <i className="ri-arrow-right-line align-middle"></i>
//                     </Link>
//                   </div>
//                 ) : (
//                   <div>
//                     <div className="w-25 w-sm-50 pt-3 mx-auto">
//                       <img src={bell} className="img-fluid" alt="user-pic" />
//                     </div>
//                     <div className="text-center pb-5 mt-2">
//                       <h6 className="fs-18 fw-semibold lh-base">
//                         Hey! You have no any notifications{" "}
//                       </h6>
//                     </div>
//                   </div>
//                 )}
//               </SimpleBar>
//             </TabPane>
//             <TabPane tabId="3" className="p-2">
//               {NotificationsListCount.length > 0 ? (
//                 messageNotifications?.map((notification, index) => (
//                   <div
//                     key={index}
//                     className="text-reset notification-item d-block dropdown-item position-relative"
//                   >
//                     <Link
//                       to="/notifications"
//                       onClick={() => setIsNotificationDropdown(!isNotificationDropdown)}
//                       type="button"
//                     >
//                       <div className="d-flex">
//                         <div className="avatar-xs me-3">
//                           <span className="avatar-title rounded-circle">
//                             <i className="ri-message-line"></i>
//                           </span>
//                         </div>
//                         <div className="flex-1 pe-5">
//                           <h6 className="mt-0 mb-2 text-14">
//                             {notification.title}
//                           </h6>
//                           <p className="mb-0 text-uppercase text-14">
//                             <span>
//                               <i className="mdi mdi-clock-outline me-2"></i>
//                               {dayjs(notification.created_at).format(
//                                 "MMMM D, YYYY h:mm A"
//                               )}
//                             </span>
//                           </p>
//                         </div>
//                         {
//                           notification?.media_file !== null &&
//                           <div className="circle-container">
//                           <div className="circle" onClick={() => modalOpen(notification.media_file)}>
//                               <span className="circle-text">Pl</span>
//                           </div>
//                       </div>
//                         }

//                       </div>
//                     </Link>
//                   </div>
//                 ))
//               ) : (
//                 <div>
//                   <div className="w-25 w-sm-50 pt-3 mx-auto">
//                     <img src={bell} className="img-fluid" alt="user-pic" />
//                   </div>
//                   <div className="text-center pb-5 mt-2">
//                     <h6 className="fs-18 fw-semibold lh-base">
//                       Hey! You have no any notifications{" "}
//                     </h6>
//                   </div>
//                 </div>
//               )}
//             </TabPane>

//           </TabContent>
//         </DropdownMenu>
//       </Dropdown>
//     </React.Fragment>
//   );
// };

// export default NotificationDropdown;

import React, { useState, useEffect } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import dayjs from "dayjs";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getNotificationsList } from "../../store/notification/action";
import "simplebar-react/dist/simplebar.min.css"; // Import SimpleBar styles

import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";
import Encripted from "../../pages/Dashboard/Encripted";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import MediaPlayer from "./MediaPlayer";
import img from '../../assets/images/play botton.jpg'
// import './Notification.css'; // Import the CSS for styling

const NotificationDropdown = () => {
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);

  const dispatch = useDispatch();
  const { notificationsList, NotificationsListCount } = useSelector((state) => ({
    notificationsList: state.Notifications.NotificationsList,
    NotificationsListCount: state.Notifications.NotificationsListCount,
  }));

  const client_code = localStorage.getItem("client_id");
  const [modal, setModal] = useState(false);
  const [media, setMedia] = useState('');

  const messageNotifications = NotificationsListCount?.filter(notification => notification.type === "Message");
  const messageNotifications1 = NotificationsListCount?.filter(notification => notification.type === "Alert");

  // Fetch data
  useEffect(() => {
    if (client_code) {
      dispatch(
        getNotificationsList({
          enc: Encripted([])
        })
      );
    }
  }, [dispatch, client_code]);

  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  //Tab
  const [activeTab, setActiveTab] = useState("2");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggle = () => setModal(!modal);

  const modalOpen = (media) => {
    setModal(true);
    setMedia(media);
  };

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <div style={{ padding: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}>
            <p style={{ margin: 0, fontWeight: 'bold' }}>{media?.title}</p>
            <button
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              onClick={toggle}
            >
              <i className="mdi mdi-close" style={{ fontSize: '24px' }}></i>
            </button>
          </div>
          <p style={{ margin: '0' }}>{media?.description}</p>
        </div>
        <ModalBody>
          <MediaPlayer mediaUrl={media?.media_file} />
        </ModalBody>
      </Modal>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Notifications"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle bx bx-bell fs-22"
        >
          {NotificationsListCount?.length > 0 && (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger center-padding">
              {NotificationsListCount?.length}
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern border-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 text-color3 text-10"> Notifications </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <span className="badge badge-soft-light text-3 text-color3">
                    {NotificationsListCount?.length} New
                  </span>
                </div>
              </Row>
            </div>

            <div className="px-2 pt-2">
              <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => toggleTab("2")}
                  >
                    Alerts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => toggleTab("3")}
                  >
                    Messages
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="2" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {NotificationsListCount?.length > 0 &&
                  messageNotifications1?.map((notification, index) => (
                    <div key={index} className="notification-item">
                      <div className="notification-content">
                        <Link
                          to="/notifications"
                          onClick={() => setIsNotificationDropdown(!isNotificationDropdown)}
                        >
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle">
                                <i className="ri-message-line"></i>
                              </span>
                            </div>
                            <div className="flex-1 pe-5">
                              <h6 className="mt-0 mb-2 text-14">
                                {notification.title}
                              </h6>
                              <p className="mb-0 text-uppercase text-14">
                                {/* <span>
                                  <i className="mdi mdi-clock-outline me-2"></i>
                                  {dayjs(notification.created_at).format("MMMM D, YYYY h:mm A")}
                                </span> */}
                                <span >
                                  <i className="mdi mdi-clock-outline" ></i> {
                                    notification.start_date == notification?.end_date ?
                                      dayjs(notification.start_date).format("D MMM")
                                      :
                                      dayjs(notification.start_date).format("D MMM") + ' ' + 'To' + ' ' + dayjs(notification.end_date).format("D MMM")
                                  }

                                </span>
                              </p>
                            </div>
                          </div>
                        </Link>
                        {notification?.media_file && (
                          <div className="circle-container">
                            <div className="circle" onClick={() => modalOpen(notification)}>
                              <img src={img} alt="Play" style={{ height: '30px', width: '30px' }} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                {messageNotifications1.length > 0 ? (
                  <div className="my-3 text-center">
                    <Link
                      to="/notifications"
                      onClick={() => setIsNotificationDropdown(!isNotificationDropdown)}
                      type="button"
                      className="btn btn-soft-success waves-effect waves-light"
                      style={{ borderRadius: "5px" }}
                    >
                      See All Notifications{" "}
                      <i className="ri-arrow-right-line align-middle"></i>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div className="w-25 w-sm-50 pt-3 mx-auto">
                      <img src={bell} className="img-fluid" alt="user-pic" />
                    </div>
                    <div className="text-center pb-5 mt-2">
                      <h6 className="fs-18 fw-semibold lh-base">
                        Hey! You have no alerts{" "}
                      </h6>
                    </div>
                  </div>
                )}
              </SimpleBar>
            </TabPane>

            <TabPane tabId="3" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {NotificationsListCount?.length > 0 &&
                  messageNotifications?.map((notification, index) => (
                    <div key={index} className="notification-item">
                      <div className="notification-content">
                        <Link
                          to="/notifications"
                          onClick={() => setIsNotificationDropdown(!isNotificationDropdown)}
                        >
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle">
                                <i className="ri-message-line"></i>
                              </span>
                            </div>
                            <div className="flex-1 pe-5">
                              <h6 className="mt-0 mb-2 text-14">
                                {notification.title}
                              </h6>
                              <p className="mb-0 text-uppercase text-14">
                                {/* <span>
                                  <i className="mdi mdi-clock-outline me-2"></i>
                                  {dayjs(notification.created_at).format("MMMM D, YYYY h:mm A")}
                                </span> */}
                                <span >
                                  <i className="mdi mdi-clock-outline" ></i> {
                                    notification.start_date == notification?.end_date ?
                                      dayjs(notification.start_date).format("D MMM")
                                      :
                                      dayjs(notification.start_date).format("D MMM") + ' ' + 'To' + ' ' + dayjs(notification.end_date).format("D MMM")
                                  }

                                </span>
                              </p>
                            </div>
                          </div>
                        </Link>
                        {notification?.media_file && (
                          <div className="circle-container">
                            <div className="circle" onClick={() => modalOpen(notification)}>
                              <img src={img} alt="Play" style={{ height: '30px', width: '30px' }} />

                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                {messageNotifications.length > 0 ? (
                  <div className="my-3 text-center">
                    <Link
                      to="/notifications"
                      onClick={() => setIsNotificationDropdown(!isNotificationDropdown)}
                      type="button"
                      className="btn btn-soft-success waves-effect waves-light"
                      style={{ borderRadius: "5px" }}
                    >
                      See All Notifications{" "}
                      <i className="ri-arrow-right-line align-middle"></i>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div className="w-25 w-sm-50 pt-3 mx-auto">
                      <img src={bell} className="img-fluid" alt="user-pic" />
                    </div>
                    <div className="text-center pb-5 mt-2">
                      <h6 className="fs-18 fw-semibold lh-base">
                        Hey! You have no messages{" "}
                      </h6>
                    </div>
                  </div>
                )}
              </SimpleBar>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
