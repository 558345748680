import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_NOTIFICATIONS } from "./actionType";

import {
  NotificationsApiResponseSuccess,
  NotificationsApiResponseError,
  getNotificationsList,
} from "./action";

//Include Both Helper File with needed methods
import { getNotificationsListApi } from "../../helpers/fakebackend_helper";

function* getNotificationList(data) {
  try {
    const response = yield call(getNotificationsListApi, data.payload);
    yield put(NotificationsApiResponseSuccess(GET_NOTIFICATIONS, response));
  } catch (error) {
    yield put(NotificationsApiResponseError(GET_NOTIFICATIONS, error));
  }
}

export function* watchGetNotificationsList() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationList);
}

function* NotificationSaga() {
  yield all([fork(watchGetNotificationsList)]);
}

export default NotificationSaga;
