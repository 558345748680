import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { getCart } from "../../store/cart/action";
import { removeToCart } from "../../store/cart/action";
import { getPaymentDetails } from "../../store/checkOut/action";
//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import image1 from "../../assets/images/products/img-1.png";
import image2 from "../../assets/images/products/img-2.png";
import image3 from "../../assets/images/products/img-3.png";
import image6 from "../../assets/images/products/img-6.png";
import image5 from "../../assets/images/products/img-5.png";
import active from "../../assets/images/logo/active.png";
const MyCartDropdown = () => {
  const dispatch = useDispatch();

  const client_code = localStorage.getItem('client_id');
  const client_token = localStorage.getItem('client_token');

  useEffect(() => {
    if (client_code) {
       dispatch(
        getCart({ client_code: client_code, client_token: client_token })
      );
    }

  }, [dispatch, client_code]);

  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));
  ////console.log(Cart);
  const { Checkout } = useSelector((state) => ({
    Checkout: state.PaymentDetails.PaymentDetails,
  }));

  ////console.log(Checkout);

  const cardItemTotal = useRef(null);

  const cartData = [
    {
      id: 1,
      img: image1,
      product: "Branded T-Shirts",
      quantity: 10,
      price: 32,
      total: 320,
    },
    {
      id: 2,
      img: image2,
      product: "Bentwood Chair",
      quantity: 5,
      price: 18,
      total: 90,
    },
    {
      id: 3,
      img: image3,
      product: "Borosil Paper Cup",
      quantity: 3,
      price: 250,
      total: 750,
    },
    {
      id: 4,
      img: image6,
      product: "Gray Styled T-Shirt",
      quantity: 1,
      price: 1250,
      total: 1250,
    },
    {
      id: 5,
      img: image5,
      product: "Stillbird Helmet",
      quantity: 2,
      price: 495,
      total: 990,
    },
  ];

  ////console.log(Cart)
  //const items = props.cartData.length;
  const values = [];
  Cart !== null && Cart.length > 0 && Cart.forEach((element) => {
    if (element.feature_or_plan === "Plan" && element.discounted_price !== '') {
      const value = +element.discounted_price;
      values.push(value);
    } else if (element.amount !== "") {
      const value = +element.amount;
      values.push(value);
    } else {
      const value = +element.amount;
      values.push(value);
    }
    // if (element.amount !== "") {
    //   const value = +element.amount;
    //   values.push(value);
    // } else {
    //   const value = +element.amount;
    //   values.push(value);
    // }
    ////console.log(values);
    //  ////console.log(element);

  });
  ////console.log(values);
  const subTotal = values.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  ////console.log(subTotal);

  const [isCartDropdown, setIsCartDropdown] = useState(false);

  const [cartItem, setCartItem] = useState(cartData.length);

  const toggleCartDropdown = () => {
    ////console.log(isCartDropdown);
    setIsCartDropdown(!isCartDropdown);
    setCartItem(cartData.length);
  };

  const removeItem = (e, ele) => {
    e.stopPropagation();
    //ele.nativeEvent.stopImmediatePropagation();
    var price = ele
      .closest(".dropdown-item-cart")
      .querySelector(".cart-item-price").innerHTML;
    ////console.log("cardItemTotal", cardItemTotal);
    var subTotal = cardItemTotal.current.innerHTML;
    cardItemTotal.current.innerHTML = subTotal - price;

    ele.closest(".dropdown-item-cart").remove();
    const element = document.querySelectorAll(".dropdown-item-cart").length;
    const ecart = document.getElementById("empty-cart");

    if (element === 0) {
      ecart.style.display = "block";
    } else {
      ecart.style.display = "none";
    }

    setCartItem(element);
  };

  const removeToCartHandler = (data) => {
    ////console.log(data);
    const item = {
      client_code: client_code,
      client_token: client_token,
      cart_id: data.id,
    };
    dispatch(removeToCart(item));
  };

  ////console.log(Cart);
  const f = Cart !== null && Cart.length > 0 && Cart.find((e) => e.feature_or_plan === "Plan");
  const g = Cart !== null && Cart.length > 0 && Cart.findIndex((e) => e.feature_or_plan === "Plan");
  const paymentData = {};

  // //console.log(f);
  ////console.log(g);
  if (Cart !== null && Cart.length > 0 && f) {
    paymentData.client_code = client_code;
    paymentData.client_token = client_token;
    paymentData.master_plan_or_feature_id = Cart[g].feature_or_plan_id;
    paymentData.duration_type = Cart[g].duration_type;
    paymentData.duration = Cart[g].duration;
    paymentData.type = Cart[g].feature_or_plan;
    paymentData.amount = Cart[g].discounted_price;
  } else if (Cart !== null && Cart.length > 0) {
    paymentData.client_code = client_code;
    paymentData.client_token = client_token;
    paymentData.master_plan_or_feature_id = Cart[0].feature_or_plan_id;
    paymentData.duration_type = Cart[0].duration_type;
    paymentData.duration = Cart[0].duration;
    paymentData.type = Cart[0].feature_or_plan;
    paymentData.amount = Cart[0].amount;
  }
  const cloneCart = Cart !== null && [...Cart];
  ////console.log(cloneCart);
  Cart !== null && cloneCart.splice(g, 1);
  ////console.log(cloneCart);
  const addFeature = [];
  cloneCart.forEach((e) => {
    ////console.log(e);
    let fet = {
      id: e.feature_or_plan_id,
      amount: e.features && e.amount || e.plans && e.discounted_price,
      duration: e.duration,
      duration_type: e.duration_type,
    };
    addFeature.push(fet)
  });
  //const t = [];
  paymentData.additional_features = addFeature;
  const paymentDetailsHandler = () => {
    ////console.log("pay");
    dispatch(getPaymentDetails(paymentData));
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isCartDropdown}
        toggle={toggleCartDropdown}
        className="topbar-head-dropdown ms-1 header-item"
        data-toggle="tooltip"
        data-placement="bottom"
        //data-class="tooltip-class-header"
        title="Cart"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar ri-shopping-cart-2-line btn-ghost-secondary fs-22 rounded-circle"
        >
          {/* <i className="ri-shopping-cart-2-line fs-22" /> */}
          {Cart !== null && Cart.length > 0 && <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger center-padding">
            {Cart !== null && Cart.length > 0 ? Cart.length : 0}
            {/* <span className="visually-hidden">unread messages</span> */}
          </span>}

        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-xl dropdown-menu-end p-0"
          aria-labelledby="page-header-cart-dropdown"
        >
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fs-16 fw-semibold"> My Cart</h6>
              </Col>
              <div className="col-auto dropdown-tabs">
                <span className="badge cart_badge">
                  {" "}
                  {Cart !== null && Cart.length > 0 ? Cart.length : 0} New
                </span>
              </div>
            </Row>
          </div>
          <SimpleBar style={{ maxHeight: "300px" }}>
            <div className="p-2">
              {Cart !== null && Cart.length > 0 ? (
                Cart.map((item, key) => {
                  ////console.log(item)
                  return (

                    <div
                      className="d-block dropdown-item text-wrap dropdown-item-cart px-3 py-2"
                      key={key}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={(item.features && item.features.logo) || (item.plans && item.plans.image) || active}
                          className="me-3 rounded-circle avatar-sm p-2 bg-light"
                          alt="user-pic"
                        />
                        <div className="flex-1">
                          <h6 className="mt-0 mb-1 text-4 colorText1">
                            <Link
                              to="/apps-ecommerce-product-details"
                              className="text-reset colorText1"
                            >
                              {(item.features
                                && item.features.title)
                                || (item.plans && item.plans.plan_name)}
                            </Link>
                          </h6>
                          <p className="mb-0 text-21 colorText1">
                            {item.duration_type}
                          </p>
                        </div>
                        <div className="px-2">
                          <h5 className="m-0 colorText1 text-4">
                            ₹ {(item.features
                              && Number(item.amount).toFixed(2))
                              || (item.plans && Number(item.discounted_price).toFixed(2))}
                          </h5>
                        </div>
                        <div className="ps-2">
                          <button
                            type="button"
                            className="btn btn-icon btn-sm btn-ghost-secondary remove-item-btn"
                            // onClick={(e) => {
                            //   removeItem(e, e.target);
                            // }}
                            onClick={() => {
                              removeToCartHandler(item);
                            }}
                          >
                            <i className="ri-close-fill fs-16"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div
                  className="text-center empty-cart"
                  id="empty-cart"
                // style={{ display: "none" }}
                >
                  <div className="avatar-md mx-auto my-3">
                    <div className="avatar-title bg-soft-info text-info fs-36 rounded-circle">
                      <i className="bx bx-cart"></i>
                    </div>
                  </div>
                  <h5 className="mb-3">Your Cart is Empty!</h5>
                  {/* <Link
                    to="/apps-ecommerce-products"
                    className="btn btn-success w-md mb-3"
                  >
                    Shop Now
                  </Link> */}
                </div>
              )}
            </div>
          </SimpleBar>
          {Cart !== null && Cart.length > 0 && (
            <div
              className="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border d-grid"
              id="checkout-elem"
            >
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5 className="m-0 colorText2 text-9">Total:</h5>
                <div className="px-2">
                  <h5 className="m-0">
                    ₹{" "}
                    <span ref={cardItemTotal} id="cart-item-total">
                      {`${subTotal.toFixed(2)}`}
                    </span>
                  </h5>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <Link
                  to="/cart"
                  onClick={() => { setIsCartDropdown(!isCartDropdown); }}
                  className="btn margin-overview-button1 btn-primary text-center w-100 me-3"
                  style={{ borderRadius: '5px' }}
                >
                  View Cart
                </Link>
                <Link
                  to="/checkout"
                  onClick={() => { setIsCartDropdown(!isCartDropdown); }}
                  className="btn btn-success text-center w-100"
                  style={{ borderRadius: "5px" }}
                //onClick={paymentDetailsHandler}
                >
                  Checkout
                </Link>
              </div>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default MyCartDropdown;
