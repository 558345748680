import {
  API_TOTAL_REFERRED_SUCCESS,
  API_TOTAL_REFERRED_FAIL,
  GET_TOTAL_REFERRED,
} from "./actionType";

const INIT_STATE = {
  referredData: [],
};

const totalReferred = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_TOTAL_REFERRED_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TOTAL_REFERRED:
          return {
            ...state,
            referredData:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_TOTAL_REFERRED_FAIL:
      switch (action.payload.actionType) {
        case GET_TOTAL_REFERRED:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_TOTAL_REFERRED: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default totalReferred;
