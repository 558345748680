// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { isEmpty } from "lodash";
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Container,
//   Form,
//   Input,
//   Label,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   TabContent,
//   TabPane,
//   Button,
// } from "reactstrap";
// import copy from 'copy-to-clipboard';
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// //redux
// import { useSelector, useDispatch } from "react-redux";
// // actions
// import { ResetPhoneState } from "../../store/Profile/action";
// import { checkModificationStatus, checkModificationStatusNew } from "../../helpers/fakebackend_helper";
// import MobileNumberEditIndex from "../../Components/CustomComponents/Models/personalDetails/MobileNumberEditIndex";
// import EmailEditIndex from "../../Components/CustomComponents/Models/EmailChange/EmailEditIndex";
// import BankESign from "../../Components/CustomComponents/Models/BankEsign/bankEsign";

// const PersonalDetails = (props) => {

//   const [copy1, setCopy1] = useState(false);

//   const client_code = localStorage.getItem("client_id");
//   const dispatch = useDispatch();
//   const { data } = props;
//   const [checked, setChecked] = useState(true);
//   const [mobileModificationStatus, setMobileModificationStatus] = useState(false);
//   const [emailModificationStatus, setEmailModificationStatus] = useState(false);
//   useEffect(() => {
//     //console.log("reset");
//     dispatch(ResetPhoneState());
//   }, []);
//   const openState = {
//     CUpdate: true,
//     otp1: false,
//     vNumber1: false,
//     otp2: false,
//     vNumber2: false,
//     done: false,
//   };

// const copyToClipboard = () => {
//     //console.log("hello")
//     copy(data.DPID.trim() + data.DPCODE.trim());

//     setCopy1(true);
//     setTimeout(() => {
//       setCopy1(false);
//     }, 3000);
//     //alert(`You have copied "${copyText}"`);
//   }


//   useEffect(() => {
//     setMobileModificationStatus(false);

//     if (props.activeTab === "1") {
//       checkModificationStatus({
//         client_code: client_code,
//         entry_type: "mobile",
//        })
//         .then((response) => {
//           //console.log(response);
//           if (response.status == true) {
//             setMobileModificationStatus(true);
//           } else {

//             setMobileModificationStatus(false);
//           }

//         })
//         .catch((err) => {

//         });
//     }
//   }, [props.activeTab, data,]);
//   useEffect(() => {
//     setMobileModificationStatus(false);

//     if (props.activeTab === "1") {
//       checkModificationStatus({
//         client_code: client_code,
//         entry_type: "email",

//       })
//         .then((response) => {
//           //console.log(response);
//           if (response.status == true) {
//             setEmailModificationStatus(true);
//           } else {

//             setEmailModificationStatus(false);
//           }

//         })
//         .catch((err) => {

//         });
//     }
//   }, [props.activeTab, data,]);

//   console.log(openState,'openState');
//   // useEffect(() => {
//   //   if (props.activeTab === "1") {
//   //     checkModificationStatusNew({
//   //       client_code: client_code,
//   //       entry_type: "mobile",
//   //       esign_flag: "NO",
//   //     })
//   //       .then((response) => {
//   //         //console.log(response);
//   //         if (response.status === true) {
//   //           setOpenMobile(false);
//   //           setMobileModificationStatus(true);
//   //         } else {
//   //           setOpenMobile(true);
//   //           setMobileModificationStatus(false);
//   //         }
//   //         //props.sendData(modalMobile);
//   //       })
//   //       .catch((err) => {
//   //         ////console.log(err);
//   //       });
//   //   }
//   // }, [props.activeTab, data]);
//   //   const d = true

//   // if (openEmail) {
//   //   return <EmailEditIndex data={"mobile"} openState={openState} open={true} />
//   // }

//   return (
//     <>
//       <Row>
//         <Col className="profile-main-head">
//           <h4 className="text-9 text-color5 mb-3 profile-details-header ">
//             Personal Details
//           </h4>
//           {/* <p className="text-color2 text-15 mb-3">
//             Update your personal details here.
//           </p> */}
//         </Col>
//       </Row>
//       <Row className="align-items-center my-3">
//         <Col lg={2}>
//           <p className="text-101 text-color1 margin-0">Mobile Number</p>
//         </Col>
//         <Col lg={4} className="d-flex p-2 justify-content-between align-items-center">
//           <h4 className="text-color1 text-4 margin-0 ">
//             {!isEmpty(data) && data.MOBILE}
//           </h4>
//           {/* <button
//             className="text-11 text-color7 profile-edit-button1"
//             onClick={() =>
//                toast.info(
//             "Already we have one request for mobile update",
//             { autoClose: 1500 })
//             }
//           >
//             edit
//           </button> */}
//           {/* {openMobile === true ? (
//             <MobileNumberEditIndex data={"mobile"} openState={openState} data2={{
//               client_code: client_code,
//               pan_no: data.PANGIR,
//               field_name: "mobile",
//               esign_flag: "NO",
//             }} />
//           ) : (
//             <button
//               className="text-11 text-color7 profile-edit-button1"
//               onClick={() =>
//                 toast.info("Already we have one request for mobile update", {
//                   autoClose: 1500,
//                 })
//               }
//             >
//               Edit
//             </button>
//           )} */}
//         {mobileModificationStatus ? <p style={{backgroundColor : "#90EE90"}}>pending</p> : <MobileNumberEditIndex data={"mobile"} openState={openState} data2={{
//             client_code: client_code,
//             entry_type: "mobile"
//           }} 

//           />}  
//         </Col>
//       </Row>
//       {/* <div className="w-100 d-flex align-items-center mt-3 mb-4">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0">Mobile Number</p>
//         </div>
//         <div className="width5 d-flex p-2 justify-content-between">
//           <h4 className="text-color1 text-4 margin-0 ">(+91) 987 643 6543</h4>
//           <MobileNumberEditIndex data={"mobile"} />
//         </div>
//       </div> */}
//       {/* <Row className="align-items-center my-2">
//         <Col lg={2}>
//           <p className="text-4 text-color1 margin-0">Whatsapp Number</p>
//         </Col>
//         <Col
//           lg={4}
//           className={`d-flex p-2 justify-content-between ${
//             checked && "dative-mobile"
//           }`}
//         >
//           <h4 className="text-color1 text-4 margin-0 ">(+91) 987 643 6543</h4>
//           {!checked ? (
//             <MobileNumberEditIndex data={"mobile"} openState={openState}/>
//           ) : (
//             <button
//               disabled
//               className={`text-11 ${
//                 checked ? "text-color8" : "text-color7"
//               } profile-edit-button1`}
//             >
//               edit
//             </button>
//           )}
//         </Col>
//       </Row> */}
//       {/* <div className="w-100 d-flex align-items-center mt-3 mb-3">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0">Whatsapp Number</p>
//         </div>
//         <div
//           className={`width5 d-flex p-2 justify-content-between ${
//             checked && "dative-mobile"
//           }`}
//         >
//           <h4 className="text-color1 text-4 margin-0 ">(+91) 987 643 6543</h4>
//           <button
//             disabled={checked}
//             className={`text-11 ${
//               checked ? "text-color8" : "text-color7"
//             } profile-edit-button1`}
//           >
//             edit
//           </button>
//         </div>
//       </div> */}
//       {/* <Row className="align-items-center">
//         <Col lg={2}>
//           <p className="text-11 text-color1 margin-0">Mobile Number</p>
//         </Col>
//         <Col lg={4} className="d-flex p-2 justify-content-between">
//           <div className="form-check">
//             <Input
//               type="checkbox"
//               className="form-check-input"
//               id="same"
//               name="same"
//               onChange={(event) => sameFunction(event)}
//               checked={checked}
//             />
//             <Label className="form-check-label  text-color1 text-11" for="same">
//               Same as mobile number
//             </Label>
//           </div>
//         </Col>
//       </Row> */}
//       {/* <div className="w-100 d-flex align-items-center mt-1 mb-2">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0"> </p>
//         </div>
//         <div className="width5 d-flex p-2 justify-content-between">
//           <div className="form-check">
//             <Input
//               type="checkbox"
//               className="form-check-input"
//               id="same"
//               name="same"
//               onChange={(event) => sameFunction(event)}
//               checked={checked}
//             />
//             <Label className="form-check-label  text-color1 text-11" for="same">
//               Same as mobile number
//             </Label>
//           </div>
//         </div>
//       </div> */}
//       <Row className="align-items-center mt-2 mb-3">
//         <Col lg={2}>
//           <p className="text-101 text-color1 margin-0">Email ID</p>
//         </Col>
//         <Col lg={4} className="d-flex p-2 justify-content-between align-items-center">
//           <h4 className="text-color1 text-4 margin-0 ">
//             {!isEmpty(data) && data.EMAIL}
//           </h4>
//           {/* <button
//             className="text-11 text-color7 profile-edit-button1"
//             onClick={() =>

//             }
//           >
//             edit
//           </button> */}
//           {/* {openEmail === true ? (
//             <EmailEditIndex data={"mobile"} openState={openState} data2={{
//               client_code: client_code,
//               pan_no: data.PANGIR,
//               field_name: "email",
//               esign_flag: "NO",
//             }} />
//           ) : (
//             <button
//               className="text-11 text-color7 profile-edit-button1"
//               onClick={() =>
//                 toast.info("Already we have one request for email update", {
//                   autoClose: 1500,
//                 })
//               }
//             >
//               Edit
//             </button>
//           )} */}
//          { emailModificationStatus ?
//           <p style={{backgroundColor : "#90EE90"}}>pending</p> : 
//          <EmailEditIndex data={"mobile"} openState={openState} data2={{
//             client_code: client_code,
//             entry_type: "email"
//             // pan_no: data.PANGIR,
//             // field_name: "email",
//             // esign_flag: "NO", 
//           }} />}
//         </Col>
//       </Row>
//       {/* <div className="w-100 d-flex align-items-center mt-3 mb-4">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0">Email ID</p>
//         </div>
//         <div className="width5 d-flex p-2 justify-content-between">
//           <h4 className="text-color1 text-4 margin-0 ">joey@gmail.com</h4>
//           <button className="text-11 text-color7 profile-edit-button1">
//             edit
//           </button>
//         </div>
//       </div> */}
//       <Row>
//         <h4 className="text-9 text-color5 my-2">Demat Details</h4>
//       </Row>
//       <Row className="align-items-center mt-3 mb-1">
//         <Col lg={2}>
//           <p className="text-101 text-color1 margin-0">Demat ID</p>
//         </Col>
//         <Col
//           lg={4}
//           className="d-flex p-2 justify-content-between dative-mobile align-items-center"
//         >
//           <h4 className="text-color1 text-4 margin-0 ">
//             {!copy1 && !isEmpty(data) ? (data.DPID.trim() + data.DPCODE.trim()) : "Copied!"}
//           </h4>
//           <button
//             type="button"
//             onClick={copyToClipboard}
//             className="copy-to-clip-button2"
//           >
//             <i className="ri-file-copy-line text-4 text-color7"></i>
//           </button>
//         </Col>
//       </Row>
//       {/* <div className="w-100 d-flex align-items-center mt-3 mb-3">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0">Demat ID</p>
//         </div>
//         <div className="width5 d-flex p-2 justify-content-between dative-mobile align-items-center">
//           <h4 className="text-color1 text-4 margin-0 ">1234567890123456</h4>
//           <button
//             onClick={() => {
//               myFunction("1234567890123456");
//             }}
//             className="copy-to-clip-button2"
//           >
//             <i className="ri-file-copy-line text-4 text-color7"></i>
//           </button>
//         </div>
//       </div> */}
//       <Row className="align-items-center">
//         <Col lg={2}>
//           {/* <p className="text-11 text-color1 margin-0">Mobile Number</p> */}
//         </Col>
//         <Col lg={5} className="d-flex p-2 justify-content-between">
//           <h4 className="text-color8  text-11">
//             (For IPO application and CDSL TPIN authorisation)
//           </h4>
//         </Col>
//       </Row>
//       {/* <div className="w-100 d-flex align-items-center mt-2 mb-2">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0"> </p>
//         </div>
//         <div className="width3 d-flex p-2 justify-content-between">
//           <h4 className="text-color8  text-11">
//             (For IPO application and CDSL TPIN authorisation)
//           </h4>
//         </div>
//       </div> */}
//       <Row className="align-items-center my-3">
//         <Col lg={2}>
//           <p className="text-101 text-color1 margin-0">Depository Name</p>
//         </Col>
//         <Col
//           lg={4}
//           className="d-flex p-2 justify-content-between dative-mobile"
//         >
//           <h4 className="text-color1 text-4 margin-0 ">
//             {!isEmpty(data) && data.CDEPOSITORYNAME}
//           </h4>
//           {/* <MobileNumberEditIndex data={"mobile"} openState={openState}/> */}
//         </Col>
//       </Row>
//       {/* <div className="w-100 d-flex align-items-center mt-3 mb-4">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0">DP</p>
//         </div>
//         <div className="width5 d-flex p-2 justify-content-between dative-mobile">
//           <h4 className="text-color1 text-4 margin-0 ">
//             BIGUL Broking Limited
//           </h4>
//         </div>
//       </div> */}
//       <Row className="align-items-center my-3">
//         <Col lg={2}>
//           <p className="text-101 text-color1 margin-0">Depository</p>
//         </Col>
//         <Col
//           lg={4}
//           className="d-flex p-2 justify-content-between dative-mobile"
//         >
//           <h4 className="text-color1 text-4 margin-0 ">{!isEmpty(data) && data.DPID.trim() === "12033500" ? "CDSL" : "NSDL"}</h4>
//           {/* <MobileNumberEditIndex data={"mobile"} openState={openState}/> */}
//         </Col>
//       </Row>
//       {/* <div className="w-100 d-flex align-items-center mt-3 mb-5">
//         <div className="width4">
//           <p className="text-11 text-color1 margin-0">Depository</p>
//         </div>
//         <div className="width5 d-flex p-2 justify-content-between dative-mobile">
//           <h4 className="text-color1 text-4 margin-0 ">CDSL</h4>
//         </div>
//       </div> */}
//     </>
//   );
// };

// export default PersonalDetails;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { ResetPhoneState } from "../../store/Profile/action";
import { DDPIShameESign, SymphonyWithdrawalBalance, checkModificationStatus, checkModificationStatusNew, esignPayment, modificationEsign, sendApi } from "../../helpers/fakebackend_helper";
import MobileNumberEditIndex from "../../Components/CustomComponents/Models/personalDetails/MobileNumberEditIndex";
import EmailEditIndex from "../../Components/CustomComponents/Models/EmailChange/EmailEditIndex";
import MobileLinkedCompleted from "../../Components/CustomComponents/Models/personalDetails/MobileLinkedCompleted";
import axios from "axios";
import { MOD_EKYC_NEW, redirectApp } from "../../helpers/url_helper";
import Encripted from "../Dashboard/Encripted"
import Loader from "../../Components/Common/Loader";


const PersonalDetails = (props) => {

  const [copy1, setCopy1] = useState(false);
  const client_code = localStorage.getItem("client_id");
  const dispatch = useDispatch();
  const { data, Balance } = props;
 
  const [modificationMobile, setModificationMobile] = useState({});
  const [modificationEmail, setModificationEmail] = useState({});
  const [state, setState] = useState(0);
  const [EsignModal, setEsignModal] = useState(false);
  const [balance, setBalance] = useState(false);
  const [deseble, setDeseble] = useState(false);
  const [shouldContinueApi, setShouldContinueApi] = useState(false);
  const symphony_token = localStorage.getItem("symphony_token");
  const client_token = localStorage.getItem("client_token");
  const [esignName, setEsignName] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setShouldContinueApi(false)
  }, [props.activeTab === "1"])
  useEffect(() => {
    dispatch(ResetPhoneState());
  }, []);
  const openState = {
    CUpdate: true,
    otp1: false,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: false,
  };

  const copyToClipboard = () => {
    copy(data.DPID.trim() + data.DPCODE.trim());
    setCopy1(true);
    setTimeout(() => {
      setCopy1(false);
    }, 3000);

  }



  useEffect(() => {

    let intervalId;

    const callApi = () => {
      checkModificationStatus({
        client_code: client_code,
        entry_type: esignName,

      })
        .then((response) => {
          if (response.status == true && response?.data?.modification?.length > 0) {
            if (response?.data?.modification[0]?.is_esign == 1) {
              if (esignName == 'email') {
                setModificationEmail(response?.data?.modification[0])
              } else {
                setModificationMobile(response?.data?.modification[0])
              }
              setShouldContinueApi(false)
              setEsignModal(false)

            }

          } else {
            toast('something went wrong')
            // setShouldContinueApi(false)
          }
        })
        .catch((err) => {
          toast('something went wrong')

          // setShouldContinueApi(false)
        });


    };

    if (props.activeTab === "1" && shouldContinueApi) {
      intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
    }

    return () => {
      clearInterval(intervalId); // Cleanup on unmount or condition change
    };
  }, [props.activeTab === "1", shouldContinueApi]);


  useEffect(() => {
    setState(0)
    // setShouldContinueApi(false);
    // setEsignModal(false)
    if (props.activeTab === "1") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "mobile",
      })
        .then((response) => {
          if (response.status == true && response?.data?.modification?.length > 0) {
            setModificationMobile(response?.data?.modification[0])
          } else {
            setModificationMobile({})

          }

        })
        .catch((err) => {

        });
    }
  }, [props.activeTab === "1", data, state, EsignModal]);

  useEffect(() => {
    // setLoading(true)
    setState(0)
    // setMobileModificationStatus(false);
    if (props.activeTab === "1") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "email",
      })
        .then((response) => {
          // setLoading(false)

          //console.log(response);
          if (response.status == true && response?.data?.modification?.length > 0) {
            setModificationEmail(response?.data?.modification[0])
          } else {

            setModificationEmail({})

          }

        })
        .catch((err) => {
          // setLoading(false)
          toast('something went wrong')


        });
    }
  }, [data, state, EsignModal]);
  useEffect(() => {
    setLoading(true)
    setState(0)
    // setMobileModificationStatus(false);
    if (props.activeTab === "1") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "email",
      })
        .then((response) => {
          setLoading(false)

          //console.log(response);
          if (response.status == true && response?.data?.modification?.length > 0) {
            setModificationEmail(response?.data?.modification[0])
          } else {

            setModificationEmail({})

          }

        })
        .catch((err) => {
          setLoading(false)
          toast('something went wrong')


        });
    }
  }, [props.activeTab === "1"]);

  const handleStateChange = (newState, entry) => {
    setState(newState);
    setEsignName(entry)
    setEsignModal(true)

  };


  useEffect(() => {
    if (state === 1) {
      handleEsign(esignName);
    }
  }, [state])

  const handleEsign = (e) => {
    setEsignName(e)
    setEsignModal(true)
    setBalance(false)
  }

  const toggle = () => {
    setEsignModal(!EsignModal)
    setDeseble(false)
    // setShouldContinueApi(false)
  };


  const handleEsignConfirm = () => {
    setBalance(false)
    if (isNaN(Balance)) {
      toast.error('Unable to fetch your ledger balance. Please try after some time.');
      return; // Exit early if Balance is not a number
  }
    if (Balance < 25) {
      setBalance(true)
    }
    else {
     
 setDeseble(true)
      modificationEsign({
        client_code: client_code,
        entry_type: esignName,
      })
        .then((response) => {
          if (response?.status) {
            setDeseble(false)
            window.open(`${response?.redirectUrl}`, "_blank");
            setShouldContinueApi(true)
            setTimeout(() => {
              setEsignModal(false)
            }, 1000);
          }
          else {
            toast(response.message)
            setDeseble(false)
          }
        })
        .catch((err) => {
          setDeseble(false)
          toast('something went wrong')
        });
    }


  }

  const handlepay = () => {
    const payload = {
      ClientCode: client_code,
      amount: Balance > 0 ? (25 - Balance) : (Math.abs(Balance) + 25),
      redirectUrl: redirectApp,

    }
    window.location.href = 'https://payment.bigul.co/v1/?enc=' + Encripted([payload])

  }

  const handleCancel = async () => {
    setLoading(true)

    try {
      const payload = {
        client_code: client_code,
        entry_type: esignName
      };
      // const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', payload);
      const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', { 'enc': Encripted([payload]) });

      if (response?.status == true) {


        setEsignModal(false)
        setBalance(false)
        setShouldContinueApi(false)
        setTimeout(() => {
          setLoading(false)
        }, 1000);

      } else {
        setTimeout(() => {
          setLoading(false)
        }, 1000);

        toast.error('something went wrong please try again')
      }
    } catch (error) {
      setLoading(false)

      toast.error('something went wrong please try again')
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Modal isOpen={EsignModal} toggle={toggle}  >
        <ModalHeader toggle={toggle}>
          {balance ? 'Proceed to Pay' : 'Proceed to Esign'}
        </ModalHeader>
        <ModalBody>
          {
            balance ?
              <h4 className="text-9 text-color5 mt-3 text-center">
                The amount available in your ledger balance is not not sufficient for this transaction.
              </h4>
              :
              <h4 className="text-9 text-color5 mt-3 text-center">
                Please proceed to E-sign to confirm your request for modification. Rs. 25 (including GST) charge will be applicable for modification. The amount will be deducted from your ledger balance.
              </h4>
          }

        </ModalBody>
        <div className="d-flex justify-content-center">
          {
            balance ?
              <>
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handlepay}
                >
                  {" "}
                  Proceed to pay
                </button>
                <button
                  type="button"
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'gray',
                    color: 'white',
                    border: '1px solid gray',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleCancel}
                >
                  Cancel Request
                </button>
              </>
              :

              <>
                <button
                  type="button"
                  style={{ marginLeft: '10px' }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleEsignConfirm}
                  disabled={deseble}

                >
                  {" "}
                  {deseble ? 'please wait...' : 'Proceed to eSign'}

                </button>
                <button
                  type="button"
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'gray',
                    color: 'white',
                    border: '1px solid gray',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleCancel}
                >
                  Cancel Request
                </button>
              </>
          }

        </div>
      </Modal>
      <Row>
        <Col className="profile-main-head">
          <h4 className="text-9 text-color5 mb-3 profile-details-header ">
            Personal Details
          </h4>
        </Col>
      </Row>
      <Row className="align-items-center my-3">
        <Col lg={2} md={3}>
          <p className="text-101 text-color1 margin-0 mb-2">Mobile Number</p>
        </Col>
        <Col lg={5} md={5} className="d-flex  justify-content-between11 align-items-center">

          <h4 className="text-color1 text-15 margin-0 ">
            {!isEmpty(data) && data.MOBILE}
          </h4>
          {
            modificationMobile?.new_value ?
              null :
              <MobileNumberEditIndex data={"mobile"} openState={openState} data2={{
                client_code: client_code,
                entry_type: "mobile"
              }} state={state} onStateChange={handleStateChange} name={'mobile'}
              />
          }


        </Col>
      </Row>
      {
        modificationMobile?.new_value &&
        <div style={{ display: 'flex' }}>
          <p>Modification status of new mobile number {modificationMobile?.new_value} is </p>
          <div style={{ marginLeft: '5px' }}>

            {modificationMobile?.is_esign == 0 ?
              <p

                onClick={() => handleEsign('mobile')}

                style={{ color: '#5367fc', cursor: 'pointer' }}
              >
                {" "}
                Esign Pending.
              </p>
              :
              modificationMobile?.is_send_to_ld == 1 ?
                <p style={{ color: 'green' }}>In progress.</p> :
                modificationMobile?.is_esign == 1 ?
                  <p className="text-5 text-color10 m-0"

                  >Pending verification.</p>
                  : null
            }
          </div>

        </div>
      }


      <Row className="align-items-center mt-2 mb-3">
        <Col lg={2} md={3}>
          <p className="text-101 text-color1 margin-0">Email ID</p>
        </Col>
        <Col lg={6} md={5} className="d-flex justify-content-between11 align-items-center">
          <h4 className="text-color1 text-15 margin-0 ">
            {!isEmpty(data) && data?.EMAIL}

          </h4>
          {/* {
            modificationEmail?.new_value ?
              <h4 className="text-color1 text-4 margin-0 "> Old -
                {!isEmpty(data) && data?.EMAIL}
                <span className="d-block">New -{modificationEmail?.new_value}</span>
              </h4>
              :
              <h4 className="text-color1 text-4 margin-0 ">
                {!isEmpty(data) && data?.EMAIL}

              </h4>
          } */}

          {
            modificationEmail?.new_value ?
              null :
              <Col lg={4}>
                <EmailEditIndex data={"mobile"} openState={openState} data2={{
                  client_code: client_code,
                  entry_type: "email"

                }} state={state} onStateChange={handleStateChange} name={'email'} />
              </Col>
          }

          {/* {modificationEmail?.is_esign == 0 ?
            <button
              style={{ marginLeft: '20px' }}
              type="button"
              className="btn btn-primary modal-content-Button my-3"
              onClick={() => handleEsign('email')}
              disabled={shouldContinueApi}
            >
              {" "}
              Esign Pending
            </button>
            :
            modificationEmail?.is_send_to_ld == 1 ?
              <p style={{ color: 'green' }}>In progress</p> :
              modificationEmail?.is_esign == 1 ?
                <p className="text-5 text-color10 m-0"

                >Pending verification</p> :
                <Col lg={4}>
                  <EmailEditIndex data={"mobile"} openState={openState} data2={{
                    client_code: client_code,
                    entry_type: "email"

                  }} state={state} onStateChange={handleStateChange} name={'email'} />
                </Col>
          } */}
        </Col>
      </Row>
      {
        modificationEmail?.new_value &&
        <div style={{ display: 'flex' }}>
          <p>Modification status of new Email ID {modificationEmail?.new_value} is </p>
          <div style={{ marginLeft: '5px' }}>

            {modificationEmail?.is_esign == 0 ?
              <p

                onClick={() => handleEsign('email')}
                //  disabled={shouldContinueApi}
                style={{ color: '#5367fc', cursor: 'pointer' }}
              >
                {" "}
                Esign Pending.
              </p>
              :
              modificationEmail?.is_send_to_ld == 1 ?
                <p style={{ color: 'green' }}>In progress.</p> :
                modificationEmail?.is_esign == 1 ?
                  <p className="text-5 text-color10 m-0"

                  >Pending verification.</p>
                  : null
            }
          </div>

        </div>
      }
      <Row>
        <h4 className="text-9 text-color5 my-2 mt-5">Demat Details</h4>
      </Row>
      <Row className="align-items-center mt-3 mb-1">
        <Col lg={2}>
          <p className="text-101 text-color1 margin-0">Demat ID</p>
        </Col>
        <Col
          lg={4}
          className=""
        ><div className="mt-1 mt-lg-0 d-flex justify-content-between  align-items-center dative-mobile p-2">
            <h4 className="text-color1 text-4 margin-0 ">
              {!copy1 && !isEmpty(data) ? (data.DPID.trim() + data.DPCODE.trim()) : "Copied!"}
            </h4>

            <button
              type="button"
              onClick={copyToClipboard}
              className="copy-to-clip-button2"
            >
              <i className="ri-file-copy-line text-4 text-color7"></i>
            </button> </div>
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col lg={2}>
          {/* <p className="text-11 text-color1 margin-0">Mobile Number</p> */}
        </Col>
        <Col lg={5} className="">
          <div className="">
            <h4 className="text-color8  text-11">
              (For IPO application and CDSL TPIN authorisation)
            </h4>
          </div>
        </Col>
      </Row>

      <Row className="align-items-center my-3">
        <Col lg={2}>
          <p className="text-101 text-color1 margin-0">Depository Name</p>
        </Col>
        <Col
          lg={4}
          className=""
        > <div className="d-flex p-2 justify-content-between mt-1 mt-lg-0 dative-mobile">
            <h4 className="text-color1 text-4 margin-0 ">
              {!isEmpty(data) && data.CDEPOSITORYNAME}
            </h4>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center my-3">
        <Col lg={2}>
          <p className="text-101 text-color1 margin-0">Depository</p>
        </Col>
        <Col
          lg={4}
          className=""
        ><div className="d-flex p-2 justify-content-between mt-1 mt-lg-0 dative-mobile">
            <h4 className="text-color1 text-4 margin-0 ">{!isEmpty(data) && data.DPID.trim() === "12033500" ? "CDSL" : "NSDL"}</h4>
            {/* <MobileNumberEditIndex data={"mobile"} openState={openState}/> */}
          </div>
        </Col>
      </Row>

    </>
  );
};

export default PersonalDetails;
