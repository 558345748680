import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";
import { pledgeUpdate } from "../../../../helpers/fakebackend_helper";
import dayjs from "dayjs";

// TODO Ui and design and functions.
const ConfirmChanges1 = (props) => {
  const history = useNavigate();
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  
  const validationSchema = Yup.object().shape({
    condition: Yup.bool()
      .oneOf([true], "You need to accept the terms and conditions")
      .required("This field is required"),
  });

  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));

  const initialPackage = {
    condition: false,
  };
  const [formValues, setFormValues] = useState(initialPackage);

  const modal = {
    CChange1: false,
    CChange2: true,
  };

  const tog_signUpModals = () => {
    props.tog(false);
  };

  const onSubmit = () => {
    ////console.log("submit");
    props.sendData(modal);
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    //props.sendData(modal);
  };

  const onSubmitHandler2 = (e) => {
    const mainPledge =
      props.data.length > 0 && props.data2 === false
        ? props.data.filter((el) => {
            return el.checked === true;
          })
        : props.data.length > 0 && props.data2 === true
        ? props.data.filter((el) => {
            return el;
          })
        : [];
    //console.log(mainPledge);

    if (mainPledge.length > 0) {
      for (let i = 0; i < mainPledge.length; i++) {
        const values = {
          apiKey: generatedKey,
          code: encryptKey,
          date: dayjs(new Date()).format("DD-MM-YYYY"),
          scode: mainPledge[i].scripcode,
          isin: mainPledge[i].isin,
          dpcode: mainPledge[i].dpcode,
          dpid: mainPledge[i].dpid,
          pdpid: mainPledge[i].dpid,
          pdpcode:
            mainPledge[i].dpid.trim() === "IN301477" ? "10068648" : "02653093",
          qty: mainPledge[i].qty,
        };
        //console.log(values);

        pledgeUpdate(values)
          .then((response) => {
            //console.log(i);
            //console.log(response);
          })
          .catch((error) => {
            //console.log(i);
            //console.log(error);
          });
          if(i + 1 === mainPledge.length ) {
            setIsSubmit(true);
          }
        //console.log(i);
      }
    }
  };

  const compleateHandler = () => {
    history("/overview");
  }

  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom mb-0">
       { isSubmit === false ? 'Confirm' : null}
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        {isSubmit === false ?
          <>
          <h4 className="text-center text-15 text-color1 px-4">
          Thank you for giving a pledge request. Your depository will send you a
          confirmation message on your registered mobile number/ email. Please
          follow the instructions and confirm your pledge to enable limits.
        </h4>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-primary modal-content-Button my-3"
            onClick={(e) => onSubmitHandler2(e)}
          >
            Okay
          </button>
        </div>
        </> : <>
          <h4 className="text-center text-99 text-color1 px-4">
          Thank you 
        </h4>
        <h4 className="text-center text-15 text-color1 px-4">
          Your pledge request has been submitted
        </h4>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-primary modal-content-Button my-3"
            onClick={(e) => compleateHandler(e)}
          >
            Okay
          </button>
        </div>
        </>}
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default ConfirmChanges1;
