import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConformUpdate from './ConformUpdate';
import VerificationNumber from './VerificationNumber';
import LinkNewMobile from './LinkNewEmail';
import VerifyMobile from './VerifyMobile';
import MobileLinkedCompleted from './EmailLinkedCompleted';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { ResetPhoneState } from "../../../../store/Profile/action";
import { checkModificationStatus } from "../../../../helpers/fakebackend_helper";

const EmailEditIndex = (props) => {
  const dispatch = useDispatch();
  const { openState } = props;

  useEffect(() => {
    ////console.log('reset')
    dispatch(ResetPhoneState());
  }, [])
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  const incomeammount =profileDetails[0]?.NINCOME
  useEffect(() => {
    setModelMobile(openState)
  },[props])
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [modalMobile, setModelMobile] = useState({});
  ////console.log(openState);
  // const [modalEmail, setModelEmail] = useState({
  //   CUpdate: true,
  //   otp1: false,
  //   vNumber1: false,
  //   otp2: false,
  //   vNumber2: false,
  //   done: false
  // });
  ////console.log(modalMobile);


  const sendData = (data) => {
    ////console.log(data);
    //e.preventDefault();
    setModelMobile(data)
  }

  const tog_signUpModalsFinal = (data) => {
    // //console.log('submit')
    setmodal_signUpModals(!modal_signUpModals);
  }
 
  const tog_signUpModals = () => {
    setModelMobile(openState)
    setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        className="text-11 text-color7 profile-edit-button1"
        // onClick={() => tog_signUpModals()}
        onClick={() => {
          if(incomeammount <= 0){
            toast.error('To complete your requested modification, we need your most recent annual income information. Please update your profile with your current income details. Once your income is verified and updated in your account, you can proceed with your modification.')
            return
          }
          checkModificationStatus(props.data2)
            .then((response) => {
              ////console.log(response);
              if (response.status === true && response?.data?.modification?.length > 0) {
                toast.info("Already we have one request for Email ID update", {
                  autoClose: 1500,
                })
              } else {
                tog_signUpModals();
              }
              //props.sendData(modalMobile);
            })
            .catch((err) => {
              ////console.log(err);
            });
        }}
      >
        Edit
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {modalMobile.CUpdate && <ConformUpdate state={props.state} onStateChange={props.onStateChange} sendData={sendData} tog={tog_signUpModals} />}
        {modalMobile.otp1 && <VerificationNumber state={props.state} onStateChange={props.onStateChange} sendData={sendData} tog={tog_signUpModals} name={props?.name} />}
        {modalMobile.vNumber1 && <LinkNewMobile state={props.state} onStateChange={props.onStateChange} sendData={sendData} tog={tog_signUpModals} name={props?.name} />}
        {modalMobile.vNumber2 && <VerifyMobile state={props.state} onStateChange={props.onStateChange} sendData={sendData} tog={tog_signUpModals} name={props?.name} />}
        {modalMobile.done && <MobileLinkedCompleted state={props.state} onStateChange={props.onStateChange} sendData={tog_signUpModalsFinal} name={props?.name} />}
      </Modal>
    </React.Fragment>
  );
};

export default EmailEditIndex;
