

import "./Header.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUnconvertedLeads, getRemoveAllUnconvertedLeads } from "../../store/AllUnconvertedLeads/action"
import Loader from "../../Components/Common/Loader";
import {
  lastYear,
  referredOnAscendingSort,
  referredDOnescendingSort,
  dateFormaterForApi,
  // unCodeUser,
} from "../../helpers/helpingMethoads";
import dayjs from "dayjs";
import moment from "moment/moment";
import CustomPagination from "../../Components/referandEarnComponent/paginationIncome";
import CustomPaginationOP from "../../Components/referandEarnComponent/paginationIncomeOP";
import { POST_GET_REFERRAL_EARNING } from "../../helpers/url_helper";
import Encrypted from "../Dashboard/Encripted";

const Report = () => {
  const dispatch = useDispatch();
  const [isStatusOpened, setIsStatusOpened] = useState(false);
  const [isSortOpened, setIsSortOpened] = useState(false);

  const [createdDateSortToggle, setCreatedDateSortToggle] = useState(false);
  const [nameSortToggle, setNameSortToggle] = useState(false);
  const [startDate, setStartDate] = useState(dayjs(lastYear));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const [toggleValue, setToggleValue] = useState("inprocess");
  const [accOpnedFinalData, setAccOpnedFinalData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [state, setState] = useState("5");
  const [brokarage, setbrokarage] = useState("");
  //dropdown value
  const [selectedValue, setSelectedValue] = useState(10);
  const [selectedOpenedValue, setSelectedopenedValue] = useState(10);
  const [accOpeningInProcess, setAccOpeningInProcess] = useState([]);
  const [isLoader,setIsLoader] = useState(false)
  //pagination account opned
  const [currentPage, setCurrentPage] = useState(1);
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const unCodeUser = client_code
  // const unCodeUser = "Y0WX32"
  const handleStatusBody = () => {
    setIsStatusOpened(!isStatusOpened);
  };
  const handleSortBody = () => {
    setIsSortOpened(!isSortOpened);
  };

  const onChangeStartDate = (date) => {
    setStartDate(date.$d);
  };
  const onChangeToDate = (date) => {
    setEndDate(date.$d);
  };



  useEffect(() => {
    if (toggleValue === "inprocess") {
    
      dispatch(
        getAllUnconvertedLeads({
          duration: "custom_date_range",
          refcode: unCodeUser,
          start_date: dateFormaterForApi(startDate),
          end_date: dateFormaterForApi(endDate),
          limt: state,
          page: 1,
          client_code:client_code,
          client_token:client_token
        })
      );
    } else if (toggleValue === "opened") {
      const durationPass = "one_year"
      getTotalEarningShow({ durationPass })
      dispatch(getRemoveAllUnconvertedLeads());
    }
  }, [startDate, endDate, toggleValue, state]);

  const handleUnconvertedLeadsApi = () => {
   
    dispatch(
      getAllUnconvertedLeads({
        duration: "custom_date_range",
        refcode: unCodeUser,
        start_date: dateFormaterForApi(startDate),
        end_date: dateFormaterForApi(endDate),
        limt: state,
        page: 1,
        client_code:client_code,
        client_token:client_token
      })
    );
  };
  const { unconvertedLeads } = useSelector((state) => ({
    unconvertedLeads: state.allUnconvertedLeads,
  }));
  const unconvertedData1 = unconvertedLeads?.allUnconvertedLeads;
  const unconvertedData = unconvertedData1?.data?.list_arr;
  const unconvertedCount = unconvertedData1?.data?.count;
  useEffect(() => {
    if (unconvertedData) {
      const sortedData = unconvertedData.sort((a, b) => {
        return new Date(b.created_date) - new Date(a.created_date);
      });
      const adjustedSelectedValue = Math.min(selectedValue, sortedData?.length);
      setAccOpeningInProcess(unconvertedData?.slice(0, adjustedSelectedValue));

    }
  }, [unconvertedData, selectedValue]);

  useEffect(() => {
    if (createdDateSortToggle) {
      const sortedData = referredOnAscendingSort(accOpeningInProcess);
      setAccOpeningInProcess(sortedData);
    } else {
      const sortedData = referredDOnescendingSort(accOpeningInProcess);
      setAccOpeningInProcess(sortedData);
    }
  }, [createdDateSortToggle]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  // pagination account opned function
  const entriesPerPage = parseInt(selectedValue, 10);
  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;
  const paginatedData = unconvertedData?.slice(startIndex, endIndex);

  const totalPages = Math.ceil(unconvertedCount / entriesPerPage);

  const handleReferredSorting = () => {
    setCreatedDateSortToggle(!createdDateSortToggle);

  };



  // pagination account opneding process function
  const [currentPageOP, setCurrentPageOP] = useState(1)
  const handlePageChangeOP = (newPage) => {
    setCurrentPageOP(newPage);
  };
  const startIndexOP = (currentPageOP - 1) * entriesPerPage;
  const endIndexOP = startIndexOP + entriesPerPage;
  const OPData = responseData && responseData?.slice(startIndexOP, endIndexOP);

  const totalPagesOP = Math.ceil(responseData?.length / entriesPerPage);

  useEffect(() => {
    if (nameSortToggle) {
      const sortedData = nameAscendingSort(accOpeningInProcess);
      setAccOpeningInProcess(sortedData);
    } else {
      const sortedData = nameDescendingSort(accOpeningInProcess);
      setAccOpeningInProcess(sortedData);
    }
  }, [nameSortToggle]);

  const handleNameSorting = () => {
    setNameSortToggle(!nameSortToggle);
  };

  const nameAscendingSort = (data) => {
    const sortedData = [...data].sort((a, b) => (a.name > b.name ? 1 : -1));
    return sortedData;
  };

  const nameDescendingSort = (data) => {
    const sortedData = [...data].sort((a, b) => (a.name > b.name ? -1 : 1));
    return sortedData;
  };

  const dateAscendingSort = (data) => {
    const sortedData = [...data].sort((a, b) => {
      return new Date(a.dopeningdate) - new Date(b.dopeningdate);
    });
    return sortedData;
  };

  const dateDescendingSort = (data) => {
    const sortedData = [...data].sort((a, b) => {
      return new Date(b.dopeningdate) - new Date(a.dopeningdate);
    });
    return sortedData;
  };

  const [openingDateSortToggle, setOpeningDateSortToggle] = useState(false);

  const [openingNameSortToggle, setOpeningNameSortToggle] = useState(false);

  const handleOpeningDateSort = () => {
    if (openingDateSortToggle) {
      const sortedData = dateAscendingSort(accOpnedFinalData);
      setAccOpnedFinalData(sortedData);
      setOpeningDateSortToggle(!openingDateSortToggle);
    } else {
      const sortedData = dateDescendingSort(accOpnedFinalData);
      setAccOpnedFinalData(sortedData);
      setOpeningDateSortToggle(!openingDateSortToggle);
    }
  };

  const handleOpeningNameSorting = () => {
    if (openingNameSortToggle) {
      const sortedData = nameAscendingSort(accOpnedFinalData);
      setAccOpnedFinalData(sortedData);
      setOpeningNameSortToggle(!openingNameSortToggle);
    } else {
      const sortedData = nameDescendingSort(accOpnedFinalData);
      setAccOpnedFinalData(sortedData);
      setOpeningNameSortToggle(!openingNameSortToggle);
    }
  };

  const getTotalEarningShow = async ({ durationPass }) => {
    setIsLoader(true)
    try {
      const apiUrl = POST_GET_REFERRAL_EARNING;
      const payload = {
        duration: durationPass,
        refcode: unCodeUser,
        client_code:client_code,
        client_token:client_token
      };
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'enc':Encrypted([payload])}),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoader(false)
      setResponseData(data?.data?.list_arr);
      setbrokarage(data?.data?.total_brok_earning)
    } catch (error) {
      setIsLoader(false)
      console.error('Error fetching data:', error);
    }
  };

  const handleIncomeClientToggle = (name) => {
    setToggleValue(name);
  };
console.log(":>>>",unconvertedLeads?.allUnconvertedLeads?.data?.list_arr?.length == 0 );

  return (
    <div
      className="main_wr pt-2"
    >
      <div className="container">
        <div className="row main_body_wr">
          <div className="main_body_heading">
            <div className="col-lg-8">
              {/* <ToggleButton onModalOpenedDataReceived={handleRecivedData} />
               */}
              <div className="income_client_switch_wr">
                {toggleValue === "inprocess" ? (
                  <button
                    className="income_button_first ative_button"
                    onClick={() => handleIncomeClientToggle("inprocess")}
                  >
                    <div className="client_wr income_wr_first ative_button_text">
                      <span>A/c Opening in Process</span>
                    </div>
                  </button>
                ) : (
                  <button
                    className="income_button_first deative_button"
                    onClick={() => handleIncomeClientToggle("inprocess")}
                  >
                    <div className="client_wr income_wr_first deactive_btn_text">
                      <span>A/c Opening in Process</span>
                    </div>
                  </button>
                )}

                {toggleValue === "opened" ? (
                  <button
                    className=" income_button_first ative_button"
                    onClick={() => handleIncomeClientToggle("opened")}
                  >
                    <div className="client_wr ative_button_text">
                      <span>A/c Opened</span>
                    </div>
                  </button>
                ) : (
                  <button
                    className="income_button_first deative_button"
                    onClick={() => handleIncomeClientToggle("opened")}
                  >
                    <div className="client_wr deactive_btn_text">
                      <span>A/c Opened</span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="body_wr" style={{ minHeight: "400px" }}>
            <div className="clien_wise_text bg-white">
              <div className=" d-flex monthly_cat_select ">
                <div className="d-flex ">
                  {toggleValue === "inprocess" ? (
                    <h4>Account Openings in Process</h4>
                  ) : (
                    <div style={{flexDirection:'row',alignItems:"center"}}>
                    <h4>Accounts Opened</h4>
                    <p  className="acc_process_heading_text" style={{fontSize:'14px',marginLeft:"10px",marginTop:'5px'}}>Last 12 months A/c Opened</p>

                    </div>
                  )}
                </div>
                {toggleValue === "inprocess" ? (
                  <div className="d-flex col-md-4 show_ertry_wr">
                    {/* <div className="show_text">Show inprocess</div> */}
                    <div className="lead_filter d-flex">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedValue}
                        onChange={(e) => {
                          setSelectedValue(parseInt(e.target.value, 10));
                        }}
                      >
                        <option selected value="5">
                          5
                        </option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="show_text">entries</div>
                  </div>) : (
                  <div className="d-flex col-md-4 show_ertry_wr">
                    {/* <div className="show_text">Show opened</div> */}
                    <div className="lead_filter  d-flex">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedOpenedValue}
                        onChange={(e) => {
                          setSelectedopenedValue(parseInt(e.target.value, 10));
                        }}
                      //       onChange={(e) => handleAllLeadsLimit(e)}
                      >
                        <option selected value="5">
                          5
                        </option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>q
                      </select>
                    </div>
                    <div className="show_text">entries</div>
                  </div>
                )}
              </div>
              <div className="client_wise_income_table_wr mt-3">
                <div className="table-responsive">
                  {toggleValue === "inprocess" ? (
                    <div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">SR.no</th>
                            <th cope="col">
                              <div onClick={() => handleNameSorting()}>
                                Name
                              </div>
                            </th>
                            <th cope="col">Mobile no</th>
                            <th cope="col">
                              <div onClick={() => handleReferredSorting()}>
                                Referred On
                              </div>
                            </th>
                            <th cope="col">Stage On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.length === 0 ? (
                            <tr>
                              <td colSpan="5" className="centered-paragraph">
                              {/* {unconvertedLeads?.allUnconvertedLeads?.data?.list_arr?.length == 0 ? 
                              <div style={{ height: '200px' }}>
                              <Loader />
                              </div>
                            :
                            "No records found!"
                            } */}
                                No records found!
                              </td>
                            </tr>
                          ) : (
                            paginatedData?.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1 + startIndex}</td>
                                <td>
                                  {item?.fullname !== "  " && item?.fullname !== null
                                    ? item?.fullname
                                    : "NA"}
                                </td>
                                <td>{item?.mob_number ? item?.mob_number : 'NA'}</td>
                                <td>{item?.created_date ? moment(item?.created_date).format("DD-MMM-YYYY") : 'NA'}</td>
                                {/* <td>{item?.step ? getStageNameForSteps(parseInt(item?.step)) : 'NA'}</td> */}
                                <td>{item?.step_name ? item?.step_name : "NA"}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      {unconvertedData?.length === 0 ?
                        null
                        :
                        <CustomPagination currentPage={currentPage} totalPages={totalPages} paginate={handlePageChange} />
                      }
                    </div>
                  ) : (
                    <>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">SR.no</th>
                            <th
                              cope="col"
                              onClick={() => handleOpeningNameSorting()}
                            >
                              <span>Name</span>
                            </th>
                            <th cope="col">Mobile no</th>
                            <th cope="col" onClick={() => handleOpeningDateSort()}>
                              Account Opening Date

                            </th>
                            <th cope="col">Account Opening Income (₹)</th>
                            <th cope="col">Brokerage Income (₹)</th>
                          </tr>
                        </thead>

                        <tbody>
                          {OPData && OPData.length > 0 ? (
                            OPData.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.MOBILE}</td>
                                <td>{moment(item?.dopeningdate).format("DD-MMM-YYYY")}</td>
                                <td>{item?.AcOpenEarnAmount}</td>
                                <td>{item?.BrokerageEarnAmount}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="centered-paragraph">
                                {/* No records found! */}
                                {isLoader == false ? 
                              "No records found!"
                              :
                              <div style={{ height: '200px' }}>
                              <Loader />
                              </div>
                            }
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {OPData && OPData.length > 0 ?
                        <CustomPaginationOP currentPage={currentPageOP} totalPages={totalPagesOP} paginate={handlePageChangeOP} />
                        :
                        null}

                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;

