import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_TOTAL_REFERRED } from "./actionType";

import {
  totalReferredApiResponseError,
  totalReferredApiResponseSuccess,
} from "./action";

import { postTotalReferredApi } from "../../helpers/fakebackend_helper";

function* totalReferred(data) {
  try {
    const response = yield call(postTotalReferredApi, data.payload);
    yield put(totalReferredApiResponseSuccess(GET_TOTAL_REFERRED, response));
  } catch (error) {
    yield put(totalReferredApiResponseError(GET_TOTAL_REFERRED, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_TOTAL_REFERRED, totalReferred);
}

function* TotalReferredSaga() {
  yield all([fork(watchGetCart)]);
}

export default TotalReferredSaga;
