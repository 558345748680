import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import * as moment from "moment";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

import Loader1 from "../../Components/Common/Loader1";
//action
import { getPAndLCash } from "../../store/statements/action";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const PAndLCash = (props) => {
  const [counter, setCounter] = useState(0);
  const [total, setTotal] = useState("");
  const [includeCharges, setIncludeCharges] = useState(false);
  const dispatch = useDispatch();
  const [startDate1, setStartDate1] = useState(new Date(2015, 3, 1));

  const [endDate1, setEndDate1] = useState(new Date());
  const [isDownload, setIsDownload] = useState(false);
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });
  const { generatedKey } = useSelector((state) => ({
    generatedKey: state.GenerateKey.generatedKey,
  }));
  const { encryptKey } = useSelector((state) => ({
    encryptKey: state.EncryptKey.encryptKey,
  }));
  const { PAndLCash } = useSelector((state) => ({
    PAndLCash: state.Statements.PAndLCash,
  }));
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  //console.log(PAndLCash);
  useEffect(() => {
    dispatch(
      getPAndLCash({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
        charges: "N",
      })
    );
  }, [dispatch]);
  const DateFilter = () => {
    setCounter(2);
    dispatch(
      getPAndLCash({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
        charges: includeCharges === true ? "Y" : "N",
      })
    );
  };
  //console.log(total)

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    if (PAndLCash.length === 0) {
      setTotal(0);
    }
  }, [PAndLCash]);

  function removeValue(value, index, arr) {
    //console.log(value)
    // //console.log(index)
    // //console.log(arr)
    // If the value at the current array index matches the specified value (2)
    if (
      //value.LNarrat === "Opening Balance       " ||
      value.SCRIPNAME === "Total"
    ) {
      // Removes the value from the original array
      setTotal(arr[index]);
      arr.splice(index, 1);
      return true;
    }
    return false;
  }

  // Pass the removeValue function into the filter function to return the specified value
  const x = PAndLCash.filter(removeValue);
  const TradeBookCash = [
    {
      ScriptCode: "31 INFOTECH",
      ScriptName: "31 INFOTECH",
      Qty: "1",
      Invested: "42.42",
      Realized: "-56.00",
      Unrealized: "0.00",
      Total: "-56.00",
    },
    {
      ScriptCode: "AVONMORE CAPITAL",
      ScriptName: "AVONMORE CAPITAL & M",
      Qty: "0",
      Invested: "75.42",
      Realized: "0.00",
      Unrealized: "-40.00",
      Total: "-56.00",
    },
    {
      ScriptCode: "AVONMORE CAPITAL",
      ScriptName: "AVONMORE CAPITAL & M",
      Qty: "0",
      Invested: "75.42",
      Realized: "0.00",
      Unrealized: "-40.00",
      Total: "-56.00",
    },
    {
      ScriptCode: "AVONMORE CAPITAL",
      ScriptName: "AVONMORE CAPITAL & M",
      Qty: "0",
      Invested: "75.42",
      Realized: "0.00",
      Unrealized: "-40.00",
      Total: "-56.00",
    },
    {
      ScriptCode: "AVONMORE CAPITAL",
      ScriptName: "AVONMORE CAPITAL & M",
      Qty: "0",
      Invested: "75.42",
      Realized: "0.00",
      Unrealized: "-40.00",
      Total: "-56.00",
    },
  ];

  const AmountFormatter = (data) => {
    //console.log(data.value);
    switch (true) {
      case data.value > 0:
        return (
          <div className="text-color6 ms-5"> {data.value.toFixed(2)} </div>
        );
      case data.value < 0:
        return (
          <div className="text-color11 ms-5"> {data.value.toFixed(2)} </div>
        );
      default:
        return <div className="ms-5"> {data.value.toFixed(2)} </div>;
    }
  };

  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   //TODO
      //   Header: "#",
      //   Cell: (cell) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="productCheckBox form-check-input"
      //         value={cell.row.original._id}
      //         //onClick={() => displayDelete()}
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Script Code",
      //   accessor: "scripcode",
      //   filterable: true,
      // },
      {
        //Header: "Scrip Name",
        Header: <h4 className="d-flex m-0 fs-13"> Scrip Name </h4>,
        accessor: "SCRIPNAME",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex"> {cell.value} </span>;
        },
      },
      {
        Header: "Buy Qty",
        accessor: "BQTY",
        filterable: true,
      },
      {
        //Header: "Buy Avg Price (₹)",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            {" "}
            Buy Avg Price(₹){" "}
          </h4>
        ),
        accessor: "BAVG",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {" "}
              {cell.value.toFixed(2)}{" "}
            </span>
          );
        },
        // Cell: (cell) => {
        //   return cell.value.toFixed(2)
        // },
      },
      {
        //Header: "Buy Value",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13"> Buy Value </h4>
        ),
        accessor: "BVALUE",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {" "}
              {cell.value.toFixed(2)}{" "}
            </span>
          );
        },
      },
      {
        Header: "Sell Qty",
        accessor: "SQTY",
        filterable: true,
      },
      {
        // Header: "Sell Avg Price",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            {" "}
            Sell Avg Price{" "}
          </h4>
        ),
        accessor: "SAVG",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {" "}
              {cell.value.toFixed(2)}{" "}
            </span>
          );
        },
      },
      // {
      //   Header: "Buy Date",
      //   accessor: "lexp",
      //   Cell: (cell) => {
      //     return dayjs(cell.value).format(" DD MMM YYYY");
      //   },
      // },

      {
        //Header: "Sell Value",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13"> Sell Value </h4>
        ),
        accessor: "SVALUE",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {" "}
              {cell.value.toFixed(2)}{" "}
            </span>
          );
        },
      },
      {
        Header: "Net Holding",
        accessor: "NQTY",
        filterable: true,
      },
      {
        //Header: "Market Rate",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            {" "}
            Market Rate{" "}
          </h4>
        ),
        accessor: "MARKET",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {" "}
              {cell.value.toFixed(2)}{" "}
            </span>
          );
        },
      },
      {
        //Header: "Market Value",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            {" "}
            Market Value{" "}
          </h4>
        ),
        accessor: "MARKVALUE",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {" "}
              {cell.value.toFixed(2)}{" "}
            </span>
          );
        },
      },
      // {
      //   Header: "Invested (₹)",
      //   accessor: "aavg",
      //   filterable: true,
      //   Cell: (cell) => {
      //     return cell.value.toFixed(2)
      //   },
      // },
      // {
      //   Header: "Realized (₹)",
      //   accessor: "realgain",
      //   filterable: true,
      //   Cell: AmountFormatter,
      // },
      // {
      //   Header: "Unrealized (₹)",
      //   accessor: "unrealgain",
      //   filterable: true,
      //   Cell: AmountFormatter,
      // },
      {
        //Header: "Profit / Loss",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            {" "}
            Profit / Loss{" "}
          </h4>
        ),
        accessor: "Pnl",
        filterable: true,
        Cell: (cell) => {
          switch (true) {
            case cell.value > 0:
              return (
                <div className="text-color6 d-flex justify-content-end">
                  {" "}
                  {cell.value.toFixed(2)}{" "}
                </div>
              );
            case cell.value < 0:
              return (
                <div className="text-color11 d-flex justify-content-end">
                  {" "}
                  {cell.value.toFixed(2)}{" "}
                </div>
              );
            default:
              return (
                <div className="d-flex justify-content-end">
                  {" "}
                  {cell.value.toFixed(2)}{" "}
                </div>
              );
          }
        },
        //Cell: AmountFormatter,
      },
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  const myArray = [];
  for (let i = 0; i < PAndLCash.length; i += 1) {
    const childArray = PAndLCash[i];
    const obj = {
      // scripcode: childArray.scripcode.replace("#", ""),
      SCRIPNAME: childArray.SCRIPNAME.replace("#", ""),
      BQTY: childArray.BQTY,
      BAVG: childArray.BAVG,
      BVALUE: childArray.BVALUE,
      SQTY: childArray.SQTY,
      SAVG: childArray.SAVG,
      SVALUE: childArray.SVALUE,
      NQTY: childArray.NQTY,
      MARKET: childArray.MARKET,
      MARKVALUE: childArray.MARKVALUE,
      Pnl: childArray.Pnl,
    };
    myArray.push(obj);
  }

  const userHeader = [
    "Buy Qty",
    "Quantity",
    "Buy Avg Price",
    "Buy Value",
    "Sell Qty",
    "Sell Avg Price",
    "Sell Value",
    "Net Holding",
    "Market Rate ",
    "Market value",
    "Profit / Loss",
  ];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${
      !isEmpty(profileDetails) ? profileDetails[0].FIBSACCT.trim() : "USER"
    }_${dayjs(new Date()).format("YYYY-MM-DD")}_PAndLCash`
  );
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const downloadHandler = () => {
    setIsDownload(true);
  };
  console.log(total);
  return (
    <React.Fragment>
      {" "}
      {/* <div>
                                                                                                            <div className="d-flex justify-content-between p-3">
                                                                                                              <div className="input-group input-group-width-25">
                                                                                                                <Flatpickr
                                                                                                                  placeholder="Select date"
                                                                                                                  id="exampleInputdate"
                                                                                                                  className="form-control summary-table-date"
                                                                                                                  options={{
                                                                                                                    mode: "range",
                                                                                                                    dateFormat: "d M, Y",
                                                                                                                    maxDate: new Date(),
                                                                                                                  }}
                                                                                                                  value={selectDate.date}
                                                                                                                  onChange={(date) => {
                                                                                                                    setSelectDate({ date: date });
                                                                                                                  }}
                                                                                                                />
                                                                                                                <Label htmlFor="exampleInputdate" className="form-label">
                                                                                                                  <span
                                                                                                                    className="input-group-text summary-table-date px-2 py-0"
                                                                                                                    id="basic-addon1"
                                                                                                                  >
                                                                                                                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                                                                                                                  </span>
                                                                                                                </Label>
                                                                                                              </div>
                                                                                                              <div></div>

                                                                                                              <div className="d-flex">
                                                                                                                <button
                                                                                                                  type="button"
                                                                                                                  className="btn btn-success text-4 margin-overview-button2"
                                                                                                                  style={{ borderRadius: "5px" }}
                                                                                                                  onClick={downloadHandler}
                                                                                                                >
                                                                                                                  <i className="ri-download-2-line me-2 align-bottom"></i>
                                                                                                                  Download Report
                                                                                                                </button>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </div> */}{" "}
      <div>
        <div
          className="d-flex justify-content-between align-items-end  p-3 mobile-display-block"
          style={{ borderBottom: "1px solid #8080802b" }}
        >
          <div>
            <div className="d-flex align-items-end mobile-display-block">
              <div className="input-group-width-50 me-4 mb-2">
                <h5 className="text-10 text-center-statement"> From </h5>{" "}
                <div className="d-flex">
                  <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date text-center-statement"
                    options={{
                      //mode: "range",
                      dateFormat: "d M, Y",
                      maxDate: new Date(),
                      minDate: new Date(2015, 3, 1),
                      // enable: [
                      //   function (date) {
                      //     return date >= startDate1;
                      //   },
                      // ],
                    }}
                    value={startDate1}
                    onChange={(date) => {
                      ////console.log(date);
                      setStartDate1(date[0]);
                    }}
                  />{" "}
                  {/* <Label htmlFor="exampleInputdate" className="form-label">
                                                                                                              <span
                                                                                                                className="input-group-text summary-table-date px-2 py-0"
                                                                                                                id="basic-addon1"
                                                                                                              >
                                                                                                                <i className="ri-calendar-2-line fs-4 text-color1"></i>
                                                                                                              </span>
                                                                                                            </Label> */}{" "}
                </div>{" "}
              </div>{" "}
              <div className="input-group-width-50 me-4 mb-2">
                <h5 className="text-10 text-center-statement"> To </h5>{" "}
                <div className="d-flex">
                  <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date text-center-statement"
                    options={{
                      //mode: "range",
                      dateFormat: "d M, Y",
                      maxDate: new Date(),
                      enable: [
                        function (date) {
                          return date >= startDate1;
                        },
                      ],
                    }}
                    value={endDate1}
                    onChange={(date) => {
                      ////console.log(date);
                      setEndDate1(date[0]);
                    }}
                  />{" "}
                  {/* <Label htmlFor="exampleInputdate" className="form-label">
                                                                                              <span
                                                                                                className="input-group-text summary-table-date px-2 py-0"
                                                                                                id="basic-addon1"
                                                                                              >
                                                                                                <i className="ri-calendar-2-line fs-4 text-color1"></i>
                                                                                              </span>
                                                                                            </Label> */}{" "}
                </div>{" "}
              </div>{" "}
              <div className="input-group-width-50 mb-2">
                <div className="d-flex align-items-center mobile-statement-input-center">
                  <input
                    className="form-check-input summary-table-date2 me-3"
                    style={{ height: "22px", width: "22px" }}
                    type="checkbox"
                    id="formCheck16"
                    value="Equity Cash - BSE, NSE"
                    onChange={(event) => setIncludeCharges(!includeCharges)}
                    //checked={checked.nse}
                  />{" "}
                  <h5 className="text-10 m-0"> Include Charges </h5>{" "}
                </div>{" "}
              </div>{" "}
              <div className="statement-search">
                <button
                  type="button"
                  className="text-4 mb-2 btn btn-primary margin-overview-button1"
                  style={{ borderRadius: "5px", height: "50%" }}
                  onClick={DateFilter}
                >
                  Search{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
            <div>
              {" "}
              <span style={{ color: "#878a99", fontSize: "smaller" }}>
                {" "}
                Note : P&L(Cash) includes data post 2015{" "}
              </span>{" "}
            </div>{" "}
          </div>{" "}
          <div className="text-center d-flex d-md-block align-items-center mt-2 mt-sm-0 w-md-auto justify-content-between">
            <h5 className="text-10 text-color7 mb-2 text-end pe-2 pe-md-0">
              {" "}
              P&L:{" "}
              <span
                className={`text-10 mb-2 ${
                  total !== "" && total !== 0 && total.Pnl > 0
                    ? "text-color6"
                    : total !== "" && total !== 0 && total.Pnl < 0
                    ? "text-color11"
                    : "text-color7"
                }`}
              >
                {" "}
                {total !== "" && total !== 0
                  ? total.Pnl.toLocaleString("en-IN").toString()
                  : 0}{" "}
              </span>{" "}
            </h5>{" "}
            <div className="d-flex download-button-mobile">
              <button
                type="button"
                className="btn btn-success text-4 mb-2 margin-overview-button2"
                style={{ borderRadius: "5px", height: "50%" }}
                onClick={downloadHandler}
                disabled={PAndLCash.length === 0}
              >
                <i className="ri-download-2-line me-2 align-bottom"> </i>
                Download Report{" "}
              </button>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      {counter === 0 && PAndLCash.length ? (
        <TableContainer2
          columns={columns}
          data={PAndLCash.reverse() || []}
          isGlobalFilter={true}
          isAddUserList={false}
          customPageSize={8}
          divClass="table-responsive table-card mb-1"
          tableClass="align-middle table-nowrap text-center"
          theadClass="table-light text-muted text-uppercase fs-13"
          tableTrClasses="summary-table-tr px-4"
          // handleOrderClick={handleCustomerClicks}
          isOrderFilter={true}
          SearchPlaceholder="Search for order ID, customer, order status or something..."
        />
      ) : counter !== 0 ? (
        <Loader error={"empty"} />
      ) : (
        <Loader1 error={"empty"} />
      )}{" "}
    </React.Fragment>
  );
};

export default PAndLCash;
