import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams } from 'react-router';
//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

// Algo Dashboard components
import Widgets from "./Widgets";
import MyInvestedAlgos from "../Dashboard/MyInvestedAlgos";
import SuggestedAlgos from "./SuggestedAlgos";
import Summary from "./Summary";
import SuggestedMyAlgos from "./SuggestedMyAlgos";

const AlgoDashboard = () => {
  const { id } = useParams();
  document.title = "Bigul Admin & Dashboard";

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="My Algo Dashboard"
            titleMain="My Algo Dashboard"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />
          <Row>
            <Widgets />
          </Row>
          <Row className="spacing-remove">
            <SuggestedMyAlgos />
          </Row>
          <Row className="pt-1 spacing-remove">
            <MyInvestedAlgos />
            <SuggestedAlgos />
          </Row>
          <Row className="spacing-remove-demounting2 margin-top-demounting15 ">
            <Summary />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AlgoDashboard;
