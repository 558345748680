import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

//Dashboard components
import EcommerceCheckout from './EcommerceCheckout';
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";
import { VerifyOtpStateReset } from '../../store/VerifyOTP/action';

const Checkout = () => {
  const dispatch = useDispatch();
  document.title = "Bigul Admin & Dashboard";
  const generatedKey  = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
  const { VerifyOtpData } = useSelector((state) => ({
    VerifyOtpData: state.VerifyOTP.oderDetails,
  }));

  useEffect(() => {
      dispatch(VerifyOtpStateReset());
  },[]);

  return (
    
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Checkout"
            titleMain="CHECKOUT"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="My Cart"
            to='/cart'
          />
          <Row>
            <EcommerceCheckout  generatedKey={generatedKey} encryptKey={encryptKey} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Checkout;
