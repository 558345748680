import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";
import total_invested from "../../assets/images/logo/total_invested.png";
import Encripted from "../../pages/Dashboard/Encripted";

const Widget4 = (props) => {
  const { item, bgColor, bg, data } = props;
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const addFundHandler = () => {
    window.open(
      `https://payment.bigul.co/?ClientCode=${Encripted([])}`,
      "_blank"
    );
  };

  const amountFormatter = (data) => {
    if (+data > 0) {
      //  ////console.log(data);
      return `${data.toString()}`;
    }
    //  ////console.log(data);
    return `${data.toString().slice(1)}`;
  };

  return (
    <Col xl={3} lg={6} md={6} id="suggested-algos">
      <Card className="card-invest-algo">
        <CardBody>
          <div className="d-flex align-items-center mb-3">
            <div className="flex-grow-1">
              <p className={"text-uppercase text-1 mb-0 text-color1"}>
                Available Margin
              </p>
            </div>
            {item.percentage && bgColor && (
              <div className="flex-shrink-0 align-self-end">
                <span
                  className={`badge text-3 ${bgColor}`}
                  style={{ borderRadius: "5px" }}
                >
                  <i className={"align-middle me-1 " + item.badge}></i>{" "}
                  {item.percentage}
                </span>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h4 className="mb-0">
                {" "}
                <CountUp
                  start={0}
                  end={amountFormatter(data)}
                  decimals={item.decimal}
                  separator={item.separator}
                  prefix={item.prefix}
                  duration={3}
                  //className={`text-2 text-color1`}
                  className={`text-2 ${
                    data > 0 ? "text-color11" : "text-color6"
                  }`}
                />
              </h4>
            </div>
            <div className="">
              <button
                type="button"
                className="btn btn-primary btn-sm text-5"
                onClick={addFundHandler}
              >
                + Add Funds
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Widget4;
