import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_MY_FEATURE_PLAN } from "./actionType";

import {
  MyFeaturePlanApiResponseSuccess,
  MyFeaturePlanApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getMyFeaturePlanApi } from "../../helpers/fakebackend_helper";

function* getMyFeaturePlan(data) {
  ////console.log(data)
  try {
    const response = yield call(getMyFeaturePlanApi, data.data);
    yield put(MyFeaturePlanApiResponseSuccess(GET_MY_FEATURE_PLAN, response));
  } catch (error) {
    yield put(MyFeaturePlanApiResponseError(GET_MY_FEATURE_PLAN, error));
  }
}

export function* watchGetMyFeaturePlan() {
  yield takeEvery(GET_MY_FEATURE_PLAN, getMyFeaturePlan);
}

function* MyFeaturePlanSaga() {
  yield all([fork(watchGetMyFeaturePlan)]);
}

export default MyFeaturePlanSaga;
