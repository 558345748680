import {
  COLLATERAL_API_RESPONSE_SUCCESS,
  COLLATERAL_API_RESPONSE_ERROR,
  COLLATERAL_GET_AVAILABLE_MARGIN
} from "./actionType";


export const CollateralApiResponseSuccess = (actionType, data) => ({
  type: COLLATERAL_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const CollateralApiResponseError = (actionType, error) => ({
  type: COLLATERAL_API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCollateral = (data) => {
  return ({
  type: COLLATERAL_GET_AVAILABLE_MARGIN,
  data,
})};