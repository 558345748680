import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Notifications
import Notifications from "./notification/reducer";

// E Learning
import ELearning from "./eLearning/reducer";

// E Learning Category
import ELearningCategory from "./eLearningCategory/reducer";

// Profile Details
import ProfileDetails from "./Profile/reducer";

// Bigul Highlights
import Highlights from "./bigulHighlights/reducer";

// Dashboard Suggested Algos
import SuggestedAlgos from "./dashboardSuggestedAlgos/reducer";


// Dashboard Add On
import AddOn from "./addOn/reducer";

// Dashboard Feature And Plan
import FeatureAndPlan from "./featureAndPlan/reducer";

// My Plan
import MyPlan from "./myPlan/reducer";

// My Plan Duration
import MyPlanDuration from "./myPlanDuration/reducer";

// My Feature Plan
import MyFeaturePlan from "./myFeaturePlan/reducer";

// My Feature Suggested Plan
import MyFeatureSuggestedPlan from "./myFeatureSuggestedPlan/reducer";

// Generate Key
import GenerateKey from "./generateKey/reducer";

// Encrypt Key
import EncryptKey from "./encryptKey/reducer";

// Statements
import Statements from "./statements/reducer";

// MarginOverview
import MarginOverview from "./marginOverview/reducer";

// Sa
import Sa from './sa/reducer';

//Pms
import Pms from './pms/reducer';

//Mf
import Collateral from './Collateral/reducer';

//Mf
import Mf from './mf/reducer';

// ClientID
import ClientID from './eSignClientId/reducer';

//Algo summary table
import SummaryTable from './algoSummaryTable/reducer';

//Investment Summary
import InvestmentSummary from './investedSummary/reducer';

//MyInvestedAlgos
import MyInvestedAlgos from './myInvestedAlgo/reducer';

//Cart
import Cart from './cart/reducer';

// Payment Details
import PaymentDetails from "./checkOut/reducer";

// Plan History table
import PlanHistory from "./planHistoryTable/reducer";

//VERIFY_OTP
import VerifyOTP  from "./VerifyOTP/reducer";

//Calendar
import Calendar from "./calendar/reducer";
//Chat
import chat from "./chat/reducer";
//Ecommerce
import Ecommerce from "./ecommerce/reducer";

//Project
import Projects from "./projects/reducer";

// Tasks
import Tasks from "./tasks/reducer";
//Form advanced
import changeNumber from "./formAdvanced/reducer";

//Crypto
import Crypto from "./crypto/reducer";

//TicketsList
import Tickets from "./tickets/reducer";
//Crm
import Crm from "./crm/reducer";

//Invoice
import Invoice from "./invoice/reducer";

//Mailbox
import Mailbox from "./mailbox/reducer";

// Dashboard Analytics
import DashboardAnalytics from "./dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRM from "./dashboardCRM/reducer";

// Dashboard Ecommerce
import DashboardEcommerce from "./dashboardEcommerce/reducer";

// Dashboard Cryto
import DashboardCrypto from "./dashboardCrypto/reducer";

// Dashboard Cryto
import DashboardProject from "./dashboardProject/reducer";

// Dashboard NFT
import DashboardNFT from "./dashboardNFT/reducer";

// Pages > Team
import Team from "./team/reducer";

// File Manager
import FileManager from "./fileManager/reducer"

// To do
import Todos from "./todos/reducer"

// Job
import Jobs from  './job/reducer'

//API Key
import APIKey from "./apikey/reducer";

// algo transtion
import GetTranstions from "./algoGetTranstions/reducer"
import GetList from "./algoGetList/reducer"
// refer and earn
import refferalData from "./ReferralLink/reducer";
import encryptedCode from "./CodeEncryption/reducer";
import apiKeyData from "./GenerateApiKey/reducer";
import isLoading from "./MyTradeReferSummary/reducer";
import referSum from "./MyTradeReferSummary/reducer";
import referredData from "./TotalReferred/reducer";
import AddReferalNumber from "./AddReferal/reducer";
import allUnconvertedLeads from "./AllUnconvertedLeads/reducer";
import getAlgoStatus from "./AlgoStatusCheck/reducer";


const rootReducer = combineReducers({
    // public
    getAlgoStatus,
    GetTranstions,
    GetList,
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Calendar,
    chat,
    Projects,
    Ecommerce,
    Tasks,
    changeNumber,
    Crypto,
    Tickets,
    Crm,
    Invoice,
    Mailbox,
    DashboardAnalytics,
    DashboardCRM,
    DashboardEcommerce,
    DashboardCrypto,
    DashboardProject,
    DashboardNFT,
    Team,
    FileManager,
    Todos,
    Jobs,
    APIKey,
    Notifications,
    ELearning,
    ProfileDetails,
    Highlights,
    SuggestedAlgos,
    AddOn,
    FeatureAndPlan,
    MyPlan,
    MyPlanDuration,
    MyFeaturePlan,
    MyFeatureSuggestedPlan,
    GenerateKey,
    EncryptKey,
    Statements,
    MarginOverview,
    InvestmentSummary,
    MyInvestedAlgos,
    Sa,
    Pms,
    Cart,
    PaymentDetails,
    SummaryTable,
    PlanHistory,
    ELearningCategory,
    VerifyOTP,
    Mf,
    Collateral,
    ClientID,
    // refer and earn
    allUnconvertedLeads,
    encryptedCode,
    apiKeyData,
    isLoading,
    referSum,
    referredData,
    refferalData,
    AddReferalNumber
});

export default rootReducer;