import {
  API_GET_CART_LIST_RESPONSE_SUCCESS,
  API_GET_CART_LIST_RESPONSE_ERROR,
  GET_GET_CART_LIST,
  ADD_ITEM_TO_CART_FAIL,
  ADD_ITEM_TO_CART_SUCCESS,
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_TO_CART,
  REMOVE_ITEM_TO_CART_SUCCESS,
  REMOVE_ITEM_TO_CART_FAIL,
  UPDATE_ITEM_TO_CART,
  UPDATE_ITEM_TO_CART_SUCCESS,
  UPDATE_ITEM_TO_CART_FAIL
} from "./actionType";

// common success
export const cartApiResponseSuccess = (actionType, data) => ({
  type: API_GET_CART_LIST_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const cartApiResponseError = (actionType, error) => ({
  type: API_GET_CART_LIST_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCart = (data) => ({
  type: GET_GET_CART_LIST,
  data,
});

// Add to Cart success
export const addToCartApiResponseSuccess = (actionType, data) => {
  // //console.log(data)
  // //console.log(actionType)
  return ({
  type: ADD_ITEM_TO_CART_SUCCESS,
  payload: {actionType},
})};
// Add to Cart error
export const addToCartApiResponseError = (actionType, error) => ({
  type: ADD_ITEM_TO_CART_FAIL,
  payload: { actionType, error },
});
//Add to Cart
export const addToCart = (data) => ({
  type: ADD_ITEM_TO_CART,
  data,
});

// Remove to Cart success
export const removeToCartApiResponseSuccess = (data) => ({
  type: REMOVE_ITEM_TO_CART_SUCCESS,
  payload: { data },
});
// Remove to Cart error
export const removeToCartApiResponseError = (actionType, error) => ({
  type: REMOVE_ITEM_TO_CART_FAIL,
  payload: { actionType, error },
});
// Remove to Cart
export const removeToCart = (data) => ({
  type: REMOVE_ITEM_TO_CART,
  data,
});

// Update to Cart success
export const UpdateCartApiResponseSuccess = (data) => {
  ////console.log(data)
  
  return({
  type: UPDATE_ITEM_TO_CART_SUCCESS,
  payload: { data },
})};
// Update to Cart error
export const UpdateCartApiResponseError = (actionType, error) => ({
  type: UPDATE_ITEM_TO_CART_FAIL,
  payload: { actionType, error },
});
// Update to Cart
export const UpdateCart = (data, data2) => {
  // //console.log(data)
  // //console.log(data2)
  return({
  type: UPDATE_ITEM_TO_CART,
  data,
  data2
})};