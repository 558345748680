import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { UpdatedPhone } from "../../../../store/Profile/action";
import loaderImage from "../../../../assets/images/loader-icon.png";
import {
  emailAddressUpdateApiCall,
  generateOtp,
  verifyOtp,
  resendOtp,
  modMailSend,
  ModEmailCheck,
  modification_profile,
  ModEmailCheck_new,
  Verify_otp_modi_new,
  mobileNumberUpdate_new
} from "../../../../helpers/fakebackend_helper";
import axios from "axios";

const LinkNewEmail = (props) => {
  const [generateOtpSuccess1, setGenerateSuccessOtp1] = useState(false);
  const [eSignButton, setESignButton] = useState(false);
  const [generateOtpSuccess, setGenerateSuccessOtp] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [showError, setError] = useState("");
  const [disableButton, setDisableButton] = useState(0);
  const dispatch = useDispatch();
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const validationSchema = Yup.object().shape({
    pan_no: Yup.string().required("This field is required"),
    old_email: Yup.string().email().required("This field is required"),
    // new_email: Yup.string().email('This is not a valid Email ID'),
  });
  const client_code = localStorage.getItem("client_id");

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));
  ////console.log(profileDetails);
  const initialValues = {
    pan_no: !isEmpty(profileDetails.userProfile[0])
      ? profileDetails.userProfile[0].PANGIR
      : "",
    old_email: !isEmpty(profileDetails.userProfile[0])
      ? profileDetails.userProfile[0].EMAIL
      : "",
    new_email: "",
  };
  ////console.log(initialValues)
  const [formValues, setFormValues] = useState(initialValues);
  const [formValues2, setFormValues2] = useState({});
  const [counter, setCounter] = useState(30);

  useEffect(() => {
    generateOtpSuccess1 === true &&
      counter > 0 &&
      setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setGenerateSuccessOtp1(false);
    }
  }, [counter, generateOtpSuccess1]);

  function onSubmitHandler(values) {

    if (values.new_email.length <= 0) {
      toast.error("Please enter email", {
        autoClose: 1500,
      });
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(values.new_email);
    if (isValid) {
    } else {
      toast.error("This is not a valid Email ID", {
        autoClose: 1500,
      });
      return false;
    }
    setDisableButton(1);
    axios.get('https://apinew.bigul.app:4040/api/v1/validateEmail/' + values.new_email)
      .then(response => {
        if (response?.status == false && response?.message == 'email does not exist') {
          const newValue = {
            ...values,
            client_code: client_code,
            email: profileDetails.userProfile[0].EMAIL,
            otp_type: "email",
          };
          setFormValues2(newValue);
          const mailcheck = {
            email: values.new_email,
            client_code: client_code,

          };
          ModEmailCheck_new(mailcheck)
            .then((response_mo) => {
              if (response_mo.status == true) {
                setDisableButton(0);
                setGenerateSuccessOtp(true);
                setGenerateSuccessOtp1(true);
                setCounter(30);
                toast.success("OTP has been sent to your new Email ID.", {
                  autoClose: 1500,
                });
              }
            })
            .catch((err) => {
              setDisableButton(0);
              console.log(err);
            });
        }
        else {
          setDisableButton(0);
          toast.error('Email address already exists !')
        }
      })
      .catch(error => {
        setDisableButton(0);
        toast(error.message || 'something went wrong')
      });
  }

  const modalMobile = {
    CUpdate: false,
    otp1: false,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: true,
  };
  function tog_signUpModals() {
    // //console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(modalMobile);
  };
  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
          Update Email ID
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <Formik
          initialValues={{
            pan_no: formValues.pan_no,
            old_email: formValues.old_email,
            new_email: formValues.new_email,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            ////console.log(values);
            onSubmitHandler(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => {
            ////console.log(values);
            return (
              <Form
                autoComplete="off"
                id="my-form"
                className="flex-col justify-center w-full"
              >
                {/* <Row className="mb-3">
                    <Col lg={4}>
                      <Label
                        htmlFor="pan_no"
                        className="py-2 m-0 text-16 text-color5"
                      >
                        PAN Number :
                      </Label>
                    </Col>
                    <Col lg={7}>
                      <Input
                        name="pan_no"
                        type="text"
                        className="nominee-form-input text-color1 text-4"
                        id="pan_no"
                        placeholder="PAN Number"
                        disabled
                        value={values.pan_no}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.pan_no && touched.pan_no}
                        // helperText={touched.pan_no && errors.pan_no}
                      />
                      {errors.pan_no && (
                        <label
                          className="mt-2 mx-4"
                          style={{ color: "#f44336" }}
                        >
                          <h6 className="fs-10 text-danger">{errors.pan_no}</h6>
                        </label>
                      )}
                    </Col>
                  </Row> */}
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label
                      htmlFor="old_email"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      Old Email ID :
                    </Label>
                  </Col>
                  <Col lg={7}>
                    <Input
                      name="old_email"
                      type="text"
                      className="nominee-form-input text-color1 text-4"
                      id="old_email"
                      placeholder="Old Email"
                      value={values.old_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      error={errors.old_email && touched.old_email}
                    // helperText={
                    //   touched.old_mobile_number && errors.old_mobile_number
                    // }
                    />
                    {errors.old_email && (
                      <label className="mt-2 mx-4" style={{ color: "#f44336" }}>
                        <h6 className="fs-10 text-danger">
                          {errors.old_email}
                        </h6>
                      </label>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label
                      htmlFor="new_email"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      New Email ID :
                    </Label>
                  </Col>
                  <Col lg={7}>
                    <Input
                      name="new_email"
                      type="text"
                      className="nominee-form-input text-color1 text-4"
                      id="new_email"
                      placeholder="New Email ID"
                      value={values.new_email}
                      disabled={generateOtpSuccess === true}
                      onChange={(e) => {
                        const re = /^[a-z0-9][a-z0-9.@\-]*$/;
                        const newEmail = e.target.value;
                        // Check if the new mobile number is equal to the old mobile number
                        const isSameAsOldEmail = newEmail == values.old_email;
                        setFieldValue('isSameAsOldEmail', isSameAsOldEmail);

                        if (e.target.value === '' || re.test(e.target.value)) {
                          setFieldValue('new_email', e.target.value)
                        }
                      }}
                      onBlur={handleBlur}
                      error={errors.new_email && touched.new_email}
                    // helperText={
                    //   touched.new_mobile_number && errors.new_mobile_number
                    // }
                    />
                    {/* {(
                      <label className="mt-2 mx-4" >
                        <h6 className="fs-10">You will receive OTP on your new email id.</h6>
                      </label>
                    )} */}
                    {values.isSameAsOldEmail && (
                      <label className="mt-2 mx-4" style={{ color: "#f44336" }}>
                        <h6 className="fs-10 text-danger">New email cannot be same as old email</h6>
                      </label>
                    )}
                    {errors.new_email && (
                      <label className="mt-2 mx-4" style={{ color: "#f44336" }}>
                        <h6 className="fs-10 text-danger">
                          {errors.new_email}
                        </h6>
                      </label>
                    )}
                  </Col>
                  <div>
                    <h4 className="text-11 text-color8 text-center mt-3">
                      You will receive OTP on your new email id.
                    </h4>
                  </div>
                </Row>
                {!values.isSameAsOldEmail && !errors.new_email ?
                  generateOtpSuccess === false && (
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary modal-content-Button mt-2"
                        disabled={disableButton == 0 ? false : true}
                      //onClick={(e) => onClickHandler(e)}
                      >
                        {disableButton == 0 ? 'Send OTP' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
                      </button>
                    </div>
                  ) :
                  generateOtpSuccess === false && (
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary modal-content-Button mt-2"
                        //onClick={(e) => onClickHandler(e)}
                        disabled
                      >
                        Confirm with OTP
                      </button>
                    </div>
                  )
                }

                {generateOtpSuccess === true && (
                  <Row className="mt-4">
                    <Col lg={11}>
                      {/* <form> */}
                      <div className="input-group otp-box-input justify-content-center">
                        <Input
                          type="text"
                          className="form-control modal-input-element otp-box"
                          placeholder="Enter 6 Digit OTP"
                          // onChange={(e) => {
                          //   // //console.log(e.target.value);
                          //   e.preventDefault();
                          //   setEmailOtp(e.target.value);
                          // }}
                          maxLength={6}
                          onChange={(e) => {
                            setError('');
                            const re = /^[0-9]*$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              if (e.target.value.length <= 6) { // Ensure the max length is not exceeded
                                setEmailOtp(e.target.value);
                                // setFieldValue('mobile_otp', e.target.value)
                                setError('');
                              }
                            } else {
                              setError('Only digits are allowed');
                              // setMobileOtp('');
                            }
                            // const re = /^[0-9]*$/;
                            // if (e.target.value === '' || re.test(e.target.value)) {
                            //   //setFieldValue('new_mobile_number', e.target.value)
                            //   setEmailOtp(e.target.value);
                            // }
                          }}
                        />
                        <button
                          className="btn btn-primary modal-input-button"
                          type="button"
                          disabled={disableButton == 0 ? false : true}
                          onClick={(e) => {
                            setError('')
                            if (!emailOtp) {
                              setError('Please enter OTP'); // Show alert if OTP is empty
                              return; // Stop execution if OTP is empty
                            }
                            if (emailOtp?.length < 6) {
                              setError('Please enter coreect OTP'); // Show alert if OTP is empty
                              return; // Stop execution if OTP is empty
                            }

                            const DummyConetnet = {
                              email: formValues2.new_email,
                              otp: emailOtp,
                              client_code: client_code,

                            };






                            setDisableButton(1);
                            try {

                              Verify_otp_modi_new(DummyConetnet)
                                .then((response) => {
                                  if (response.status) {
                                    var mod_email_mobile = {
                                      client_name: profileDetails.userProfile[0].FIBSACCT,
                                      mod_type: 'email',
                                      mod_mobile: profileDetails.userProfile[0].MOBILE,
                                      mod_email: profileDetails.userProfile[0].EMAIL,
                                    };
                                    modMailSend(mod_email_mobile)
                                      .then((response) => {
                                        const newVal2 = {
                                          client_code: client_code,
                                          entry_type: 'email',
                                          old: JSON.stringify({
                                            email: profileDetails.userProfile[0].EMAIL,
                                          }),
                                          new: JSON.stringify({
                                            email: values.new_email,
                                          }),
                                          modify_key: JSON.stringify(['email'])
                                        };

                                        mobileNumberUpdate_new(newVal2)
                                          .then((response) => {
                                            console.log(response);
                                            if (response.status === true) {
                                              setDisableButton(0);

                                              props.tog(false)
                                              props.onStateChange(1, props?.name);


                                            }
                                            else {
                                              setDisableButton(0);
                                              toast.success(response.message, {
                                                autoClose: 1500,
                                              });
                                            }

                                          })
                                          .catch((err) => {
                                            setDisableButton(0);

                                          });
                                      })
                                      .catch((err) => {
                                        setDisableButton(0);

                                      });


                                  }
                                  else {
                                    setDisableButton(0);

                                    setError(response.message);
                                  }
                                })
                                .catch((err) => {
                                  setDisableButton(0);

                                });


                            } catch (error) {
                              setDisableButton(0);
                              console.log('ERROR ', error);
                            }
                            //onClickHandler(e);
                          }}
                        >

                          {disableButton == 0 ? 'Verify' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
                        </button>
                      </div>
                      {showError && <p className="error_msg" style={{ color: "red", fontSize: "smaller", textAlign: "center", textTransform: "capitalize", marginTop: "10px" }}>{showError === 'email Invalid OTP.' ? 'Invalid OTP' : showError}</p>}
                      <h4 className="text-11 text-color8 mt-3 text-center">
                        An OTP has been sent to your new Email ID.
                      </h4>
                      {/* <h4 className="text-11 text-color8">
              Time remaining 256sec
            </h4> */}
                      <h4 className="text-11 text-color8 mt-3 text-center">
                        Didn’t receive OTP?{" "}
                        {counter === 0 ? <button
                          type="button"
                          className="ms-1 resend-button"
                          onClick={() => {
                            const newValue = {
                              ...formValues2,
                              client_code: client_code,
                              email: profileDetails.userProfile[0].EMAIL,
                              otp_type: "email",
                              otp: emailOtp,
                            };

                            const otpValues = {
                              client_code: client_code,
                              pan_no: profileDetails.userProfile[0].PANGIR,
                              mobile_number: profileDetails.userProfile[0].MOBILE,
                              email: formValues2.new_email,
                              otp_type: "email",
                            };

                            ModEmailCheck_new(otpValues)
                              .then((response) => {
                                // //console.log(response);
                                if (response.status === true) {
                                  setGenerateSuccessOtp1(true);
                                  setCounter(30);
                                  toast.success(
                                    "OTP has been sent to your new Email ID.",
                                    {
                                      autoClose: 1500,
                                    }
                                  );
                                } else {
                                  toast.error("Couldn't send OTP. Try again!", {
                                    autoClose: 1500,
                                  });
                                }
                              })
                              .catch((err) => {
                                ////console.log(err);
                              });
                          }}
                        >
                          Resend OTP
                        </button> :
                          <button
                            className="ms-1 resend-button1"
                            type="button"
                            disabled
                          >
                            Resend OTP
                          </button>}
                        {counter > 0 && `${counter} Seconds`}
                      </h4>
                      {/* </form> */}
                    </Col>
                  </Row>
                )}
                {eSignButton === true && (
                  <Row>
                    <Col lg={11}>
                      <button type="button">E Sign</button>
                    </Col>
                  </Row>
                )}
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </React.Fragment>
  );
};

// VerificationNumber.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
// };

export default LinkNewEmail;
