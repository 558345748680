import {
  GET_CATEGORY_ELEARNINGVIDEOS,
  API_CATEGORY_RESPONSE_SUCCESS,
  API_CATEGORY_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
  ELearningVideosCategoryList: [],
};

const ELearningCategoryVideos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_CATEGORY_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CATEGORY_ELEARNINGVIDEOS:
          return {
            ...state,
            ELearningVideosCategoryList: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_CATEGORY_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_CATEGORY_ELEARNINGVIDEOS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_CATEGORY_ELEARNINGVIDEOS: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default ELearningCategoryVideos;
