import {
  API_MF_RESPONSE_SUCCESS,
  API_MF_ERROR,
  GET_MF
} from "./actionType";

// SA success
export const MFApiResponseSuccess = (actionType, data) => ({
  type: API_MF_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// SA error
export const MFApiResponseError = (actionType, error) => ({
  type: API_MF_ERROR,
  payload: { actionType, error },
});

export const getMF = (data) => {
  return ({
  type: GET_MF,
  data,
})};