import { addDays, format, subMonths, subYears } from "date-fns";

// export const unCodeUser = "Y09022592"
// export const unCodeUser = "Y0904788"
// export const unCodeUser = "Y09020893"
// export const unCodeUser = "Y09013442" yes
// export const unCodeUser = "Y09014058"

export const mobileNumberWrapper = (phoneNumber) => {
  const maskedPhoneNumber = phoneNumber.replace(/(\d{6})(\d{4})/, "******$2");
  return maskedPhoneNumber;
};

export const emailWrapper = (email) => {
  const atIndex = email.indexOf("@");
  const modifiedEmail = "****" + email.slice(atIndex - 4);
  return modifiedEmail;
};

export const dateFormaterForData = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join("-");
};
export const dateFormaterForApi = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};

const currentDate = new Date();
export const today = format(addDays(currentDate, -1), "yyyy-MM-dd");
export const last7Days = format(addDays(currentDate, -7), "yyyy-MM-dd");
export const lastMonths = format(subMonths(currentDate, 1), "yyyy-MM-dd");
export const last3Months = format(subMonths(currentDate, 3), "yyyy-MM-dd");
export const last6Months = format(subMonths(currentDate, 6), "yyyy-MM-dd");
export const lastYear = format(subYears(currentDate, 1), "yyyy-MM-dd");
export const currentYear = currentDate.getFullYear();

export const referredOnAscendingSort = (data) => {
  const sortedData = [...data].sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });
  return sortedData;
};

export const referredDOnescendingSort = (data) => {
  const sortedData = [...data].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });
  return sortedData;
};
export const nameAscendingSort = (data) => {
  const sortedData = [...data].sort((a, b) =>
    a.full_name > b.full_name ? 1 : -1
  );
  return sortedData;
};

export const nameDescendingSort = (data) => {
  const sortedData = [...data].sort((a, b) =>
    a.full_name > b.full_name ? -1 : 1
  );
  return sortedData;
};

export function getStageNameForSteps(step) {
  if (step === 1 || step === 2) {
    return "Mobile Verified";
  } else if (step === 3 || step === 4) {
    return "Email Verified";
  } else if (step === 5) {
    return "PAN Verified";
  } else if (step === 6) {
    return "KRA/Digilocker Completed";
  } else if (step === 7) {
    return "Personal Details";
  } else if (step === 8) {
    return "Occupational Details";
  } else if (step === 9) {
    return "Bank Details";
  } else if (step === 10) {
    return "Segments";
  } else if (step === 11 || step === 12) {
    return "Document upload";
  } else if (step === 13 || step === 14) {
    return "Selfee and Signature";
  } else if (step === 15 || step === 16) {
    return "E-sign";
  }
}
