import React, { useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import {checkModificationStatus} from '../../../../helpers/fakebackend_helper';
// TODO Ui and design and functions.
const MobileLinkedCompleted = (props) => {
const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  const modalMobile = {
    CUpdate: false,
    otp1: true,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: false
  }
  const client_code = localStorage.getItem("client_id");
  ////console.log(profileDetails);
  function tog_signUpModals() {
    // //console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
   // //console.log("submit");
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(false)
    props.onStateChange(1,props.name);
  
    // checkModificationStatus({
    //   client_code: client_code,
    //   pan_no: !isEmpty(profileDetails.userProfile) && profileDetails.userProfile[0].PANGIR.trimEnd(),
    //   field_name: "mobile",
    //   esign_flag: "NO",
    // })
    //   .then((response) => {
    //     //response);
        
    //     //props.sendData(modalMobile);
    //   })
    //   .catch((err) => {
    //     ////console.log(err);
    //   });
   
  }
  return (
    <React.Fragment>
        <ModalHeader
          className="px-4 pt-4 justify-content-center"
          // toggle={() => {
          //   tog_signUpModals();
          // }}
        >
          <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
          {'Your profile modification request was successful'}
          </h4>
        </ModalHeader>
        <ModalBody className="p-4">
          <form onSubmit={submitHandler()}>
 <h4
                className="form-check-label text-15 text-color1 px-3 text-center"
                htmlFor="checkTerms"
              >
                {'Updating mobile number will take up to 48 hours to reflect on your profile (owing to updation on exchanges and depositories).'}
              </h4>
            {/* <div>
              <h4 className="text-15 text-color1 text-center mt-3">We will notify you over email for the same.</h4>
            </div> */}
            <div className="text-center">
              <button type="submit" className="btn btn-primary modal-content-Button my-3" onClick={(e) => onClickHandler(e)}>
                Okay
              </button>
            </div>
          </form>
        </ModalBody>
    </React.Fragment>
  );
};



export default MobileLinkedCompleted;
