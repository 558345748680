import {
  GET_PROFILEDETAILS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  API_UPDATE_PHONE_RESPONSE_SUCCESS,
  API_UPDATE_PHONE_RESPONSE_ERROR,
  UPDATE_PHONE,
  API_UPDATE_EMAIL_RESPONSE_SUCCESS,
  API_UPDATE_EMAIL_RESPONSE_ERROR,
  UPDATE_EMAIL,
  RESET_PHONE
} from "./actionType";

// Get Profile success
export const ProfileDetailsApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Get Profile error
export const ProfileDetailsApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});
// Get Profile
export const getProfileDetails = (data) => ({
  type: GET_PROFILEDETAILS,
  payload: data,
});


// Updated Phone success
export const UpdatedPhoneApiResponseSuccess = (actionType, data) => ({
  type: API_UPDATE_PHONE_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Updated Phone error
export const UpdatedPhoneApiResponseError = (actionType, error) => ({
  type: API_UPDATE_PHONE_RESPONSE_ERROR,
  payload: { actionType, error },
});
// Updated Phone
export const UpdatedPhone = (data) => ({
  type: UPDATE_PHONE,
  data,
});
// reset state 
export const ResetPhoneState = () => ({
  type: RESET_PHONE,
});


// Updated Email success
export const UpdatedEmailApiResponseSuccess = (actionType, data) => ({
  type: API_UPDATE_EMAIL_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Updated Email error
export const UpdatedEmailApiResponseError = (actionType, error) => ({
  type: API_UPDATE_EMAIL_RESPONSE_ERROR,
  payload: { actionType, error },
});
// Updated Email
export const UpdatedEmail = (data) => ({
  type: UPDATE_EMAIL,
  data,
});