import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { conformOpenApi } from "../../../../store/marginOverview/action";

// TODO Ui and design and functions.
const WithdrawFundsPopup = (props) => {
  const [errorAmount, setErrorAmount] = useState(false);
  const [errorAmount2, setErrorAmount2] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const modalMobile = {
    CUpdate: false,
    otp1: true,
    done: false,
  };

  ////console.log(props);
  function tog_signUpModals() {
    // //console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(modalMobile, value);
    //dispatch(conformOpenApi());
  };
  const amountFormatter2 = (data) => {
    //console.log(data);
    let Total = 0;
    if (data > 0) {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      return `- ₹${data.toLocaleString("en-IN").toString().splice(1)}`;
    } else {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };
 //console.log( props.wAmount)
  const min = 1;
  const max = props.wAmount;

  const handleChange = (event) => {
    //console.log(event.target.value)
    // 👇 Get input value from "event"
    setValue(event.target.value);
    if (+event.target.value > +max) {
      ////console.log("Hello")
      setErrorAmount(true);
    } else if (+event.target.value <= 0) {
      setErrorAmount(true);
      setErrorAmount2(true);
    } else if (+event.target.value === 0) {
      ////console.log(event.target.value)
      setErrorAmount(true);
      setErrorAmount2(true);
    } else {
      setErrorAmount(false);
      setErrorAmount2(false);
    }

    //const value = Math.max(min, Math.min(max, Number(event.target.value)));
    ////console.log(value);
  };
  ////console.log(+amountFormatter(props.data.availableMargin[0].Final1));
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 py-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
          Withdraw Funds
        </h4>
      </ModalHeader>
      <ModalBody className="p-0">
        <form onSubmit={submitHandler()}>
          <div className="d-flex custom-border2 pb-3">
            <h3 className="text-10 text-color1 px-4 mb-0">
              Amount available for withdrawal :
            </h3>
            <h3 className="text-10 text-color6 mb-0"> ₹{parseFloat(props.wAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
          </div>
          <div className="px-4 mt-2">
            <Label
              htmlFor="iconInput"
              className="form-label text-10 text-color1"
            >
              Amount to withdraw
            </Label>
            <div className="form-icon">
              <Input
                type="number"
                className="form-control form-control-icon nominee-form-input2"
                id="iconInput"
                placeholder="Enter Amount"
                value={value}
                onChange={handleChange}
              />
              <i>₹</i>
            </div>
            {errorAmount === true && errorAmount2 === false ? (
              <h4 className="text-color11 text-5 mt-2">
                Withdrawal amount cannot be more than the current balance
              </h4>
            ) : errorAmount === true && errorAmount2 === true ? (
              <h4 className="text-color11 text-5 mt-2">Enter valid amount</h4>
            ) : null}
          </div>

          <div className="mt-3 px-4">
            {/* <h4 className="text-11 text-color1">
              Select bank account to transfer in
            </h4> */}
          </div>
          <div className="d-flex mb-2 px-4 mt-3">
            <h3 className="text-4 text-color7 text-center">{`Amount will be credited in ${
              props.data.bankDetails[0].Bankname
            } bank a/c ending with ${props.data.bankDetails[0].Acnumber.substring(
              props.data.bankDetails[0].Acnumber.length - 4
            )}`}</h3>
            {/* <div className="mb-2">
              <Label
                className="form-check-label text-11 text-color5 me-3"
                htmlFor="flexRadioDefault1"
              >
                Email
              </Label>
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
            </div> */}
            {/* <div className="">
            <Label
                className="form-check-label text-11 text-color5"
                htmlFor="flexRadioDefault2"
              >
                Mobile
              </Label>
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                defaultChecked
              />
            </div> */}
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary modal-content-Button my-3 w-50"
              onClick={(e) => onClickHandler(e)}
              disabled={+value === 0 || errorAmount === true}
            >
              Continue
            </button>
          </div>
        </form>
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default WithdrawFundsPopup;
