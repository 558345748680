// Actions Get
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PROFILEDETAILS = "GET_PROFILEDETAILS";
// update Phone
export const API_UPDATE_PHONE_RESPONSE_SUCCESS = "API_UPDATE_PHONE_RESPONSE_SUCCESS";
export const API_UPDATE_PHONE_RESPONSE_ERROR = "API_UPDATE_PHONE_RESPONSE_ERROR";
export const UPDATE_PHONE = "UPDATE_PHONE";
export const RESET_PHONE = 'RESET_PHONE';
// update Email
export const API_UPDATE_EMAIL_RESPONSE_SUCCESS = "API_UPDATE_EMAIL_RESPONSE_SUCCESS";
export const API_UPDATE_EMAIL_RESPONSE_ERROR = "API_UPDATE_EMAIL_RESPONSE_ERROR";
export const UPDATE_EMAIL = "UPDATE_EMAIL";

