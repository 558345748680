import React, { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import * as moment from "moment";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader1 from "../../Components/Common/Loader1";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

import { getPlanHistory } from "../../store/planHistoryTable/action";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer3 from "../../Components/CustomComponents/TableContainer3";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import { CSVLink } from 'react-csv';

const SubscriptionPlanHistoryTable = () => {
  const [isDownload, setIsDownload] = useState(false);
  const dispatch = useDispatch();
  const startDate = new Date();
  const endDate = new Date() ;
  startDate.setDate(startDate.getDate() - 90);
  
  const [selectDate, setSelectDate] = useState({date: [startDate, endDate]});
  const { PlanSummary } = useSelector((state) => ({
    PlanSummary: state.PlanHistory.PlanHistoryData,
  }));
    console.log(PlanSummary)
    const client_code  = localStorage.getItem('client_id');
    const client_token = localStorage.getItem('client_token');

  useEffect(() => {
    dispatch(getPlanHistory({ client_code: client_code, client_token: client_token, startDate: dayjs(selectDate.date[0]).format("YYYY-MM-DD"), endDate: dayjs(selectDate.date[1]).format("YYYY-MM-DD")}));
  }, [dispatch, selectDate, client_token, client_code]);


  ////console.log(PlanSummary.length > 0 && new Date(PlanSummary[0].to_date));


  const summaryData = [
    {
      productID: "F1203988",
      planName: "Feature 1",
      date: "01/01/2023 07:29pm",
      validity: "01/06/2023",
      amount: "Rs 399",
      Status: true,
      sinceInception: "13366.50",
    },
    {
      productID: "F1203913",
      planName: "Feature 2",
      date: "01/01/2023 07:29pm",
      validity: "01/06/2023",
      amount: "Rs 299",
      Status: true,
      sinceInception: "899.52",
    },
    {
      productID: "F1203914",
      planName: "Feature 3",
      date: "01/01/2023 07:29pm",
      validity: "01/06/2023",
      amount: "Rs 999",
      Status: true,
      sinceInception: "881.13",
    },
    {
      productID: "F1203915",
      planName: "Feature 4",
      date: "01/01/2023 07:29pm",
      validity: "01/06/2023",
      amount: "Rs 499",
      Status: true,
      sinceInception: "1,744.00",
    },
    {
      productID: "F1203945",
      planName: "Feature 5",
      date: "01/01/2023 07:29pm",
      validity: "01/06/2023",
      amount: "Rs 399",
      Status: false,
      sinceInception: "9,841.85",
    },
  ];

  

  // Customers Column
  const columns = useMemo(
    () => [
      {
        Header: "Purchase ID",
        accessor: "purchase_id",
        filterable: true,
      },
      {
        Header: "Plan Name",
        accessor: "plan_name",
        filterable: true,
      },
      {
        Header: "Purchase D&T",
        accessor: "subscription_date",
        filterable: true,
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY")
        }
      },
      {
        Header: "Validity",
        accessor: "to_date",
        filterable: true,
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY")
        }
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "Status",
        Cell: (cell) => {
          switch (dayjs(cell.value).isBefore(new Date())) {
            case false:
              return (
                <span className="badge bg-success price-off-badge text-uppercase">
                  Active
                </span>
              );
            case true:
              return (
                <span className="badge badge-soft-danger price-off-badge-exp text-uppercase">
                  EXPIRED
                </span>
              );
          }
        },
      },
      {
        Header: "Payment Type",
        accessor: "payOption",
        filterable: true,
        Cell: (cell) => {
          return cell.value
        }
      },
    ],
    []
  );

  const downloadHandler = () => {
    window.open(
      `https://payment.bigul.co/api/algo/export-dump/PRO1`,
      "_blank"
    );
  }
  const dynamicFilename = "Subscription_report_" + client_code + ".csv"
  return (
    <React.Fragment>
      <Card id="customerList" className="p-0">
        <CardHeader className="border-0 p-0">
          {/* <div className="p-4" style={{borderBottom: '1px solid #ebe7e7'}}>
            <h4 className="card-title mb-0 flex-grow-1 text-6 text-color1">
              Subscription Plan History
            </h4>
          </div> */}
          <div>
            <div className="d-flex justify-content-between  px-3 pb-2 pt-4 align-items-center">
              <div className="input-group input-group-width-25 mt-2">
                <Flatpickr
                 placeholder="Select date"
                 id="exampleInputdate"
                 className="form-control summary-table-date"
                 options={{
                   mode: "range",
                   dateFormat: "d M, Y",
                   maxDate: new Date(),
                 }}
                 value={selectDate.date}
                 onChange={date => {
                   setSelectDate({ date: date });
                 }}
                />
                <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label>
              </div>

              <div className="d-flex">
                {PlanSummary.length <= 0 ? ('') : (
                    <CSVLink
                  className="btn btn-success text-4 margin-overview-button2"
                  style={{ borderRadius: "5px" }}
                  data={PlanSummary || []} // Pass the PlanSummary data
                  filename={dynamicFilename}
                  headers={columns.map((column) => ({
                    label: column.Header,
                    key: column.accessor,
                  }))}
                >
                  Download Report
                </CSVLink>
                  )}


                {/* <button
                  type="button"
                  className="btn btn-success text-4 margin-overview-button2"
                  style={{ borderRadius: "5px" }}
                  onClick={downloadHandler}
                >
                  <i className="ri-download-2-line me-2 align-bottom"></i>
                  Download Report
                </button> */}
              </div>
            </div>
          </div>
        </CardHeader>
        <div className="card-body py-2 px-0">
          {/* <div className="d-flex p-4 justify-content-between">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="ri-calendar-2-line"></i>
                    </span>
                    <Flatpickr
                      placeholder="Select date"
                      className="form-control"
                      options={{
                        mode: "range",
                        dateFormat: "d M, Y",
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => setIsExportCSV(true)}
                  >
                    <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                    Download Report
                  </button>
                </div> */}
          <div>
            {PlanSummary !== null && PlanSummary.length ? (
              <TableContainer3
                columns={columns}
                data={PlanSummary || []}
                isGlobalFilter={true}
                isAlgoSummary={true}
                customPageSize={50}
                //className="custom-header-css"
                //theadClass="summary-table-header"
                //thClass="summary-table-checkbox "
                //thClass="summary-table-th"
                tableClass="align-middle table-nowrap text-center"
                theadClass="table-light text-muted text-uppercase fs-13"
                tableTrClasses='summary-table-tr px-4'
                //handleCustomerClick={handleCustomerClicks}
                //isCustomerFilter={true}
                //SearchPlaceholder='Search for customer, email, phone, status or something...'
              />
            ) : (
              <Loader1 />
            )}
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default SubscriptionPlanHistoryTable;
