import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import * as moment from "moment";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader1 from "../../Components/Common/Loader1";
//action
import { getPAndLDerivative } from "../../store/statements/action";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const PAndLDerivative = (props) => {
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  const [counter, setCounter] = useState(0);
  const [total, setTotal] = useState("");
  const [includeCharges, setIncludeCharges] = useState(true);
  const [segmentType, setSegmentType] = useState("F");
  const dispatch = useDispatch();
  const [startDate1, setStartDate1] = useState(new Date(2023, 3, 1));
  const [endDate1, setEndDate1] = useState(new Date());
  const [isDownload, setIsDownload] = useState(false);
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });
  const { generatedKey } = useSelector((state) => ({
    generatedKey: state.GenerateKey.generatedKey,
  }));
  const { encryptKey } = useSelector((state) => ({
    encryptKey: state.EncryptKey.encryptKey,
  }));
  const { PAndLDerivative } = useSelector((state) => ({
    PAndLDerivative: state.Statements.PAndLDerivative,
  }));
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  //console.log(PAndLDerivative);
  useEffect(() => {
    dispatch(
      getPAndLDerivative({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
        segment: segmentType,
        charges: "N",
      })
    );
  }, [dispatch]);

  const DateFilter = () => {
    setCounter(2);
    dispatch(
      getPAndLDerivative({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
        segment: segmentType,
        charges: includeCharges === true ? "Y" : "N",
      })
    );
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    if (PAndLDerivative.length === 0) {
      setTotal(0);
    }
  }, [PAndLDerivative]);

  function removeValue(value, index, arr) {
    ////console.log(value)
    // //console.log(index)
    // //console.log(arr)
    // If the value at the current array index matches the specified value (2)
    if (
      //value.LNarrat === "Opening Balance       " ||
      value.SCRIPNAME === "Total"
    ) {
      // Removes the value from the original array
      setTotal(arr[index]);
      arr.splice(index, 1);
      return true;
    }
    return false;
  }

  PAndLDerivative.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    ////console.log(new Date(dayjs(a.dtoftran).format(" DD MMM YYYY")))
    return (
      new Date(dayjs(b.Lexp).format(" DD MMM YYYY")) -
      new Date(dayjs(a.Lexp).format(" DD MMM YYYY"))
    );
  });

  // Pass the removeValue function into the filter function to return the specified value
  const x = PAndLDerivative.filter(removeValue);

  const AmountFormatter = (data) => {
    switch (true) {
      case data.value > 0:
        return <div className="text-color6">{data.value.toFixed(2)}</div>;
      case data.value < 0:
        return <div className="text-color11">{data.value.toFixed(2)}</div>;
      default:
        return <div>{data.value.toFixed(2)}</div>;
    }
  };

  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   //TODO
      //   Header: "#",
      //   Cell: (cell) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="productCheckBox form-check-input"
      //         value={cell.row.original._id}
      //         //onClick={() => displayDelete()}
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Script Code",
      //   accessor: "sCRIPCODE",
      //   filterable: true,
      // },
      {
        //Header: "Scrip Name",
        Header: <h4 className="d-flex m-0 fs-13">Scrip Name</h4>,
        accessor: "SCRIPNAME",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex">{cell.value}</span>;
        },
      },
      // {
      //   Header: "Buy Date",
      //   accessor: "lexp",
      //   Cell: (cell) => {
      //     return dayjs(cell.value).format(" DD MMM YYYY");
      //   },
      // },
      {
        //Header: "Strike Price",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Strike Price</h4>
        ),
        accessor: "Strike",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
      },
      {
        Header: "OT",
        accessor: "Op",
        filterable: true,
      },
      {
        Header: "Expiry Date",
        accessor: "Lexp",
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY");
        },
      },
      {
        Header: "Buy Qty",
        accessor: "BQTY",
        filterable: true,
      },
      {
        //Header: "Buy Value",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Buy Value</h4>
        ),
        accessor: "BVALUE",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        // Cell: (cell) => {
        //   return cell.value.toFixed(2);
        // },
      },
      {
        //Header: "Buy Avg ",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Buy Avg</h4>
        ),
        accessor: "BAVG",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
      {
        Header: "Sell Qty",
        accessor: "SQTY",
        filterable: true,
        //Cell: AmountFormatter,
      },
      {
        //Header: "Sell Value",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Sell Value</h4>
        ),
        accessor: "SVALUE",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Sell Avg",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Sell Avg</h4>
        ),
        accessor: "SAVG",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },

      {
        Header: "Net Qty",
        accessor: "NQTY",
        filterable: true,
      },
      {
        //Header: "Net Value",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Net Value</h4>
        ),
        accessor: "NVALUE",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        // Cell: (cell) => {
        //   return cell.value.toFixed(2);
        // },
      },
      {
        //Header: "Net Avg",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Net Avg</h4>
        ),
        accessor: "NAVG",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Market Rate",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Market Rate</h4>
        ),
        accessor: "MARKET",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Market Value",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Market Value</h4>
        ),
        accessor: "MARKVALUE",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Today's P&L",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Today's P&L</h4>
        ),
        accessor: "TPnl",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "P&L",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">P&L</h4>,
        accessor: "Pnl",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  const myArray = [];
  for (let i = 0; i < PAndLDerivative.length; i += 1) {
    const childArray = PAndLDerivative[i];
    const obj = {
      SCRIPNAME: childArray.SCRIPNAME,
      Strike: childArray.Strike,
      Op: childArray.Op,
      Lexp: childArray.Lexp,
      BQTY: childArray.BQTY,
      BVALUE: childArray.BVALUE,
      BAVG: childArray.BAVG,
      SQTY: childArray.SQTY,
      SVALUE: childArray.SVALUE,
      SAVG: childArray.SAVG,
      NQTY: childArray.NQTY,
      NVALUE: childArray.NVALUE,
      NAVG: childArray.NAVG,
      MARKET: childArray.MARKET,
      MARKVALUE: childArray.MARKVALUE,
      TPnl: childArray.TPnl,
      Pnl: childArray.Pnl,
    };
    myArray.push(obj);
  }

  const userHeader = [
    "Scrip Name",
    "Strike Price",
    "OT",
    "Expiry date",
    "Buy Qty",
    "Buy Value",
    "Buy Avg ",
    "Sell Qty",
    "Sell Value",
    "Sell Avg",
    "Net Qty",
    "Net value",
    "Net Avg",
    "Market Rate",
    "Market value",
    "Today's P&L",
    "P&L",
  ];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${
      !isEmpty(profileDetails) ? profileDetails[0].FIBSACCT.trim() : "USER"
    }_${dayjs(new Date()).format("YYYY-MM-DD")}_PAndLDerivative`
  );
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const downloadHandler = () => {
    setIsDownload(true);
  };

  return (
    <React.Fragment>
      <div>
        <div
          className="d-flex justify-content-between align-items-end  p-3 mobile-display-block"
          style={{ borderBottom: "1px solid #8080802b" }}
        >
          <div className="d-flex align-items-end mobile-display-block">
            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement">
                {" "}
                Select Segment Type{" "}
              </h5>
              <div className="text-center-statement">
                <select
                  className="select-segment-type text-center-statement"
                  onChange={(e) => {
                    setSegmentType(e.target.value);
                  }}
                >
                  <option value="F" key="F&O">
                    F&O
                  </option>
                  <option value="D" key="Currency">
                    Currency
                  </option>
                  <option value="O" key="Commodity">
                    Commodity
                  </option>
                </select>
              </div>
            </div>
            <div className="d-flex align-items-end mobile-display-block">
              <div className="input-group-width-50 me-4 mb-2">
                <h5 className="text-10 text-center-statement"> From </h5>
                <div className="d-flex">
                  {/* <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date text-center-statement"
                    options={{
                      //mode: "range",
                      dateFormat: "d M, Y",
                      maxDate: new Date(),
                      enable: [
                        function (date) {
                          return (
                            (date >= startDate1)
                          );
                        },
                      ],
                    }}
                    value={startDate1}
                    onChange={(date) => {
                      //(date);
                      setStartDate1(date[0]);
                    }}
                  /> */}
                  <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date text-center-statement"
                    options={{
                      //mode: "range",
                      dateFormat: "d M, Y",
                      maxDate: new Date(),
                      minDate: new Date(2023, 3, 1),
                      // enable: [
                      //   function (date) {
                      //     return date >= startDate1;
                      //   },
                      // ],
                    }}
                    value={[startDate1]}
                    onChange={(date) => {
                      ////console.log(date);
                      setStartDate1(date[0]);
                    }}
                  />
                  {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
                </div>
              </div>

              <div className="input-group-width-50 me-4 mb-2">
                <h5 className="text-10 text-center-statement"> To</h5>
                <div className="d-flex">
                  <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date text-center-statement"
                    options={{
                      //mode: "range",
                      dateFormat: "d M, Y",
                      maxDate: new Date(),
                      enable: [
                        function (date) {
                          return date >= startDate1;
                        },
                      ],
                    }}
                    value={[endDate1]}
                    onChange={(date) => {
                      // //console.log(date);
                      setEndDate1(date[0]);
                    }}
                  />
                  {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
                </div>
              </div>
              {/* <div className="input-group-width-50 mb-2">
                <div className="d-flex align-items-center mobile-statement-input-center">
                  <input
                    className="form-check-input summary-table-date2 me-3"
                    type="checkbox"
                    id="formCheck16"
                    value="Equity Cash - BSE, NSE"
                    onChange={(event) => setIncludeCharges(!includeCharges)}
                    //checked={checked.nse}
                  />
                  <h5 className="text-10 m-0"> Include Charges </h5>
                </div>
              </div> */}
              <div className="statement-search">
                <button
                  type="button"
                  className="text-4 mb-2 btn btn-primary margin-overview-button1"
                  style={{ borderRadius: "5px", height: "50%" }}
                  onClick={DateFilter}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {/* {console.log("Pnl",total.Pnl)} */}
          <div className="text-center d-flex d-md-block align-items-center mt-2 mt-sm-0 w-md-auto justify-content-between">
            <h5 className="text-10 text-color7 mb-2 text-end pe-md-0 pe-2">
              {" "}
              P&L :{" "}
              <span
                className={`text-10 mb-2 ${
                  total !== "" && total !== 0 && total.Pnl > 0
                    ? "text-color6"
                    : total !== "" && total !== 0 && total.Pnl < 0
                    ? "text-color11"
                    : "text-color7"
                }`}
              >
                {total !== "" && total !== 0
                  ? total.Pnl.toLocaleString("en-IN").toString()
                  : 0}
              </span>
            </h5>
            <div className="d-flex download-button-mobile">
              <button
                type="button"
                className="btn btn-success text-4 mb-2 margin-overview-button2"
                style={{ borderRadius: "5px", height: "50%" }}
                onClick={downloadHandler}
                disabled={PAndLDerivative.length === 0}
              >
                <i className="ri-download-2-line me-2 align-bottom"></i>
                Download Report
              </button>
            </div>
          </div>
        </div>
      </div>
      {counter === 0 && PAndLDerivative.length ? (
        <TableContainer2
          columns={columns}
          data={PAndLDerivative.reverse() || []}
          isGlobalFilter={true}
          isAddUserList={false}
          customPageSize={8}
          divClass="table-responsive table-card mb-1"
          tableClass="align-middle table-nowrap text-center"
          theadClass="table-light text-muted text-uppercase fs-13"
          tableTrClasses="summary-table-tr px-4"
          // handleOrderClick={handleCustomerClicks}
          isOrderFilter={true}
          SearchPlaceholder="Search for order ID, customer, order status or something..."
        />
      ) : counter !== 0 ? (
        <Loader error={"empty"} />
      ) : (
        <Loader1 error={"empty"} />
      )}
    </React.Fragment>
  );
};

export default PAndLDerivative;
