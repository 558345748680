import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import refAndEarnBorder from "../../assets/images/logo/refAndEarnBorder.png";

const Questions = () => {
  return (
    <React.Fragment>
      <Col xxl={12} xl={12} lg={12} className="my-5">
        {/* <div className="d-flex justify-content-center refer-and-earn-border-div">
        <img src={refAndEarnBorder} />
        </div> */}
        <div className="d-flex justify-content-center align-items-center">
          <div style={{marginRight: '200px'}}>
            <h4 className="text-12 text-color3">Got any questions? </h4>
            <h4 className="text-27 text-color3">
              Reach out to us on support@bigul.co
            </h4>
          </div>
          <div>
            <button className="refAndEarnButton3">Frequently Asked Questions</button>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Questions;
