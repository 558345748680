import { call, put, takeEvery, all, fork, retry } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { VERIFY_OTP, VERIFY_OTP_RESET } from "./actionType";

import {
  VerifyOtpApiResponseSuccess,
  VerifyOtpApiResponseError,
  VerifyOtpStateReset
} from "./action";

//Include Both Helper File with needed methods
import { submitOtpApi } from "../../helpers/fakebackend_helper";


// add to cart
function* VerifyOtp(data, callback) {
  // //console.log(data); 
  try {
    const response = yield call(submitOtpApi, data.data);
    console.log(response);
    yield call(callback, response)
    ////console.log(response)
    yield put(VerifyOtpApiResponseSuccess('VERIFY_OTP_SUCCESS', response));
    

    //toast.success("Item added successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(VerifyOtpApiResponseError(error));
    //toast.error("Item added Failed", { autoClose: 3000 });
  }
}

export function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP, VerifyOtp);
}

// function* VerifyOtpReset(data) {
//   //console.log(data);
//   // try {
//   //     // const response = yield call(submitOtpApi,data.data);
//   //     // //console.log(response)
//   //     yield put(VerifyOtpStateReset('VERIFY_OTP_SUCCESS'));
//   //     //toast.success("Item added successfully", { autoClose: 3000 });
//   // } catch (error) {
//   //     yield put(VerifyOtpStateReset(error));
//   //     //toast.error("Item added Failed", { autoClose: 3000 });
//   // }
//   return yield put(VerifyOtpStateReset('VERIFY_OTP_RESET'));
// }

// export function* watchVerifyOtpReset() {
//   yield takeEvery(VERIFY_OTP_RESET, VerifyOtpReset);
// }

function* VerifyOtpSaga() {
  yield all([
    fork(watchVerifyOtp),
    //fork(watchVerifyOtpReset),
  ]);
}

export default VerifyOtpSaga;
