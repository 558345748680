import React, { useEffect } from "react";
import { Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import ELearningSlider from './ELearningSlider';

const Algo2 = () => {
  //document.title = "Bigul Admin & Dashboard";
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Row className="px-4 mt-4">
          <ELearningSlider />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Algo2;
