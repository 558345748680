import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_GENERATE_API_KEY } from "./actionType";

import {
  generateApiKeyResponseError,
  generateApiKeyResponseSuccess,
} from "./action";

import { generateApiKeyApi } from "../../helpers/fakebackend_helper";

function* apiKeyD(data) {
  try {
    const response = yield call(generateApiKeyApi, data.payload);
    yield put(generateApiKeyResponseSuccess(GET_GENERATE_API_KEY, response));
  } catch (error) {
    yield put(generateApiKeyResponseError(GET_GENERATE_API_KEY, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_GENERATE_API_KEY, apiKeyD);
}

function* GenerateApiKeySaga() {
  yield all([fork(watchGetCart)]);
}

export default GenerateApiKeySaga;
