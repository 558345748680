import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_MY_INVESTED_ALGO_TOP, GET_MY_INVESTED_ALGO_GRAPH } from "./actionType";

import {
  MyInvestedAlgosApiResponseSuccess,
  MyInvestedAlgosApiResponseError,
  MyInvestedAlgosGraphApiResponseSuccess,
  MyInvestedAlgosGraphApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getMyInvestedAlgosApi, getMyInvestedAlgosGraphApi } from "../../helpers/fakebackend_helper";

function* getMyInvestedAlgos(data) {
    ////console.log(data);
  try {
    const response = yield call(getMyInvestedAlgosApi,data.data);
    yield put(MyInvestedAlgosApiResponseSuccess(GET_MY_INVESTED_ALGO_TOP, response));
  } catch (error) {
    yield put(MyInvestedAlgosApiResponseError(GET_MY_INVESTED_ALGO_TOP, error));
  }
}

export function* watchGetMyInvestedAlgos() {
  yield takeEvery(GET_MY_INVESTED_ALGO_TOP, getMyInvestedAlgos);
}

function* getMyInvestedAlgosGraph(data) {
    ////console.log(data);
  try {
    const response = yield call(getMyInvestedAlgosGraphApi,data.data);
    yield put(MyInvestedAlgosGraphApiResponseSuccess(GET_MY_INVESTED_ALGO_GRAPH, response));
  } catch (error) {
    yield put(MyInvestedAlgosGraphApiResponseError(GET_MY_INVESTED_ALGO_GRAPH, error));
  }
}

export function* watchGetMyInvestedAlgosGraph() {
  yield takeEvery(GET_MY_INVESTED_ALGO_GRAPH, getMyInvestedAlgosGraph);
}

function* MyInvestedAlgosGraphSaga() {
  yield all([
    fork(watchGetMyInvestedAlgos),
    fork(watchGetMyInvestedAlgosGraph)
  ]);
}

export default MyInvestedAlgosGraphSaga;
