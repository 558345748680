import React from "react";
import _, { isEmpty } from "lodash";

import { Card, CardBody, Col, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
// action
import { addToCart } from "../../store/cart/action";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//import "swiper/css/pagination";
import active from "../../assets/images/logo/active.png";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Autoplay } from "swiper";
import MyFeature from "../../Components/CustomComponents/Models/myFeature/myFeature";

const AvailableFeatures = (props) => {
  const dispatch = useDispatch();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));
  const charactersLimit = (data, limit) => {
    //  ////console.log(data);
    // const stringWithTags = "<h1>Hello, World!</h1>";
    const withoutTags = data.replace(/(<([^>]+)>)/gi, "");
    //  ////console.log(withoutTags);
    if (withoutTags === undefined) {
      return withoutTags;
    }
    if (data !== null || data !== undefined) {
      if (withoutTags.length > limit) {
        return withoutTags.substring(0, limit).concat(" ...");
      }
    }
    return withoutTags;
  };
  const { data, data2, myPlan } = props;

  const filteredUsers = Cart.filter((el) => {
    return el.feature_or_plan === "Plan";
  });
  const filteredUsers2 =
    data.length > 0
      ? data.filter((el) => {
          ////console.log(el.feature_durations[0].feature_id )
          ////console.log(myPlan)
          return el.feature_durations[0].feature_id !== data2.feature_id;
        })
      : [];
  // //console.log(data);
  // //console.log(data2);
  // //console.log(filteredUsers2);
  // //console.log(filteredUsers);
  // //console.log(Cart);
  // const thirdArray = data.length > 0 && data2.length > 0 ? data.filter((elem) => {
  //   return data2.some((ele) => {
  //   return ele.feature_id !== elem.feature_durations[0].feature_id;
  //     });
  //   }) : [];
  //   //console.log(thirdArray);
  // const RegisteredEvents = data.filter(obj1 =>{
  //  return data2.find(obj2 => {
  //   //console.log(obj1)
  //   //console.log(obj2)
  //   return +obj1.id !== +obj2.feature_id})
  // }

  // );
  // //console.log(RegisteredEvents);
  // const result = _.filter(data, (array, index) => {
  //   //console.log(array.id)
  //   //console.log(data2[index])
  //   const data3 = data2[index] !== undefined && data2[index].feature_id !== array.id;
  //   if (data3 === true) {
  //     return array;
  //   }
  // });
  ////console.log(myPlan);
  const RegisteredEvents = data.filter((obj1) => {
    return !data2.some((obj2) => {
      ////console.log(obj1);
      ////console.log(obj2);
      return +obj1.id === +obj2.feature_id;
    });
  });
  // //console.log(RegisteredEvents);
  const addToCartHandler = (data) => {
    ////console.log(data);
    const item = {
      client_code: client_code,
      client_token: client_token,
      feature_or_plan_id: data.id,
      feature_or_plan: "Feature",
      duration_type: data.feature_durations[0].duration_type,
    };
    dispatch(addToCart(item));
  };
  const candidateListData = [
    {
      id: 1,
      img: active,
      name: "Algo Invest 1",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 2,
      img: active,
      name: "Algo Invest 2",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 3,
      img: active,
      name: "Algo Invest 3",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 4,
      img: active,
      name: "Algo Invest 4",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 5,
      img: active,
      name: "Algo Invest 5",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 6,
      img: active,
      name: "Algo Invest 6",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 7,
      img: active,
      name: "Algo Invest 7",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
  ];
  // const match = Cart.find((el) => {
  //   return el.feature_or_plan_id === item.feature_or_plan_id;
  // });
  return (
    <React.Fragment>
      <Col xxl={11} xl={11} className="mx-auto my-0">
        <Row className="mt-4 justify-content-center">
          {RegisteredEvents.length > 0 ? (
            RegisteredEvents.map((item, index) => {
              const match = Cart.find((el) => {
                ////console.log(el)
                return (
                  el.feature_or_plan !== "Plan" &&
                  el.feature_or_plan_id === item.id
                );
              });
              const match2 =
                !isEmpty(myPlan) &&
                myPlan.features.length > 0 &&
                myPlan.features.find((el) => {
                  // //console.log(el)
                  return el.id === item.id;
                });
              ////console.log(match2);
              return (
                <React.Fragment key={index}>
                  <Col xl={4} lg={4} md={4}>
                    <Card>
                      <CardBody
                        className="pt-3 pb-3 px-3"
                        style={{ height: "260px" }}
                      >
                        <div
                          className="d-flex mb-1 align-items-center"
                          style={{ height: "60px" }}
                        >
                          <div className="avatar-sm flex-shrink-0">
                            <span className="text-primary rounded-circle fs-1">
                              <div className="card-invest-div-logo rounded-circle align-middle background-color2">
                                <img
                                  className="card-invest-logo align-middle"
                                  src={active}
                                  style={{ width: "45px", height: "45px" }}
                                />
                              </div>
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="text-28 text-color6 text-end margin-0">
                              {/* Rs{" "}
                              {Number(
                                item.feature_durations[0].amount
                              ).toLocaleString("en-US")}{" "}
                              /- */}
                              {Number(item.feature_durations[0].amount) === 0
                                ? "Free"
                                : `₹ ${Number(
                                    item.feature_durations[0].amount
                                  ).toFixed(2)}`}
                            </h6>
                            <h5 className="mt-1 text-1 text-color2 text-end">
                              {item.feature_durations[0].duration_type ||
                                "Per Month"}
                            </h5>
                          </div>
                        </div>
                        <h5
                          className="mt-2 text-6 text-color1"
                          // style={{ height: "110px" }}
                        >
                          Algo Invest Pro
                        </h5>
                        <p
                          className="text-color1 text-17 mt-2"
                          style={{ height: "65px" }}
                        >
                          Easy to invest with one click save profile for later.
                          Easy to invest with one click save profile for later.
                          Easy to invest with one click save profile for later.
                        </p>
                        <div className="d-flex justify-content-between">
                          {/* <button className="text-11 text-color7 profile-edit-button1">
                        View Details
                      </button> */}
                          <MyFeature
                            data={item.id}
                            match2={!isEmpty(match2) ? true : false}
                          />
                          {!isEmpty(match) ? (
                            <button
                              className="text-4 btn btn-primary addons-feature-button-normal"
                              disabled
                              onClick={() => {
                                addToCartHandler(item);
                              }}
                            >
                              <i className="ri-check-line fs-15 align-middle pe-2 text-light"></i>{" "}
                              Added
                            </button>
                          ) : !isEmpty(match2) ? (
                            <button
                              className="text-4 btn btn-primary addons-feature-button-normal"
                              disabled
                              onClick={() => {
                                addToCartHandler(item);
                              }}
                            >
                              Subscribed
                            </button>
                          ) : (
                            <button
                              className="addons-feature-button-normal11"
                              disabled={!isEmpty(match)}
                              onClick={() => {
                                addToCartHandler(item);
                              }}
                            >
                              Insert Now
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              );
            })
          ) : (
            <h4 className="text-center">Suggested Feature Not Available!</h4>
          )}
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default AvailableFeatures;
