import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { isEmpty } from "lodash";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//import "swiper/css/pagination";
import active from "../../assets/images/logo/active.png";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Autoplay } from "swiper";
import Carousel from "react-multi-carousel";

import ActivePlan from "../../Components/CustomComponents/Models/activePlan/ActivePlan";

const Features = (props) => {
  const { data } = props;

  // const d = data.to_date
  // const myArray = data.to_date.split("-");
  // let text = myArray.join("/");
  // let ff = new Date(text)
  // //console.log(text)
  // //console.log(ff);

  const charactersLimit = (data, limit) => {
    if (data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data;
  };

  return (
    <React.Fragment>
        {data?.plan.length === 1 || data?.plan.length <= 2 ? 
          <div className="justify-content-center" style={{ display: "flex" }}>
            {data?.plan.map((item, index) => (
              <Col className="px-2" lg={6} key={index}>
                <Card className="pricing-box ribbon-box ribbon-fill text-center">
                  {!isEmpty(item) && item.id !== 65 && item.message === "Will be activated within 24 hours" ? (
                    <div className="ribbon ribbon-secondary background-color13 shadow-none text-29">
                      Pending
                    </div>
                  ) : (
                    <div className="ribbon ribbon-secondary background-color12 shadow-none text-29">
                      Active
                    </div>
                  )}
                  <Row className="g-0">
                    <Col lg={6}>
                      <CardBody className="h-100">
                        <CardHeader className="plan-button">
                          <h5 className="mb-0 plan-button-text">
                            {!isEmpty(item) && item.plan_name}
                          </h5>
                        </CardHeader>
                        {!isEmpty(item) && item.id === 65 ? (
                          <div className="pb-3 pt-4 mb-2">
                            <h2 className="text-8 text-color6 m-0">
                              {!isEmpty(item) && item.message}
                            </h2>
                          </div>
                        ) : (
                          <div className="pb-3 pt-4 mb-2">
                            <p className="card-text text-color1 text-11 mt-2">
                              {!isEmpty(item) && charactersLimit(item.description, 120)}
                            </p>
                            {/* {!isEmpty(item) && item.msg_title ? <p>{ !isEmpty(item) && item.msg_title}</p> : <p> </p>} */}
                            <h2 className="text-8 text-color6 m-0">
                              {!isEmpty(item) && item.message}
                              {console.log("item", item)}
                            </h2>
                          </div>
                        )}
                        <ActivePlan
                          data={!isEmpty(item) && item.plan_id}
                          btnClass="addons-feature-button-normal mb-2"
                          btnText="View Details"
                        />
                      </CardBody>
                    </Col>
                    <Col lg={6}>
                      <CardBody className="border-start mt-4 mt-lg-0 h-100">
                        <CardHeader className="background-color10 border-radius-1 p-2">
                          <h5 className="text-19 text-color1 mb-0">Plan Features</h5>
                        </CardHeader>
                        <CardBody className="pb-0 text-start">
                          <ul className="list-unstyled vstack gap-3 mb-0">
                            {!isEmpty(item) &&
                              item.features.length > 0 &&
                              item.features.slice(0, 3).map((el, index) => (
                                <li className="text-15 text-color1" key={index}>
                                  <i className="ri-checkbox-circle-line fs-15 align-middle pe-2 text-success"></i>
                                  {el.title} <span className="text-20 text-color6"></span>
                                </li>
                              ))}
                          </ul>
                        </CardBody>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </div>
          :
          <Col xxl={12} xl={12}>
            <Row>
              <Col lg={12}  >
                <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay
                autoPlaySpeed={2000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={true}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 2,
                    partialVisibilityGutter: 40,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 700,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                  mobile: {
                    breakpoint: {
                      max: 700,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                
                {data?.plan.map((item, index) => (
                  <div key={index} className="px-2">
                      <Col className="px-2" >
                        <Card className="pricing-box ribbon-box ribbon-fill text-center">
                          {!isEmpty(item) && item.id !== 65 && item.message === "Will be activated within 24 hours" ? <div className="ribbon ribbon-secondary background-color13 shadow-none text-29">
                            Pending
                          </div> : <div className="ribbon ribbon-secondary background-color12 shadow-none text-29">
                            Active
                          </div>}
                          <Row className="g-0">
                            <Col lg={6}>
                              <CardBody className="h-100">
                                <CardHeader className="plan-button">
                                  <h5 className="mb-0 plan-button-text">
                                    {!isEmpty(item) && item.plan_name}
                                  </h5>
                                </CardHeader>
                              {!isEmpty(item) && item.id === 65 ? <div className="pb-3 pt-4 mb-2">
                                  <h2 className="text-8 text-color6 m-0">
                                    {!isEmpty(item) && item.message}
                                  </h2>
                                </div> : <div className="pb-3 pt-4 mb-2">
                                <p className="card-text text-color1 text-11 mt-2">
                                    {!isEmpty(item) &&
                                      charactersLimit(
                                        item.description,
                                        120
                                      )}
                                  </p>
                                  {/* {!isEmpty(item) && item.msg_title ? <p>{ !isEmpty(item) && item.msg_title}</p> : <p> </p>} */}
                                  <h2 className="text-8 text-color6 m-0">
                                    {!isEmpty(item) && item.message}
                                    {console.log("item",item)}
                                  </h2>
                                </div>}
                                <ActivePlan
                                  data={!isEmpty(item) && item.plan_id}
                                  btnClass="addons-feature-button-normal mb-2"
                                  btnText="View Details"
                                />
                              </CardBody>
                            </Col>
                            <Col lg={6}>
                              <CardBody className="border-start mt-4 mt-lg-0 h-100">
                                <CardHeader className="background-color10 border-radius-1 p-2">
                                  <h5 className="text-19 text-color1 mb-0">Plan Features</h5>
                                </CardHeader>
                                <CardBody className="pb-0 text-start">
                                  <ul className="list-unstyled vstack gap-3 mb-0">
                                    {!isEmpty(item) &&
                                      item.features.length > 0 &&
                                      item.features.slice(0, 3).map((el, index) => {
                                        return (
                                          <li className="text-15 text-color1 " key={index}>
                                            <i className="ri-checkbox-circle-line fs-15 align-middle pe-2 text-success"></i>
                                            {el.title}{" "}
                                            <span className="text-20 text-color6"></span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </CardBody>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                  </div>
              ))}
                </Carousel>
              </Col>
          </Row>
         </Col> 
        }
         
       
     
   

 </React.Fragment>
  );
};

export default Features;
