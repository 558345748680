import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_ELEARNINGVIDEOS } from "./actionType";

import {
  ELearningApiResponseSuccess,
  ELearningApiResponseError,
  getNotificationsList,
} from "./action";

//Include Both Helper File with needed methods
import { getELearningApiListApi } from "../../helpers/fakebackend_helper";

function* getELearningVideosList(data) {
  try {
    const response = yield call(getELearningApiListApi, data.data);
    yield put(ELearningApiResponseSuccess(GET_ELEARNINGVIDEOS, response));
  } catch (error) {
    yield put(ELearningApiResponseError(GET_ELEARNINGVIDEOS, error));
  }
}

export function* watchGetELearningVideosList() {
  yield takeEvery(GET_ELEARNINGVIDEOS, getELearningVideosList);
}

function* ELearningVideosSaga() {
  yield all([fork(watchGetELearningVideosList)]);
}

export default ELearningVideosSaga;
