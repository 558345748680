import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";
import { getELearningByCategoryApi } from '../../helpers/fakebackend_helper';
//Dashboard components
import VideoCollectionHeader from './VideoCollectionHeader';
import ELearningSlider from './ELearningSlider';
import Gallery from "./Gallery";
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";

const SelectedElearning = () => {
  const [videoData, setVideoData] = useState({});
  const { ID } = useParams();
  ////console.log(ID)
  document.title = "Bigul Admin & Dashboard";
  const client_code  = localStorage.getItem('client_id');
  const client_token = localStorage.getItem('client_token');

  useEffect(() => {
    
    getELearningByCategoryApi({ client_code: client_code, client_token: client_token, e_id: ID })
      .then((response) => {
        ////console.log(response);
        setVideoData(response);
        // setIsDownload(true);
      })
      .catch((err) => {
        ////console.log(err);
      });
  },[ID]);

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Commodity"
            titleMain="Video Collection"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Videos"
            to="/elearning"
          />
          <Row>
            <VideoCollectionHeader id={ID} />
          </Row>
          <Row className="pb-4">
            <ELearningSlider />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SelectedElearning;
