import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
//action
import { withdraw } from "../../../../store/marginOverview/action";
import EncClient from "../../../../pages/Dashboard/EncClient";
// TODO Ui and design and functions.
const ConfirmWithdrawal = (props) => {
  const dispatch = useDispatch();
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));
  const modalMobile = {   
    CUpdate: false,
    otp1: false,
    done: true,
  };
  function tog_signUpModals() {
    // //console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }
  const onClickHandler = (e) => {
    dispatch(
      withdraw({ apiKey: generatedKey, dcode: EncClient(), amount: props.value })
    );
 
    props.sendData(modalMobile);
  };
  ////console.log(props);
  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
          Confirm Withdrawal
        </h4>
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="d-flex custom-border2 pb-3">
          <h3 className="text-15 text-color1 px-4 mb-0">
            Funds will be transferred to this account
          </h3>
        </div>
        {/* <div className="px-4 mt-4">
          <h4 className="text-11 text-color1">Amount</h4>
          <h3 className="text-6 text-color6 px-2 mt-3">{`₹ ${props.value}`}</h3>
          <h4 className="text-11 text-color1 mt-4">Account Number</h4>
          <h3 className="text-6 text-color1 px-2 mt-3">{props.data.bankDetails[0].Acnumber}</h3>
          <h4 className="text-11 text-color1 mt-4">Bank</h4>
          <h3 className="text-6 text-color7 px-2 mt-3">{props.data.bankDetails[0].Bankname}</h3>
        </div> */}
        <div className="px-4 mt-4 text-center justify-content-center">
          <div className="d-flex align-items-center">
            <h4 className="text-9 text-color1 me-3">Amount:</h4>
            <h3 className="text-99 text-color6">{`₹${props.value}`}</h3>
          </div>
          <div className="d-flex align-items-center">
            <h4 className="text-9 text-color1 me-3">Account Number:</h4>
            <h3 className="text-99 text-color1 ">
              {props.data.bankDetails[0].Acnumber}
            </h3>
          </div>

          <div className="d-flex align-items-center">
            <h4 className="text-9 text-color1 me-3">Bank:</h4>
            <h3 className="text-99 text-color7">
              {props.data.bankDetails[0].Bankname}
            </h3>
          </div>
        </div>
        <div className="text-center my-3">
          <button
            type="submit"
            className="btn btn-primary modal-content-Button my-3 w-50"
            onClick={(e) => onClickHandler(e)}
          >
            Confirm
          </button>
        </div>
      </ModalBody>
    </React.Fragment>
  );
};

export default ConfirmWithdrawal;
