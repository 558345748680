import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";
import axios from "axios";
import { MOD_EKYC_NEW } from "../../../../helpers/url_helper";
import Encrypted from "../../../../pages/Dashboard/Encripted";
import loaderImage from "../../../../assets/images/loader-icon.png";

// TODO Ui and design and functions.
const ConfirmChanges1 = (props) => {
  const [disableButton, setDisableButton] = useState(0);

  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const validationSchema = Yup.object().shape({
    condition: Yup.bool().oneOf(
      [true],
      "Please accept the Terms & Conditions to proceed."
    ).required('This field is required'),
  });
  const client_code = localStorage.getItem("client_id");

  const initialPackage = {
    condition: false,
  };
  const [formValues, setFormValues] = useState(initialPackage);

  const modal = {
    CChange1: false,
    CChange2: true,
  };

  const tog_signUpModals = () => {
    props.tog(false);
  }

  const onSubmit = () => {
    setDisableButton(1)
    const formData={
      client_code:client_code,
      entry_type:'dpscheme',
      old: JSON.stringify({
        dpscheme: 'BIGULIND',
      }),
      new: JSON.stringify({
        dpscheme: props.data,
      }),
      modify_key: JSON.stringify(['dpscheme'])
    }
    // const formData = new FormData();
    // // Append each piece of nominee data to the FormData.
    // formData.append('client_code', client_code);
    // formData.append('entry_type', 'dpscheme');
    // formData.append('new[dpscheme]', props.data);
    // formData.append('old[dpscheme]', 'BIGULIND');

    // formData.append('modify_key[]', 'dpscheme');
    axios.post(MOD_EKYC_NEW + '/update-modification', {'enc':Encrypted([formData])}, {
      headers: {
        // 'Content-Type': 'applicat',
        'Authorization': 'Bearer your_token', // Replace with your actual token.
      },
    })
      .then(response => {

        if (response.status) {
          setDisableButton(0)

          props.tog(false);
          props.handlechangeScheme(true)
          setTimeout(() => {
            props.funCancel(false)
          }, 1000);

          return
        }
        else {
    setDisableButton(0)

          toast(response.message)
        }
      })
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(modal);
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
          Confirm Changes
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <Formik
          initialValues={{
            condition: formValues.condition,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => {
            ////console.log(values);

            return (
              <Form
                noValidate
                autoComplete="off"
                id="my-form"
                className="flex-col justify-center w-full"
              >
                <div className="d-flex mb-2 d-none">
                  <div className="flex-shrink-0">
                    <i className=" ri-error-warning-fill fs-4 text-color10"></i>
                  </div>
                  <div className="flex-grow-1 ms-2 text-muted text-11 px-3 pt-1">
                    Please map your own .................................... to your
                    trading account. You cannot link a third party
                    ............................. to your trading account.
                  </div>
                </div>
                <div className="d-flex mb-2 d-none">
                  <div className="flex-shrink-0">
                    <i className=" ri-error-warning-fill fs-4 text-color10"></i>
                  </div>
                  <div className="flex-grow-1 ms-2 text-muted text-11 px-3 pt-1">
                    If modifying ..............................................., post
                    successful verification, your existing
                    ......................................................... will be
                    ..............
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <div className="flex-shrink-0">
                    <i className=" ri-error-warning-fill fs-4 text-color10"></i>
                  </div>
                  <div className="flex-grow-1 ms-2 text-muted text-11 px-3 pt-1">
                    Charge of the selected scheme will be charged for modification of DP Scheme from ledger balance.
                  </div>
                </div>
                <div className="mb-3 form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="checkTerms"
                    name="condition"
                    value={values.condition}
                    onBlur={handleBlur}
                    error={errors.condition && touched.condition}
                    helperText={touched.condition && errors.condition}
                    onChange={handleChange}
                    checked={values.condition}
                  />
                  <Label
                    className="form-check-label text-15 text-color1 px-3"
                    htmlFor="checkTerms"
                  >

                    I hereby confirm change of my DP Scheme. I understand that there may be implications associated with changing my DP scheme. Upon selection of my preferable scheme, I will be levied with the charges associated with the scheme.

                  </Label>
                  {errors.condition && (
                    <label
                      className="mt-3 mx-3"
                      style={{ color: "#f44336" }}
                    >
                      <h6 className="fs-12 text-danger">{errors.condition}</h6>
                    </label>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary nominee-page-button-save my-3"
                  //onClick={(e) => onClickHandler(e)}
                  >
                     {disableButton == 0 ? 'Confirm' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default ConfirmChanges1;
