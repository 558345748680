import {
  GET_TRADE_REFER_SUMMARY,
  GET_TRADE_REFER_SUM,
  API_TRADE_REFER_SUMMARY_SUCCESS,
  API_TRADE_REFER_SUMMARY_FAIL,
} from "./actionType";

export const tradeReferApiResponseSuccess = (actionType, data) => ({
  type: API_TRADE_REFER_SUMMARY_SUCCESS,
  payload: { actionType, data },
});

export const tradeReferApiResponseError = (actionType, error) => ({
  type: API_TRADE_REFER_SUMMARY_FAIL,
  payload: { actionType, error },
});

export const getTradeReferSummary = (data) => ({
  type: GET_TRADE_REFER_SUMMARY,
  payload: data,
});
export const getTradeReferSum = (data) => ({
  type: GET_TRADE_REFER_SUM,
  payload: data,
});
