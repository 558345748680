import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";

//Import Breadcrumb
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";

const MyPlansHeader = () => {
  // document.title = "Shopping Cart | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <Col xs={12} className="d-flex text-center justify-content-center">
        <div className="width-mobile-50 ">
          <h4 className="text-6 text-color5 mb-4 text-uppercase">My Active Plan</h4>
          <h6 className="text-4 text-color1 mb-4">
          Effortlessly manage active subscriptions and select the perfect plan for you. Explore exclusive Bigul features designed to elevate your experience.
          </h6>
          {/* <button
            className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
            type="button"
            style={{ borderRadius: "5px" }}
          >
            Switch to Features
          </button> */}
          <Link
            to="/subscription/features"
            className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
            style={{ borderRadius: "5px" }}
          >
            Switch to Features
          </Link>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default MyPlansHeader;
