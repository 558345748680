import {
  GET_MY_PLAN_DURATION,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
  myPlanDuration: [],
};

const MyPlanDuration = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MY_PLAN_DURATION:
          return {
            ...state,
            myPlanDuration: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_MY_PLAN_DURATION:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_MY_PLAN_DURATION: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default MyPlanDuration;
