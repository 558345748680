import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_SA } from "./actionType";

import {
  SAApiResponseSuccess,
  SAApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getCurrentOverviewSAApi } from "../../helpers/fakebackend_helper";

function* getSA(data) {
  try {
    const response = yield call(getCurrentOverviewSAApi, data.data);
    yield put(SAApiResponseSuccess(GET_SA, response));
  } catch (error) {
    yield put(SAApiResponseError(GET_SA, error));
  }
}

export function* watchGetSA() {
  yield takeEvery(GET_SA, getSA);
}

function* SaSaga() {
  yield all([
    fork(watchGetSA)
  ]);
}

export default SaSaga;
