import React, {useEffect} from "react";
//redux
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
// actions
import { getInvestmentSummary } from "../../store/investedSummary/action";
import { cyptoWidgets2 } from "../../common/data";
import Widget1 from "../../Components/CustomComponents/Widget1";
import Widget2 from "../../Components/CustomComponents/Widget2";

const Widgets = () => {
  const dispatch = useDispatch();

  const { SA } = useSelector((state) => ({
    SA: state.Sa.sa,
  }));
  // console.log("sa",SA)
  const SACurrent = !isEmpty(SA) && typeof SA !== "string" ? +SA.total_current_value.replace(/,/g, "") : 0;
  const SAInv = !isEmpty(SA) && typeof SA !== "string" ? +SA.total_invested_value.replace(/,/g, "") : 0;
  const SAReturn = !isEmpty(SA) && typeof SA !== "string" ? SACurrent - SAInv : 0;
  const SAPer = !isEmpty(SA) && typeof SA !== "string" ? (SAReturn / SAInv) * 100: 0;
  ////console.log(SA);

  const { PMS } = useSelector((state) => ({
    PMS: state.Pms.pms,
  }));
  const PmsCurrent = !isEmpty(PMS) && typeof PMS !== "string" ? +PMS.total_current_value.replace(/,/g, "") : 0;
  const PmsInv = !isEmpty(PMS) && typeof PMS !== "string" ? +PMS.total_invested_value.replace(/,/g, "") : 0;
  const PmsReturn = !isEmpty(PMS) && typeof PMS !== "string" ? PmsCurrent - PmsInv : 0;
  const PmsPer = !isEmpty(PMS) && typeof PMS !== "string" ? (PmsReturn / PmsInv) * 100 : 0;
  // console.log("PMS",PMS);

  const { MF } = useSelector((state) => ({
    MF: state.Mf.mf,
  }));
  const MfCurrent = !isEmpty(MF) && typeof MF !== "string" ? +MF.total_current_value.replace(/,/g, "") : 0;
  const MfInv = !isEmpty(MF) && typeof MF !== "string" ? +MF.total_invested_value.replace(/,/g, "") : 0;
  const MfReturn = !isEmpty(MF) && typeof MF !== "string" ? MfCurrent - MfInv : 0;
  const MfPer = !isEmpty(MF) && typeof MF !== "string" ? (MfReturn / MfInv) * 100 : 0;
//  console.log("MF",MF);

  const PMSTotalCurrent = SACurrent + PmsCurrent;
  const PMSTotalInv = SAInv + PmsInv;
  const PMSTotalReturn = PMSTotalCurrent - PMSTotalInv;
  const PMSTotalPer = ((PMSTotalReturn / PMSTotalInv) * 100);

  ////console.log(PMS);
  const { InvestmentSummary } = useSelector((state) => ({
    InvestmentSummary: state.InvestmentSummary.InvestmentSummary,
  }));
  const INVCurrent = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.currentvalue : 0;
  const INVInv = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.totalinvested : 0;
  const INVReturn = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.PNL : 0;
  const INVPer = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.totalreturn : 0;
  ////console.log(InvestmentSummary);

  const TotalInvested = SAInv + MfInv + PmsInv + INVInv;
  const CurrentInvested = SACurrent + PmsCurrent + MfCurrent + INVCurrent;
  const TotalReturn = CurrentInvested - TotalInvested;
  var TotalReturnPer = (TotalReturn / TotalInvested) * 100;
  if(isNaN(TotalReturnPer)){
    TotalReturnPer = 0;
  }else{
    TotalReturnPer = TotalReturnPer.toFixed(2);
  }
  // console.log('TotalReturnPerTotalReturnPerTotalReturnPer',TotalReturnPer)


  const chartData = [];
  
    // chartData.push(isEmpty(InvestmentSummary.portfoliovalueResult) ? 0 : +InvestmentSummary.portfoliovalueResult.totalinvested);
    // chartData.push(isEmpty(SA) || tt === true  ? 0 : +SA.total_current_value.replace(/,/g, ''));
    // chartData.push(0);
    // chartData.push(!isEmpty(PMS) ? 0 : +PMS.total_current_value);
    // const subTotal = chartData.reduce(function (acc, val) {
    //   return acc + val;
    // }, 0);
  const generatedKey  = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
   // //console.log(InvestmentSummary);
  //const myJSON = !isEmpty(InvestmentSummary) &&  JSON.parse(InvestmentSummary.replace(/\bNaN\b/g, "null"))
  ////console.log( JSON.parse(InvestmentSummary.replace(/\bNaN\b/g, "null")));
  ////console.log(JSON.parse(myJSON));
  
  useEffect(() => {
    dispatch(getInvestmentSummary({apiKey: generatedKey, code: encryptKey }));
  }, [dispatch, generatedKey, encryptKey]);
  // {console.log("TotalInvested",TotalInvested)}
  return (
    <React.Fragment>
      <Widget1 item={cyptoWidgets2[0]} data={TotalInvested} />
      <Widget1 item={cyptoWidgets2[1]} bgColor="badge-color-profit" data={CurrentInvested} />
      <Widget1
        item={cyptoWidgets2[2]}
        bgColor="badge-color-loss"
        percentage={true}
        data={TotalReturn} 
        data2={TotalReturnPer} 
      />
      <Widget2 />
    </React.Fragment>
  );
};

export default Widgets;
