import {
  GET_ENCRYPT_KEY,
  API_ENCRYPT_RESPONSE_SUCCESS,
  API_ENCRYPT_RESPONSE_ERROR,
} from "./actionType";

// common success
export const EncryptKeyApiResponseSuccess = (actionType, data) => ({
  type: API_ENCRYPT_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const EncryptKeyApiResponseError = (actionType, error) => ({
  type: API_ENCRYPT_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getEncryptKey = () => ({
  type: GET_ENCRYPT_KEY,
});