
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import Flatpickr from "react-flatpickr";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getELearningList } from "../../store/eLearning/action";
import { getELearningCategoryList } from '../../store/eLearningCategory/action';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Autoplay } from "swiper";

import ambassador_bg from "../../assets/images/logo/ambassador_bg.png";
import { AlgoGetTranstionSuccess, getTranstions } from "../../store/algoGetTranstions/action";
import { getList } from "../../store/algoGetList/action";
import closeIcon from "../../assets/images/algo/small-close.png";
import cardIcon1 from "../../assets/images/algo/card-1.png"
import cardIcon2 from "../../assets/images/algo/card-2.png"
import cardIcon3 from "../../assets/images/algo/card-3.png"
import cardIcon4 from "../../assets/images/algo/card-4.png"
import arrowDownRIght from "../../assets/images/algo/arrow-down-right.png"

import arrowUpRIght from "../../assets/images/algo/arrow-up-right.svg";
import calender from "../../assets/images/algo/calendar.svg";
import filterIcon from "../../assets/images/algo/filter-icon.svg"
import DatePicker from "react-flatpickr";
import Pagination from "./Pagination";
// import moment from "moment";

const ELearningSlider = () => {
  window.location.href = '/dashboard';
  return false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client_code = localStorage.getItem('client_id');
  const client_token = localStorage.getItem('client_token');

  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [searchDateVisible, setSearchDateVisible] = useState(true);
  const [searchDateBVisible, setSearchDateBVisible] = useState(true);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [activeTab, setActiveTab] = useState('transaction');


  // table date selecte from to to date state
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleToDateChange = (date) => {
    setEndDate(date);
  };
// entries select 10 25 100 >>>>>>>>>>
  const handleEntriesPerPageChange = (entries) => {
    setEntriesPerPage(entries);
  };
//>>>>>>>>>>>No clear function>.......
  const toggleSearchDateB = () => {
    setSearchDateBVisible(!searchDateBVisible);
  };
  // get data algo List redux get result
  const { algoListShow } = useSelector((state) => ({
    algoListShow: state.GetList.getlistData,
  }));
console.log("check the get",algoListShow);

  // get data algo Transition list redux get result
  const { algoTranList } = useSelector((state) => ({
    algoTranList: state.GetTranstions.getTranstionData.gldertrResult,
  }));

  const [selectedCtclId, setSelectedCtclId] = useState({ clientName: '', ctcl_id: 'all' });

  // DropDown Select function state manage
  const handleSelectChange = (e) => {
    const selectedClientName = e.target.value;
    const selectedAlgo = selectedClientName === "all" ? "all" :
      algoListShow.find((algo) => algo?.ctcl_master?.fm_algo_name === selectedClientName);
    setSelectedCtclId({
      clientName: selectedClientName,
      ctcl_id: selectedAlgo == undefined ? "all" : selectedAlgo.ctcl_master.ctcl_id,
    });
  };



// first time call api 
  useEffect(() => {
    dispatch(getList({
      client_code: client_code,
    }))
    dispatch(getTranstions({
      client_code: client_code,
      ctcl_id: selectedCtclId.ctcl_id,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD")
    }))
  }, [])

  // first time call api date update DropDown Algo Name Select update 
  useEffect(() => {
    dispatch(getTranstions({
      client_code: client_code,
      ctcl_id: selectedCtclId.ctcl_id == null ? "all" : selectedCtclId.ctcl_id,
      // client_code:"MN0U09306",
      // ctcl_id:"111111111111122",
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD")
    }))
  }, [selectedCtclId, startDate, endDate])

  // Algo transion table manage function  call return
  const TransactionTable = ({ data, entriesPerPage }) => {
    console.log(data.length);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = data.slice(indexOfFirstEntry, indexOfLastEntry);
    const totalPages = Math.ceil(data.length / entriesPerPage);
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    return (
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Transaction Date</th>
              <th>Scrip Name</th>
              <th>Strike Price</th>
              <th>Type (Buy/Sell)</th>
              <th>Qty</th>
              <th>Order Price</th>
              <th>Trade Value</th>
              <th>Exchange</th>
              <th>Brokerage</th>
              <th>Charges/Taxes</th>
              <th>Product type</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((transaction, index) => (
              <tr key={index}>
                <td><p>Transaction Date</p><span>{transaction?.dtoftran}</span></td>
                <td><p>Scrip Name</p><span>{transaction?.scripname}</span></td>
                <td><p>Strike Price</p><span>{transaction?.strikeprice}</span></td>
                <td>
                  <p>Type (Buy/Sell)</p>
                  <button className={transaction?.buysell === 'B' ? 'buy' : 'sell'}>
                    {transaction?.buysell === 'B' ? 'Buy' : transaction?.buysell === 'S' ? 'Sell' : transaction?.buysell}
                  </button>

                </td>
                <td><p>Qty</p><span>{transaction?.quantity}</span></td>
                <td><p>Order Price</p><span>{(transaction?.final_rat1).toFixed(2)}</span></td>
                <td><p>Trade Value</p><span>{transaction?.Net_val}</span></td>
                <td><p>Exchange</p><span>{transaction?.exchcode}</span></td>
                <td><p>Brokerage</p><span>{transaction?.brokerage}</span></td>
                <td><p>Charges/Taxes</p><span>{transaction?.total_amount}</span></td>
                {/* <td><p>Product type</p><span>{transaction?.booktype}</span></td> */}
                <td><p>Product type</p><span>{transaction?.segment_type}</span></td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
        entriesPerPage={entriesPerPage}
        totalEntries={data.length}
        onPageChange={handlePageChange}
        currentPage={currentPage}
      />
      </div>
    );
  };

//table select click to manage "Transaction Table" && "P&L Table"
const handleTabClick = (tab) => {
  setActiveTab(tab);
};

  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="container">

          <div className="heading-part">
            <h4>Client Algo Dashboard</h4>
            <div className="right">
              <button
                className="generate-btn"
                data-bs-toggle="modal"
                data-bs-target="#algo-key"
              >
                Generate Algo Key &gt;
              </button>
              <ul>
                <li>
                  <a href="#">Dashboard &nbsp;> &nbsp; </a>
                </li>
                <li>
                  <a href="#">  Statements</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="filter-part" style={{ display: 'flex' }}>
            <label>Select Date <span id="search-close"><img src={closeIcon} alt="Close" /></span></label>
            <div className="input-group date" id="datepicker">
              <span className="input-group-append">
                <span className="input-group-text">
                  <img src={calender} alt="Calendar" />
                </span>
              </span>
              <input type="text" value="2023-11-16" className="form-control" id="date" placeholder="From" />
            </div>
            <div className="input-group date" id="datepicker2">
              <span className="input-group-append">
                <span className="input-group-text">
                  <img src={calender} alt="Calendar" />
                </span>
              </span>
              <input type="text" className="form-control" value="2023-11-16" id="date" placeholder="To" />
            </div>
            <button className="search-btn">
              Search
            </button>
          </div>


          <div className="row">
            <div className="col-6 col-md-3">
              <div className="dashboard-card">
                <div className="price">
                  <div className="icon">
                    <img src={cardIcon1} alt="Card 1" />
                  </div>
                  <h5>Invested amount</h5>
                </div>
                <h4>₹1,00,00,000</h4>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="dashboard-card">
                <div className="price">
                  <div className="icon">
                    <img src={cardIcon2} alt="Card 1" />
                  </div>
                  <h5>CURRENT VALUE</h5>
                </div>
                <h4>₹1,01,00,000</h4>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="dashboard-card">
                <div className="price">
                  <div className="icon">
                    <img src={cardIcon3} alt="Card 1" />
                  </div>
                  <h5>DAY’S GAIN P/L</h5>
                </div>
                <h4>₹1,00,000  <span className="up"><img src={arrowUpRIght} />1 %</span></h4>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="dashboard-card">
                <div className="price">
                  <div className="icon">
                    <img src={cardIcon4} alt="Card 1" />
                  </div>
                  <h5>DAY’S GAIN P/L</h5>
                </div>
                <h4>₹3,00,000 <span className="down"><img src={arrowDownRIght} />3 %</span></h4>
              </div>
            </div>
          </div>
          <div className="gain">
            <div className="row">
              <div className="col-10 col-md-3">
                <div className="gain-card">
                  <div className="left">
                    <p>Algo 1 gain P/L</p>
                    <h4>₹10,000</h4>
                  </div>
                  <span className="up"><img src={arrowUpRIght} alt="Up" />10.10 %</span>
                </div>
              </div>
              {/* Repeat the similar code for other gain cards */}
              <div className="col-10 col-md-3">
                <div className="gain-card">
                  <div className="left">
                    <p>Algo 2 gain P/L</p>
                    <h4>₹10,000</h4>
                  </div>
                  <span className="up"><img src={arrowUpRIght} alt="Up" />10.10 %</span>
                </div>
              </div>
              <div className="col-10 col-md-3">
                <div className="gain-card">
                  <div className="left">
                    <p>Algo 3 gain P/L</p>
                    <h4>₹10,000</h4>
                  </div>
                  <span className="up"><img src={arrowUpRIght} alt="Up" />10.10 %</span>
                </div>
              </div>
              <div className="col-10 col-md-3">
                <div className="gain-card">
                  <div className="left">
                    <p>Algo 4 gain P/L</p>
                    <h4>₹10,000</h4>
                  </div>
                  <span className="up"><img src={arrowUpRIght} alt="Up" />10.10 %</span>
                </div>
              </div>
            </div>
          </div>

          <div className="download-sec">
            <div className="left">
              <label>Select Algo</label>
              <select className="form-control dropdown"
                value={selectedCtclId?.clientName || ''}
                onChange={handleSelectChange}>
                <option key={"all"}>All</option>
                {algoListShow.map((algoName) => (
                  <option key={algoName.ctcl_master.ctcl_id}>{algoName?.ctcl_master?.fm_algo_name}</option>
                ))}
              </select>
            </div>
            <span
              className="search-date"
              id="search-date-b"
              onClick={toggleSearchDateB}
            >
              Select Date to Search
            </span>
            <div className="right">
            <label className="main">Select Date</label>
            <div> 
            <label className="d-block">From</label>
              <div className="input-group date" id="datepicker4">
             
                <span className="input-group-append">
                  <span className="input-group-text" onClick={() => setIsEndDatePickerOpen(true)}>
                    {/* Use your calendar icon here */}
                    <img src={calender} alt="Calendar" />
                  </span>
                </span>
                <Flatpickr
                  options={{
                    dateFormat: "Y-m-d",
                    // maxDate: "today",
                  }}
                  value={startDate}
                  onChange={handleStartDateChange}
                  className="form-control rounded-10" // Add your custom class for styling
                />
              </div>
              </div>
              <div>
              <label className="d-block">To</label>
              <div className="input-group date" id="datepicker5">
      <span className="input-group-append">
        <span className="input-group-text" onClick={() => setIsStartDatePickerOpen(true)}>
          <img src={calender} alt="Calendar" />
        </span>
      </span>
      <Flatpickr
                  options={{
                    dateFormat: "Y-m-d",
                    // maxDate: "today",
                  }}
                  value={endDate}
                  onChange={handleToDateChange}
                  className="form-control rounded-10" // Add your custom class for styling
                />
    </div>
    </div>

              <button className="download-btn">Download Reports</button>
            </div>
          </div>
          {/* Rest of your code... */}

          <div className="table-part">
            <div className="table-tabs">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button className="nav-link active" id="transaction-tab" data-bs-toggle="tab" data-bs-target="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="true">Transaction table</button> */}
                  <button
            className={`nav-link ${activeTab === 'transaction' ? 'active' : ''}`}
            id="transaction-tab"
            data-bs-toggle="tab"
            data-bs-target="#transaction"
            type="button"
            role="tab"
            aria-controls="transaction"
            aria-selected={activeTab === 'transaction'}
            onClick={() => handleTabClick('transaction')}
          >
            Transaction table
          </button>
                </li>
                <li className="nav-item" role="presentation">
                  {/* <button className="nav-link" id="tp-tab" data-bs-toggle="tab" data-bs-target="#tp" type="button" role="tab" aria-controls="tp" aria-selected="false">P&L Table</button> */}
                  <button
            className={`nav-link ${activeTab === 'tp' ? 'active' : ''}`}
            id="tp-tab"
            data-bs-toggle="tab"
            data-bs-target="#tp"
            type="button"
            role="tab"
            aria-controls="tp"
            aria-selected={activeTab === 'tp'}
            onClick={() => handleTabClick('tp')}
          >P&L Table</button>
                </li>
              </ul>
              <div className="right d-none">
                <button className="filter"><img src={filterIcon}></img></button>
                <div className="form-group">
                  <label>Show</label>
                  <select
                    className="form-control"
                    value={entriesPerPage}
                    onChange={(e) => handleEntriesPerPageChange(Number(e.target.value))}
                  >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                  <label>entries</label>
                </div>
              </div>
            </div>


            <div className="tab-pane fade show active" id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
              <TransactionTable data={algoTranList == undefined ? [] : algoTranList} entriesPerPage={entriesPerPage} />
            </div>


          </div>



        </div>
      </div>




    </React.Fragment>
  );
};

export default ELearningSlider;
