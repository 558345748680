// Actions
// export const API_GET_CART_LIST_RESPONSE_SUCCESS = "API_GET_CART_LIST_RESPONSE_SUCCESS";
// export const API_GET_CART_LIST_RESPONSE_ERROR = "API_GET_CART_LIST_RESPONSE_ERROR";
// export const GET_GET_CART_LIST = "GET_GET_CART_LIST";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

export const VERIFY_OTP_RESET = 'VERIFY_OTP_RESET'

// export const REMOVE_ITEM_TO_CART = "REMOVE_ITEM_TO_CART";
// export const REMOVE_ITEM_TO_CART_SUCCESS = "REMOVE_ITEM_TO_CART_SUCCESS";
// export const REMOVE_ITEM_TO_CART_FAIL = "REMOVE_ITEM_TO_CART_FAIL";

// export const UPDATE_ITEM_TO_CART = "UPDATE_ITEM_TO_CART";
// export const UPDATE_ITEM_TO_CART_SUCCESS = "UPDATE_ITEM_TO_CART_SUCCESS";
// export const UPDATE_ITEM_TO_CART_FAIL = "UPDATE_ITEM_TO_CART_FAIL";
