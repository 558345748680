import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import classnames from "classnames";
//import "swiper/css/pagination";
import active from "../../assets/images/logo/active.png";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Autoplay } from "swiper";
import Active1 from "./Active1";
import styled from "styled-components";

const AvailableFeatures = (props) => {
 
  const { data, myPlan } = props;
  const [activeTab, setActiveTab] = useState("1");
  //Tab
  // console.log("data",data);
  // console.log("ggfd",myPlan);
  // //console.log(typeof(activeTab))
  const toggleTab = (tab, data) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  // {!isEmpty(data) && data.plan_name}
  return (
    <React.Fragment>
      <Row className="justify-content-center mt-4">
        <Col lg={5}>
          <div className="text-center mb-4">
            <div className="d-inline-flex">
              <Nav
                className="nav-pills arrow-navtabs plan-nav rounded mb-3 p-1"
                id="pills-tab"
                role="tablist"
              >
                <NavItem className="mx-1 my-2">
                  <NavLink
                    href="#"
                    className={`${classnames(
                      { active: activeTab === "1" },
                      "text-10 text-color3 border-radius-2"
                    )} ${activeTab !== "1" && "in-active-price-tab"}`}
                    onClick={() => {
                      toggleTab("1", "monthly");
                    }}
                  >
                    Monthly
                  </NavLink>
                </NavItem>
                <NavItem className="mx-1 my-2">
                  <NavLink
                    href="#"
                    className={`${classnames(
                      { active: activeTab === "2" },
                      "text-10 text-color3 border-radius-2"
                    )} ${activeTab !== "2" && "in-active-price-tab"}`}
                    onClick={() => {
                      toggleTab("2", "quarterly");
                    }}
                  >
                    Quarterly
                  </NavLink>
                </NavItem>
                <NavItem className="mx-1 my-2">
                  <NavLink
                    href="#"
                    className={`${classnames(
                      { active: activeTab === "3" },
                      "text-10 text-color3 border-radius-2"
                    )} ${activeTab !== "3" && "in-active-price-tab"}`}
                    onClick={() => {
                      toggleTab("3", "annually");
                    }}
                  >
                    Annually{" "}
                    {/* <span className={`${activeTab === "3" ? "badge bg-primary price-off-badge2" : 'badge bg-success price-off-badge'}`}>
                      25% Off
                    </span> */}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </Col>
        <Col xl={9}>
          <Row>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Active1
                  data={!isEmpty(data) && data.monthly}
                  myPlan={myPlan}
                />
              </TabPane>
              <TabPane tabId="2">
                <Active1
                  data={!isEmpty(data) && data.quarterly}
                  myPlan={myPlan}
                />
              </TabPane>
              <TabPane tabId="3">
                <Active1
                  data={!isEmpty(data) && data.annually}
                  myPlan={myPlan}
                />
              </TabPane>
            </TabContent>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AvailableFeatures;
