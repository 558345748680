import React from "react";
import { Spinner } from "reactstrap";
import loader from '../../assets/gif/Light Theme/pattern.gif';
import { toast } from "react-toastify";
import loaderImage from "../../assets/gif/algo/loaderfloting.gif";

import "react-toastify/dist/ReactToastify.css";
 
const Loader = (props) => {
  const client_type = localStorage.getItem("client_type");

  const currentPath = window.location.pathname;
  return (
    <React.Fragment>
      {/* <div
        className={`d-flex justify-content-center align-items-center mx-2 mt-2 ${props.class}`}
        style={{ height: "200px" }}
      >
        <Spinner color="primary"> Loading... </Spinner>
      </div> */}
      <div className="opacity-[0.85] fixed inset-0 z-[999] site-loader-box">
        <p></p>
        <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center">
          <img src={client_type == "Bonanza"?loaderImage:loader} alt="" className='site-loader-img' style={currentPath == '/dashboard' ? {
            "width": "150px",
            "height": "auto",
            "margin": "0 auto",
            "display": "block",
            "left": "50%",
            "top": "50%",
            "position": "absolute",
            "transform": "translate(-50%, -50%)"
          }:{
            "width": "100px",
            "height": "auto",
            "margin": "0 auto",
            "display": "block"
          }} />
        </div>
      </div>
 
      {/* {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })} */}
    </React.Fragment>
  );
};
 
export default Loader;
 