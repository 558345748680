import React from "react";
import { Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";
import NotificationCart from "./NotificationCart";
import NeedHelp from "./NeedHelp";

const Notifications = () => {
  document.title = "Bigul Admin & Dashboard";

  return (
    <React.Fragment>
      <div className="page-content main-content-div-notification">
        <Container fluid>
          <BreadCrumb
            title="Notifications"
            titleMain="Notifications"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />
          <Row>
            <NotificationCart />
            <NeedHelp />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Notifications;
