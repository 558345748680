import {
  API_SUMMARY_RESPONSE_SUCCESS,
  API_SUMMARY_RESPONSE_ERROR,
  GET_SUMMARY
} from "./actionType";

const INIT_STATE = {
    summaryTableData : [],
};

const SummaryTable = (state = INIT_STATE, action) => {
  ////console.log(action.payload);
  switch (action.type) {
    case API_SUMMARY_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SUMMARY:
          return {
            ...state,
            summaryTableData: action.payload.data,
          };
        default:
          return { ...state };
      }
    case API_SUMMARY_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_SUMMARY:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_SUMMARY: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default SummaryTable;
