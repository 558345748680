import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_PLAN_HISTORY } from "./actionType";

import {
  PlanHistoryApiResponseSuccess,
  PlanHistoryApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getPlanHistoryApi } from "../../helpers/fakebackend_helper";

// Trade Book Cash
function* getPlanHistory({ payload: data }) {
  try {
    const response = yield call(getPlanHistoryApi, data);
    yield put(PlanHistoryApiResponseSuccess(GET_PLAN_HISTORY, response));
  } catch (error) {
    yield put(PlanHistoryApiResponseError(GET_PLAN_HISTORY, error));
  }
}

export function* watchGetPlanHistory() {
  yield takeEvery(GET_PLAN_HISTORY, getPlanHistory);
}



function* PlanHistorySaga() {
  yield all([
    fork(watchGetPlanHistory)
  ]);
}

export default PlanHistorySaga;
