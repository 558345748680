import { API_SUMMARY_RESPONSE_ERROR } from "../algoSummaryTable/actionType";
import { API_ALGO_GET_LIST_RESPONSE_ERROR, API_ALGO_GET_LIST_SUCCESS,GET_LIST } from "./actionType";

  
  // SummaryTable
  export const AlgoGetListSuccess = (actionType, data) => ({
    type: API_ALGO_GET_LIST_SUCCESS,
    payload: { actionType, data },
  });
  // SummaryTable error
  export const AlgoGetListApiResponseError = (actionType, error) => ({
    type: API_ALGO_GET_LIST_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  // SummaryTable
  export const getList = (data) => ({
    type: GET_LIST,
    payload: data,
  });
  