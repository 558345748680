import React, { useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";

const InviteFriend = () => {
  return (
    <React.Fragment>
      <Col xxl={12} xl={12} lg={12} className="mb-3">
        <h4 className="text-25 text-color6">Rs 6500</h4>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="text-12 text-color5">My Total Earnings</h4>
            <p className="text-color5 text-26">Last updates view mins ago</p>
          </div>
          <div>
            <button className="me-2 refAndEarnButton">Refer More</button>
            <button className="refAndEarnButton2"><i className="ri-download-2-line me-2 align-bottom"></i>Download Refer List</button>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default InviteFriend;
