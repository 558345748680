import {
  API_SA_RESPONSE_SUCCESS,
  API_SA_ERROR,
  GET_SA
} from "./actionType";

// SA success
export const SAApiResponseSuccess = (actionType, data) => ({
  type: API_SA_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// SA error
export const SAApiResponseError = (actionType, error) => ({
  type: API_SA_ERROR,
  payload: { actionType, error },
});

export const getSA = (data) => {
  return ({
  type: GET_SA,
  data,
})};