import {
  GET_MY_FEATURE_SUGGESTED_PLAN,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR
} from "./actionType";

// common success
export const MyFeatureSuggestedPlanApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const MyFeatureSuggestedPlanApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getMyFeatureSuggestedPlan = (data) => ({
  type: GET_MY_FEATURE_SUGGESTED_PLAN,
  data: data
});
