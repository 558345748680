import {
  API_ALL_UNCONVERTED_LEADS_SUCCESS,
  API_ALL_UNCONVERTED_LEADS_FAIL,
  GET_ALL_UNCONVERTED_LEADS,
  RESET_ALLUNCONVERTED_STATE,
} from "./actionType";

const INIT_STATE = {
  allUnconvertedLeads: [],
};

const allUnconvertedLeads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_ALL_UNCONVERTED_LEADS_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ALL_UNCONVERTED_LEADS:
          return {
            ...state,
            allUnconvertedLeads:
              action.payload.data !== null ? action.payload.data : [],
          };
        default:
          return { ...state };
      }
    case API_ALL_UNCONVERTED_LEADS_FAIL:
      switch (action.payload.actionType) {
        case GET_ALL_UNCONVERTED_LEADS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_ALL_UNCONVERTED_LEADS: {
      return {
        ...state,
      };
    }
    case RESET_ALLUNCONVERTED_STATE:
      const newState = INIT_STATE;
      console.log("Resetting Redux State:", newState);
      return newState;
    default:
      return { ...state };
  }
};

export default allUnconvertedLeads;
