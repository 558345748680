import React, { useEffect } from "react";
import { Card, CardBody, Col, Row, Container } from "reactstrap";
import refAndEarnBorder from "../../assets/images/logo/refAndEarnBorder.png";
import bigul_logo_white from "../../assets/images/logo/bigul_logo_white.png";
import playStore from "../../assets/images/logo/playStore.png";
import windows from "../../assets/images/logo/windows.png";
import appleStore from "../../assets/images/logo/appleStore.png";
import Destip from "../../assets/images/logo/Destip.png";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center">
        <Col xxl={4} xl={4} lg={4} className="my-5 pe-5">
          <img src={bigul_logo_white} alt="bigul logo" height="44" />
          <p className="text-color3 text-10 mt-3 mb-2">Download Our App</p>
          <Row>
            <Col xxl={4} xl={4} lg={4}>
              {" "}
              <img src={playStore} alt="bigul logo" height="40" />
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              {" "}
              <img src={appleStore} alt="bigul logo" height="40" />
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              {" "}
              <img src={Destip} alt="bigul logo" height="40" />
            </Col>
            <Col xxl={4} xl={4} lg={4} className="mt-3">
              {" "}
              <img src={windows} alt="bigul logo" height="40" />
            </Col>
          </Row>
        </Col>
        <Col xxl={2} xl={2} lg={2} className="my-5 px-4">
          <h4>Company</h4>
          <p className="text-color3">Client Referral</p>
          <p className="text-color3">Partners</p>
          <p className="text-color3">Careers</p>
          <p className="text-color3">About Us</p>
        </Col>
        <Col xxl={2} xl={2} lg={2} className="my-5 px-4">
          <h4>Tools</h4>
          <p className="text-color3">Calculators</p>
          <p className="text-color3">Investment Options</p>
          <p className="text-color3">Knowledge Center</p>
          <p className="text-color3">Support Center</p>
          <p className="text-color3">Webinar</p>
          <p className="text-color3">Marketplace</p>
          <p className="text-color3">Algo Trading</p>
          <p className="text-color3">Subscription Plans</p>
        </Col>
        <Col xxl={2} xl={2} lg={2} className="my-5 px-4">
          <h4>Policy</h4>
          <p className="text-color3">Terms & Conditions</p>
          <p className="text-color3">Policies & Circulars</p>
          <p className="text-color3">Disclaimer</p>
        </Col>
      </div>
      {/* <div className="d-flex justify-content-between">
        <h4>2023 © BIGUL</h4>
        <div className="d-flex">
          <h4>Follow Us On</h4>
        </div>
      </div> */}
      <Container fluid>
        <Row>
          <Col sm={6}><h4 className="text-color3">{new Date().getFullYear()} © BIGUL.</h4></Col>
          <Col sm={6}>
            <div className="text-sm-end d-none d-sm-block text-color3">
            Follow Us On
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Footer;
