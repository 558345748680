import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isExplore, setIsExplore] = useState(false);
    const [isMyAlgos, setIsMyAlgos] = useState(false);
    const [isPortfolio, setIsPortfolio] = useState(false);
    const [isStatements, setIsStatements] = useState(false);
    const [isMore, setIsMore] = useState(false);
    // const [isForms, setIsForms] = useState(false);
    // const [isTables, setIsTables] = useState(false);
    // const [isCharts, setIsCharts] = useState(false);
    // const [isIcons, setIsIcons] = useState(false);
    // const [isMaps, setIsMaps] = useState(false);
    // const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    // const [isEmail, setEmail] = useState(false);
    // const [isSubEmail, setSubEmail] = useState(false);
    // const [isEcommerce, setIsEcommerce] = useState(false);
    // const [isProjects, setIsProjects] = useState(false);
    // const [isTasks, setIsTasks] = useState(false);
    // const [isCRM, setIsCRM] = useState(false);
    // const [isCrypto, setIsCrypto] = useState(false);
    // const [isInvoices, setIsInvoices] = useState(false);
    // const [isSupportTickets, setIsSupportTickets] = useState(false);
    // const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    // const [isLanding, setIsLanding] = useState(false);
    // const [isJobs, setIsJobs] = useState(false);
    // const [isJobList, setIsJobList] = useState(false);
    // const [isCandidateList, setIsCandidateList] = useState(false);

    // Authentication
    // const [isSignIn, setIsSignIn] = useState(false);
    // const [isSignUp, setIsSignUp] = useState(false);
    // const [isPasswordReset, setIsPasswordReset] = useState(false);
    // const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    // const [isLockScreen, setIsLockScreen] = useState(false);
    // const [isLogout, setIsLogout] = useState(false);
    // const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    // const [isVerification, setIsVerification] = useState(false);
    // const [isError, setIsError] = useState(false);

    // Pages
    // const [isProfile, setIsProfile] = useState(false);

    // Charts
    // const [isApex, setIsApex] = useState(false);

    // Multi Level
    // const [isLevel1, setIsLevel1] = useState(false);
    // const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    var client_type = localStorage.getItem('client_type');

    function updateIconSidebar(e) {
        console.log("hhe")
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'ReferEarn') {
            setIsExplore(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Explore') {
            setIsExplore(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'MyAlgos') {
            setIsMyAlgos(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Portfolio') {
            setIsPortfolio(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Statements') {
            setIsStatements(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Profile') {
            setIsMore(false);
            document.body.classList.remove("menu")
        }
        // if (iscurrentState !== 'AdvanceUi') {
        //     setIsAdvanceUi(false);
        // }
        // if (iscurrentState !== 'Forms') {
        //     setIsForms(false);
        // }
        // if (iscurrentState !== 'Tables') {
        //     setIsTables(false);
        // }
        // if (iscurrentState !== 'Charts') {
        //     setIsCharts(false);
        // }
        // if (iscurrentState !== 'Icons') {
        //     setIsIcons(false);
        // }
        // if (iscurrentState !== 'Maps') {
        //     setIsMaps(false);
        // }
        // if (iscurrentState !== 'MuliLevel') {
        //     setIsMultiLevel(false);
        // }
        // if (iscurrentState === 'Widgets') {
        //     history("/widgets");
        //     document.body.classList.add('twocolumn-panel');
        // }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isExplore,
        isMyAlgos,
        isPortfolio,
        isStatements,
        isMore
    ]);

    // useEffect(() => {
    //     document.body.classList.remove('twocolumn-panel');
    //     if (iscurrentState !== 'Dashboard') {
    //         setIsDashboard(false);
    //     }
    //     if (iscurrentState !== 'Apps') {
    //         setIsApps(false);
    //     }
    //     if (iscurrentState !== 'Auth') {
    //         setIsAuth(false);
    //     }
    //     if (iscurrentState !== 'Pages') {
    //         setIsPages(false);
    //     }
    //     if (iscurrentState !== 'Landing') {
    //         setIsLanding(false);
    //     }
    //     if (iscurrentState !== 'BaseUi') {
    //         setIsBaseUi(false);
    //     }
    //     if (iscurrentState !== 'AdvanceUi') {
    //         setIsAdvanceUi(false);
    //     }
    //     if (iscurrentState !== 'Forms') {
    //         setIsForms(false);
    //     }
    //     if (iscurrentState !== 'Tables') {
    //         setIsTables(false);
    //     }
    //     if (iscurrentState !== 'Charts') {
    //         setIsCharts(false);
    //     }
    //     if (iscurrentState !== 'Icons') {
    //         setIsIcons(false);
    //     }
    //     if (iscurrentState !== 'Maps') {
    //         setIsMaps(false);
    //     }
    //     if (iscurrentState !== 'MuliLevel') {
    //         setIsMultiLevel(false);
    //     }
    //     if (iscurrentState === 'Widgets') {
    //         history("/widgets");
    //         document.body.classList.add('twocolumn-panel');
    //     }
    // }, [
    //     history,
    //     iscurrentState,
    //     isDashboard,
    //     isApps,
    //     isAuth,
    //     isPages,
    //     isBaseUi,
    //     isAdvanceUi,
    //     isForms,
    //     isTables,
    //     isCharts,
    //     isIcons,
    //     isMaps,
    //     isMultiLevel
    // ]);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",            // Navigation Start -  Dashboard Navigation
            label: "Dashboard",
            icon: "ri-home-6-line",
            link: "/dashboard",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            }
        },
        {
            id: "referEarn",
            label: "Refer & Earn",
            icon: "ri-compass-3-line",
            link: "/refer-and-earn",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('referEarn');
                updateIconSidebar(e);
            }
        },
        // {
        //     id: "explore",           
        //     label: "Explore",
        //     icon: "ri-compass-3-line",
        //     link: "/#",
        //     stateVariables: isDashboard,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIscurrentState('Explore');
        //         updateIconSidebar(e);
        //     }
        // },
        // {
        //     id: "myAlgos",           
        //     label: "My Algos",
        //     icon: "ri-code-s-slash-line",
        //     link: "/algo/overview",
        //     stateVariables: isPortfolio,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsDashboard(!isPortfolio);
        //         setIscurrentState('MyAlgos');
        //         updateIconSidebar(e);
        //     }
        // },
        // {
        //     id: "marketplace",           
        //     label: "Marketplace",
        //     icon: "ri-briefcase-4-line",
        //     link: "/#",
        //     stateVariables: isPortfolio,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsDashboard(!isPortfolio);
        //         setIscurrentState('marketplace');
        //         updateIconSidebar(e);
        //     }
        // },
        {
            id: "statements",
            label: "Statements",
            icon: "ri-file-text-line",
            link: "/statements",
            stateVariables: isStatements,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isStatements);
                setIscurrentState('Statements');
                updateIconSidebar(e);
            }
        },
        {
            id: "profile",
            label: "Profile",
            icon: "ri-add-circle-line",
            link: "/profile",
            stateVariables: isMore,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isMore);
                setIscurrentState('Profile');
                updateIconSidebar(e);
            }
        },
        // {
        //     id: "Family  Declaration",
        //     label: "Family  Declaration",
        //     icon: "ri-group-line",
        //     link: "/family",
        //     stateVariables: isMore,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsDashboard(!isMore);
        //         setIscurrentState('Family  Declaration');
        //         updateIconSidebar(e);
        //     }
        // },

    ];
    const menuItems1 = [
        {
            label: "Menu",
            isHeader: true,
        },

        {
            id: "Family  Declaration",
            label: "Family  Declaration",
            icon: "ri-group-line",
            link: "/family",
            stateVariables: isMore,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isMore);
                setIscurrentState('Family  Declaration');
                updateIconSidebar(e);
            }
        },

    ];
    return <React.Fragment>{client_type == "Bonanza" ? menuItems1 : menuItems}</React.Fragment>;
    // return <React.Fragment>{ menuItems}</React.Fragment>;
};
export default Navdata;