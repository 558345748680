import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_LIST } from "./actionType";



//Include Both Helper File with needed methods
import { getAlgoList, getAlgoTranstionList } from "../../helpers/fakebackend_helper";
import { AlgoGetListApiResponseError, AlgoGetListSuccess } from "./action";

// Trade Book Cash
function* getListAlgo( {payload:data}) {
  try {
    const response = yield call(getAlgoList, data);
    console.log(">?????Api response algo ++++++++????>?",response);
    yield put(AlgoGetListSuccess(GET_LIST, response));
  } catch (error) {
    yield put(AlgoGetListApiResponseError(GET_LIST, error));
  }
}

export function* watchGetListAlgoSaga() {
  yield takeEvery(GET_LIST, getListAlgo);
}

function* GetListAlgoSaga() {
  yield all([
    fork(watchGetListAlgoSaga)
  ]);
}

export default GetListAlgoSaga;
