import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConfirmChanges1 from './ConfirmChanges1';
import ConfirmChanges2 from './ConfirmChanges2';
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { ResetPhoneState } from "../../../../store/Profile/action";
import { toast } from "react-toastify";
// TODO Ui and design and functions.
const DPSchemeModelIndex = (props) => {
  
  const { openState, data, data2 } = props;
  console.log(props,'jjjj');
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [modalAdditional, setModelAdditional] = useState({
    CChange1: true,
    CChange2: false,
  });

  useEffect(() => {
    setModelAdditional(openState)
  },[props])

  const sendData = (data) => {
    ////console.log(data);
    //e.preventDefault();
    setModelAdditional(data)
  }

  const tog_signUpModalsFinal = (data) => {
    // //console.log('submit')
    setmodal_signUpModals(!modal_signUpModals);
   

  }
 
  const tog_signUpModals = () => {
    setModelAdditional(openState)
    setmodal_signUpModals(!modal_signUpModals);
    
   
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        className="btn btn-primary me-3 additional-page-button-save"
        type="button"
        onClick={() => {
          if(props.data.length == 0){
            toast.error('Please Select Scheme')

          }else{
            tog_signUpModals()
          }
        }}
      >
        Confirm
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {modalAdditional.CChange1 && <ConfirmChanges1 sendData={sendData} tog={tog_signUpModals} data={data} data2={data2} funCancel={props.funCancel} handlechangeScheme={props.handlechangeScheme}  />}
       
      </Modal>
    </React.Fragment>
  );
};

export default DPSchemeModelIndex;
