import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-component";
import { gallery } from "./pagesData";
import dayjs from "dayjs";
const Gallery = () => {
  const [displayCategory, setCategory] = useState("All Videos");
  var advancedFormat = require("dayjs/plugin/advancedFormat");
  dayjs.extend(advancedFormat);

  const { ELearningVideosList } = useSelector((state) => ({
    ELearningVideosList: state.ELearning.ELearningVideosList,
  }));

  const { ELearningVideosCategoryList } = useSelector((state) => ({
    ELearningVideosCategoryList:
      state.ELearningCategory.ELearningVideosCategoryList,
  }));
  //   //console.log(ELearningVideosCategoryList);
  // //console.log(ELearningVideosList);
  const t = ELearningVideosList.filter((el) => {
    // //console.log(el)
    // //console.log(el.e_learning_category_id)
    return displayCategory === el.e_learning_category_id;
  });
  // //console.log(t);
  // //console.log(displayCategory);
  const charactersLimit = (data, limit) => {
    if (data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data;
  };
  const ff = ELearningVideosList.filter(
    (el) =>
      displayCategory === el.e_learning_category_id ||
      displayCategory === "All Videos"
  )
  console.log(ff)
  return (
    <React.Fragment>
      <Col lg={12}>
        <div className="">
          <CardBody>
            <Col lg={8} style={{ margin: "0 auto" }}>
              <div className="text-center">
                <ul
                  className="list-inline categories-filter animation-nav"
                  id="filter"
                >
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      onClick={() => setCategory("All Videos")}
                      className={
                        displayCategory === "All Videos"
                          ? "categories active"
                          : "categories"
                      }
                      data-filter="*"
                    >
                      All Videos
                    </Link>
                  </li>
                  {ELearningVideosCategoryList.length > 0 &&
                    ELearningVideosCategoryList.map((el, index) => {
                      ////console.log(el)
                      let g = el.slug.split("-");
                      ////console.log(g.join(' ').charAt(0).toUpperCase() + g.join(' ').slice(1))
                      return (
                        <li className="list-inline-item" key={index}>
                          <Link
                            to="#"
                            onClick={() => setCategory(el.id)}
                            className={
                              displayCategory === el.id
                                ? "categories active"
                                : "categories"
                            }
                            data-filter=".el.id"
                          >
                            {g.join(" ").charAt(0).toUpperCase() +
                              g.join(" ").slice(1)}
                          </Link>
                        </li>
                      );
                    })}
                  {/* <li className="list-inline-item"><Link to="#" onClick={() => setCategory(2)} className={displayCategory === 2 ? "categories active" : "categories"} data-filter=".1">Mutual Funds</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Commodity")} className={displayCategory === "Commodity" ? "categories active" : "categories"} data-filter=".Commodity">Designing</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Equity")} className={displayCategory === "Equity" ? "categories active" : "categories"} data-filter=".Equity">Equity</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Invest Language")} className={displayCategory === "Invest Language" ? "categories active" : "categories"} data-filter=".Invest Language">Invest Language</Link></li>
                          
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Profit & Loss")} className={displayCategory === "Profit & Loss" ? "categories active" : "categories"} data-filter=".Profit & Loss">Profit & Loss</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("High Reward")} className={displayCategory === "High Reward" ? "categories active" : "categories"} data-filter=".High Reward">High Reward</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Share Selection")} className={displayCategory === "Share Selection" ? "categories active" : "categories"} data-filter=".Share Selection">Share Selection</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Investment Type")} className={displayCategory === "Investment Type" ? "categories active" : "categories"} data-filter=".Investment Type">Investment Type</Link></li> */}
                </ul>
              </div>
            </Col>
            <Row>
              <Col lg={12}>
                <Masonry className="row gallery-wrapper">
                  {ff.length > 0 ? ff.map((el, key) => {
                    let domain = el.video_url.substring(
                      el.video_url.indexOf("https") + 0,
                      el.video_url.indexOf("title") - 2
                    );
                    return (
                      <Col
                        xxl={3}
                        xl={3}
                        sm={6}
                        className="element-item project designing development"
                        key={key}
                      >
                        <Card className="card-height-100">
                          <CardBody className="py-0 px-0">
                            <iframe
                              width="100%"
                              height="180px"
                              src={domain}
                              title="YouTube video player"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen="allowfullscreen"
                              style={{
                                borderTopRightRadius: "12px",
                                borderTopLeftRadius: "12px",
                              }}
                            ></iframe>
                            {/* {video.video_url} */}
                            <Link
                              to={`/elearning/${el.id}/video`}
                              //onClick={() => {selectedVideoHandler(video)}}
                            >
                              <div className="px-2 pt-1 pb-3">
                                <h5 className="text-9 text-color5 mb-1">
                                  {charactersLimit(el.title, 60)}
                                </h5>
                                <h5 className="main-header-secondary-page text-color4 mb-0 mt-2">
                                  {charactersLimit(el.category.category, 25)}
                                </h5>
                                <h5 className="main-header-secondary-page text-color4 mb-0 mt-2">
                                  {dayjs(el.created_at).format("DD MMM YYYY")}
                                </h5>
                              </div>
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }) : <h4 className="text-center mt-5">No relevant videos found!</h4>}
                </Masonry>
                {/* <div className="text-center my-2">
                        <Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load More </Link>
                      </div> */}
              </Col>
            </Row>
          </CardBody>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Gallery;
