import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_SUGGESTED_ALGO } from "./actionType";

import {
  SuggestedAlgoApiResponseSuccess,
  SuggestedAlgoApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getSuggestedAlgoApi } from "../../helpers/fakebackend_helper";

function* getSuggestedAlgo(data) {
  try {
    const response = yield call(getSuggestedAlgoApi, data.data);
    yield put(SuggestedAlgoApiResponseSuccess(GET_SUGGESTED_ALGO, response));
  } catch (error) {
    yield put(SuggestedAlgoApiResponseError(GET_SUGGESTED_ALGO, error));
  }
}

export function* watchGetSuggestedAlgo() {
  yield takeEvery(GET_SUGGESTED_ALGO, getSuggestedAlgo);
}

function* SuggestedAlgoSaga() {
  yield all([fork(watchGetSuggestedAlgo)]);
}

export default SuggestedAlgoSaga;
