import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// actions
import {
  getMyInvestedAlgosTop,
  getMyInvestedAlgosGraph,
} from "../../store/myInvestedAlgo/action";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import { RevenueCharts } from "./DashboardEcommerceCharts";

const MyInvestedAlgos = () => {
  const [period, setPeriod] = useState("1M");
  const dispatch = useDispatch();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const { MyInvestedAlgos } = useSelector((state) => ({
    MyInvestedAlgos: state.MyInvestedAlgos,
  }));
  ////console.log(MyInvestedAlgos);

  useEffect(() => {
    dispatch(getMyInvestedAlgosTop({ client_code: client_code }));
    dispatch(
      getMyInvestedAlgosGraph({ type: period, client_code: client_code })
    );
  }, [dispatch, period, client_code]);

  const onChangeChartPeriod = (pType) => {
    setPeriod(pType);
  };
  let dollarIndianLocale = new Intl.NumberFormat("en-IN", {
    //style: 'currency',
    currency: "INR",
  });

  const date = [];
  if (
    MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
    !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph)
  ) {
    date.push(
      MyInvestedAlgos.myInvestedAlgosGraph.date
        ? MyInvestedAlgos.myInvestedAlgosGraph.date
        : MyInvestedAlgos.myInvestedAlgosGraph["month-year"]
    );
  }

  ////console.log(date);
  // const totalInvestment = {};
  // totalInvestment.data =
  //   MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
  //   !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
  //   MyInvestedAlgos.myInvestedAlgosGraph.total_investment.map(Number);
  // totalInvestment.type =
  //   MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
  //   !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
  //   "bar";
  // totalInvestment.name =
  //   MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
  //   !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
  //   "Investment";
  let myData= [] 
  if( MyInvestedAlgos.myInvestedAlgosGraph.success === true && !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph)) {
    myData = MyInvestedAlgos.myInvestedAlgosGraph.total_return
    }
    ////console.log(myData);
  const totalReturn = {};
  totalReturn.data =
    MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
    !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
    myData.map(Number);
  totalReturn.type =
    MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
    !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
    "line";
  totalReturn.name =
    MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
    !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
    "Return";
  const myDataChart = [];
  // myDataChart.push(
  //   MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
  //     !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
  //     totalInvestment
  // );
  myDataChart.push(
    MyInvestedAlgos.myInvestedAlgosGraph.success === true &&
      !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) &&
      totalReturn
  );
 ////console.log(myDataChart);
  return (
    <React.Fragment>
      <Col xxl={9} xl={9} className="spacing-remove-demounting1">
        <Card>
          <CardHeader className="border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1 text-6 text-color1">
              My Invested Algos
            </h4>
            <div className="d-flex gap-1 item-hide me-2">
              {/* <button
                type="button"
                className={`btn ${
                  period === "ALL" ? "btn-primary" : "btn-soft-primary"
                } btn-sm`}
                onClick={() => {
                  onChangeChartPeriod("ALL");
                }}
              >
                ALL
              </button> */}
              <button
                type="button"
                className={`btn ${
                  period === "1M" ? "btn-primary" : "btn-soft-primary"
                } btn-sm`}
                onClick={() => {
                  onChangeChartPeriod("1M");
                }}
              >
                1M
              </button>
              <button
                type="button"
                className={`btn ${
                  period === "6M" ? "btn-primary" : "btn-soft-primary"
                } btn-sm`}
                onClick={() => {
                  onChangeChartPeriod("6M");
                }}
              >
                6M
              </button>
              <button
                type="button"
                className={`btn ${
                  period === "1Y" ? "btn-primary" : "btn-soft-primary"
                } btn-sm`}
                onClick={() => {
                  onChangeChartPeriod("1Y");
                }}
              >
                1Y
              </button>
            </div>
            {/* <div className="d-flex gap-1">
                <Link
                  to="/myalgos"
                  className="btn btn-primary btn-sm text-5"
                >
                  See My Algos
                </Link>
            </div> */}
          </CardHeader>

          <CardHeader className="p-0 border-0 bg-soft-light">
            <Row className="g-0 text-center">
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <CountUp
                      prefix="₹"
                      start={0}
                      end={MyInvestedAlgos.myInvestedAlgos.Total_investment}
                      duration={3}
                      separator=","
                      className="text-9 text-color1"
                    />
                  </h5>
                  <p className="text-color1 mb-0 main-header-secondary-page">
                    Total Investment
                  </p>
                </div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <CountUp
                      //suffix="K"
                      prefix="₹"
                      start={0}
                      decimals={2}
                      end={MyInvestedAlgos.myInvestedAlgos.Total_return}
                      duration={3}
                      className="text-9 text-color1"
                    />
                  </h5>
                  <p className="text-color1 mb-0 main-header-secondary-page">
                    Total Return
                  </p>
                </div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <CountUp
                      start={0}
                      end={MyInvestedAlgos.myInvestedAlgos.Total_return ? 1 : 0} //TODO make it Dynamic
                      duration={3}
                      className="text-9 text-color1"
                    />
                  </h5>
                  <p className="text-color1 mb-0 main-header-secondary-page">
                    No. of Algos
                  </p>
                </div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0 border-end-0">
                  <h5 className="mb-1 text-success">
                    <CountUp
                      start={0}
                      end={
                        MyInvestedAlgos.myInvestedAlgos[
                          "Profif&loss_percentage"
                        ]
                      }
                      //end={252}
                      decimals={2}
                      duration={3}
                      suffix="%"
                      className={`text-9 ${
                        MyInvestedAlgos.myInvestedAlgos[
                          "Profif&loss_percentage"
                        ] > 0
                          ? "text-color6"
                          : "text-color11"
                      }`}
                    />
                  </h5>
                  <p className="text-color1 mb-0 main-header-secondary-page">
                    Profit & Loss
                  </p>
                </div>
              </Col>
            </Row>
          </CardHeader>

          {MyInvestedAlgos.myInvestedAlgosGraph.success === true ? (
            <CardBody className="p-0 pb-2 my-algos-border">
              <div className="w-100">
                <div dir="ltr">
                  <RevenueCharts
                    series={myDataChart.length > 0 && myDataChart}
                    //dataColors='["--vz-primary", "--vz-danger"]'
                    dataColors='["--vz-danger"]'
                    categories={
                      !isEmpty(MyInvestedAlgos.myInvestedAlgosGraph) && date[0]
                    }
                  />
                </div>
              </div>
            </CardBody>
          ) : (
            <CardBody className="p-0 pb-2 my-algos-border">
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <div dir="ltr">No Data Found</div>
              </div>
            </CardBody>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MyInvestedAlgos;
