import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import {
  Input,
  Modal,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { selectedFeatureApi } from "../../../../helpers/fakebackend_helper";
import { addToCart } from "../../../../store/cart/action";
import active from "../../../../assets/images/logo/active.png";
// TODO Ui and design and functions.
const MyFeature = (props) => {
  console.log(props,'props');
  
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState({});

  const [info, setInfo] = useState([]);
  const { data, btnText, btnClass } = props;
  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));

 // //console.log(props.data2);
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);

  useEffect(() => {
    if (modal_signUpModals === true) {
      selectedFeatureApi({
        planID: data,
        code: client_code,
        token: client_token,
      })
        .then((response) => {
          ////console.log(response);
          const d =  response.data.feature_durations.find(el => el.duration === props.data2.duration);
          ////console.log(d);
          setValue(response.data);
          setInfo(d);
          setIsLoading(false);
          ////console.log(response);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [modal_signUpModals, client_code, client_token]);
  const [sellerId, setSellerID] = useState(0);

  const tog_signUpModals = () => {
    setmodal_signUpModals(!modal_signUpModals);
  };

  const selectHandler = (seller, index) => {
    setSellerID(index);
    setInfo(seller);
  };

  const item = {
    client_code: client_code,
    client_token: client_token,
    feature_or_plan_id: info.feature_id,
    feature_or_plan: "Feature",
    duration_type: info.duration_type,
  };

  const addToCartHandler = () => {
    dispatch(addToCart(item));
    setmodal_signUpModals(!modal_signUpModals);
  };
  const match = Cart.find((el) => {
    return el.feature_or_plan_id === item.feature_or_plan_id;
  });
  ////console.log(match);
  return (
    <React.Fragment>
      <button
        type="button"
        className={btnClass ? btnClass : "addons-feature-button-normal"}
        onClick={() => tog_signUpModals()}
      >
        {btnText}
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        <ModalHeader
          className="px-4 py-4"
          toggle={() => {
            tog_signUpModals();
          }}
        ></ModalHeader>
        <div className="d-flex justify-content-between align-items-center px-3 additional-transactions pb-4">
          <div className="d-flex align-items-center" style={{ width: "65%" }}>
            <img
              src={(!isEmpty(value) && value.logo) || active}
              style={{ width: "50px" }}
            />
            <div className="ms-2">
              <h4 className="text-6 text-color1 spacing-remove-bottom">
                {!isEmpty(value) && value.title}
              </h4>
              <p className="m-0 text-color2 text-1">{!isEmpty(value) && value.sub_heading}</p>
            </div>
          </div>
          <div className="text-end" style={{ width: "45%" }}>
            <h4 className="text-8 text-color6">
              {/* Rs {!isEmpty(info) && Number(info.amount).toLocaleString("en-US")}{" "}
              /-{" "} */}
               {!isEmpty(info) && Number(info.amount) === 0 ? 'Free' :  `₹ ${Number(info.amount).toFixed(2)}`}
            </h4>
            <p className="m-0 text-color2 text-1 text-end">
              {!isEmpty(info) && info.duration_type}
            </p>
          </div>
        </div>
        <ModalBody className="px-3">
          <p className="text-1 text-color4">
            {!isEmpty(value) && value.long_description}
          </p>
          {/* {!isEmpty(value) && value.feature_durations.length > 0 && (
            <h5 className="text-color1 text-9 mb-3">Select Feature Duration</h5>
          )} */}
          {/* <div>
            <ul className="list-unstyled text-muted vstack gap-3">
              {!isEmpty(value) &&
                value.long_description.length > 0 &&
                value.long_description.map((subItem, index) => {
                  //console.log(subItem);
                  return (
                    <li key={index}>
                      <div className="d-flex">
                        {subItem.status === "Yes" ? (
                          <div className="flex-shrink-0 text-success me-2">
                            <i className="ri-checkbox-circle-line fs-15 align-middle"></i>
                          </div>
                        ) : subItem.status === "" ? (
                          <div className="flex-shrink-0 text-danger me-2 d-none">
                            <i className=" ri-close-circle-line fs-15 align-middle"></i>
                          </div>
                        ) : (
                          <div className="flex-shrink-0 text-danger me-2">
                            <i className=" ri-close-circle-line fs-15 align-middle"></i>
                          </div>
                        )}

                        {subItem.status === "" ? (
                          <div className="flex-grow-1 text-15 text-color4 d-none">
                            {subItem.title}
                          </div>
                        ) : (
                          <div className="flex-grow-1 text-15 text-color4">
                            {subItem.title}
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div> */}
          <h5>Plan Duration</h5>
          <div>
            {!isEmpty(value) &&
              value.feature_durations.length > 0 &&
              value.feature_durations.map((seller, index) => {
                ////console.log(seller);
                return (
                  <Card
                    key={index}
                    //onClick={() => selectHandler(seller, index)}
                    className={` suggested-algos-col-card ${
                      info.duration === seller.duration && "selected-box-border"
                    }`}
                  >
                    <CardBody
                      className="pt-3 pb-3 px-3"
                      style={{ height: "80px" }}
                    >
                      <div className="d-flex mb-1">
                          <img
                              src={value.image || active}
                              style={{ width: "40px", height: '40px' }}
                            />
                        <div className="flex-grow-1">
                          <h5
                            className="mt-2 text-4 text-color1 ms-2"
                            style={{ height: "25px" }}
                          >
                            {seller.duration_type}
                          </h5>
                        </div>
                        <div className="flex-shrink-0 ">
                          <h4 className="text-20 text-color6">
                            {/* Rs {Number(seller.amount).toLocaleString("en-US")}{" "}
                            /- */}
                            {Number(seller.amount) === 0 ? 'Free' :  `₹ ${Number(seller.amount).toFixed(2)}`}
                          </h4>
                          <p className="text-14 text-color2 text-end">
                            {seller.duration === 30
                              ? "1 Month"
                              : seller.duration === 90
                              ? "3 Months"
                              : "12 Months"}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                );
              })}
          </div>
          {/* {!isEmpty(info) && (
            <div className="d-flex text-center justify-content-center">
              <button
                className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                style={{ borderRadius: "5px" }}
                disabled={!isEmpty(match)}
                onClick={() => {
                  addToCartHandler(item);
                }}
              >
                Add to Cart
              </button>
            </div>
          )} */}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default MyFeature;
