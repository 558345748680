import {
    API_ALGO_GET_LIST_SUCCESS,
    GET_LIST,
    API_ALGO_GET_LIST_RESPONSE_ERROR
  } from "./actionType";
  
  const INIT_STATE = {
      getlistData : [],
  };
  const GetList = (state = INIT_STATE, action) => {
    // console.log(">>>>>>>check the data >>>>>>>",action.payload.data);
    switch (action.type) {
      case API_ALGO_GET_LIST_SUCCESS:
        switch (action.payload.actionType) {
          case GET_LIST:
            return {
              ...state,
              getlistData: action.payload.data.data,
            };
          default:
            return { ...state };
        }
      case API_ALGO_GET_LIST_RESPONSE_ERROR:
        switch (action.payload.actionType) {
          case GET_LIST:
            return {
              ...state,
              error: action.payload.error,
            };
          default:
            return { ...state };
        }
      case GET_LIST: {
        return {
          ...state,
        };
      }
      default:
        return { ...state };
    }
  };
  
  export default GetList;
  