import {
  GET_GENERATE_KEY,
  API_GENERATE_RESPONSE_SUCCESS,
  API_GENERATE_RESPONSE_ERROR,
} from "./actionType";

// common success
export const GenerateKeyApiResponseSuccess = (actionType, data) => ({
  type: API_GENERATE_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const GenerateKeyApiResponseError = (actionType, error) => ({
  type: API_GENERATE_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getGenerateKey = () => ({
  type: GET_GENERATE_KEY,
});