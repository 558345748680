import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConfirmChanges1 from './ConfirmChanges1';
import ConfirmChanges2 from './ConfirmChanges2';
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { ResetPhoneState } from "../../../../store/Profile/action";
// TODO Ui and design and functions.
const DPSchemeModelIndex = (props) => {
  const dispatch = useDispatch();
  const { openState, data, data2 } = props;
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [modalAdditional, setModelAdditional] = useState({
    CChange1: false,
    CChange2: true,
  });

  // useEffect(() => {
  //   setModelAdditional(openState)
  // },[props])

  const sendData = (data) => {
    ////console.log(data);
    //e.preventDefault();
    setModelAdditional(data)
  }

  const tog_signUpModalsFinal = (data) => {
    // //console.log('submit')
    setmodal_signUpModals(!modal_signUpModals);
  }
 
  const tog_signUpModals = () => {
    setModelAdditional({
      CChange1: false,
      CChange2: true,
    })
    setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        //className="btn btn-primary text-4 me-3 margin-overview-button1"
        type="button"
        onClick={() => tog_signUpModals()}
        className="btn btn-primary text-4  mb-3 me-3 margin-overview-button1"
                style={{ borderRadius: "5px" }}
      >
      Proceed For E-Sign
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {/* {modalAdditional.CChange1 && <ConfirmChanges1 sendData={sendData} tog={tog_signUpModals} />} */}
        {modalAdditional.CChange2 && <ConfirmChanges2 sendData={tog_signUpModalsFinal} tog={tog_signUpModals} data={data} data2={data2} />}
      </Modal>
    </React.Fragment>
  );
};

export default DPSchemeModelIndex;
