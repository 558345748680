import {
  API_PMS_RESPONSE_SUCCESS,
  API_PMS_ERROR,
  GET_PMS
} from "./actionType";

// SA success
export const PMSApiResponseSuccess = (actionType, data) => ({
  type: API_PMS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// SA error
export const PMSApiResponseError = (actionType, error) => ({
  type: API_PMS_ERROR,
  payload: { actionType, error },
});

export const getPMS = (data) => {
  return ({
  type: GET_PMS,
  data,
})};