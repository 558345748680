import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
// actions
import { getMyInvestedAlgosTop } from "../../store/myInvestedAlgo/action";
import { cyptoWidgets3 } from '../../common/data';
import Widget3 from "../../Components/CustomComponents/Widget3";
import Widget4 from "../../Components/CustomComponents/Widget4";


const Widgets = () => {
    const dispatch = useDispatch();
    const client_code  = localStorage.getItem('client_id');
    const client_token = localStorage.getItem('client_token');
    const { MyInvestedAlgos } = useSelector((state) => ({
      MyInvestedAlgos: state.MyInvestedAlgos,
    }));
    ////console.log(MyInvestedAlgos);

    const { availableMargin } = useSelector((state) => ({
      availableMargin: state.MarginOverview,
    }));
  
  const generatedKey  = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
  ////console.log(availableMargin);
 
  ////console.log( JSON.parse(InvestmentSummary.replace(/\bNaN\b/g, "null")));

  //   ////console.log(JSON.parse(myJSON));
  
  useEffect(() => {
    dispatch(getMyInvestedAlgosTop({client_code: client_code}));
  }, [dispatch, client_code]);

    return (
        <React.Fragment>
            <Widget3 item={cyptoWidgets3[0]} bg={'background-color1'} data={!isEmpty(MyInvestedAlgos.myInvestedAlgos) && MyInvestedAlgos.myInvestedAlgos.Total_investment} />
            <Widget3 item={cyptoWidgets3[1]} bg={'background-color5'} data={!isEmpty(MyInvestedAlgos.myInvestedAlgos) && MyInvestedAlgos.myInvestedAlgos.Current_value} />
            <Widget3 item={cyptoWidgets3[2]} bgColor='badge-color-profit' bg={'background-color4'} data={!isEmpty(MyInvestedAlgos) && MyInvestedAlgos.myInvestedAlgos.Total_return} data2={!isEmpty(MyInvestedAlgos.myInvestedAlgos) && MyInvestedAlgos.myInvestedAlgos}  />
            <Widget4 item={cyptoWidgets3[2]} data={availableMargin.availableMargin.length > 0  && availableMargin.availableMargin[0].Final1}  />
        </React.Fragment>
    );
};

export default Widgets;