import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//redux
import { useSelector, useDispatch } from "react-redux";
// action
import { addToCart, addToCartApiResponseSuccess } from "../../store/cart/action";

//import "swiper/css/pagination";
import active from "../../assets/images/logo/active.png";
import MyPlan from "../../Components/CustomComponents/Models/myPlan/MyPlan";
import { addItemToCartApi } from "../../helpers/fakebackend_helper";

const Active1 = (props) => {
  const dispatch = useDispatch();
  const { data, myPlan } = props;
  const [loading, setLoading] = useState({}); 

  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));

  const feature_cat_id = Cart?.map((item) => {
    const idsArray = item.plans?.feature_category_ids?.split(',').map(id => parseInt(id.trim()));
    return idsArray || [];
  });
  const flatFeatureCatIds = feature_cat_id?.flat() || [];


  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const filteredUsers = Cart.filter((el) => {
    return el.feature_or_plan === "Plan";
  });


  const filteredUsers2 =
    data.length > 0 ?
      data.filter((el) => {
        return el?.subscription_plan_id !== myPlan?.plan_id;
      }) :
      [];

  const active_plan = myPlan?.map(item => item?.plan_id);
  const planDurationIds = Cart?.flatMap(item => item?.plans?.plan_durations?.map(plan => plan?.id));

 
  


  const addToCartHandler = (data) => {
    const catid_arr = data.id;
    const item = {
      client_code: client_code,
      client_token: client_token,
      feature_or_plan_id: data.subscription_plan_id,
      feature_or_plan: "Plan",
      duration_type: data.duration_type,
    };
    const isValueInArray = planDurationIds?.includes(catid_arr);
   
      if (isValueInArray) {
        toast.error("A brokerage plan already exists in the cart", { autoClose: 3000 });
      } else {
        // dispatch(addToCart(item));
        setLoading(prevState => ({ ...prevState, [data.id]: true }));
        addItemToCartApi(item)
        .then((res) => {
         if(res.status ==true){
          dispatch(addToCartApiResponseSuccess(res))
          setLoading(prevState => ({ ...prevState, [data.id]: false }));
         }
          
          
        })
        .catch((error) => {
          console.log(error,'error');
          
          setLoading(prevState => ({ ...prevState, [data.id]: false }));

          toast.error("Failed to add item to cart");
        })
      }
    
  };


  const charactersLimit = (data, limit) => {
    if (data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data;
  };

  const canAddToCart = (subscriptionPlanId) => {
    const myPla = myPlan.find(plan => plan.plan_id === subscriptionPlanId);

    if (myPla) {
      return myPla.days_rem < myPla.days_condation;
    }
    return false; // Default to false if plan not found or condition not met
  };


  return (
    <React.Fragment >
      <Row className="justify-content-center" >
        {
          filteredUsers2.length > 0 ?
            filteredUsers2?.map((item, index) => {
              // condition to match item is in cart start
              const match = Cart.find((el) => {
                // //console.log(item);
                return el.feature_or_plan === 'Plan' && el.feature_or_plan_id === item.subscription_plan_id;
              });
              // condition to match item is in cart end
              return (<
                                Col lg={4}
                sm={6}
                key={index} >
                <
                                Card className="pricing-box ribbon-box right mb-4 mb-sm-0 h-auto h-sm-100"
                  style={
                    {}} >
                  {
                    item.plan.flag !== "" ? (<
                                        div className="ribbon-two ribbon-two-danger" >
                      <
                                        span > {item.plan.flag} < /span> <
                                        /div>
                        ) : (
                        ""
                        )
                                } <
                                CardBody className="pt-4 px-3 m-2" >
                          <
                                div className="d-flex align-items-center" >
                            <
                                div className="flex-grow-1 w-75" >
                              <
                                h5 className="mb-1 text-6 text-color1 pe-2" > {item.plan.plan_name} <
                                /h5> <
                                p className="text-color2 text-1 mb-0" > {charactersLimit(item.plan.sub_title, 20)} <
                                /p> <
                                /div> <
                                div className="avatar-sm" >
                                    <
                                div className="avatar-title bg-light rounded-circle text-primary" > { /* <i className={"fs-20 " + price2.icon}></i> */} <
                                        img src={item.plan.image || active}
                                        style={
                                          { width: "48px" }}
                                      /> <
                                /div> <
                                /div> <
                                /div> <
                                div className="ms-auto pt-4"
                                        style={
                                          { height: "70px" }} > {
                                          Number(item.discounted_price) !== 0 && (Number(item.discounted_price) !== Number(item.pre_discount)) ? < h2 className="text-4 text-color13 text-decoration-line-through" > ₹{" "} {Number(item.pre_discount).toFixed(2)}

                                            <
                                    /h2> : <h2 className="text-4 text-color13 text-decoration-line-through" style={{ height: '10px' }}> {""} <
                                    /h2>} <
                                    h2 className="text-6 text-color6" >

                                                {Number(item.discounted_price) === 0 ? 'Free' : `₹ ${Number(item.discounted_price).toFixed(2)}`}

                                                <
                                    span className="text-1 text-color2 ms-3" > {item.duration_type} <
                                    /span> <
                                    /h2> <
                                    /div> <
                                                    hr className="my-4 text-muted" />
                                                  <
                                    div >
                                                    <
                                    ul
                                                      className="list-unstyled text-muted vstack gap-3"
                                                      style={
                                                        { minHeight: "180px" }} >
                                                      {
                                                        item.long_description.length > 0 &&
                                                        item.long_description
                                                          .slice(0, 5)
                                                          .map((subItem, index) => {
                                                            // //console.log(subItem);
                                                            return (<
                                                li key={index} >
                                                              <
                                                div className="d-flex" > {
                                                                  subItem.status === "Yes" ? (<
                                                        div className="flex-shrink-0 text-success me-2" >
                                                                    <
                                                        i className="ri-checkbox-circle-line fs-15 align-middle" > < /i> <
                                                        /div>
                                                                      ) : subItem.status === "" ? ( <
                                                        div className="flex-shrink-0 text-danger me-2 d-none" >
                                                                        <
                                                        i className=" ri-close-circle-line fs-15 align-middle" > < /i> <
                                                        /div>
                                                                          ) : ( <
                                                        div className="flex-shrink-0 text-danger me-2" >
                                                                            <
                                                        i className=" ri-close-circle-line fs-15 align-middle" > < /i> <
                                                        /div>
                                                                              )
                                                }


                                                                              {
                                                                                subItem.status === "" ? (<
                                                        div className="flex-grow-1 text-15 text-color4 d-none" > {subItem.title} <
                                                        /div>
                                                                                  ) : ( <
                                                        div className="flex-grow-1 text-15 text-color4" > {subItem.title} <
                                                        /div>
                                                                                    )
                                                } <
                                                /div> <
                                                /li>
                                                                                    );
                                        })
                                    } {
                                                                                      /* <li>
                                                                                                              <div className="d-flex">
                                                                                                                <div className="flex-shrink-0 text-success me-2">
                                                                                                                  <i className=" ri-checkbox-circle-line fs-15 align-middle"></i>
                                                                                                                </div>
                                                                                                                <div className="flex-grow-1 text-15 text-color4">
                                                                                                                  Unlimited Videos
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                              <div className="d-flex">
                                                                                                                <div className="flex-shrink-0 text-success me-2">
                                                                                                                  <i className="ri-checkbox-circle-line fs-15 align-middle"></i>
                                                                                                                </div>
                                                                                                                <div className="flex-grow-1 text-15 text-color4">
                                                                                                                  Unlimited Videos
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                              <div className="d-flex">
                                                                                                                <div className="flex-shrink-0 text-success me-2">
                                                                                                                  <i className=" ri-checkbox-circle-line fs-15 align-middle"></i>
                                                                                                                </div>
                                                                                                                <div className="flex-grow-1 text-15 text-color4">
                                                                                                                  Early Access Content
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                              <div className="d-flex">
                                                                                                                <div className="flex-shrink-0 text-danger me-2">
                                                                                                                  <i className=" ri-close-circle-line fs-15 align-middle"></i>
                                                                                                                </div>
                                                                                                                <div className="flex-grow-1 text-15 text-color4">
                                                                                                                  Unlimited Newsletter
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                              <div className="d-flex">
                                                                                                                <div className="flex-shrink-0 text-danger me-2">
                                                                                                                  <i className=" ri-close-circle-line fs-15 align-middle"></i>
                                                                                                                </div>
                                                                                                                <div className="flex-grow-1 text-15 text-color4">
                                                                                                                  24/7 Support
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                              <div className="d-flex">
                                                                                                                <div className="flex-shrink-0 text-danger me-2">
                                                                                                                  <i className=" ri-close-circle-line fs-15 align-middle"></i>
                                                                                                                </div>
                                                                                                                <div className="flex-grow-1 text-15 text-color4">
                                                                                                                  All Features Active
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                              <div className="d-flex">
                                                                                                                <div className="flex-shrink-0 text-danger me-2">
                                                                                                                  <i className=" ri-close-circle-line fs-15 align-middle"></i>
                                                                                                                </div>
                                                                                                                <div className="flex-grow-1 text-15 text-color4">
                                                                                                                  Live Expert Connect
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </li> */
                                                                                    } <
                                    /ul> <
                                                                                      div className="d-flex justify-content-between" > {
                                                                                        /* <button className="text-11 text-color7 profile-edit-button1">
                                                                                                                  View Details
                                                                                                                </button> */
                                                                                      }
                                                                                      <MyPlan
                                                                                        data={item.subscription_plan_id}
                                                                                        durationData={item.duration}
                                                                                        data2={item}
                                                                                        btnText={"View Details"}
                                                                                        btnClass="text-11 text-color7 profile-edit-button1"
                                                                                        myPlan={myPlan?.plan_id}
                                                                                      />
                                                                                      {
                                                                                        filteredUsers.length > 0 && !isEmpty(match) ? (
                                                                                          <button className="text-4 btn btn-primary addons-feature-button-normal"
                                                                                            style={
                                                                                              { borderRadius: "5px" }}
                                                                                            disabled onClick={
                                                                                              () => {
                                                                                                addToCartHandler(item);
                                                                                              }
                                                                                            } >
                                                                                            <
                                                                                              i className="ri-check-line fs-15 align-middle pe-2 text-light" > </i>{" "}
                                                                                            Added </button>
                                                                                        ) :
                                                                                          myPlan?.plan_id !== 65 || active_plan?.includes(parseInt(item?.subscription_plan_id)) ? (
                                                                                            item.brokrage_status === 'yes' && item.is_brokrage_status_active === 'yes' ?
                                                                                              <button className="text-4 btn btn-primary addons-feature-button-normal"
                                                                                                style={
                                                                                                  { borderRadius: "5px" }}
                                                                                                disabled onClick={
                                                                                                  () => {
                                                                                                    addToCartHandler(item);
                                                                                                  }
                                                                                                } >
                                                                                                Add to Cart
                                                                                              </button>
                                                                                              :
                                                                                              item.brokrage_status === 'no' && active_plan?.includes(parseInt(item?.subscription_plan_id)) ?
                                                                                                <button className="text-4 btn btn-primary addons-feature-button-normal"
                                                                                                  style={
                                                                                                    { borderRadius: "5px" }}
                                                                                                  disabled={!canAddToCart(item?.subscription_plan_id)}
                                                                                                  onClick={
                                                                                                    () => {
                                                                                                      addToCartHandler(item);
                                                                                                    }
                                                                                                  } >
                                                                                                  Add to Cart
                                                                                                </button>
                                                                                                : <button className="text-4 btn btn-primary addons-feature-button-normal"
                                                                                                  style={
                                                                                                    { borderRadius: "5px" }}
                                                                                                    disabled={loading[item.id]}
                                                                                                  onClick={
                                                                                                    () => {
                                                                                                      addToCartHandler(item);
                                                                                                    }
                                                                                                  } >
                                                                                                  Add to Cart </button>
                                                                                          ) : (
                                                                                            < button className="text-4 btn btn-primary addons-feature-button-normal"
                                                                                              style={
                                                                                                { borderRadius: "5px" }}
                                                                                              disabled={!isEmpty(match)}
                                                                                              onClick={
                                                                                                () => {
                                                                                                  addToCartHandler(item);
                                                                                                }
                                                                                              } >
                                                                                              Add to Cart
                                                                                            </button>
                                                                                          )
                                                                                      } <
                                    /div> <
                                    /div> <
                                    /CardBody> <
                                    /Card> <
                                    /Col>
                                                                                      );
                            }): < h4 className="text-center" > Suggested Plan Not Available! < /h4>}
                                                                                      </Row>

                                                                                    </React.Fragment>
                                                                                    );
                };

export default Active1;