import {
    API_ALGO_GET_TRANSTIONS_SUCCESS,
    API_ALGO_GET_TRANSTIONS_RESPONSE_ERROR,
    GET_TRANSTIONS
  } from "./actionType";
  
  const INIT_STATE = {
      getTranstionData : [],
  };
  const GetTranstions = (state = INIT_STATE, action) => {
    // console.log(">>>>>>>check the data >>>>>>>",action.payload.data);
    switch (action.type) {
      case API_ALGO_GET_TRANSTIONS_SUCCESS:
        switch (action.payload.actionType) {
          case GET_TRANSTIONS:
            return {
              ...state,
              getTranstionData: action.payload.data.data,
            };
          default:
            return { ...state };
        }
      case API_ALGO_GET_TRANSTIONS_RESPONSE_ERROR:
        switch (action.payload.actionType) {
          case GET_TRANSTIONS:
            return {
              ...state,
              error: action.payload.error,
            };
          default:
            return { ...state };
        }
      case GET_TRANSTIONS: {
        return {
          ...state,
        };
      }
      default:
        return { ...state };
    }
  };
  
  export default GetTranstions;
  