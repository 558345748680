import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import Dashboard from "../referAndEarn/Dashboard";
import ELearningSlider from "../Algo2/ELearningSlider"
import Report from "./Report";
import { Link } from "react-router-dom";
const ReferEarn = () => {
  //document.title = "Bigul Admin & Dashboard";
  const [activeTab, setActiveTab] = useState('dashboard');

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Perform navigation or other actions based on the clicked tab
    if (tab === 'dashboard') {
      // Navigate to the dashboard
      console.log('Navigate to Dashboard');
    } else if (tab === 'report') {
      // Navigate to the report
      console.log('Navigate to Report');
    }
  };
  return (
    <React.Fragment>
    {/* <div className="page-content pb-0">
      <Row className="px-4 mt-4">

     <Dashboard />
      </Row>
    </div> */}
    <div className="page-content pb-0">
      <div className="main_body_heading pb-0">
      <div className="container refer-earn-container">
      <div className="mx-3">
    <div className="col-12">
        <div className="mt-0 page-title-box d-sm-flex align-items-center justify-content-between page-header-spacing pb-2 mobile-padding">
            <div className="px-1">
                <h2 className="main-header-page mb-sm-0 mb-lg-1 text-color1 dashboard-top-text ">
                  Refer & Earn</h2>               
            </div>
            <div className="page-title-right item-hide">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
<Link to="/dashboard">

                    <span>
                       {/* Dashboard */}
                       {/* {activeTab == 'dashboard' ? */}
                        Dashboard
                    </span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                      <span>
                      Refer & Earn
                     </span>
                  </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div className="col-md-12 px-3">
<div className="my_referral_wr p-0 mt-2">
<ul role="tablist" className="refer-earn-tab">
  <li className="nav-item">
      <span onClick={() => handleTabClick('dashboard')} style={{ cursor: 'pointer', color: activeTab === 'dashboard' ? 'blue' : 'black', backgroundColor: activeTab === 'dashboard' ? '#fcfcff' : '#ffffff', borderColor: activeTab === 'dashboard' ? 'blue' : '#ffffff' }}>
         Dashboard
     </span>
  </li>
  
  <li className="nav-item">
      <span onClick={() => handleTabClick('report')} style={{ cursor: 'pointer', color: activeTab === 'report' ? 'blue' : 'black', backgroundColor: activeTab === 'report' ? '#fcfcff' : '#ffffff', borderColor: activeTab === 'report' ? 'blue' : '#ffffff'  }}>
         Report
      </span>
  </li> 
</ul>
</div>     
</div> 
      </div>
      </div>



      {/* Render the content of the selected tab (dashboard or report) */}
      {activeTab === 'dashboard' && <Dashboard />}
      {activeTab === 'report' && <Report />}
    </div>
  </React.Fragment>
  );
};

export default ReferEarn;
