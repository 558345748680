import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import AdditionalDetailsModelIndex from "../../Components/CustomComponents/Models/additionalDetails/AdditionalDetailsModelIndex";
import DPSchemeModelIndex from "../../Components/CustomComponents/Models/DPScheme/DPSchemeModelIndex";
import WithdrawFunds from "../../Components/CustomComponents/Models/CompleatePayment/WithdrawFunds";
import { useSelector, useDispatch } from "react-redux";
import {
  SegmentActivationApiCall,
  checkModificationStatus,
  dpShameList,
  DDPIESign,
  DDPIShameESign,
  sendApi2,
  sendApi,
  ledgerApi,
  marginApi,
  SymphonyWithdrawalBalance
} from "../../helpers/fakebackend_helper";
import { getCollateral } from "../../store/Collateral/action";
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";

const DPScheme = (props) => {
  const { ClientID } = useSelector((state) => ({
    ClientID: state.ClientID.ClientID,
  }));
  const dispatch = useDispatch();
  const history = useNavigate();
  const [eSign, setESign] = useState(false);
  const [eSignStart, setESignStart] = useState(false);
  const [eSignComplected, setESignComplected] = useState(false);
  const [modStatusDDPI, setModStatusDDPI] = useState(0);
  const [modStatusDPShame, setModStatusDPShame] = useState(0);
  const [checked, setChecked] = useState("");
  const [scheme, setScheme] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState("");
  const symphony_token = localStorage.getItem("symphony_token");

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [shameList, setShameList] = useState([]);
  //console.log(shameList)
  const [eSign2, setESign2] = useState(false);
  const [eSignStart2, setESignStart2] = useState(false);
  const [eSignComplected2, setESignComplected2] = useState(false);

  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");

  const [activePay, setActivePay] = useState(1);
  const [price, setPrice] = useState(0);
  const [ledger, setLedger] = useState({});
  const [margin, setMargin] = useState({});
  const [ledgerBalances, setLedgerBalance] = useState(0);
  const [marginBalances, setMarginBalance] = useState(0);
  const [TotalValue, setTotalValue] = useState(0);

  const { Checkout } = useSelector((state) => ({
    Checkout: state.PaymentDetails.PaymentDetails,
  }));
  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));

  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));

  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getCollateral({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch]);

  useEffect(() => {
    ledgerApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(new Date("1/4/2015")).format("YYYY-MM-DD"),
      endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    })
      .then((response) => {
        //console.log(response);
        const d = response.finResult.find((el) => {
          return el.LNarrat === "Total : ";
        });
        setLedger(d);
        if (d.Lbal < 0) {
          setLedgerBalance(Math.abs(d.Lbal));
        } else if (d.Lbal > 0) {
          setLedgerBalance(-Math.abs(d.Lbal));
        } else {
          setLedgerBalance(0);
        }
        //console.log(d);
      })
      .catch((err) => {
        ////console.log(err);
      });
    marginApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(new Date("1/4/2015")).format("YYYY-MM-DD"),
      endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    })
      .then((response) => {
        // console.log(response);
        const d = response.finResult.find((el) => {
          return el.LNarrat === "Total : ";
        });
        if (d.Lbal < 0) {
          setMarginBalance(Math.abs(d.Lbal));
        } else if (d.Lbal > 0) {
          setMarginBalance(-Math.abs(d.Lbal));
        } else {
          setMarginBalance(0);
        }
        setMargin(d);
        //console.log(d);
      })
      .catch((err) => {
        ////console.log(err);
      });

    SymphonyWithdrawalBalance({
      client_token: client_token,
      token: symphony_token,
    })
      .then((response) => {

        if (!response.balance_arr.withdrawal) {
          setTotalValue(0);
        } else {
          var total_margin_set = response.balance;
          console.log("total_margin_settotal_margin_settotal_margin_set", total_margin_set);
          setTotalValue(total_margin_set);
        }
      })
      .catch((err) => {
        setTotalValue(0);
        console.log(err);
      });
  }, [generatedKey, encryptKey]);

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));
  //console.log(profileDetails)
  const openState = {
    CChange1: true,
    CChange2: false,
  };
  console.log(checked);
  useEffect(() => {
    if (props.activeTab === "2") {
      dpShameList()
        .then((response) => {
          //console.log(response);
          if (response.success === true) {
            setShameList(response.data);
          }
          //props.sendData(modalMobile)
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (props.activeTab === "2") {
      checkModificationStatus({
        client_code: client_code,
        pan_no:
          !isEmpty(profileDetails.userProfile[0]) &&
          profileDetails.userProfile[0].PANGIR,
        field_name: "dp_scheme",
        esign_flag: "YES",
      })
        .then((response) => {
          //console.log(response);
          // if (response.success === true) {
          //   setModStatusDPShame(true);
          // } else {
          //   setModStatusDPShame(false);
          // }
          if (
            response.success === true &&
            response.data.esign_status === false
          ) {
            setModStatusDPShame(1);
          } else if (response.success === false) {
            setModStatusDPShame(2);
          } else if (
            response.success === true &&
            response.data.esign_status === true
          ) {
            setModStatusDPShame(3);
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (props.activeTab === "2") {
      checkModificationStatus({
        client_code: client_code,
        pan_no:
          !isEmpty(profileDetails.userProfile[0]) &&
          profileDetails.userProfile[0].PANGIR,
        field_name: "ddpi_status",
        esign_flag: "YES",
      })
        .then((response) => {
          //console.log(response);
          if (
            response.success === true &&
            response.data.esign_status === false
          ) {
            setModStatusDDPI(1);
          } else if (response.success === false) {
            setModStatusDDPI(2);
          } else if (
            response.success === true &&
            response.data.esign_status === true
          ) {
            setModStatusDDPI(3);
          }
          //props.sendData(modalMobile);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [props.activeTab]);

  const Catalogues = [
    {
      id: "Settlement Transactions",
      name: "Settlement Transactions",
    },
    {
      id: "Margin Pledge Transactions",
      name: "Margin Pledge Transactions",
    },
    {
      id: "Mutual Fund Transactions",
      name: "Mutual Fund Transactions",
    },
    {
      id: "Tendering Shares",
      name: "Tendering Shares",
    },
  ];
  const [list, setList] = useState(Catalogues);
  // useEffect(() => {
  //   setList(Catalogues);
  // }, [list]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setIsCheckAll(false);
    }
  };

  useEffect(() => {
    if (isCheckAll === false && isCheck.length === 4) {
      setIsCheckAll(true);
    }
  }, [isCheckAll, isCheck]);

  const sameFunction = (event) => {
    ////console.log(event.target.checked);
    setChecked(event.target.checked);
  };

  const handleSelectScheme = (e) => {
    setSelectedScheme(e.target.value);
    setScheme(true);
  };

  const endFunction = (data) => {
    //console.log("data")
    setChecked(!checked);
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const eSignfun = () => {
    // const initialValues = {
    //   ClientCode: client_code,
    //   IsESign: "Yes",
    //   IsType: "equity",
    //   pan_number: profileDetails.userProfile[0].PANGIR,
    //   full_address: `${profileDetails.userProfile[0].ADDRESS1},${profileDetails.userProfile[0].ADDRESS2},${profileDetails.userProfile[0].ADDRESS3},${profileDetails.userProfile[0].PINCODE},${profileDetails.userProfile[0].CITY},${profileDetails.userProfile[0].CSTATE},${profileDetails.userProfile[0].COUNTRY}`,
    //   full_name: profileDetails.userProfile[0].FIBSACCT,
    //   dpCode: profileDetails.userProfile[0].DPCODE,
    //   email: profileDetails.userProfile[0].EMAIL,
    // };

    const initialValues = {
      ClientCode: client_code,
      pan_number: profileDetails.userProfile[0].PANGIR,
      IsESign: "Yes",
      IsType: "equity",
      esignType: "dp_scheme",
      full_name: profileDetails.userProfile[0].FIBSACCT,
      email: profileDetails.userProfile[0].EMAIL,
    }

    DDPIESign(initialValues)
      .then((response) => {
        //console.log(response);
        if (
          response.success === true &&
          response.message === "URL Generated Successfully."
        ) {
          //console.log(response);
          setESignStart(true);
          setESign(false);
          window.open(`${response.data.URLL}`, "_blank");
        } else {
          setESign(false);
          setESignComplected(true);
        }
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  useEffect(() => {
    if (eSignStart === true) {
      const initialValues = {
        ClientCode: client_code,
        pan_number: profileDetails.userProfile[0].PANGIR,
        IsESign: "Yes",
        IsType: "equity",
        esignType: "dp_scheme",
        full_name: profileDetails.userProfile[0].FIBSACCT,
        email: profileDetails.userProfile[0].EMAIL,
      }
      let current = setInterval(
        () =>
          DDPIESign(initialValues)
            .then((response) => {
              //console.log(response);
              if (
                response.success === false &&
                response.message === "ESign Already Done." &&
                response.data === ""
              ) {
                setESignStart(false);
                setESignComplected(true);
                toast.success("ESign Completed Successfully", {
                  autoClose: 1500,
                });
                history("/dashboard");
              }
            })
            .catch((err) => {
              ////console.log(err);
            }),
        10000
      );
      if (eSignStart === false) {
        return () => {
          clearInterval(current);
        };
      }
    }
  }, [eSignStart]);

  const eSignfun2 = () => {
    const initialValues = {
      ClientCode: client_code,
      pan_number: profileDetails.userProfile[0].PANGIR,
      IsESign: "Yes",
      IsType: "equity",
      esignType: "dp_scheme",
      full_name: profileDetails.userProfile[0].FIBSACCT,
      email: profileDetails.userProfile[0].EMAIL,
    }

    DDPIShameESign(initialValues)
      .then((response) => {
        console.log('8');
        if (
          response.success === true &&
          response.message === "URL Generated Successfully."
        ) {
          setESignStart2(true);
          setESign2(false);
          window.open(`${response.data.URLL}`, "_blank");
        } else {
          setESign2(false);
          setESignComplected2(true);
          toast.error(`${response.message}`, {
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  useEffect(() => {
    if (eSignStart2 === true) {
      const initialValues = {
        ClientCode: client_code,
        pan_number: profileDetails.userProfile[0].PANGIR,
        IsESign: "Yes",
        IsType: "equity",
        esignType: "dp_scheme",
        full_name: profileDetails.userProfile[0].FIBSACCT,
        email: profileDetails.userProfile[0].EMAIL,
      }
      let current = setInterval(
        () =>
          DDPIShameESign(initialValues)
            .then((response) => {
              console.log('9');
              if (
                response.success === false &&
                response.message === "ESign Already Done."
              ) {
                setESignStart2(false);
                setESignComplected2(true);
              }
            })
            .catch((err) => {
              ////console.log(err);
            }),
        10000
      );
      if (eSignStart2 === false) {
        return () => {
          clearInterval(current);
        };
      }
    }
  }, [eSignStart2]);

  const shameListd = shameList.filter((el) => {
    //console.log(el.toUpperCase());
    const d = !isEmpty(profileDetails.userProfile)
      ? profileDetails.userProfile[0].CFEESMAINCODE
      : "";
    //console.log(d);
    return (
      el !== "BSDA (Other)" && el !== "LICIPO SCHEME" && el.toUpperCase() !== d
    );
  });

  const val = {
    client_code: client_code,
    client_token: client_token,
    // subscriber_ids:
    //   Checkout !== null &&
    //   !isEmpty(Checkout) &&
    //   Checkout.subscriber_ids.length > 0 &&
    //   Checkout.subscriber_ids.toString(),
    terms_and_conditions: 1,
    payoption: "ledger",
  };

  const val2 = {
    client_code: client_code,
    client_token: client_token,
    // subscriber_ids:
    //   Checkout !== null &&
    //   !isEmpty(Checkout) &&
    //   Checkout.subscriber_ids.length > 0 &&
    //   Checkout.subscriber_ids.toString(),
    terms_and_conditions: 1,
    payoption: "bank",
  };

  const payBankHandler = () => {
    setActivePay(2);
    // window.open(
    //   // `https://payment.bigul.co/?ClientCode=${client_code}&dpScheme=dpScheme&dpSchemeName=${checked}&amount=${price}&redirectUrl=http://biguldashboardstaging.bonanzawealth.com/dp_scheme`,
    //   `https://payment.bigul.co/?ClientCode=${client_code}&dpScheme=dpScheme&dpSchemeName=${checked}&amount=${price}&redirectUrl=https://dashboard.bigul.co/dp_scheme`
    //   // ,"_blank"
    // );
    window.location.href = `https://payment.bigul.app/?ClientCode=${client_code}&dpScheme=dpScheme&dpSchemeName=${checked}&amount=${price}&redirectUrl=https://dashboard.bigul.app/dp_scheme`;

    // sendApi(val2)
    //   .then((response) => {
    //     ////console.log(response);
    //     if (response.status === true) {
    //       window.open(`${response.data.url}`, "_self");
    //     }
    //   })
    //   .catch((err) => {
    //     ////console.log(err);
    //   });
  };

  const payBankLedgerHandler = () => {
    window.location.href = '/dp_scheme?amount=' + price;
  };

  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    if (el.CollateralQuantity > 0) {
      CollateralValues.push(
        (el.CollateralQuantity * el.BuyAvgPrice * el.Haircut) / 100
      );
    }
    return el.CollateralQuantity > 0;
  });

  const CollateralValuessubTotal = CollateralValues.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  const A =
    !isEmpty(ledger) && !isEmpty(margin) && Collateral.length > 0
      ? ledgerBalances - marginBalances / 2
      : 0;
  //console.log(A);
  const B =
    !isEmpty(ledger) && !isEmpty(margin) && Collateral.length > 0
      ? CollateralValuessubTotal - marginBalances / 2
      : 0;

  let WithdrawalAmount = 0;

  if (
    !isEmpty(ledger) &&
    !isEmpty(margin) &&
    Collateral.length > 0 &&
    ledgerBalances <= 0
  ) {
    WithdrawalAmount = 0;
  } else if (
    !isEmpty(ledger) &&
    !isEmpty(margin) &&
    Collateral.length > 0 &&
    ledgerBalances > 0 &&
    A < 0
  ) {
    WithdrawalAmount = 0;
  } else if (
    !isEmpty(ledger) &&
    !isEmpty(margin) &&
    Collateral.length > 0 &&
    ledgerBalances > 0 &&
    A > 0 &&
    B > 0
  ) {
    WithdrawalAmount = A + 0;
  } else {
    WithdrawalAmount = A + B;
  }

  const amountFormatter3 = (data) => {
    let Total = 0;
    if (data > 0) {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      return `- ₹${data.toLocaleString("en-IN").toString().splice(1)}`;
    } else {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  console.log(profileDetails);
  return (
    <Col lg={12} className="mt-4">
  <div className="table-responsive table-card">
    <table className="table table-nowrap table-striped-columns mb-0">
      <thead className="pledge-table-header">
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Particulars</th>
          <th scope="col">
            <Input
              name="333 Scheme"
              type="checkbox"
              className="p-2 me-2 check_box"
              checked={checked === "333"}
              onChange={() => {
                setChecked("333");
                setPrice(333);
              }}
            />
            333 Scheme
          </th>
          <th scope="col">
            <Input
              name="999 New Scheme"
              type="checkbox"
              className="p-2 me-2 check_box"
              checked={checked === "999"}
              onChange={() => {
                setChecked("999");
                setPrice(999);
              }}
            />
            999 New Scheme
          </th>
          <th scope="col">
            <Input
              name="Free Scheme"
              type="checkbox"
              className="p-2 me-2 check_box"
              checked={checked === "FREESCHEME"}
              onChange={() => {
                setChecked("FREESCHEME");
                setPrice(0);
              }}
            />
            Free Scheme
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>
            Demat Account
            <br /> Opening
          </td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>
            1st year annual
            <br /> maintenance charge
          </td>
          <td>333</td>
          <td>999</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Annual Maintenance</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Individual</td>
          <td>
            (1 year onwards <br />
            Rs. 250/-)
          </td>
          <td>NIL</td>
          <td>300</td>
        </tr>
        <tr>
          <td></td>
          <td>
            CM/ Corporates/
            <br /> Other
          </td>
          <td>
            No scheme
            <br /> available
          </td>
          <td>
            No scheme <br />
            available
          </td>
          <td>
            No scheme <br />
            available
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>
            Account Closing
            <br /> Charges
          </td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td>4</td>
          <td>
            Dematerialisation/
            <br /> Destatementized- <br /> per certificate,
            <br />
            (courier charges extra)
          </td>
          <td>3/-</td>
          <td>25/-</td>
          <td>25/-</td>
        </tr>
        <tr>
          <td>5</td>
          <td>
            Rematerialisation -<br /> per certificate, <br />
            (courier charges extra)
          </td>
          <td>
            Rs. 15 per 100 securities or
            <br /> part there of or Rs. 15 per
            <br /> certificate whichever is higher
          </td>
          <td>
            Rs. 25 per 100 securities or
            <br /> part there of or Rs. 25 per
            <br /> certificate whichever is higher
          </td>
          <td>
            Rs. 25 per 100 securities or
            <br /> part there of or Rs. 25 per
            <br /> certificate whichever is higher
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Debit Transaction</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Market</td>
          <td>12/- per Debit Transaction</td>
          <td>
            NIL (per year 100 transaction
            <br /> FREE from client's dp to <br />
            bonanza pool upto 3 years)
          </td>
          <td>15/- per Debit Transaction</td>
        </tr>
        <tr>
          <td></td>
          <td>Off market/Inter Depository</td>
          <td>15/-</td>
          <td>25/-</td>
          <td>25/-</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Pledge</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Creation</td>
          <td>100/-</td>
          <td>
            Rs 100 or 0.01% whichever
            <br /> is higher
          </td>
          <td>
            Rs 100 or 0.01% whichever
            <br /> is higher
          </td>
        </tr>
        <tr>
          <td></td>
          <td>Invocation</td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>Closer</td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>Margin pledge charge</td>
          <td>
            Pledge | Unpledge |<br /> Invocation | Repledge
          </td>
          <td>
            Pledge | Unpledge |<br /> Invocation | Repledge
          </td>
          <td>
            Pledge | Unpledge |<br /> Invocation | Repledge
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            Pre script transaction
            <br /> charge other transaction
          </td>
          <td>12 | 12 | 12 | 1</td>
          <td>12 | 12 | 12 | 1</td>
          <td>12 | 12 | 12 | 1</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Other Transaction</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Transaction</td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>Nominee</td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>Modification if a/c detail</td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>Freezing / Defreezing</td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>Failed Instruction</td>
          <td>NIL</td>
          <td>NIL</td>
          <td>NIL</td>
        </tr>
        <tr>
          <td></td>
          <td>Additional Statement (quarterly)</td>
          <td>25/-</td>
          <td>25/-</td>
          <td>25/-</td>
        </tr>
        <tr>
          <td></td>
          <td>Additional Statement (annually)</td>
          <td>50/-</td>
          <td>50/-</td>
          <td>50/-</td>
        </tr>
        <tr>
          <td></td>
          <td>
            Cheque Dishonored charges
            <br /> (Per Instance)
          </td>
          <td>100/-</td>
          <td>200/-</td>
          <td>200/-</td>
        </tr>
        <tr>
          <td></td>
          <td>Additional DIS Book (per book)</td>
          <td>35/-</td>
          <td>50/-</td>
          <td>50/-</td>
        </tr>
        <tr>
          <td></td>
          <td>Lost DIS Book (per book)</td>
          <td>70/-</td>
          <td>100/-</td>
          <td>100/-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="px-3 mt-5">
    <DPSchemeModelIndex
      openState={openState}
      data={checked}
      data2={profileDetails}
      funCancel={props.funCancel}
      handlechangeScheme={props.handlechangeScheme}
    />
    <button
      className="btn additional-page-button-save ms-3 right"
      type="button"
      onClick={() => props.funCancel(false)}
    >
      Cancel
    </button>
    <div className="d-flex align-items-center my-4">
      <div className="ms-auto"></div>
    </div>
  </div>
</Col>

  );
};

export default DPScheme;
