import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Table,
  Row,
  //Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Col,
  Button,
} from "reactstrap";
import { DefaultColumnFilter } from "../Common/filters";

const TableContainer2 = ({
  columns,
  data,
  customPageSize,
  tableClass,
  thClass,
  tableTrClasses,
  
}) => {
  const reversedData = React.useMemo(() => data.slice().reverse(), [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data:reversedData,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
        // sortBy: [
        //   {
        //     desc: true,
        //   },
        // ], stop shorting
      },
    },
    useGlobalFilter,
    useFilters,
    //useSortBy, stop shorting
    useExpanded,
    usePagination,
    useRowSelect
  );
 
  // console.log(pageSize);
  // console.log(customPageSize);
  const [collection, setCollection] = React.useState(
    data.slice(0, customPageSize)
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const updatePage = (p) => {
    //console.log(p - 1);
    const pp = p - 1;
    setCurrentPage(p);
    // const to = customPageSize * p;
    // const from = to - customPageSize;
    gotoPage(pp);
    //setCollection(data.slice(from, to));
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    gotoPage(event);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <Button className={`${"pagination-but-dis"} pagination-but`}>
          Previous
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button className={`${"pagination-but-dis"} pagination-but ms-2`}>
          Next
        </Button>
      );
    }
    return originalElement;
  };
  return (
    <Fragment>
      <div className={`summary-table`} style={{ overflow: "auto" }}>
        <Table
          hover
          {...getTableProps()}
          className={tableClass}
          style={{ marginBottom: "0" }}
          id="Table1"
        >
          <thead className="summary-table-header">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={`${thClass} px-4`}
                    // {...column.getSortByToggleProps()}  stop shorting
                    // style={{padding: '25px'}}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
             
              
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className={
                            tableTrClasses
                              ? tableTrClasses
                              : "plan-summary-table-tr2 px-4"
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
        {/* <Col lg={12}>
          <Pagination
            listClassName="justify-content-center"
            className="pagination-separated mb-0"
          >
            <PaginationItem>
              {" "}
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {" "}
                <i className="mdi mdi-chevron-left" />{" "}
              </Button>{" "}
            </PaginationItem>
            {pageOptions.map((index) => {
              //console.log(data);
              return (
                <PaginationItem key={index}>
                  {" "}
                  <PaginationLink to="#">
                    <Button
                      color="primary"
                      onClick={() => {
                        onChangeInInput(index);
                      }}
                    >
                      {index + 1}
                    </Button>
                  </PaginationLink>{" "}
                </PaginationItem>
              );
            })}
            <PaginationItem>
              {" "}
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {" "}
                <i className="mdi mdi-chevron-right" />{" "}
              </Button>{" "}
            </PaginationItem>
          </Pagination>
        </Col> */}

        <div
          className=" justify-content-end align-items-center px-2 py-3 ml-5"
          // style={{ display: "contents !important", padding: "50px" }}
        >
          <div></div>
          {/* {pageOptions.length > 0 && (
            <Button
              className={`${
                !previousPage ? "pagination-pre-dis" : "pagination-pre-act"
              } pagination-pre`}
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
          )}
          {pageOptions.map((index) => {
            return (
              <Button
                key={index}
                className={`${
                  pageIndex === index
                    ? "pagination-but-ac"
                    : "pagination-but-dis"
                } pagination-but`}
                onClick={() => {
                  onChangeInInput(index);
                }}
              >
                {index + 1}
              </Button>
            );
          })}
          {pageOptions.length > 0 && (
            <Button
              className={`${
                !canNextPage ? "pagination-pre-dis" : "pagination-pre-act"
              } pagination-pre`}
              onClick={nextPage}
              disabled={!canNextPage}
            >
              Next
            </Button>
          )} */}
          <Pagination
            pageSize={customPageSize}
            onChange={updatePage}
            current={currentPage}
            total={data.length}
            itemRender={PrevNextArrow}
          />
        </div>
      </div>
    </Fragment>
  );
};

TableContainer2.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer2;
