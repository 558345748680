import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { conformOpenApi } from "../../../../store/marginOverview/action";
import { submitOtpApi } from "../../../../helpers/fakebackend_helper";
import { sendApi, resendOtpApi } from "../../../../helpers/fakebackend_helper";
import { VerifyOtp } from '../../../../store/VerifyOTP/action';
import loaderImage from "../../../../assets/images/loader-icon.png";

// TODO Ui and design and functions.
const WithdrawFundsPopup = (props) => {
  const navigate = useNavigate();
  const [generateOtpSuccess1, setGenerateSuccessOtp1] = useState(false);
  const [counter, setCounter] = useState(30);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const generatedKey = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const [value, setValue] = useState(0);
  const [disableButton, setDisableButton] = useState(0);
  const modalMobile = {
    CUpdate: false,
    done: true,
  };
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const val = {
    client_code: "Y09018",
    client_token: "$#Rdfdf334434RRR",
    subscriber_ids: "30,31",
    terms_and_conditions: 1,
    payoption: "ledger",
  };


  const { VerifyOtpData } = useSelector((state) => ({
    VerifyOtpData: state.VerifyOTP.oderDetails,
  }));
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  useEffect(() => {
    if (VerifyOtpData.length > 0) {
      return navigate("/order/summary");
    }
  }, [VerifyOtpData])
  ////console.log(VerifyOtpData)
  useEffect(() => {
    sendApi(props.cartData)
      .then((response) => {
        setGenerateSuccessOtp1(true);
        setCounter(30);
        toast.success("OTP has been sent to your new mobile number..", {
          autoClose: 1500,
        });
        ////console.log(response);
      })
      .catch((err) => {
        ////console.log(err);
      });
  }, []);
  // //console.log(props);
  function tog_signUpModals() {
    //   ////console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }

  // console.log(profileDetails[0].CSTATE, 'profileDetails');
  const submitHandler = () => {
    //  ////console.log("submit");
  };
  const onClickHandler = () => {
    if (otp.length == 0) {
      toast('Please Enter OTP')
    }
    else {
      setDisableButton(1);
      const data = {
        api_key: generatedKey,
        // client_code: client_code,
        client_token: client_token,
        subscriber_ids: props.cartData.subscriber_ids,
        mobile_otp: otp,
        amt: Number(props?.amount.total) + Number(props?.amount.gst),
        glamt: Number(props?.amount.total),
        brcode: profileDetails[0]?.BRCODE,
        cgst: profileDetails[0].STATECODE !== '27' ? (9 / 100) * Number(props?.amount.total).toFixed(2) : 0,
        sgst: profileDetails[0].STATECODE !== '27' ? (9 / 100) * Number(props?.amount.total).toFixed(2) : 0,
        igst: profileDetails[0].STATECODE !== '27' ? 0 : ((18 / 100) * Number(props?.amount.total)).toFixed(2),

      };
      submitOtpApi(data)
        .then((response) => {
          setDisableButton(0);
         if (response.status !== false) {
          //alert('/call_back?amount='+response.get_total_amount+'&payment_type=ledger')
          // navigate('/call_back?amount='+response.get_total_amount+'&payment_type=ledger')
          
          window.location = '/call_back?amount='+response.get_total_amount+'&payment_type=ledger';
            // props.sendData(modalMobile, value);
          } else {
            toast.error("Invalid OTP", { autoClose: 3000 });
          }
        })
        .catch((err) => {
          setDisableButton(0);
          //console.log(err);
          // toast.error("Invalid OTP", { autoClose: 3000 })
          // dispatch(toast.error("Invalid OTP", { autoClose: 3000 }));
        });

      // dispatch(VerifyOtp(data));
    }
    //e.preventDefault();


    // dispatch(conformOpenApi());
  };

  const resendOtpHandler = (e) => {
    e.preventDefault();
    // const data = {
    //   client_code: client_code,
    //   client_token: client_token,
    //   subscriber_ids: props.cartData.subscriber_ids,
    //   mobile_otp: otp,
    // };

    const data2 = {
      // client_code: client_code,
      client_token: client_token,
      subscriber_ids: props.cartData.subscriber_ids,
    };

    resendOtpApi(data2)
      .then((response) => {
        ////console.log(response);
        if (response.status !== false) {
          toast.success("OTP has been sent to your new mobile number..", { autoClose: 1500 });
          setGenerateSuccessOtp1(true);
          setCounter(30);
        } else {
          toast.error("Couldn't send OTP. Try again!", { autoClose: 1500 });
        }
      })
      .catch((err) => {
        // //console.log(err);
        // toast.error("Invalid OTP", { autoClose: 3000 })
        // dispatch(toast.error("Invalid OTP", { autoClose: 3000 }));
      });

    //dispatch(conformOpenApi());
  };
  useEffect(() => {
    generateOtpSuccess1 === true &&
      counter > 0 &&
      setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setGenerateSuccessOtp1(false);
    }
  }, [counter, generateOtpSuccess1]);

  const amountFormatter = (data) => {
    if (+data > 0) {
      //  ////console.log(data);
      return `${data.toString().slice(1)}`;
    }
    //  ////console.log(data);
    return `${data.toString().slice(1)}`;
  };

  //  ////console.log(+amountFormatter(props.data.availableMargin[0].Final1));
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          props.tog()
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">Verify OTP</h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <form onSubmit={submitHandler()}>
          <div className="mb-3">
            <p className="text-11 text-color1">SMS Verification</p>
          </div>
          <div className="input-group">
            <Input
              type="number"
              className="form-control modal-input-element"
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
            <button
              className="btn btn-primary modal-input-button"
              type="button"
              disabled = {disableButton == 0 ? false : true}
              onClick={() => {
                onClickHandler();
              }}
            >
              {disableButton == 0 ? 'Verify' : <img src={loaderImage} className="custom-loader" style={{height:"20px", marginRight:"5px"}} />}
            </button>
          </div>
          <h4 className="text-11 text-color8 mt-3 ">
            OTP sent on your registered number.
          </h4>
          {/* <h4 className="text-11 text-color8">
              Time remaining 256sec
            </h4> */}
          <h4 className="text-11 text-color8 mt-3">
            Didn’t receive OTP ?{" "}
            {counter === 0 ? <button type="button"
              className="resend-otp-btn "
              onClick={(e) => {
                resendOtpHandler(e);
              }}>
              Resend
            </button> : <button type="button"
              className="resend-button1"
              disabled
              onClick={(e) => {
                resendOtpHandler(e);
              }}>
              Resend
            </button>}
            {counter > 0 && `${counter} Seconds`}
          </h4>
        </form>
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default WithdrawFundsPopup;
