import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import {
  Input,
  Modal,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { selectedPlanApi } from "../../../../helpers/fakebackend_helper";
import { addToCart, removeToCart } from "../../../../store/cart/action";
import active from "../../../../assets/images/logo/active.png";
import monthly from "../../../../assets/images/logo/monthly.png";
import quertly from "../../../../assets/images/logo/quertly.png";
import annualy from "../../../../assets/images/logo/annualy.png";
// TODO Ui and design and functions.
const MyPlan = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState({});
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const [info, setInfo] = useState([]);
  const [info1, setInfo1] = useState([]);
  const { data, btnText, btnClass, durationData, myPlan, data2 } = props;
 
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [sellerId, setSellerID] = useState(0);
  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));
  console.log("value",value)
  // console.log("data2",data2)
  // console.log("info",info)
  // console.log("myPlans",myPlan)
  // console.log("cart",Cart)

  const feature_cat_id = Cart?.map((item) => {
    const idsArray = item.plans?.feature_category_ids?.split(',').map(id => parseInt(id.trim()));
    return idsArray || [];
  });
  const flatFeatureCatIds = feature_cat_id?.flat() || [];

  const active_plan = myPlan?.map(item => item?.plan_id);
  // console.log("active_plan",active_plan)
  // //console.log(durationData);
  useEffect(() => {
    if (durationData === 30) {
      setSellerID(0);
    } else if (durationData === 90) {
      setSellerID(1);
    } else {
      setSellerID(2);
    }
  }, [durationData]);

  ////console.log(Cart);
 // //console.log(info);
  useEffect(() => {
    if (modal_signUpModals === true) {
      selectedPlanApi({ planID: data, code: client_code, token: client_token })
        .then((response) => {
          ////console.log(response);
          if (response.status === true) {
            console.log(response.data,'main');
            setValue(response.data);
            if(response.data.plan_durations.length > 1 ) {
              setInfo(response.data.plan_durations[sellerId]);
              setSellerID(0);  setInfo1(response?.data)
            
             
            } else {
              setInfo(response.data.plan_durations[0]);
              setSellerID(0);  setInfo1(response?.data)
             
            }

          }
          setIsLoading(false);
          ////console.log(response);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [modal_signUpModals, client_token, client_code]);

  // //console.log(sellerId);

  const tog_signUpModals = () => {
    if (durationData === 30) {
      setSellerID(0);
    } else if (durationData === 90) {
      setSellerID(1);
    } else {
      setSellerID(2);
    }
    setmodal_signUpModals(!modal_signUpModals);
  };

  const selectHandler = (seller, index) => {
    setSellerID(index);
    setInfo(seller);
  };

  const item = {
    client_code: client_code,
    client_token: client_token,
    feature_or_plan_id: info.subscription_plan_id,
    feature_or_plan: "Plan",
    duration_type: info.duration_type,
  };
  const filteredUsers = Cart.filter((el) => {
    return el.feature_or_plan === "Plan";
  });

  console.log("flatFeatureCatIds",flatFeatureCatIds)
  const addToCartHandler = (data) => {
    console.log("ddd",data.feature_category_id)
    if (flatFeatureCatIds.includes(parseInt(data.feature_category_id))) {
      toast.error("A brokerage plan already exists in the cart", { autoClose: 3000 });
    }else{
      dispatch(addToCart(item));
      setmodal_signUpModals(!modal_signUpModals);
    }
    
  };
  const match = Cart.find((el) => {
    return (
      el.feature_or_plan === "Plan" &&
      el.feature_or_plan_id === item.feature_or_plan_id
    );
  });
 // //console.log(value)
  return (
    <React.Fragment>
      <button
        type="button"
        className={btnClass}
        onClick={() => tog_signUpModals()}
      >
        {btnText}
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        <ModalHeader
          className="px-4 py-4"
          toggle={() => {
            tog_signUpModals();
          }}
        ></ModalHeader>
        {!isEmpty(value) && (
          <div>
            <div className="d-flex justify-content-between  px-3 additional-transactions pb-4">
              <div className="d-flex" style={{ width: "65%" }}>
                <img
                  src={value.image || active}
                  style={{ width: "50px", height: "50px" }}
                />
                <div className="ms-2">
                  <h4 className="text-6 text-color1 spacing-remove-bottom">
                    {value.plan_name}
                  </h4>
                  {/* <p className="m-0 text-color2 text-1">Details</p> */}
                  <p className="m-0 text-color2 text-1">{value.sub_title}</p>
                </div>
              </div>
              <div style={{ width: "45%" }}>
                {!isEmpty(info) && Number(info.discounted_price) !== 0 && (Number(info.discounted_price) !== Number(info.pre_discount)) && (
                  <h4 className="text-4 text-color13 text-decoration-line-through text-end">
                    ₹ {!isEmpty(info) && Number(info.pre_discount).toFixed(2)}{" "}
                  </h4>
                )}
                <h4 className="text-6 text-color6 text-end">
                  {!isEmpty(info) && Number(info.discounted_price) === 0
                    ? "Free"
                    : `₹ ${Number(info.discounted_price).toFixed(2)} `}
                </h4>
                <p className="m-0 text-color2 text-1 text-end">
                  {!isEmpty(info) && info.duration_type}
                </p>
              </div>
            </div>
            <ModalBody className="px-3">
              <p className="text-1 text-color4">{value.description}</p>
              <h5 className="text-color1 text-9 mb-3">Feature Collection</h5>
              <div>
                <ul className="list-unstyled text-muted vstack gap-3">
                  {!isEmpty(value) &&
                    value.long_description.length > 0 &&
                    value.long_description.map((subItem, index) => {
                      return (
                        <li key={index}>
                          <div className="d-flex">
                            {subItem.status === "Yes" ? (
                              <div className="flex-shrink-0 text-success me-2">
                                <i className="ri-checkbox-circle-line fs-15 align-middle"></i>
                              </div>
                            ) : subItem.status === "" ? (
                              <div className="flex-shrink-0 text-danger me-2 d-none">
                                <i className=" ri-close-circle-line fs-15 align-middle"></i>
                              </div>
                            ) : (
                              <div className="flex-shrink-0 text-danger me-2">
                                <i className=" ri-close-circle-line fs-15 align-middle"></i>
                              </div>
                            )}

                            {subItem.status === "" ? (
                              <div className="flex-grow-1 text-15 text-color4 d-none">
                                {subItem.title}
                              </div>
                            ) : (
                              <div className="flex-grow-1 text-15 text-color4">
                                {subItem.title}
                              </div>
                            )}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <h5 className="text-color1 text-9 mb-3">Select Plan Duration</h5>
              <div>
                {!isEmpty(value) &&
                  value.plan_durations.length > 0 &&
                  value.plan_durations.map((seller, index) => {
                    ////console.log(seller);
                    var imageshow = monthly;
                    if(seller.duration_type == 'Quarterly'){
                      imageshow = quertly;
                    }if(seller.duration_type == 'Annually'){
                      imageshow = annualy;
                    }

                    return (
                      <Card
                        key={index}
                        onClick={() => selectHandler(seller, index)}
                        className={`${
                          sellerId === index && "selected-box-border"
                        } ${
                          index > 2 && "item-hide"
                        } suggested-algos-col-card ${
                          sellerId !== index && "non-selected-box-border"
                        }`}
                      >
                        <CardBody
                          className="pt-3 pb-3 px-3"
                          style={{ height: "80px" }}
                        >
                          <div className="d-flex mb-1">
                            <img
                              src={seller.image || imageshow}
                              style={{ width: "50px" }}
                            />
                            <div className="flex-grow-1">
                              <h5
                                className="ms-2 mt-2 text-4 text-color1"
                                style={{ height: "25px" }}
                              >
                                {seller.duration_type}
                              </h5>
                            </div>
                            <div className="flex-shrink-0 ">
                              <h4 className="text-20 text-color6">
                                {Number(seller.discounted_price) === 0
                                  ? "Free"
                                  : `₹ ${Number(
                                      seller.discounted_price
                                    ).toFixed(2)}`}
                              </h4>
                              <p className="text-14 text-color2 text-end">
                                {seller.duration === 30
                                  ? "1 Month"
                                  : seller.duration === 90
                                  ? "3 Months"
                                  : "12 Months"}
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  })}
              </div>
              <div className="d-flex text-center justify-content-center">
                {/* {filteredUsers.length > 0 && !isEmpty(match) ? (
                  <button
                    className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                    style={{ borderRadius: "5px" }}
                    disabled
                    onClick={() => {
                      addToCartHandler(item);
                    }}
                  >
                    <i className="ri-check-line fs-15 align-middle pe-2 text-light"></i>{" "}
                    Added
                  </button>
                ) : filteredUsers.length > 0 && isEmpty(match) ? (
                  <button
                    className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                    style={{ borderRadius: "5px" }}
                    disabled
                    onClick={() => {
                      addToCartHandler(item);
                    }}
                  >
                    Add to Cart
                  </button>
                ) : (
                  <button
                    className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                    style={{ borderRadius: "5px" }}
                    disabled={!isEmpty(match)}
                    onClick={() => {
                      addToCartHandler(item);
                    }}
                  >
                    Add to Cart
                  </button>
                )} */}
                {/* {myPlan !== 65 ? null : <button
                  className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                  style={{ borderRadius: "5px" }}
                  //disabled={!isEmpty(match)}
                  onClick={() => {
                    addToCartHandler(item);
                  }}
                >
                  Add to Cart
                </button>} */}
               
              </div>
              
                 {/* {myPlan !== 65  ? (
                    info1.brokrage_status === "yes" && info1.is_brokrage_status_active === "yes" ? (
                      <p>Multiple platform usage subscriptions not accepted</p>
                    ) : (
                      flatFeatureCatIds.includes(parseInt(info1.feature_ids)) ? (
                        
                        <button
                          className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                          style={{ borderRadius: "5px" }}
                          disabled // Disable the button if already added
                        >
                          Added
                        </button>
                      ) : (
                        <button
                          className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                          style={{ borderRadius: "5px" }}
                          onClick={() => {
                            addToCartHandler(item);
                          }}
                        >
                          Add to Cart
                        </button>
                      )
                    )
                  ) : (
                    <button
                      className="text-4 mb-4 btn btn-primary mb-3 margin-overview-button1"
                      style={{ borderRadius: "5px" }}
                      onClick={() => {
                        addToCartHandler(item);
                      }}
                    >
                      Add to Cart
                    </button>
                  )} */}
                  {console.log("ff",value.id)}
                  {console.log("item",item)}
                  {console.log(item?.feature_or_plan_id)}
                  {filteredUsers.length > 0 && !isEmpty(match) ? ( 
                    <button className = "text-4 btn btn-primary addons-feature-button-normal"
                      style = {
                          { borderRadius: "5px" } }
                      disabled onClick = {
                          () => {
                              addToCartHandler(value);
                          }
                      } >
                      <i className = "ri-check-line fs-15 align-middle pe-2 text-light" > </i>{" "}
                      Added </button>
                  ) : 
                  myPlan?.plan_id !== 65 || value.id === item?.feature_or_plan_id ? ( 
                    value.brokrage_status === 'yes' && value.is_brokrage_status_active === 'yes' ?
                    <p>Multiple platform usage subscriptions not accepted</p>
                    // <button className = "text-4 btn btn-primary addons-feature-button-normal"
                    //   style = {
                    //       { borderRadius: "5px" } }
                    //   disabled onClick = {
                    //       () => {
                    //           addToCartHandler(value);
                    //       }
                    //   } >
                    //   Add to Cart
                    //   </button>
                      : 
                      item.brokrage_status === 'no' && active_plan?.includes(parseInt(item?.feature_or_plan_id)) ? 
                        <button className = "text-4 btn btn-primary addons-feature-button-normal"
                          style = {
                              { borderRadius: "5px" } }
                          disabled onClick = {
                              () => {
                                  addToCartHandler(value);
                              }
                          } >
                          Add to Cart
                          </button>
                      : <button className = "text-4 btn btn-primary addons-feature-button-normal"
                      style = {
                          { borderRadius: "5px" } }
                            onClick = {
                          () => {
                              addToCartHandler(value);
                          }
                      } >
                      Add to Cart </button>
                  ) : ( 
                    <button className = "text-4 btn btn-primary addons-feature-button-normal"
                      style = {
                          { borderRadius: "5px" } }
                      disabled = {!isEmpty(match) }
                      onClick = {
                          () => {
                              addToCartHandler(value);
                          }
                      } >
                      Add to Cart 
                      </button>
                  )
              }

            </ModalBody>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default MyPlan;
