import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useNavigate  } from "react-router-dom";
import dayjs from "dayjs";
//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getELearningList } from "../../store/eLearning/action";
import { getELearningCategoryList } from '../../store/eLearningCategory/action';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Autoplay } from "swiper";

import ambassador_bg from "../../assets/images/logo/ambassador_bg.png";

const ELearningSlider = () => {
	const navigate  = useNavigate();
  var advancedFormat = require('dayjs/plugin/advancedFormat')
  dayjs.extend(advancedFormat)

  const dispatch = useDispatch();

  const client_code  = localStorage.getItem('client_id');
  const client_token = localStorage.getItem('client_token');
  
  const { ELearningVideosList } = useSelector((state) => ({
    ELearningVideosList: state.ELearning.ELearningVideosList,
  }));

  /*
  get data
  */
  useEffect(() => {
    dispatch(getELearningList({ client_code: client_code, client_token: client_token }));
    dispatch(getELearningCategoryList({ client_code: client_code, client_token: client_token }));
  }, [dispatch, client_token, client_code]);
  //  ////console.log(ELearningVideosList);
  const charactersLimit = (data, limit) => {
    if(data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data
  };

  const selectedVideoHandler = (data) => {
    navigate('/elearning/video', { 
      state : {
        data: data
      }
     });
  }
  return (
    <React.Fragment>
      {/* <Row className="spacing-remove-demounting3"> */}
      {/* <Col xl={8}> */}
      <Col xxl={12} xl={12}>
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div>
                <h4 className="card-title mb-0 flex-grow-1 text-6 text-color1">
                  E-Learning
                </h4>
              </div>
              <div className="page-title-right">
                <Link className="btn btn-primary btn-sm text-5 addons-button" to='/elearning' style={{padding: '10px'}}>
                  View Collection
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={true}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 4,
                  partialVisibilityGutter: 40,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 700,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
                mobile: {
                  breakpoint: {
                    max: 700,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {ELearningVideosList.length > 0 &&
                ELearningVideosList.map((video, index) => {
                  ////console.log(video);
                  let domain = video.video_url.substring(video.video_url.indexOf('https') + 0, video.video_url.indexOf('title') - 2);
                  let title = video.video_url.substring(video.video_url.indexOf('title') + 0, video.video_url.indexOf('title') - 2);
                  return (
                  <Col className="px-2" key={index}>
                    <Card className="card-height-100">
                      <CardBody className="py-0 px-0">
                        <iframe
                          width="100%"
                          height="180px"
                          src={domain}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen="allowfullscreen" 
                          style={{
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                          }}
                        ></iframe>
                        {/* {video.video_url} */}
                        <Link 
                       to={`/elearning/${video.id}/video`}
                        //onClick={() => {selectedVideoHandler(video)}}
                        >
                          <div className="px-2 pt-1 pb-3">
                          <h5 className="text-9 text-color5 mb-1">
                          {charactersLimit(video.title, 60)}
                          </h5>
                          <h5 className="main-header-secondary-page text-color1 mb-0 mt-2">
                          {charactersLimit(video.category.category, 25)}
                          </h5>
                          <h5 className="main-header-secondary-page text-color1 mb-0 mt-2" >{dayjs(video.created_at).format("DD MMM YYYY")}</h5>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>)
                })}
              {/* <Col className="px-2">
                <Card className="card-height-100">
                  <CardBody className="py-0 px-0">
                    <iframe
                      width="100%"
                      height="180px"
                      src="https://www.youtube.com/embed/-pB-BDPXI_k"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      style={{
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                      }}
                    ></iframe>
                    <div className="px-2 pt-1 pb-3">
                      <h5 className="text-9 text-color5 mb-1">
                        Evolved Reality
                      </h5>
                      <h5 className="main-header-secondary-page text-color4 mb-0">
                        Date - 27/01/2023
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col className="px-2">
                <Card>
                  <CardBody className="py-0 px-0">
                    <iframe
                      width="100%"
                      height="180px"
                      src="https://www.youtube.com/embed/-pB-BDPXI_k"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      style={{
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                      }}
                    ></iframe>
                    <div className="px-3 pt-1 pb-3">
                      <h5 className="text-9 text-color5 mb-1">
                        Evolved Reality
                      </h5>
                      <h5 className="main-header-secondary-page text-color4 mb-0">
                        Date - 27/01/2023
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-2">
                <Card>
                  <CardBody className="py-0 px-0">
                    <iframe
                      width="100%"
                      height="180px"
                      src="https://www.youtube.com/embed/-pB-BDPXI_k"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      style={{
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                      }}
                    ></iframe>
                    <div className="px-3 pt-1 pb-3">
                      <h5 className="text-9 text-color5 mb-1">
                        Evolved Reality
                      </h5>
                      <h5 className="main-header-secondary-page text-color4 mb-0">
                        Date - 27/01/2023
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-2">
                <Card>
                  <CardBody className="py-0 px-0">
                    <iframe
                      width="100%"
                      height="180px"
                      src="https://www.youtube.com/embed/-pB-BDPXI_k"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      style={{
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                      }}
                    ></iframe>
                    <div className="px-3 pt-1 pb-3">
                      <h5 className="text-9 text-color5 mb-1">
                        Evolved Reality
                      </h5>
                      <h5 className="main-header-secondary-page text-color4 mb-0">
                        Date - 27/01/2023
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-2">
                <Card>
                  <CardBody className="py-0 px-0">
                    <iframe
                      width="100%"
                      height="180px"
                      src="https://www.youtube.com/embed/-pB-BDPXI_k"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      style={{
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                      }}
                    ></iframe>
                    <div className="px-3 pt-1 pb-3">
                      <h5 className="text-9 text-color5 mb-1">
                        Evolved Reality
                      </h5>
                      <h5 className="main-header-secondary-page text-color4 mb-0">
                        Date - 27/01/2023
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
            </Carousel>
            {/* <Swiper
                grabCursor={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                slidesPerView={1}
                spaceBetween={10}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                loop={true}
                modules={[Autoplay]}
                className="mySwiper swiper responsive-swiper rounded gallery-light pb-4"
              >
                <div className="swiper-wrapper">
                  <SwiperSlide>
                    <Col>
                      <Card className="card-height-100">
                        <CardBody className="py-0 px-0">
                          <iframe
                            width="100%"
                            height="180px"
                            src="https://www.youtube.com/embed/-pB-BDPXI_k"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            style={{
                              borderTopRightRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></iframe>
                          <div className="px-2 pt-1 pb-3">
                            <h5 className="text-9 text-color5 mb-1">
                              Evolved Reality
                            </h5>
                            <h5 className="main-header-secondary-page text-color4 mb-0">
                              Date - 27/01/2023
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Col>
                      <Card>
                        <CardBody className="py-0 px-0">
                          <iframe
                            width="100%"
                            height="180px"
                            src="https://www.youtube.com/embed/-pB-BDPXI_k"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            style={{
                              borderTopRightRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></iframe>
                          <div className="px-3 pt-1 pb-3">
                            <h5 className="text-9 text-color5 mb-1">
                              Evolved Reality
                            </h5>
                            <h5 className="main-header-secondary-page text-color4 mb-0">
                              Date - 27/01/2023
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Col>
                      <Card>
                        <CardBody className="py-0 px-0">
                          <iframe
                            width="100%"
                            height="180px"
                            src="https://www.youtube.com/embed/-pB-BDPXI_k"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            style={{
                              borderTopRightRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></iframe>
                          <div className="px-3 pt-1 pb-3">
                            <h5 className="text-9 text-color5 mb-1">
                              Evolved Reality
                            </h5>
                            <h5 className="main-header-secondary-page text-color4 mb-0">
                              Date - 27/01/2023
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Col>
                      <Card>
                        <CardBody className="py-0 px-0">
                          <iframe
                            width="100%"
                            height="180px"
                            src="https://www.youtube.com/embed/-pB-BDPXI_k"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            style={{
                              borderTopRightRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></iframe>
                          <div className="px-3 pt-1 pb-3">
                            <h5 className="text-9 text-color5 mb-1">
                              Evolved Reality
                            </h5>
                            <h5 className="main-header-secondary-page text-color4 mb-0">
                              Date - 27/01/2023
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Col>
                      <Card>
                        <CardBody className="py-0 px-0">
                          <iframe
                            width="100%"
                            height="180px"
                            src="https://www.youtube.com/embed/-pB-BDPXI_k"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            style={{
                              borderTopRightRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></iframe>
                          <div className="px-3 pt-1 pb-3">
                            <h5 className="text-9 text-color5 mb-1">
                              Evolved Reality
                            </h5>
                            <h5 className="main-header-secondary-page text-color4 mb-0">
                              Date - 27/01/2023
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </SwiperSlide>
                </div>
              </Swiper> */}
          </Col>
        </Row>
        {/* </Col>
        <Col xl={4} className='spacing-remove-demounting2'> */}
        {/* <Card className="card-height-94 bg-primary">
            <Row className="g-1"  style={{overflow: 'hidden'}}>
              <Col xl={8} xs={8}>
                <CardBody className="pe-0 pt-4 ps-4">
                  <h3 className="text-white lh-base main-header-page mt-2 mb-4">
                    Become a BIGUL AMBASSADOR
                  </h3>
                  <p className="text-white-75 mb-4 main-header-secondary-page">
                    Bigul provides fully automated super fast rules based algo
                    trading strategies that can place trades. Get Demo Now!
                  </p>
                  <button
                    to="/apps-nft-create"
                    className="btn btn-success mb-3 active-feature-button"
                    // style={{
                    //   width: "130px",
                    //   height: "39px",
                    //   backgroundColor: "rgb(255, 223, 53)",
                    //   color: "black",
                    //   borderRadius: "5px",
                    //   border: 'none'
                    // }}
                  >
                    Enroll Now
                  </button>
                </CardBody>
              </Col>
              <Col xl={4} xs={4} className='m-0 p-0' >
                <div style={{ height: '315px', overflow: 'hidden', marginTop: '5px'}}>
                  <img
                    className="rounded-end img-fluid object-cover"
                    src={ambassador_bg}
                    alt="Card"
                    style={{ height : '98%', width: '100%'}}
                  />
                </div>
              </Col>
            </Row>
          </Card> */}
      </Col>
      {/* </Row> */}
    </React.Fragment>
  );
};

export default ELearningSlider;
