import React from "react";
import { Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

//Dashboard components
import VideoCollectionHeader from './VideoCollectionHeader';
import Gallery from "./Gallery";
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";

const Elearning = () => {
  document.title = "Bigul Admin & Dashboard";
  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Videos"
            titleMain="E-Learning"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to="/dashboard"
          />
          <Row>
            <VideoCollectionHeader />
          </Row>
          <Row>
            <Gallery
              generatedKey={generatedKey}
              encryptKey={encryptKey}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Elearning;
