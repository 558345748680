import {
  GET_ALL_UNCONVERTED_LEADS,
  API_ALL_UNCONVERTED_LEADS_SUCCESS,
  API_ALL_UNCONVERTED_LEADS_FAIL,
  RESET_ALLUNCONVERTED_STATE,
} from "./actionType";

export const unconvLeadsApiResponseSuccess = (actionType, data) => ({
  type: API_ALL_UNCONVERTED_LEADS_SUCCESS,
  payload: { actionType, data },
});

export const unconvLeadsApiResponseError = (actionType, error) => ({
  type: API_ALL_UNCONVERTED_LEADS_FAIL,
  payload: { actionType, error },
});

export const getAllUnconvertedLeads = (data) => ({
  type: GET_ALL_UNCONVERTED_LEADS,
  payload: data,
});

export const getRemoveAllUnconvertedLeads =(data)=>(
  {
    type:RESET_ALLUNCONVERTED_STATE
  }
)
