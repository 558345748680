import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_HIGHLIGHTS } from "./actionType";

import {
  HighlightsApiResponseSuccess,
  HighlightsApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getHighlightsDetailsApi } from "../../helpers/fakebackend_helper";

function* getHighlightsDetails(data) {
  try {
    const response = yield call(getHighlightsDetailsApi, data.data);
    yield put(HighlightsApiResponseSuccess(GET_HIGHLIGHTS, response));
  } catch (error) {
    yield put(HighlightsApiResponseError(GET_HIGHLIGHTS, error));
  }
}

export function* watchGetHighlightsDetails() {
  yield takeEvery(GET_HIGHLIGHTS, getHighlightsDetails);
}

function* HighlightsDetailsSaga() {
  yield all([fork(watchGetHighlightsDetails)]);
}

export default HighlightsDetailsSaga;
