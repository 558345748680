import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_CATEGORY_ELEARNINGVIDEOS } from "./actionType";

import {
  ELearningCategoryApiResponseSuccess,
  ELearningCategoryApiResponseError,
  getNotificationsList,
} from "./action";

//Include Both Helper File with needed methods
import { getELearningACategoryListApi } from "../../helpers/fakebackend_helper";

function* getELearningVideosCategoryList(data) {
  try {
    const response = yield call(getELearningACategoryListApi, data.data);
    yield put(ELearningCategoryApiResponseSuccess(GET_CATEGORY_ELEARNINGVIDEOS, response));
  } catch (error) {
    yield put(ELearningCategoryApiResponseError(GET_CATEGORY_ELEARNINGVIDEOS, error));
  }
}

export function* watchGetELearningVideosCategoryList() {
  yield takeEvery(GET_CATEGORY_ELEARNINGVIDEOS, getELearningVideosCategoryList);
}

function* ELearningVideosCategorySaga() {
  yield all([fork(watchGetELearningVideosCategoryList)]);
}

export default ELearningVideosCategorySaga;
