import {
  API_GET_PAYMENT_DETAILS_RESPONSE_SUCCESS,
  API_GET_PAYMENT_DETAILS_RESPONSE_ERROR,
  GET_PAYMENT_DETAILS,
} from "./actionType";

const INIT_STATE = {
  PaymentDetails: [],
};

const Checkout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_GET_PAYMENT_DETAILS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PAYMENT_DETAILS:
          return {
            ...state,
            PaymentDetails: action.payload.data.data !== null ?  action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_GET_PAYMENT_DETAILS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PAYMENT_DETAILS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_PAYMENT_DETAILS: {
      return {
        ...state,
      };
    }
    // // add to cart
    // case ADD_ITEM_TO_CART_SUCCESS:
    //   return {
    //     ...state,
    //     isAddToCart: true,
    //     Cart: [...state.Cart, action.payload.data],
    //   };

    // case ADD_ITEM_TO_CART_FAIL:
    //   return {
    //     ...state,
    //     isAddToCart: false,
    //     error: action.payload,
    //   };
    // // Remove to cart
    // case REMOVE_ITEM_TO_CART_SUCCESS:
    //   return {
    //     ...state,
    //     isRemoveToCart: true,
    //     Cart: state.Cart.filter(
    //       (Item) => Item._id.toString() !== action.payload.Item.toString()
    //     ),
    //   };
    // case REMOVE_ITEM_TO_CART_FAIL:
    //   return {
    //     ...state,
    //     isRemoveToCart: false,
    //     error: action.payload,
    //   };
    default:
      return { ...state };
  }
};

export default Checkout;
