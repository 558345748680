import {
  GET_TRADE_BOOK_CASH,
  API_TRADE_BOOK_CASH_RESPONSE_SUCCESS,
  API_TRADE_BOOK_CASH_RESPONSE_ERROR,
  GET_TRADE_BOOK_DERIVATIVE,
  API_TRADE_BOOK_DERIVATIVE_RESPONSE_SUCCESS,
  API_TRADE_BOOK_DERIVATIVE_RESPONSE_ERROR,
  GET_LEDGER,
  API_LEDGER_RESPONSE_SUCCESS,
  API_LEDGER_RESPONSE_ERROR,
  GET_P_AND_L_CASH,
  API_P_AND_L_CASH_RESPONSE_ERROR,
  API_P_AND_L_CASH_RESPONSE_SUCCESS,
  GET_P_AND_L_DERIVATIVE,
  API_P_AND_L_DERIVATIVE_RESPONSE_SUCCESS,
  API_P_AND_L_DERIVATIVE_RESPONSE_ERROR,
  GET_HOLDING,
  API_HOLDING_RESPONSE_SUCCESS,
  API_HOLDING_RESPONSE_ERROR,
  GET_TAX_REPORT,
  API_TAX_REPORT_RESPONSE_SUCCESS,
  API_TAX_REPORT_RESPONSE_ERROR,
  GET_CONTRACT_NOTE,
  API_CONTRACT_NOTE_RESPONSE_SUCCESS,
  API_CONTRACT_NOTE_RESPONSE_ERROR,
  API_P_AND_L_DERIVATIVE_2_RESPONSE_SUCCESS,
  API_P_AND_L_DERIVATIVE_2_RESPONSE_ERROR,
  GET_P_AND_L_DERIVATIVE_2
} from "./actionType";

// TradeBook Cash success
export const TradeBookCashApiResponseSuccess = (actionType, data) => ({
  type: API_TRADE_BOOK_CASH_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// TradeBook Cash error
export const TradeBookCashApiResponseError = (actionType, error) => ({
  type: API_TRADE_BOOK_CASH_RESPONSE_ERROR,
  payload: { actionType, error },
});
// TradeBook Cash
export const getTradeBookCash = (data) => ({
  type: GET_TRADE_BOOK_CASH,
  payload: data,
});

// TradeBook Derivative success
export const TradeBookDerivativeApiResponseSuccess = (actionType, data) => ({
  type: API_TRADE_BOOK_DERIVATIVE_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// TradeBook Derivative error
export const TradeBookDerivativeApiResponseError = (actionType, error) => ({
  type: API_TRADE_BOOK_DERIVATIVE_RESPONSE_ERROR,
  payload: { actionType, error },
});
// TradeBook Derivative
export const getTradeBookDerivative = (data) => ({
  type: GET_TRADE_BOOK_DERIVATIVE,
  payload: data,
});

// Ledger success
export const LedgerApiResponseSuccess = (actionType, data) => ({
  type: API_LEDGER_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Ledger error
export const LedgerApiResponseError = (actionType, error) => ({
  type: API_LEDGER_RESPONSE_ERROR,
  payload: { actionType, error },
});
// Ledger
export const getLedger = (data) => ({
  type: GET_LEDGER,
  payload: data,
});

// P&L Cash
export const PAndLCashApiResponseSuccess = (actionType, data) => ({
  type: API_P_AND_L_CASH_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// P&L Cash
export const PAndLCashApiResponseError = (actionType, error) => ({
  type: API_P_AND_L_CASH_RESPONSE_ERROR,
  payload: { actionType, error },
});
// P&L Cash
export const getPAndLCash = (data) => ({
  type: GET_P_AND_L_CASH,
  payload: data,
});

// P&L Derivative
export const PAndLDerivativeApiResponseSuccess = (actionType, data) => ({
  type: API_P_AND_L_DERIVATIVE_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// P&L Derivative
export const PAndLDerivativeApiResponseError = (actionType, error) => ({
  type: API_P_AND_L_DERIVATIVE_RESPONSE_ERROR,
  payload: { actionType, error },
});
// P&L Derivative
export const getPAndLDerivative = (data) => ({
  type: GET_P_AND_L_DERIVATIVE,
  payload: data,
});

// Holdings
export const HoldingsApiResponseSuccess = (actionType, data) => ({
  type: API_HOLDING_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Holdings
export const HoldingsApiResponseError = (actionType, error) => ({
  type: API_HOLDING_RESPONSE_ERROR,
  payload: { actionType, error },
});
// Holdings
export const getHoldings = (data) => ({
  type: GET_HOLDING,
  payload: data,
});

// Tax Report
export const TaxReportApiResponseSuccess = (actionType, data) => ({
  type: API_TAX_REPORT_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Tax Report
export const TaxReportApiResponseError = (actionType, error) => ({
  type: API_TAX_REPORT_RESPONSE_ERROR,
  payload: { actionType, error },
});
// Tax Report
export const getTaxReport = (data) => ({
  type: GET_TAX_REPORT,
  payload: data,
});

// Contract Note
export const ContractNoteApiResponseSuccess = (actionType, data) => ({
  type: API_CONTRACT_NOTE_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Contract Note
export const ContractNoteApiResponseError = (actionType, error) => ({
  type: API_CONTRACT_NOTE_RESPONSE_ERROR,
  payload: { actionType, error },
});
// Contract Note
export const getContractNote = (data) => ({
  type: GET_CONTRACT_NOTE,
  payload: data,
});

// P&L Derivative2
export const PAndLDerivative2ApiResponseSuccess = (actionType, data) => ({
  type: API_P_AND_L_DERIVATIVE_2_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// P&L Derivative2
export const PAndLDerivative2ApiResponseError = (actionType, error) => ({
  type: API_P_AND_L_DERIVATIVE_2_RESPONSE_ERROR,
  payload: { actionType, error },
});
// P&L Derivative2
export const getPAndLDerivative2 = (data) => ({
  type: GET_P_AND_L_DERIVATIVE_2,
  payload: data,
});