import {
  COLLATERAL_API_RESPONSE_SUCCESS,
  COLLATERAL_API_RESPONSE_ERROR,
  COLLATERAL_GET_AVAILABLE_MARGIN
} from "./actionType";

const INIT_STATE = {
  Collateral: []
};

const Collateral = (state = INIT_STATE, action) => {
  //console.log(action.payload)
  switch (action.type) {
    case COLLATERAL_API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case COLLATERAL_GET_AVAILABLE_MARGIN:
          return {
            ...state,
            Collateral: action.payload.data.rmsholding_sympResult,
          };
        default:
          return { ...state };
      }
    case COLLATERAL_API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case COLLATERAL_GET_AVAILABLE_MARGIN:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case COLLATERAL_GET_AVAILABLE_MARGIN: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default Collateral;
