import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_AVAILABLE_MARGIN, GET_BANK_DETAIlS, GET_WITHDRAW } from "./actionType";

import {
  MarginOverviewApiResponseSuccess,
  MarginOverviewApiResponseError,
  BankDetailsApiResponseSuccess,
  BankDetailsApiResponseError,
  withdrawApiResponseSuccess,
  withdrawApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getMarginOverviewApi, getBankDetailsApi, withdrawApi } from "../../helpers/fakebackend_helper";

function* getMarginOverviewDetails({ payload: data }) {
  ////console.log(data);
  try {
    const response = yield call(getMarginOverviewApi, data);
    ////console.log(response);
    yield put(MarginOverviewApiResponseSuccess(GET_AVAILABLE_MARGIN, response));
  } catch (error) {
    yield put(MarginOverviewApiResponseError(GET_AVAILABLE_MARGIN, error));
  }
}

export function* watchGetMarginOverviewDetails() {
  yield takeEvery(GET_AVAILABLE_MARGIN, getMarginOverviewDetails);
}

// bank Details
function* getBankDetails({ payload: data }) {
  ////console.log(data);
  try {
    const response = yield call(getBankDetailsApi, data);
    yield put(BankDetailsApiResponseSuccess(GET_BANK_DETAIlS, response));
  } catch (error) {
    yield put(BankDetailsApiResponseError(GET_BANK_DETAIlS, error));
    //toast.success("Contact Delete Successfully", { autoClose: 3000 });
  }
}

export function* watchGetBankDetails() {
  yield takeEvery(GET_BANK_DETAIlS, getBankDetails);
}

// withdraw
function* Withdraw({ payload: data }) {
 // //console.log(data);
  try {
    const response = yield call(withdrawApi, data);
    yield put(withdrawApiResponseSuccess(GET_WITHDRAW, response));
  } catch (error) {
    yield put(withdrawApiResponseError(GET_WITHDRAW, error));
  }
}

export function* watchWithdraw() {
  yield takeEvery(GET_WITHDRAW, Withdraw);
}

function* MarginOverviewDetailsSaga() {
  yield all([
    fork(watchGetMarginOverviewDetails),
    fork(watchGetBankDetails),
    fork(watchWithdraw),
  ]);
}

export default MarginOverviewDetailsSaga;
