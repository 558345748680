import {
  GET_AVAILABLE_MARGIN,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  API_BANK_DETAIlS_RESPONSE_SUCCESS,
  API_BANK_DETAIlS_RESPONSE_ERROR,
  GET_BANK_DETAIlS,
  WITHDRAW_RESET,
  API_WITHDRAW_OPEN_SUCCESS,
  API_WITHDRAW_CONFORM_SUCCESS,
  API_WITHDRAW_SUCCESS,
  API_WITHDRAW_ERROR,
  GET_WITHDRAW
} from "./actionType";

const INIT_STATE = {
  availableMargin:  [],
  bankDetails: [],
  withdraw: false,
  ConfirmWithdrawal: false,
  TransferredSuccessful: false
};

const MarginOverviewDetails = (state = INIT_STATE, action) => {
  ////console.log(action);
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_AVAILABLE_MARGIN:
          return {
            ...state,
            availableMargin: action.payload.data.payreqbalanceResult,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_AVAILABLE_MARGIN:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_AVAILABLE_MARGIN: {
      return {
        ...state,
      };
    }
    //bank details
    case API_BANK_DETAIlS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BANK_DETAIlS:
          return {
            ...state,
            bankDetails: action.payload.data.payreqbankResult,
          };
        default:
          return { ...state };
      }
    case API_BANK_DETAIlS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_BANK_DETAIlS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_BANK_DETAIlS: {
      return {
        ...state,
      };
    }
    //withdraw
    case WITHDRAW_RESET: {
      return {
        ...state,
        withdraw: false,
        ConfirmWithdrawal: false,
        TransferredSuccessful: false
      };
    }
    case API_WITHDRAW_OPEN_SUCCESS: {
      return {
        ...state,
        withdraw: true,
      };
    }
    case API_WITHDRAW_CONFORM_SUCCESS: {
      return {
        ...state,
        withdraw: false,
        ConfirmWithdrawal: true
      };
    }
    case API_WITHDRAW_SUCCESS:
      switch (action.payload.actionType) {
        case GET_WITHDRAW:
          return {
            ...state,
            TransferredSuccessful: true,
          };
        default:
          return { ...state };
      }
    case API_WITHDRAW_ERROR:
      switch (action.payload.actionType) {
        case GET_WITHDRAW:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_WITHDRAW: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default MarginOverviewDetails;
