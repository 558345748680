import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, FieldArray, getIn, ErrorMessage } from "formik";
// TODO Ui and design and functions.
const VerifyMobile = (props) => {
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const validationSchema = Yup.object().shape({
    pan_no: Yup.string().required("This field is required"),
    old_mobile_number: Yup.string().min(9).required("This field is required"),
    new_mobile_number: Yup.string().min(9).required("This field is required"),
  });
  const modalMobile = {
    CUpdate: false,
    otp1: false,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: true,
  };
  function tog_signUpModals() {
    // //console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(modalMobile);
  };
  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
          Verify Email
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <form onSubmit={submitHandler()}>
          <div className="mb-3">
            <p className="text-11 text-color1">Email Verification</p>
          </div>
          <div className="input-group">
            <Input
              type="number"
              className="form-control modal-input-element"
              placeholder="Enter OTP"
            />
            <button
              className="btn btn-primary modal-input-button"
              type="button"
              onClick={(e) => {
                onClickHandler(e);
              }}
            >
              Verify
            </button>
          </div>
          <h4 className="text-11 text-color8 mt-3 ">
            OTP sent on your New Email.
          </h4>
          {/* <h4 className="text-11 text-color8">
              Time remaining 256sec
            </h4> */}
          <h4 className="text-11 text-color8 mt-3">
            Didn’t receive OTP? Resend
          </h4>
        </form>
      </ModalBody>
    </React.Fragment>
  );
};

// VerificationNumber.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
// };

export default VerifyMobile;
