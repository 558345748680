// Actions
export const API_GET_PAYMENT_DETAILS_RESPONSE_SUCCESS = "API_GET_PAYMENT_DETAILS_RESPONSE_SUCCESS";
export const API_GET_PAYMENT_DETAILS_RESPONSE_ERROR = "API_GET_PAYMENT_DETAILS_RESPONSE_ERROR";
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";

// export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
// export const ADD_ITEM_TO_CART_SUCCESS = "ADD_ITEM_TO_CART_SUCCESS";
// export const ADD_ITEM_TO_CART_FAIL = "ADD_ITEM_TO_CART_FAIL";

// export const REMOVE_ITEM_TO_CART = "REMOVE_ITEM_TO_CART";
// export const REMOVE_ITEM_TO_CART_SUCCESS = "REMOVE_ITEM_TO_CART_SUCCESS";
// export const REMOVE_ITEM_TO_CART_FAIL = "REMOVE_ITEM_TO_CART_FAIL";
