import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import WithdrawFundsPopup from "./WithdrawFundsPopup";
import ConfirmWithdrawal from "./ConfirmWithdrawal";
import TransferredSuccessful from "./TransferredSuccessful";
//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { withdrawOpenApi } from "../../../../store/marginOverview/action";
// TODO Ui and design and functions.
const WithdrawFunds = (props) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');
  const { data, openState, wAmount } = props;
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);

  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));
  useEffect(() => {
    dispatch(withdrawOpenApi());
  },[]);
  
  const [modalMobile, setModelMobile] = useState({
    CUpdate: true,
    otp1: false,
    done: false,
  });
  useEffect(() => {
    setModelMobile(openState)
  },[])
  
  const sendData = (data, value) => {
    setAmount(value);
    setModelMobile(data);
  };

  const tog_signUpModalsFinal = (data) => {

    setmodal_signUpModals(!modal_signUpModals);
  };

  const tog_signUpModals = () => {
    setModelMobile(openState)
    setmodal_signUpModals(!modal_signUpModals);
  };

  const submitHandler = () => {
    //  ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        type="button"
        className="text-4 text-color7 profile-edit-button1"
        style={{ borderRadius: "5px", textDecoration: "none" }}
        onClick={() => tog_signUpModals()}
      >
       [Withdraw]
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {modalMobile.CUpdate && (
          <WithdrawFundsPopup sendData={sendData} tog={tog_signUpModals} data={data} wAmount={wAmount} />
        )}
        {modalMobile.otp1 && (
          <ConfirmWithdrawal sendData={sendData} tog={tog_signUpModals} data={data} value={amount} />
        )}
        {modalMobile.done && (
          <TransferredSuccessful sendData={tog_signUpModalsFinal} />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default WithdrawFunds;
