import {
  GET_PROFILEDETAILS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  API_UPDATE_PHONE_RESPONSE_SUCCESS,
  API_UPDATE_PHONE_RESPONSE_ERROR,
  UPDATE_PHONE,
  API_UPDATE_EMAIL_RESPONSE_SUCCESS,
  API_UPDATE_EMAIL_RESPONSE_ERROR,
  UPDATE_EMAIL,
  RESET_PHONE
} from "./actionType";

const INIT_STATE = {
  userProfile: {},
  isUpdated: false,
};

const ProfileDetails = (state = INIT_STATE, action) => {
 // //console.log(action.type)
  switch (action.type) {
    case RESET_PHONE:
      switch (action.type) {
        case RESET_PHONE:
          return {
            ...state,
            isUpdated: false,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROFILEDETAILS:
          return {
            ...state,
            userProfile: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PROFILEDETAILS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_PROFILEDETAILS: {
      return {
        ...state,
      };
    }
    // Updated Phone
    case API_UPDATE_PHONE_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UPDATE_PHONE: {
          // //console.log(state.userProfile)
          // //console.log(action.payload.data.data.new_mobile_number)
          // state.userProfile.MOBILE =  action.payload.data.new_mobile_number;
          // //console.log(state.userProfile)
          return {
            ...state,
            isUpdated: true,
            userProfile: {...state.userProfile, MOBILE:  action.payload.data.data.new_mobile_number}, 
          };
        }
        default:
          return { ...state };
      }
    case API_UPDATE_PHONE_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UPDATE_PHONE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
      // Updated Email
    case API_UPDATE_EMAIL_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UPDATE_EMAIL: {
          // //console.log(state.userProfile)
          // //console.log(action.payload.data.data.new_email)
          // state.userProfile.MOBILE =  action.payload.data.new_mobile_number;
          // //console.log(state.userProfile)
          return {
            ...state,
            userProfile: {...state.userProfile, EMAIL:  action.payload.data.data.new_email}, 
          };
        }
        default:
          return { ...state };
      }
    case API_UPDATE_EMAIL_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UPDATE_EMAIL:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default ProfileDetails;
