import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import total_invested from "../../assets/images/logo/total_invested.png";

const Widget1 = (props) => {
  const { item, percentage, data, data2, loading } = props;

  const amountFormatter = (data) => {
    if (+data > 0) {
      return `${data.toString()}`;
    }
    return `${data.toString().slice(1)}`;
  };

  return (
    <Col xl={3} lg={6} md={6} sm={6} className="dashboard-widget-bottom-margin">
      <Card className="card-invest">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title bg-light text-primary rounded-circle fs-1">
                <div
                  className="card-invest-div-logo rounded-circle align-middle"
                  style={{ backgroundColor: `${item.backGround}` }}
                >
                  <img
                    className="card-invest-logo align-middle"
                    src={item.img}
                    alt={item.label}
                  />
                </div>
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <div className="d-flex justify-content-between">
                <p className="text-uppercase text-1 mb-1 text-color1">
                  {item.label}
                </p>
                {data2 && (
                  <div
                    className=""
                    style={{ marginTop: "-1px", maxWidth: "40%" }}
                  >
                    <span
                      className={`badge text-16 ${
                        data2 > 0 ? "badge-color-profit" : "badge-color-loss"
                      }`}
                      style={{ borderRadius: "5px" }}
                    >
                      <i
                        className={`${
                          data2 > 0
                            ? "ri-arrow-right-up-line"
                            : "ri-arrow-right-down-line"
                        } align-middle me-1`}
                      ></i>
                      {amountFormatter(data2)} %<span></span>
                    </span>
                  </div>
                )}
              </div>

              {loading ? (
                <div className="loading">Loading...</div>
              ) : (
                <h4 className="mb-0 text-2 text-color1">
                  ₹{Intl.NumberFormat("en-IN").format(data.toFixed(2))}
                </h4>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Widget1;
