import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_SUMMARY } from "./actionType";

import {
  SummaryTableApiResponseSuccess,
  SummaryTableApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getSummaryTableApi } from "../../helpers/fakebackend_helper";

// Trade Book Cash
function* getSummaryTable({ payload: data }) {
  ////console.log(data)
  try {
    const response = yield call(getSummaryTableApi, data);
    yield put(SummaryTableApiResponseSuccess(GET_SUMMARY, response));
  } catch (error) {
    yield put(SummaryTableApiResponseError(GET_SUMMARY, error));
  }
}

export function* watchGetSummaryTable() {
  yield takeEvery(GET_SUMMARY, getSummaryTable);
}



function* SummaryTableSaga() {
  yield all([
    fork(watchGetSummaryTable)
  ]);
}

export default SummaryTableSaga;
