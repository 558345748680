import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { API_ALGO_GET_TRANSTIONS_SUCCESS, GET_SUMMARY, GET_TRANSTIONS } from "./actionType";

import {
  SummaryTableApiResponseSuccess,
  SummaryTableApiResponseError,
  AlgoGetTranstionSuccess,
  AlgoGetTranstionsApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getAlgoTranstionList } from "../../helpers/fakebackend_helper";

// Trade Book Cash
function* getTranstionData( {payload:data}) {
  try {
    const response = yield call(getAlgoTranstionList, data);
    console.log(">?????Api response transations ????>?",response);
    yield put(AlgoGetTranstionSuccess(GET_TRANSTIONS, response));
  } catch (error) {
    yield put(AlgoGetTranstionsApiResponseError(GET_TRANSTIONS, error));
  }
}

export function* watchGetTransionSaga() {
  yield takeEvery(GET_TRANSTIONS, getTranstionData);
}



function* GetTransionSaga() {
  yield all([
    fork(watchGetTransionSaga)
  ]);
}

export default GetTransionSaga;
