// get available margin Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_AVAILABLE_MARGIN = "GET_AVAILABLE_MARGIN";

// get bank details Actions
export const API_BANK_DETAIlS_RESPONSE_SUCCESS = "API_BANK_DETAIlS_RESPONSE_SUCCESS";
export const API_BANK_DETAIlS_RESPONSE_ERROR = "API_BANK_DETAIlS_RESPONSE_ERROR";
export const GET_BANK_DETAIlS = "GET_BANK_DETAIlS";

// withdraw 
export const WITHDRAW_RESET = 'WITHDRAW_RESET';
export const API_WITHDRAW_OPEN_SUCCESS = 'API_WITHDRAW_OPEN_SUCCESS';
export const API_WITHDRAW_CONFORM_SUCCESS = 'API_WITHDRAW_CONFORM_SUCCESS';
export const API_WITHDRAW_SUCCESS = "API_WITHDRAW_SUCCESS";
export const API_WITHDRAW_ERROR = "API_WITHDRAW_ERROR";
export const GET_WITHDRAW = "GET_WITHDRAW";
