import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, Col, Row, CardBody } from "reactstrap";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getPortfolioChartsData } from "../../store/actions";
import { getSA } from "../../store/sa/action";
import { getPMS } from "../../store/pms/action";
import { getMF } from '../../store/mf/action';
import dayjs from "dayjs";
//import Images
import stock from "../../assets/images/logo/stock.png";
import image_e from "../../assets/images/logo/image_e.png";
import image_mf from "../../assets/images/logo/image_mf.png";
import image_c from "../../assets/images/logo/image_c.png";
import image_p from '../../assets/images/logo/image_p.png';
import D from '../../assets/images/logo/D.png';
//action
import { getPAndLDerivative2 } from "../../store/statements/action";
import { PortfolioCharts } from "./DashboardCryptoCharts";

const MyPortfolio1 = (props) => {
  const { profileDate, generatedKey, encryptKey } = props;
  const dispatch = useDispatch();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");

  const { SA } = useSelector((state) => ({
    SA: state.Sa.sa,
  }));
  const SACurrent = !isEmpty(SA) && typeof SA !== "string" ? +SA.total_current_value.replace(/,/g, "") : 0;
  const SAInv = !isEmpty(SA) && typeof SA !== "string" ? +SA.total_invested_value.replace(/,/g, "") : 0;
  const SAReturn = !isEmpty(SA) && typeof SA !== "string" ? SACurrent - SAInv : 0;
  const SAPer = !isEmpty(SA) && typeof SA !== "string" ? (SAReturn / SAInv) * 100: 0;
  ////console.log(SA);

  const { PMS } = useSelector((state) => ({
    PMS: state.Pms.pms,
  }));
  const PmsCurrent = !isEmpty(PMS) && typeof PMS !== "string" ? +PMS.total_current_value.replace(/,/g, "") : 0;
  const PmsInv = !isEmpty(PMS) && typeof PMS !== "string" ? +PMS.total_invested_value.replace(/,/g, "") : 0;
  const PmsReturn = !isEmpty(PMS) && typeof PMS !== "string" ? PmsCurrent - PmsInv : 0;
  const PmsPer = !isEmpty(PMS) && typeof PMS !== "string" ? (PmsReturn / PmsInv) * 100 : 0;
 // //console.log(PMS);

  const { MF } = useSelector((state) => ({
    MF: state.Mf.mf,
  }));
  const mf = {}
  const MfCurrent = !isEmpty(MF) && typeof MF !== "string" ? +MF.total_current_value.replace(/,/g, "") : 0;
  const MfInv = !isEmpty(MF) && typeof MF !== "string" ? +MF.total_invested_value.replace(/,/g, "") : 0;
  const MfReturn = !isEmpty(MF) && typeof MF !== "string" ? MfCurrent - MfInv : 0;
  const MfPer = !isEmpty(MF) && typeof MF !== "string" ? (MfReturn / MfInv) * 100 : 0;
  ////console.log(MF);
  !isEmpty(MF) && typeof MF !== "string" ? mf.name = 'Mutual Funds' : mf.name = 'Mutual Funds';
  !isEmpty(MF) && typeof MF !== "string" ? mf.inv = MfInv.toFixed(2) : mf.inv = MfInv.toFixed(2);
  !isEmpty(MF) && typeof MF !== "string" ? mf.ret = MfReturn.toFixed(2) : mf.ret = MfReturn.toFixed(2);
  !isEmpty(MF) && typeof MF !== "string" ? mf.per = MfPer.toFixed(2) : mf.per = MfPer.toFixed(2);
  !isEmpty(MF) && typeof MF !== "string" ? mf.crnt = MfCurrent.toFixed(2) : mf.crnt = MfCurrent.toFixed(2);
  mf.img = image_mf;
  
  const pms = {}
  const PMSTotalCurrent = SACurrent + PmsCurrent;
  const PMSTotalInv = SAInv + PmsInv;
  const PMSTotalReturn = PMSTotalCurrent - PMSTotalInv;
  const PMSTotalPer =  PMSTotalReturn !== 0 && PMSTotalReturn !== 0 ? ((PMSTotalReturn / PMSTotalInv) * 100) : 0;
  // //console.log(PMSTotalReturn)
  // //console.log(PMSTotalInv)
  // //console.log(PMSTotalPer)
  pms.name = 'PMS';
  pms.inv = PMSTotalInv.toFixed(2);
  pms.ret = PMSTotalReturn.toFixed(2);
  pms.per = PMSTotalPer.toFixed(2);
  pms.crnt = PMSTotalCurrent.toFixed(2);
  pms.img = image_p;

  ////console.log(PMS);
  const { InvestmentSummary } = useSelector((state) => ({
    InvestmentSummary: state.InvestmentSummary.InvestmentSummary,
  }));
  //console.log(InvestmentSummary);
  const equity = {}
  const INVCurrent = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.currentvalue : 0;
  const INVInv = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.totalinvested : 0;
  const INVReturn = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.PNL : 0;
  const INVPer = !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? +InvestmentSummary.portfoliovalueResult.totalreturn : 0;
  !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? equity.name = 'Stock Holdings' : equity.name = 'Stock Holdings';
  !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? equity.inv = INVInv.toFixed(2) : equity.inv = INVInv.toFixed(2);
  !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? equity.ret = INVReturn.toFixed(2) :  equity.ret = INVReturn.toFixed(2);
  !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? equity.per = INVPer.toFixed(2) : equity.per = INVPer.toFixed(2);
  !isEmpty(InvestmentSummary) && !isEmpty(InvestmentSummary.portfoliovalueResult) ? equity.crnt = INVCurrent.toFixed(2) : equity.crnt = INVCurrent.toFixed(2);
  equity.img = stock;
  //console.log(equity);

  const { PAndLDerivative } = useSelector((state) => ({
    PAndLDerivative: state.Statements.PAndLDerivative2,
  }));

 // console.log(PAndLDerivative);

  const Derevative = PAndLDerivative.find((el) => {
    return el.SCRIPNAME.trim() === "Total";
  });

  const Derivatives = {};
  const DerivativesINV = null;
  const DerivativesCURRENT = PAndLDerivative.length > 0 && !isEmpty(Derevative) ? Derevative.Pnl : 0;
  const DerivativesRET = null;
  const DerivativesPER = null;
  PAndLDerivative.length > 0 && !isEmpty(Derevative) ? Derivatives.name = "Derivatives" : Derivatives.name = "Derivatives";
  PAndLDerivative.length > 0 && !isEmpty(Derevative) ? Derivatives.inv = DerivativesINV : Derivatives.inv = DerivativesINV;
  PAndLDerivative.length > 0 && !isEmpty(Derevative) ? Derivatives.ret = DerivativesRET : Derivatives.ret = DerivativesRET;
  PAndLDerivative.length > 0 && !isEmpty(Derevative) ? Derivatives.per = DerivativesPER : Derivatives.per = DerivativesPER;
  PAndLDerivative.length > 0 && !isEmpty(Derevative) ? Derivatives.crnt = DerivativesCURRENT : Derivatives.crnt = DerivativesCURRENT;
  Derivatives.img = D;
  //console.log(Derivatives);

  const displayData = [];
  displayData.push(mf);
  displayData.push(pms);
  displayData.push(equity);
  // displayData.push(Derivatives);
  //console.log(displayData)
  //const chartData = [12528, 25625, 2398, 659525];

  ////console.log(INVCurrent.toFixed(2))
  const fr = Intl.NumberFormat('en-IN').format(INVCurrent.toFixed(2));
  ////console.log(fr)
  const chartData = [];
  chartData.push(INVCurrent);
  chartData.push(PMSTotalCurrent);
  chartData.push(MfCurrent);
  // const tt = typeof SA === "string";
 // pms.inv = Intl.NumberFormat('en-IN').format(PMSTotalInv.toFixed(2));
  // chartData.push(
  //   isEmpty(InvestmentSummary.portfoliovalueResult)
  //     ? 0
  //     : +InvestmentSummary.portfoliovalueResult.totalinvested
  // );
  // chartData.push(
  //   isEmpty(SA) || tt === true ? 0 : +SA.total_current_value.replace(/,/g, "")
  // );
  // chartData.push(0);
  //chartData.push(!isEmpty(PMS) ? 0 : +PMS.total_current_value);
  ////console.log(chartData);
  ////console.log(profileDate);
  const startDate = new Date("1/4/2015");
  const endDate = new Date();
 

  useEffect(() => {
    if (!isEmpty(profileDate)) {
      dispatch(getSA({ pan: profileDate.PANGIR  }));
      dispatch(getPMS({ pan: profileDate.PANGIR }));
      dispatch(getMF({ pan: profileDate.PANGIR }));
      dispatch(
        getPAndLDerivative2({
          apiKey: generatedKey,
          code: encryptKey,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          segment: "F",
          charges: "N",
        })
      );
    }
    dispatch(getPortfolioChartsData("btc"));
  }, [dispatch, profileDate]);

  const amountFormatter = (data) => {
    ////console.log(data);
  };

  ////console.log(INVReturn)
  const d = [5080, 500.52662326, 1000, 2531];
  return (
    <React.Fragment>
      <Col xxl={6} xl={6} className="dashboard-widget-bottom-margin">
        <Card className="card-height-101 m-0">
          <CardHeader className="align-items-center d-flex">
            <h2 className="card-title mb-0 flex-grow-1 text-6 text-color1"> 
              Current Value Overview
            </h2>
          </CardHeader>
          {chartData.length > 0 && (
            <CardBody className="px-3 pt-1 align-items-center">
              <Row className="align-items-center">
                <Col xxl={6} xl={6} lg={6}>
                  <div
                    id="portfolio_donut_charts"
                    className="apex-charts col mt-4 spacing-remove-top"
                    dir="ltr"
                  >
                    <PortfolioCharts
                      series={chartData}
                      dataColors='["--vz-primary","--vz-success", "--vz-info", "--vz-warning"]'
                    />
                  </div>
                </Col>
                <Col xxl={6} xl={6} lg={6} className="mt-3">
                  <div className="d-grid gap-4">
                    {displayData.length > 0 && displayData.sort((a, b) =>  b.crnt - a.crnt).map((el, index) => {
                      ////console.log(Intl.NumberFormat('en-IN').format(+el.inv))
                      return (
                      <div className="d-flex align-items-center" key={index}>
                      <img src={el.img} className="img-fluid me-2" alt="" style={{ height: '30px', width: '30px'}} />
                      <div className="me-auto">
                        <h6 className="mb-1 text-4-1 text-color1">{el.name}</h6>
                        <p className="text-1  mb-0 text-color1">
                          Inv: <span className="text-4-1 text-color1">{Intl.NumberFormat('en-IN').format(+el.inv)}</span>
                        </p>
                      </div>
                      <div>
                        <h6 className={`mb-1 text-4-1 + ${el.ret > 0 ? 'text-color23' : el.ret < 0 ?'text-color11' : 'text-4-1'}`}>{+el.ret > 0 ? `₹${Intl.NumberFormat('en-IN').format(+el.ret)}  (${el.per}%)` : +el.ret < 0 ? `-₹${Intl.NumberFormat('en-IN').format(+el.ret).slice(1)}  (${el.per}%)` : `₹${el.ret}  (${el.per}%)`}</h6>
                        <p className="text-1 mb-0 text-color1 text-end">
                          <b>Crnt: <span className="text-4-1 text-color1">{Intl.NumberFormat('en-IN').format(+el.crnt)}</span></b>
                        </p>
                      </div>
                    </div>
                    )})}
                    {!isEmpty(Derivatives) && <div className="d-flex align-items-center" >
                      <Link to="/statements/5">
                      <img src={Derivatives.img} className="img-fluid me-2" alt="" style={{ height: '30px', width: '30px'}} />
                      </Link>

                      <div className="me-auto">
                        <h6 className="mb-0 text-4-1 text-color1">{Derivatives.name}</h6>
                        {/* <p className="text-1  mb-0 text-color1">
                          Inv: <span className="text-4 text-color1">{Intl.NumberFormat('en-IN').format(+el.inv)}</span>
                        </p> */}
                      </div>
                      <div>
                        <h6 className={`mb-0 text-4-1 + ${Derivatives.crnt > 0 ? 'text-color23' : Derivatives.crnt < 0 ?'text-color11' : 'text-4-1'}`}>{+Derivatives.crnt > 0 ? `₹${Intl.NumberFormat('en-IN').format(+Derivatives.crnt)} ` : +Derivatives.crnt < 0 ? `-₹${Intl.NumberFormat('en-IN').format(+Derivatives.crnt).slice(1)} ` : `₹${Derivatives.crnt} `}</h6>
                        {/* <p className="text-1 mb-0 text-color1 text-end">
                          Crnt: <span className="text-4 text-color1">{Intl.NumberFormat('en-IN').format(+Derivatives.crnt)}</span>
                        </p> */}
                      </div>
                    </div>}
                    {/* <div className="d-flex align-items-center">
                      <img src={image_p} className="img-fluid me-2" alt="" style={{ height: '30px', width: '30px'}} />
                      <div className="me-auto">
                        <h6 className="mb-1 text-4 text-color1">PMS</h6>
                        <p className="text-1  mb-0 text-color1">
                          Inv: <span className="text-4 text-color1">{PMSTotalInv.toFixed(2)}</span>
                        </p>
                      </div>
                      <div>
                        <h6 className={`mb-1 text-4 + ${PMSTotalReturn > 0 ? 'text-color23' : 'text-color11'}`}>{PMSTotalReturn > 0 ? `₹${PMSTotalReturn.toFixed(2)}  (${PMSTotalPer.toFixed(2)}%)` : `-₹${PMSTotalReturn.toFixed(2).slice(1)}  (${PMSTotalPer.toFixed(2)}%)`}</h6>
                        <p className="text-1 mb-0 text-color1 text-end">
                          Crnt: <span className="text-4 text-color1">{PMSTotalCurrent.toFixed(2)}</span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={image_mf} className="img-fluid me-2" alt="" style={{ height: '30px', width: '30px'}} />
                      <div className="me-auto">
                        <h6 className="mb-1 text-4 text-color1">Mutual Funds</h6>
                        <p className="text-1  mb-0 text-color1">
                          Inv: <span className="text-4 text-color1">{MfInv.toFixed(2)}</span>
                        </p>
                      </div>
                      <div>
                        <h6 className={`mb-1 text-4 + ${MfReturn > 0 ? 'text-color23' : 'text-color11'}`}>{MfReturn > 0 ? `₹${MfReturn.toFixed(2)}  (${MfPer.toFixed(2)}%)` : `-₹${MfReturn.toFixed(2).slice(1)}  (${MfPer.toFixed(2)}%)`}</h6>
                        <p className="text-1 mb-0 text-color1 text-end">
                          Crnt: <span className="text-4 text-color1">{MfCurrent.toFixed(2)}</span>
                        </p>
                      </div>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </CardBody>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MyPortfolio1;
