import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_TRADE_BOOK_CASH, GET_TRADE_BOOK_DERIVATIVE, GET_LEDGER, GET_P_AND_L_CASH, GET_P_AND_L_DERIVATIVE, GET_HOLDING, GET_TAX_REPORT, GET_P_AND_L_DERIVATIVE_2 } from "./actionType";

import {
  TradeBookCashApiResponseSuccess,
  TradeBookCashApiResponseError,
  TradeBookDerivativeApiResponseSuccess,
  TradeBookDerivativeApiResponseError,
  LedgerApiResponseSuccess,
  LedgerApiResponseError,
  PAndLCashApiResponseSuccess,
  PAndLCashApiResponseError,
  PAndLDerivativeApiResponseSuccess,
  PAndLDerivativeApiResponseError,
  HoldingsApiResponseSuccess,
  HoldingsApiResponseError,
  TaxReportApiResponseSuccess,
  TaxReportApiResponseError,
  PAndLDerivative2ApiResponseSuccess,
  PAndLDerivative2ApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getTradeBookCashApi, getTradeBookDerivativeApi, getLedgerApi, getPAndLCashApi, getPAndLDerivativeApi, getHoldingsApi, getTaxReportApi, getPAndLDerivative2Api } from "../../helpers/fakebackend_helper";

// Trade Book Cash
function* getTradeBookCash({ payload: data }) {
  try {
    const response = yield call(getTradeBookCashApi, data);
    yield put(TradeBookCashApiResponseSuccess(GET_TRADE_BOOK_CASH, response));
  } catch (error) {
    yield put(TradeBookCashApiResponseError(GET_TRADE_BOOK_CASH, error));
  }
}

export function* watchGetTradeBookCash() {
  yield takeEvery(GET_TRADE_BOOK_CASH, getTradeBookCash);
}

// Trade Book Derivative
function* getTradeBookDerivative({ payload: data }) {
  try {
    const response = yield call(getTradeBookDerivativeApi, data);
    yield put(TradeBookDerivativeApiResponseSuccess(GET_TRADE_BOOK_DERIVATIVE, response));
  } catch (error) {
    yield put(TradeBookDerivativeApiResponseError(GET_TRADE_BOOK_DERIVATIVE, error));
  }
}

export function* watchGetTradeBookDerivative() {
  yield takeEvery(GET_TRADE_BOOK_DERIVATIVE, getTradeBookDerivative);
}

// Ledger
function* getLedger({ payload: data }) {
  try {
    const response = yield call(getLedgerApi, data);
    yield put(LedgerApiResponseSuccess(GET_LEDGER, response));
  } catch (error) {
    yield put(LedgerApiResponseError(GET_LEDGER, error));
  }
}

export function* watchGetLedger() {
  yield takeEvery(GET_LEDGER, getLedger);
}

// P&L Cash
function* getPAndLCash({ payload: data }) {
  try {
    const response = yield call(getPAndLCashApi, data);
    yield put(PAndLCashApiResponseSuccess(GET_P_AND_L_CASH, response));
  } catch (error) {
    yield put(PAndLCashApiResponseError(GET_P_AND_L_CASH, error));
  }
}

export function* watchGetPAndLCash() {
  yield takeEvery(GET_P_AND_L_CASH, getPAndLCash);
}

// P&L Derivative
function* getPAndLDerivative({ payload: data }) {
  try {
    const response = yield call(getPAndLDerivativeApi, data);
    yield put(PAndLDerivativeApiResponseSuccess(GET_P_AND_L_DERIVATIVE, response));
  } catch (error) {
    yield put(PAndLDerivativeApiResponseError(GET_P_AND_L_DERIVATIVE, error));
  }
}

export function* watchGetPAndLDerivative() {
  yield takeEvery(GET_P_AND_L_DERIVATIVE, getPAndLDerivative);
}

// P&L Derivative2
function* getPAndLDerivative2({ payload: data }) {
  try {
    const response = yield call(getPAndLDerivative2Api, data);
    yield put(PAndLDerivative2ApiResponseSuccess(GET_P_AND_L_DERIVATIVE_2, response));
  } catch (error) {
    yield put(PAndLDerivative2ApiResponseError(GET_P_AND_L_DERIVATIVE_2, error));
  }
}

export function* watchGetPAndLDerivative2() {
  yield takeEvery(GET_P_AND_L_DERIVATIVE_2, getPAndLDerivative2);
}

// Holdings
function* getHoldings({ payload: data }) {
  try {
    const response = yield call(getHoldingsApi, data);
    yield put(HoldingsApiResponseSuccess(GET_HOLDING, response));
  } catch (error) {
    yield put(HoldingsApiResponseError(GET_HOLDING, error));
  }
}

export function* watchGetHoldings() {
  yield takeEvery(GET_HOLDING, getHoldings);
}

// ax Report
function* getTaxReport({ payload: data }) {
  try {
    const response = yield call(getTaxReportApi, data);
    yield put(TaxReportApiResponseSuccess(GET_TAX_REPORT, response));
  } catch (error) {
    yield put(TaxReportApiResponseError(GET_TAX_REPORT, error));
  }
}

export function* watchGetTaxReport() {
  yield takeEvery(GET_TAX_REPORT, getTaxReport);
}

function* StatementsSaga() {
  yield all([
    fork(watchGetTradeBookCash),
    fork(watchGetTradeBookDerivative),
    fork(watchGetLedger),
    fork(watchGetPAndLCash),
    fork(watchGetPAndLDerivative),
    fork(watchGetHoldings),
    fork(watchGetTaxReport),
    fork(watchGetPAndLDerivative2)
  ]);
}

export default StatementsSaga;
