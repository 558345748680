import {
  GET_REFERRAL_LINK,
  API_REFERRAL_LINK_SUCCESS,
  API_REFERRAL_LINK_FAIL,
} from "./actionType";

export const RefferlLInkApiResponseSuccess = (actionType, data) => ({
  type: API_REFERRAL_LINK_SUCCESS,
  payload: { actionType, data },
});

export const RefferlLInkApiResponseError = (actionType, error) => ({
  type: API_REFERRAL_LINK_FAIL,
  payload: { actionType, error },
});

export const getRefferlLInkCount = (data) => ({
  type: GET_REFERRAL_LINK,
  payload: data,
});
