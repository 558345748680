import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_CLIENT_ID } from "./actionType";

import {
  ClientIdApiResponseSuccess,
  ClientIdApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getClientIDApi } from "../../helpers/fakebackend_helper";

function* getClientID(data) {
  try {
    const response = yield call(getClientIDApi, data.data);
    console.log(response)
    yield put(ClientIdApiResponseSuccess(GET_CLIENT_ID, response));
  } catch (error) {
    yield put(ClientIdApiResponseError(GET_CLIENT_ID, error));
  }
}

export function* watchGetClientID() {
  yield takeEvery(GET_CLIENT_ID, getClientID);
}

function* ClientIDSaga() {
  yield all([
    fork(watchGetClientID)
  ]);
}

export default ClientIDSaga;
