import {
  GET_TOTAL_REFERRED,
  API_TOTAL_REFERRED_SUCCESS,
  API_TOTAL_REFERRED_FAIL,
} from "./actionType";

export const totalReferredApiResponseSuccess = (actionType, data) => ({
  type: API_TOTAL_REFERRED_SUCCESS,
  payload: { actionType, data },
});

export const totalReferredApiResponseError = (actionType, error) => ({
  type: API_TOTAL_REFERRED_FAIL,
  payload: { actionType, error },
});

export const getTotalReferred = (data) => ({
  type: GET_TOTAL_REFERRED,
  payload: data,
});
