import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Table,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Col,
  Button,
} from "reactstrap";
import { DefaultColumnFilter } from "../Common/filters";

const TableContainer5 = ({
  columns,
  data,
  customPageSize,
  tableClass,
  thClass,
  tableTrClasses
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    gotoPage(event);
  };
  return (
    <Fragment>
      <div className={`summary-table order-table`} >
        <Table
          hover
          {...getTableProps()}
          className={tableClass}
          style={{ marginBottom: "0" }}
          id="Table1"
        >
          <thead className="earning-table-header">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} style={{backgroundColor: '#5367fc', color: '#FFF'}}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={`${thClass} px-4`}
                    {...column.getSortByToggleProps()}
                    // style={{padding: '25px'}}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className={tableTrClasses ? tableTrClasses : "plan-summary-table-tr2 px-4"}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

TableContainer5.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer5;
