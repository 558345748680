import {
  API_GET_PAYMENT_DETAILS_RESPONSE_SUCCESS,
  API_GET_PAYMENT_DETAILS_RESPONSE_ERROR,
  GET_PAYMENT_DETAILS,
} from "./actionType";

// Payment Details common success
export const PaymentDetailsApiResponseSuccess = (actionType, data) => ({
  type: API_GET_PAYMENT_DETAILS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// Payment Details common error
export const PaymentDetailsApiResponseError = (actionType, error) => ({
  type: API_GET_PAYMENT_DETAILS_RESPONSE_ERROR,
  payload: { actionType, error },
});
// get Payment Details
export const getPaymentDetails = (data) => ({
  type: GET_PAYMENT_DETAILS,
  data,
});

// // Add to Cart success
// export const addToCartApiResponseSuccess = (actionType, data) => ({
//   type: ADD_ITEM_TO_CART_SUCCESS,
//   payload: { actionType, data },
// });
// // Add to Cart error
// export const addToCartApiResponseError = (actionType, error) => ({
//   type: ADD_ITEM_TO_CART_FAIL,
//   payload: { actionType, error },
// });
// //Add to Cart
// export const addToCart = (data) => ({
//   type: ADD_ITEM_TO_CART,
//   data,
// });

// // Remove to Cart success
// export const removeToCartApiResponseSuccess = (actionType, data) => ({
//   type: REMOVE_ITEM_TO_CART_SUCCESS,
//   payload: { actionType, data },
// });
// // Remove to Cart error
// export const removeToCartApiResponseError = (actionType, error) => ({
//   type: REMOVE_ITEM_TO_CART_FAIL,
//   payload: { actionType, error },
// });
// // Remove to Cart
// export const removeToCart = (data) => ({
//   type: REMOVE_ITEM_TO_CART,
//   data,
// });