import React, { useState, useEffect, useMemo, useCallback } from "react";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import * as moment from "moment";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader1 from "../../Components/Common/Loader1";
import Loader from "../../Components/Common/Loader";
//action
import { getTradeBookCash } from "../../store/statements/action";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

//redux
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const TradeBookCash = (props) => {
  const [isSearch, setIsSearch] = useState(false);
  const [counter, setCounter] = useState(0);
  const [startDate1, setStartDate1] = useState(new Date(2015, 3, 1));
  const [endDate1, setEndDate1] = useState(new Date());
  const dispatch = useDispatch();
  const [isDownload, setIsDownload] = useState(false);
  const [myCheck, setMycheck] = useState([]);
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });

  const { generatedKey } = useSelector((state) => ({
    generatedKey: state.GenerateKey.generatedKey,
  }));
  const { encryptKey } = useSelector((state) => ({
    encryptKey: state.EncryptKey.encryptKey,
  }));
  const { tradeBookCash } = useSelector((state) => ({
    tradeBookCash: state.Statements.tradeBookCash,
  }));

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  ////console.log(profileDetails)

  // //console.log(new Date('2023-01-25'));
  // tradeBookCash.forEach((el) => {
  //   //console.log(el)
  //   if( el.buysell === "B") {
  //     const newElement = {...el, quantity: `+${el.quantity}`}
  //     return newElement
  //     // el.quantity = `+${el.quantity}`
  //   } else {
  //     const newElement = {...el, quantity: `-${el.quantity}`}
  //     return newElement
  //     //el.quantity = `-${el.quantity}`
  //   }
  //   // return newElement
  // });
  const newArr = tradeBookCash.map((obj) => {
    if (obj.buysell === "B") {
      return { ...obj, quantity: `+${obj.quantity}` };
    } else {
      return { ...obj, quantity: `-${obj.quantity}` };
    }

    //return obj;
  });
  // //console.log(newArr);
  useEffect(() => {
    dispatch(
      getTradeBookCash({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  const DateFilter = () => {
    setCounter(2);
    dispatch(
      getTradeBookCash({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
      })
    );
  };
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const quantityFormitter = (cell, row) => {
    // //console.log(cell)
    // //console.log(row)
  };

  const deleteCheckbox = () => {
    window.alert("Hi");
    const ele = document.querySelectorAll(".orderCheckBox:checked");
    ////console.log(ele);
    // ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    // setSelectedCheckBoxDelete(ele);
  };
  // Displat Delete Button
  const [dele, setDele] = useState(0);
  const displayDelete = (cell) => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    // //console.log(ele);
  };
  // Customers Column
  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "cordernumber",
        //filterable: true,
      },
      {
        Header: "Trade Number",
        accessor: "ctradenumber",
        //filterable: true,
      },
      {
        Header: "Transaction Date",
        accessor: "dtoftran",
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY");
        },
      },
      {
        //Header: "Scrip Name",
        Header: <h4 className="d-flex m-0 fs-13">Scrip Name</h4>,
        accessor: "scripname",
        Cell: (cell) => {
          return <span className="d-flex">{cell.value}</span>;
        },
        //filterable: true,
      },
      {
        Header: "Exchange",
        accessor: "exchcode",
        //filterable: true,
      },
      {
        Header: "Buy/Sell",
        accessor: "buysell",
        Cell: (cell) => {
          switch (cell.value) {
            case "B":
              return <span className="table-bye">Buy</span>;
            case "S":
              return <span className="table-sell">Sell</span>;
          }
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        //filterable: true,
        formatter: quantityFormitter,
        Row: (row) => {
          ////console.log(row)
        },
      },
      {
        //Header: "Trade Rate  (₹)",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            Trade Rate (₹)
          </h4>
        ),
        accessor: "market",
        //filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
      },
      {
        //Header: "Trade Value (₹)",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            Trade Value (₹)
          </h4>
        ),
        accessor: "Net_val",
        //filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
      },
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  const myArray = [];
  for (let i = 0; i < tradeBookCash.length; i += 1) {
    const childArray = tradeBookCash[i];
    const obj = {
      //scripcode: childArray.scripcode.replace("#", ""),
      //scripcode: childArray.scripcode.replace("#", ""),
      cordernumber: childArray.cordernumber,
      ctradenumber: childArray.ctradenumber,
      dtoftran: childArray.dtoftran,
      scripname: childArray.scripname,
      exchcode: childArray.exchcode,
      buysell: childArray.buysell === "B" ? "Buy" : "Sell",
      quantity: childArray.quantity,
      market: childArray.market,
      Net_val: childArray.Net_val,
    };
    myArray.push(obj);
  }

  const userHeader = [
    "Order Number",
    "Trade Number",
    "Transaction Date",
    "Scrip Name",
    "Exchange",
    "Buy/Sell",
    "Quantity",
    "Trade Rate",
    "Trade Value",
  ];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${
      !isEmpty(profileDetails) ? profileDetails[0].FIBSACCT.trim() : "USER"
    }_${dayjs(new Date()).format("YYYY-MM-DD")}_TradeBookCash`
  );
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const downloadHandler = () => {
    setIsDownload(true);
  };
  ////console.log(new Date('13-Apr-2023'))
  return (
    <React.Fragment>
      <div>
        <div
          className="d-flex justify-content-between align-items-end  p-3 mobile-display-block"
          style={{ borderBottom: "1px solid #8080802b" }}
        >
          <div className="d-flex align-items-end mobile-display-block">
            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement"> From </h5>
              <div className="d-flex">
                <Flatpickr
                  placeholder="Select date"
                  id="exampleInputdate"
                  className="form-control summary-table-date text-center-statement"
                  options={{
                    //mode: "range",
                    dateFormat: "d M, Y",
                    maxDate: new Date(),
                    minDate: new Date(2015, 3, 1),
                    // enable: [
                    //   function (date) {
                    //     return (
                    //       (date >= startDate1)
                    //     );
                    //   },
                    // ],
                  }}
                  value={[startDate1]}
                  onChange={(date) => {
                    ////console.log(date);
                    setStartDate1(date[0]);
                  }}
                />
                {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
              </div>
            </div>

            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement"> To</h5>
              <div className="d-flex">
                <Flatpickr
                  placeholder="Select date"
                  id="exampleInputdate1"
                  className="form-control summary-table-date text-center-statement"
                  options={{
                    //mode: "range",
                    dateFormat: "d M, Y",
                    maxDate: new Date(),
                    enable: [
                      function (date) {
                        return date >= startDate1;
                      },
                    ],
                  }}
                  value={[endDate1]}
                  onChange={(date) => {
                    ////console.log(date);
                    setEndDate1(date[0]);
                  }}
                />
                {/* <Label htmlFor="exampleInputdate1" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
              </div>
            </div>
            <div className="statement-search">
              <button
                type="button"
                className="text-4 mb-2 btn btn-primary margin-overview-button1"
                style={{ borderRadius: "5px", height: "50%" }}
                onClick={DateFilter}
              >
                Search
              </button>
            </div>
          </div>

          <div className="d-flex download-button-mobile">
            <button
              type="button"
              className="btn btn-success text-4 mb-2 margin-overview-button2"
              style={{ borderRadius: "5px", height: "50%" }}
              onClick={downloadHandler}
              disabled={newArr.length === 0}
            >
              <i className="ri-download-2-line me-2 align-bottom"></i>
              Download Report
            </button>
          </div>
        </div>
      </div>
      {counter === 0 && newArr.length ? (
        <TableContainer2
          columns={columns}
          data={newArr || []}
          //isGlobalFilter={true}
          //isAddUserList={false}
          customPageSize={8}
          divClass="table-responsive table-card mb-1"
          tableClass="align-middle table-nowrap text-center"
          theadClass="table-light text-muted text-uppercase fs-13"
          tableTrClasses="summary-table-tr px-4"
          // handleOrderClick={handleCustomerClicks}
          // isOrderFilter={true}
          SearchPlaceholder="Search for order ID, customer, order status or something..."
        />
      ) : counter !== 0 ? (
        <Loader error={"empty"} />
      ) : (
        <Loader1 error={"empty"} />
      )}
    </React.Fragment>
  );
};

export default TradeBookCash;
