import {
  API_GENERATE_API_KEY_SUCCESS,
  API_GENERATE_API_KEY_FAIL,
  GET_GENERATE_API_KEY,
} from "./actionType";

const INIT_STATE = {
  apiKeyData: [],
};

const generateApiKeyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_GENERATE_API_KEY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_GENERATE_API_KEY:
          return {
            ...state,
            apiKeyData: action.payload.data !== null ? action.payload.data : [],
          };
        default:
          return { ...state };
      }
    case API_GENERATE_API_KEY_FAIL:
      switch (action.payload.actionType) {
        case GET_GENERATE_API_KEY:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_GENERATE_API_KEY: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default generateApiKeyReducer;
