import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConfirmChanges1 from './ConfirmChanges1';
import ConfirmChanges2 from './ConfirmChanges2';
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { ResetPhoneState } from "../../../../store/Profile/action";
import { toast } from "react-toastify";
// TODO Ui and design and functions.
const AdditionalDetailsModelIndex = (props) => {
  const dispatch = useDispatch();
  const { openState, data, data2 } = props;
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [modalAdditional, setModelAdditional] = useState({
    CChange1: false,
    CChange2: true,
  });

  useEffect(() => {
    setModelAdditional(openState)
  },[props])

  const sendData = (data) => {
    ////console.log(data);
    //e.preventDefault();
    setModelAdditional(data)
  }

  const tog_signUpModalsFinal = (data) => {
    // //console.log('submit')
    setmodal_signUpModals(!modal_signUpModals);
  }
 
  const tog_signUpModals = () => {
    setModelAdditional(openState)
    setmodal_signUpModals(!modal_signUpModals);
    //props.end(true);
  }

  const endFunction = () => {
    setModelAdditional(openState)
    setmodal_signUpModals(!modal_signUpModals);
    props.end(true);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        className="me-3 additional-page-button-save"
        type="button"
        onClick={() => {
          if(props.check.length == 0){
toast.error('Please select atleast one transaction')
          }else{
            tog_signUpModals()
          }
        }}
      >
        Confirm
      </button>
      <button
        className="me-3 additional-page-button-save"
        type="button"
        onClick={() => props.handlechangeState (false)}
      >
        Cancel
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        {/* {modalAdditional.CChange1 && <ConfirmChanges1 sendData={sendData} tog={tog_signUpModals} />} */}
        {modalAdditional.CChange2 && <ConfirmChanges2 sendData={tog_signUpModalsFinal} tog={tog_signUpModals} data={data} data2={data2} end={endFunction}  checkDdpi={props.checkDdpi}
              handlechangeState1={props.handlechangeState1} />}
      </Modal>
    </React.Fragment>
  );
};

export default AdditionalDetailsModelIndex;
