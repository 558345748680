import React from "react";
import { Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

//Dashboard components
import AddonsSlider from "../Dashboard/AddonsSlider";
import EcommerceCart from './EcommerceCart';
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";

const MyCart = () => {
  document.title = "Bigul Admin & Dashboard";

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="My Cart"
            titleMain="My Cart"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />

          <Row style={{borderBottom: '1px solid #80808036'}}>
            <EcommerceCart />
          </Row>

          <Row className="spacing-bottom-m ">
            <AddonsSlider />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyCart;
