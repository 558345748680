import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import * as moment from "moment";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader1 from "../../Components/Common/Loader1";
//action
import { getPAndLDerivative } from "../../store/statements/action";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

import { pmsDownloadApi } from "../../helpers/fakebackend_helper";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const PMS = (props) => {
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  const [counter, setCounter] = useState(0);
  const [downloadReport, setDownloadReport] = useState("");
  const [includeCharges, setIncludeCharges] = useState(false);
  const [segmentType, setSegmentType] = useState("100001");
  const [fileType, setFileType] = useState("0");
  const [reportType, setReportType] = useState("1");
  const dispatch = useDispatch();
  const [startDate1, setStartDate1] = useState(new Date(2015, 3, 1));
  const [endDate1, setEndDate1] = useState(new Date());
  const [isDownload, setIsDownload] = useState(false);
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });
  const { generatedKey } = useSelector((state) => ({
    generatedKey: state.GenerateKey.generatedKey,
  }));
  const { encryptKey } = useSelector((state) => ({
    encryptKey: state.EncryptKey.encryptKey,
  }));
  const { PAndLDerivative } = useSelector((state) => ({
    PAndLDerivative: state.Statements.PAndLDerivative,
  }));
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  ////console.log(PAndLDerivative);
  // useEffect(() => {
  //   dispatch(
  //     getPAndLDerivative({
  //       apiKey: props.generatedKey,
  //       code: props.encryptKey,
  //       startDate: dayjs(startDate1).format("YYYY-MM-DD"),
  //       endDate: dayjs(endDate1).format("YYYY-MM-DD"),
  //       segment: segmentType,
  //       charges: "N",
  //     })
  //   );
  // }, [dispatch]);

  const DateFilter = () => {
    setCounter(2);
    setDownloadReport("")
    pmsDownloadApi({
      pan: !isEmpty(profileDetails) && profileDetails[0].PANGIR,
      start_date: dayjs(startDate1).format("YYYY-MM-DD"),
      end_date: dayjs(endDate1).format("YYYY-MM-DD"),
      scheme_code: segmentType,
      type: fileType,
      report: reportType
    })
      .then((response) => {
        console.log(response);
        if(response.message === "No record found") {
          // toast.error(`${response.message}`, {
          //   autoClose: 1500,
          // });
          setDownloadReport("Not Active")
          // setDownloadReport(response.message)
        } else {
          // toast.error(`${response.message}`, {
          //   autoClose: 1500,
          // });
          // setDownloadReport(response.data)
        }
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  function removeValue(value, index, arr) {
    ////console.log(value)
    // //console.log(index)
    // //console.log(arr)
    // If the value at the current array index matches the specified value (2)
    if (
      //value.LNarrat === "Opening Balance       " ||
      value.SCRIPNAME === "Total"
    ) {
      // Removes the value from the original array
      arr.splice(index, 1);
      return true;
    }
    return false;
  }

  PAndLDerivative.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    ////console.log(new Date(dayjs(a.dtoftran).format(" DD MMM YYYY")))
    return (
      new Date(dayjs(b.Lexp).format(" DD MMM YYYY")) -
      new Date(dayjs(a.Lexp).format(" DD MMM YYYY"))
    );
  });

  // Pass the removeValue function into the filter function to return the specified value
  const x = PAndLDerivative.filter(removeValue);

  const AmountFormatter = (data) => {
    switch (true) {
      case data.value > 0:
        return <div className="text-color6">{data.value.toFixed(2)}</div>;
      case data.value < 0:
        return <div className="text-color11">{data.value.toFixed(2)}</div>;
      default:
        return <div>{data.value.toFixed(2)}</div>;
    }
  };

  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   //TODO
      //   Header: "#",
      //   Cell: (cell) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="productCheckBox form-check-input"
      //         value={cell.row.original._id}
      //         //onClick={() => displayDelete()}
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Script Code",
      //   accessor: "sCRIPCODE",
      //   filterable: true,
      // },
      {
        //Header: "Scrip Name",
        Header: <h4 className="d-flex m-0 fs-13">Scrip Name</h4>,
        accessor: "SCRIPNAME",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex" >{cell.value}</span>;
        },
      },
      // {
      //   Header: "Buy Date",
      //   accessor: "lexp",
      //   Cell: (cell) => {
      //     return dayjs(cell.value).format(" DD MMM YYYY");
      //   },
      // },
      {
        //Header: "Strike Price",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Strike Price</h4>,
        accessor: "Strike",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
      },
      {
        Header: "OT",
        accessor: "Op",
        filterable: true,
      },
      {
        Header: "Expiry Date",
        accessor: "Lexp",
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY");
        },
      },
      {
        Header: "Buy Qty",
        accessor: "BQTY",
        filterable: true,
      },
      {
        //Header: "Buy Value",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Buy Value</h4>,
        accessor: "BVALUE",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        // Cell: (cell) => {
        //   return cell.value.toFixed(2);
        // },
      },
      {
        //Header: "Buy Avg ",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Buy Avg</h4>,
        accessor: "BAVG",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },
      {
        Header: "Sell Qty",
        accessor: "SQTY",
        filterable: true,
        //Cell: AmountFormatter,
      },
      {
        //Header: "Sell Value",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Sell Value</h4>,
        accessor: "SVALUE",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Sell Avg",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Sell Avg</h4>,
        accessor: "SAVG",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },

      {
        Header: "Net Qty",
        accessor: "NQTY",
        filterable: true,
      },
      {
        //Header: "Net Value",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Net Value</h4>,
        accessor: "NVALUE",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        // Cell: (cell) => {
        //   return cell.value.toFixed(2);
        // },
      },
      {
        //Header: "Net Avg",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Net Avg</h4>,
        accessor: "NAVG",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Market Rate",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Market Rate</h4>,
        accessor: "MARKET",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Market Value",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Market Value</h4>,
        accessor: "MARKVALUE",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Today's P&L",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Today's P&L</h4>,
        accessor: "TPnl",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "P&L",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">P&L</h4>,
        accessor: "Pnl",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
        //Cell: AmountFormatter,
      },
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  const myArray = [];
  for (let i = 0; i < PAndLDerivative.length; i += 1) {
    const childArray = PAndLDerivative[i];
    const obj = {
      SCRIPNAME: childArray.SCRIPNAME,
      Strike: childArray.Strike,
      Op: childArray.Op,
      Lexp: childArray.Lexp,
      BQTY: childArray.BQTY,
      BVALUE: childArray.BVALUE,
      BAVG: childArray.BAVG,
      SQTY: childArray.SQTY,
      SVALUE: childArray.SVALUE,
      SAVG: childArray.SAVG,
      NQTY: childArray.NQTY,
      NVALUE: childArray.NVALUE,
      NAVG: childArray.NAVG,
      MARKET: childArray.MARKET,
      MARKVALUE: childArray.MARKVALUE,
      TPnl: childArray.TPnl,
      Pnl: childArray.Pnl
    };
    myArray.push(obj);
  }

  const userHeader = [
    "Scrip Name",
    "Strike Price",
    "OT",
    "Expiry date",
    "Buy Qty",
    "Buy Value",
    "Buy Avg ",
    "Sell Qty",
    "Sell Value",
    "Sell Avg",
    "Net Qty",
    "Net value",
    "Net Avg",
    "Market Rate",
    "Market value",
    "Today's P&L",
    "P&L",
  ];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${!isEmpty(profileDetails) ? profileDetails[0].FIBSACCT.trim() : "USER"}_${dayjs(new Date()).format("YYYY-MM-DD")}_PAndLDerivative`);
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const downloadHandler = () => {
    setIsDownload(true);
  };

  return (
    <React.Fragment>
      {/* <div>
        <div className="d-flex justify-content-between p-3">
          <div className="input-group input-group-width-25">
            <Flatpickr
              placeholder="Select date"
              id="exampleInputdate"
              className="form-control summary-table-date"
              options={{
                mode: "range",
                dateFormat: "d M, Y",
                maxDate: new Date(),
              }}
              value={selectDate.date}
              onChange={date => {
                setSelectDate({ date: date });
              }}
            />
            <Label htmlFor="exampleInputdate" className="form-label">
              <span
                className="input-group-text summary-table-date px-2 py-0"
                id="basic-addon1"
              >
                <i className="ri-calendar-2-line fs-4 text-color1"></i>
              </span>
            </Label>
          </div>
          <div></div>

          <div className="d-flex">
            <button
                type="button"
                className="btn btn-success text-4 margin-overview-button2"
                style={{ borderRadius: "5px" }}
                onClick={downloadHandler}
              >
              <i className="ri-download-2-line me-2 align-bottom"></i>
              Download Report
            </button>
          </div>
        </div>
      </div> */}
      <div>
        <div
          className="d-flex justify-content-between align-items-end  p-3 mobile-display-block"
          style={{ borderBottom: "1px solid #8080802b" }}
        >
          <div className="d-flex align-items-end mobile-display-block">
            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement"> Select Scheme </h5>
              <div className="text-center-statement">
                <select
                  className="select-segment-type width-mobile-50-2 text-center-statement"
                  onChange={(e) => {
                    setSegmentType(e.target.value);
                  }}
                >
                  <option value="100001" key="100001">
                  Bonanza Growth
                  </option>
                  <option value="100002" key="100002">
                  Bonanza Value
                  </option>
                  <option value="100043" key="100043">
                  Bonanza Edge
                  </option>
                  <option value="100044" key="100044">
                  Bonanza Multicap
                  </option>
                  <option value="100045" key="100045">
                  Bonanza Aegis
                  </option>
                </select>
              </div>
            </div>
            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement"> Select File Type </h5>
              <div className=" text-center-statement">
                <select
                  className="select-segment-type width-mobile-50-2 text-center-statement"
                  onChange={(e) => {
                    setFileType(e.target.value);
                  }}
                >
                  <option value="0" key="0">
                  EXCEL
                  </option>
                  <option value="1" key="1">
                  PDF
                  </option>
                </select>
              </div>
            </div>
            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement"> Select Report Type </h5>
              <div className="text-center-statement">
                <select
                  className="select-segment-type width-mobile-50-2 text-center-statement"
                  onChange={(e) => {
                    setReportType(e.target.value);
                  }}
                >
                  <option value="1" key="1">
                  Holding Report
                  </option>
                  <option value="3" key="3">
                  Investment Summary
                  </option>
                  <option value="4" key="4">
                  Transaction Summary
                  </option>
                </select>
              </div>
            </div>
            <div className="d-flex align-items-end mobile-display-block">
              <div className="input-group-width-50 me-4 mb-2">
                <h5 className="text-10 text-center-statement"> From </h5>
                <div className="d-flex">
                  <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date text-center-statement"
                    options={{
                      //mode: "range",
                      dateFormat: "d M, Y",
                      maxDate: new Date(),
                      enable: [
                        function (date) {
                          return (
                            (date >= startDate1)
                          );
                        },
                      ],
                    }}
                    value={[startDate1]}
                    onChange={(date) => {
                      //(date);
                      setStartDate1(date[0]);
                    }}
                  />
                  {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
                </div>
              </div>

              <div className="input-group-width-50 me-4 mb-2">
                <h5 className="text-10 text-center-statement"> To</h5>
                <div className="d-flex">
                  <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date text-center-statement"
                    options={{
                      //mode: "range",
                      dateFormat: "d M, Y",
                      maxDate: new Date(),
                      enable: [
                        function (date) {
                          return (
                            (date >= startDate1)
                          );
                        },
                      ],
                    }}
                    value={[endDate1]}
                    onChange={(date) => {
                      // //console.log(date);
                      setEndDate1(date[0]);
                    }}
                  />
                  {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
                </div>
              </div>
              <div className="statement-search">
              <button
                type="button"
                className="text-4 mb-2 btn btn-primary margin-overview-button1"
                style={{ borderRadius: "5px", height: "50%" }}
                onClick={DateFilter}
              >
                Download
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      {downloadReport !== "" && <div className="my-5">
                <h4 className="text-center">Your PMS is not active</h4>
                <h4 className="text-center">Click <a href="mailto:support@bigul.co" target="_blank">here</a> for our Wealth Manager.</h4>
      </div>}

    </React.Fragment>
  );
};

export default PMS;
