import React, { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import * as moment from "moment";
//action
import { getSummaryTable } from "../../store/algoSummaryTable/action";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";
import Loader1 from "../../Components/Common/Loader1";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const Summary = () => {
  const dispatch = useDispatch();
  const startDate = new Date();
  const [selectDate, setSelectDate] = useState({ date: [startDate] });
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const { customers, isCustomerCreated, isCustomerSuccess, error } =
    useSelector((state) => ({
      customers: state.Ecommerce.customers,
      isCustomerCreated: state.Ecommerce.isCustomerCreated,
      isCustomerSuccess: state.Ecommerce.isCustomerSuccess,
      error: state.Ecommerce.error,
    }));
  const { dataTable } = useSelector((state) => ({
    dataTable: state.SummaryTable.summaryTableData,
  }));
  //let dollarIndianLocale = Intl.NumberFormat('en-IN');
  let dollarIndianLocale = new Intl.NumberFormat("en-IN", {
    //style: 'currency',
    currency: "INR",
  });
  // //console.log(selectDate);
  // //console.log(dataTable);
  const terminalProfit = {};
  terminalProfit.planName = !isEmpty(dataTable) && "Terminal Profit";
  terminalProfit.ftd =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.terminal_profit_FTD);
  terminalProfit.mtd =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.terminal_profit_MTD);
  terminalProfit.ytd =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.terminal_profit_YTD);
  terminalProfit.sinceInception =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.terminal_profit_Since_Inception);

  const tax = {};
  tax.planName = !isEmpty(dataTable) && "Tax (STT+SD+SGT)";
  tax.ftd = !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.tax_FTD);
  tax.mtd = !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.tax_MTD);
  tax.ytd = !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.tax_YTD);
  tax.sinceInception =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.tax_Since_Inception);

  const charge = {};
  charge.planName = !isEmpty(dataTable) && "Charge (E TC+TO+CM+SF)";
  charge.ftd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.Charges_FTD);
  charge.mtd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.Charges_MTD);
  charge.ytd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.Charges_YTD);
  charge.sinceInception =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.Charges_Since_Inception);

  const brokerage = {};
  brokerage.planName = !isEmpty(dataTable) && "Brokerage";
  brokerage.ftd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.brokarage_FTD);
  brokerage.mtd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.brokarage_MTD);
  brokerage.ytd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.brokarage_YTD);
  brokerage.sinceInception =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.brokarage_YTD_Since_Inception);

  const netMTM = {};
  netMTM.planName = !isEmpty(dataTable) && "Net MTM";
  netMTM.ftd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.net_mtm_FTD);
  netMTM.mtd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.net_mtm_MTD);
  netMTM.ytd =
    !isEmpty(dataTable) && dollarIndianLocale.format(dataTable.net_mtm_YTD);
  netMTM.sinceInception =
    !isEmpty(dataTable) &&
    dollarIndianLocale.format(dataTable.net_mtm_Since_Inception);

  const myTableData = [];
  myTableData.push(terminalProfit);
  myTableData.push(tax);
  myTableData.push(charge);
  myTableData.push(brokerage);
  myTableData.push(netMTM);
  ////console.log(myTableData);
  useEffect(() => {
    dispatch(
      getSummaryTable({
        client_code: client_code,
        date: dayjs(selectDate.date[0]).format("DD-MM-YYYY"),
      })
    );
  }, [dispatch, client_token, client_code, selectDate]);

  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState([]);

  // Delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);

  let t = dollarIndianLocale.format(5991.307574931532);
  ////console.log(t);
  const summaryData = [
    {
      planName: "Terminal Profit",
      ftd: "-555.50",
      mtd: "13.366.50",
      ytd: "13366.50",
      sinceInception: "13366.50",
    },
    {
      planName: "Tax (STT+SD+SGT)",
      ftd: "594.73",
      mtd: "899.52",
      ytd: "899.52",
      sinceInception: "899.52",
    },
    {
      planName: "Charge (E TC+TO+CM+SF)",
      ftd: "588.35",
      mtd: "881.13",
      ytd: "881.13",
      sinceInception: "881.13",
    },
    {
      planName: "Brokerage",
      ftd: "1,144.00",
      mtd: "1,744.00",
      ytd: "1,744.00",
      sinceInception: "1,744.00",
    },
    {
      planName: "Net MTM",
      ftd: "-2,880.58",
      mtd: "9,841.85",
      ytd: "9,841.85",
      sinceInception: "9,841.85",
    },
  ];

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const customermocalstatus = [
    {
      options: [
        { label: "Status", value: "Status" },
        { label: "Active", value: "Active" },
        { label: "Block", value: "Block" },
      ],
    },
  ];

  // Delete Data
  const onClickDelete = (customer) => {
    setCustomer(customer);
    setDeleteModal(true);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customer: (customer && customer.customer) || "",
      email: (customer && customer.email) || "",
      phone: (customer && customer.phone) || "",
      date: (customer && customer.date) || "",
      status: (customer && customer.status) || "",
    },
    validationSchema: Yup.object({
      customer: Yup.string().required("Please Enter Customer Name"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Phone"),
      status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCustomer = {
          _id: customer ? customer._id : 0,
          customer: values.customer,
          email: values.email,
          phone: values.phone,
          date: date,
          status: values.status,
        };
        // update customer
        dispatch(onUpdateCustomer(updateCustomer));
        validation.resetForm();
      } else {
        const newCustomer = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          customer: values["customer"],
          email: values["email"],
          phone: values["phone"],
          date: date,
          status: values["status"],
        };
        // save new customer
        dispatch(onAddNewCustomer(newCustomer));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Delete Data
  const handleDeleteCustomer = () => {
    if (customer) {
      dispatch(onDeleteCustomer(customer._id));
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleCustomerClick = useCallback(
    (arg) => {
      const customer = arg;

      setCustomer({
        _id: customer._id,
        customer: customer.customer,
        email: customer.email,
        phone: customer.phone,
        date: customer.date,
        status: customer.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    if (customers && !customers.length) {
      dispatch(onGetCustomers());
    }
  }, [dispatch, customers]);

  useEffect(() => {
    setCustomer(customers);
  }, [customers]);

  useEffect(() => {
    if (!isEmpty(customers)) {
      setCustomer(customers);
      setIsEdit(false);
    }
  }, [customers]);

  // Add Data
  const handleCustomerClicks = () => {
    setCustomer("");
    setIsEdit(false);
    toggle();
  };

  // Node API
  // useEffect(() => {
  //   if (isCustomerCreated) {
  //     setCustomer(null);
  //     dispatch(onGetCustomers());
  //   }
  // }, [
  //   dispatch,
  //   isCustomerCreated,
  // ]);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".customerCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteCustomer(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    ////console.log("sds");
    const ele = document.querySelectorAll(".customerCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Displat Delete Button
  const [dele, setDele] = useState(0);
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       className="form-check-input"
      //       id="checkBoxAll"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="customerCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      // {
      //   Header: "#",
      //   accessor: "id",
      //   hiddenColumns: true,
      //   Cell: (cellProps) => {
      //         return (
      //           <input
      //             type="checkbox"
      //             className="customerCheckBox form-check-input"
      //             value={cellProps.row.original._id}
      //             onChange={() => deleteCheckbox()}
      //           />
      //         );
      //       },
      // },
      // { //TODO
      //   Header: "#",
      //   Cell: (cell) => {
      //     return <input type="checkbox" className="productCheckBox form-check-input" value={cell.row.original._id} onClick={() => displayDelete()} />;
      //   },
      // },
      // {
      //   Header: "#",
      //   //accessor: "planName",
      //   filterable: false,
      // },
      {
        Header: "Summary",
        accessor: "planName",
        filterable: true,
      },
      {
        Header: "FTD",
        accessor: "ftd",
        filterable: true,
      },
      {
        Header: "MTD",
        accessor: "mtd",
        filterable: true,
      },
      {
        Header: "YTD",
        accessor: "ytd",
        filterable: true,
      },
      {
        Header: "Since Inception",
        accessor: "sinceInception",
        filterable: true,
      },
    ],
    [handleCustomerClick, checkedAll]
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  const downloadHandler = () => {
    window.open(
      `https://payment.bigul.co/api/algo/export-dump/${client_code}`,
      "_blank"
    );
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  return (
    <React.Fragment>
      <Col lg={12} className="mt-3">
        <Card id="customerList">
          <CardHeader className="border-0 p-0">
            <Row className="g-4 align-items-center px-4 pt-3">
              {/* <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0 text-6">Summary</h5>
                </div>
              </div>
              <div className="col-sm-auto">
                <div className="d-flex gap-1 flex-wrap">
                  <button
                    type="button"
                    className="btn btn-info w-100 summary-table-date background-color3"
                  >
                    {" "}
                    <i className="ri-download-2-line me-2 align-bottom"></i>
                    Download Report
                  </button>{" "}
                </div>
              </div> */}
              <Col xs={12} className="mb-2 spacing-remove-bottom spacing-top-m">
                <div className="d-flex align-items-center justify-content-between mt-1 mb-2">
                  <div>
                    <h4 className="card-title mb-0 flex-grow-1 text-6 text-color1">
                      Summary
                    </h4>
                  </div>
                  {dataTable.success === true && myTableData.length > 0 ? (
                    <div className="page-title-right">
                      <button
                        type="button"
                        className="btn btn-success w-100 summary-table-date"
                        onClick={downloadHandler}
                      >
                        {" "}
                        <i className="ri-download-2-line me-2 align-bottom"></i>
                        Download Report
                      </button>{" "}
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success  summary-table-date"
                      onClick={downloadHandler}
                      disabled
                    >
                      {" "}
                      <i className="ri-download-2-line me-2 align-bottom"></i>
                      Download Report
                    </button>
                  )}
                </div>
                <div className="input-group input-group-width-25">
                  <Flatpickr
                    placeholder="Select date"
                    id="exampleInputdate"
                    className="form-control summary-table-date"
                    options={{
                      altFormat: "F j, Y",
                      dateFormat: "d-m-y",
                    }}
                    value={selectDate.date}
                    onChange={(date) => {
                      setSelectDate({ date: date });
                    }}
                  />
                  <Label htmlFor="exampleInputdate" className="form-label">
                    <span
                      className="input-group-text summary-table-date px-2 py-0"
                      id="basic-addon1"
                    >
                      <i className="ri-calendar-2-line fs-4 text-color1"></i>
                    </span>
                  </Label>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className="card-body py-2 px-0">
            <div>
              {summaryData.success === true && myTableData.length > 0 ? (
                <TableContainer2
                  columns={columns}
                  data={myTableData || []}
                  isGlobalFilter={true}
                  isAlgoSummary={true}
                  customPageSize={10}
                  //className="custom-header-css"
                  theadClass="summary-table-header"
                  tableClass="text-center"
                  //thClass="summary-table-checkbox "
                  //thClass="summary-table-th"
                  tableTrClasses='summary-table-tr px-4'
                  handleCustomerClick={handleCustomerClicks}
                  //isCustomerFilter={true}
                  //SearchPlaceholder='Search for customer, email, phone, status or something...'
                />
              ) : (
                // <Loader error={error} />
                <Loader1 error={error} />
              )}
            </div>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Summary;
