import React, { useState, useEffect } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap";
import { isEmpty } from "lodash";
//SimpleBar
import SimpleBar from "simplebar-react";
//redux
import { useSelector, useDispatch } from "react-redux";

//action
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import { getCart } from "../../store/cart/action";
import Loader from "../../Components/Common/Loader";
import Select from "react-select";
import classnames from "classnames";
import { orderSummary } from "../../common/data/ecommerce";
import { Link } from "react-router-dom";
import active from "../../assets/images/logo/active.png";
import compleate from "../../assets/images/logo/compleate.png";
import ProductDetails from "./ProductDetails";
import PaymentInfo from "./PaymentInfo";

const EcommerceCheckout = (props) => {
  const { generatedKey, encryptKey, data, data2, data3 } = props;
  console.log(data3,'data3');
  //(data2)
  // const dispatch = useDispatch();
  // const client_code = localStorage.getItem("client_id");
  // const client_token = localStorage.getItem("client_token");
  // useEffect(() => {
  //   dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
  //   dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
  //   dispatch(getCart({ client_code: client_code, client_token: client_token }));
  // }, [dispatch, client_code, client_token]);

  // const { availableMargin } = useSelector((state) => ({
  //   availableMargin: state.MarginOverview,
  // }));
  // const { Cart } = useSelector((state) => ({
  //   Cart: state.Cart.Cart,
  // }));

  // const { Checkout } = useSelector((state) => ({
  //   Checkout: state.PaymentDetails.PaymentDetails,
  // }));
  // ////console.log(Checkout);
  // const [selectedCountry, setselectedCountry] = useState(null);
  // const [selectedState, setselectedState] = useState(null);
  // const [activeTab, setactiveTab] = useState(3);
  // const [passedSteps, setPassedSteps] = useState([3]);
  // const [modal, setModal] = useState(false);
  // const [deletemodal, setDeleteModal] = useState(false);

  // const values = [];
  // Cart.length > 0 &&
  //   Cart.forEach((element) => {
  //     if (
  //       element.feature_or_plan === "Plan" &&
  //       element.discounted_price !== ""
  //     ) {
  //       const value = +element.pre_discount;
  //       values.push(value);
  //     } else if (element.amount !== "") {
  //       const value = +element.amount;
  //       values.push(value);
  //     } else {
  //       const value = +element.amount;
  //       values.push(value);
  //     }
  //   });

  // let discountValues = 0;
  // Cart.length > 0 &&
  //   Cart.forEach((element) => {
  //     if (
  //       element.feature_or_plan === "Plan" &&
  //       element.discounted_price !== ""
  //     ) {
  //       const value = +element.discount;
  //       const value2 = +element.pre_discount * (value / 100);
  //       discountValues = value2;
  //     }
  //   });

  // const subTotal = values.reduce(function (acc, val) {
  //   return acc + val;
  // }, 0);

  // // //console.log(subTotal)
  // // //console.log(discountValues);
  // const total = subTotal - discountValues;
  // ////console.log(total);
  // const taxCalculate = total * (18 / 100);
  // ////console.log(taxCalculate);
  // const finalTotal = total + taxCalculate;
  // ////console.log(finalTotal)

  // const toggledeletemodal = () => {
  //   setDeleteModal(!deletemodal);
  // };

  // const togglemodal = () => {
  //   setModal(!modal);
  // };

  // function handleSelectCountry(selectedCountry) {
  //   setselectedCountry(selectedCountry);
  // }

  // function handleSelectState(selectedState) {
  //   setselectedState(selectedState);
  // }

  // function toggleTab(tab) {
  //   if (activeTab !== tab) {
  //     var modifiedSteps = [...passedSteps, tab];

  //     if (tab >= 1 && tab <= 4) {
  //       setactiveTab(tab);
  //       setPassedSteps(modifiedSteps);
  //     }
  //   }
  // }

  //const total = Checkout !== null ? Checkout.gst + Checkout.amount : 0;

  // if (Checkout.length === 0) {
  //   return <Loader />;
  // }

  return (
    <React.Fragment>
      <Col>
        <Card className="m-0">
          <CardBody className="checkout-tab p-4">
            <div className="text-center pt-0 pb-3">
              {/* <img className="order-image" src={compleate} /> */}
              <h5 className="text-12 text-color5 mb-3 text-122">
              Congratulations! Your order has been placed.
              </h5>
              <p className="text-muted mb-4 margin-mobile-row">
                Your subscription will be activated within 24 hours
              </p>
              <div className="px-5 padding-buttom-remove3">
                <ProductDetails data={data3} generatedKey={generatedKey} encryptKey={encryptKey} />
              </div>
              <h3 className="text-9 text-9-1 text-color5 mb-3">
                Amount Paid: <span className="text-color7 text-9 text-9-1 ">₹{data2.amount}</span> 
              
              { data2.payment_type == 'ledger' ? (
                ''
              ) : (
                <>
                &nbsp;| Bigul Order ID: <span className="text-color7 text-9 text-9-1 ">{data2.orderId}</span> | Unique Transaction ID: <span className="text-color7 text-9 text-9-1">{data2.transaction_id}</span>
                </>
              )} 
              </h3>

              {/* <h3>Amount paid: {data2.amount} | Bigul order ID: {data2.orderId} | Unique transaction ID: {data2.upiTransactionId}</h3> */}
              {/* <h3 className="fw-semibold mb-4">
                Order ID:{" "}
                <a
                  href="apps-ecommerce-order-details"
                  className="text-decoration-underline"
                >
                  VZ2451
                </a>
              </h3> */}
              <p className="text-muted mb-3">
                For any queries, you can reach us at{" "}
                <a href="mailto:support@bigul.co" target="_blank">support@bigul.co</a> or contact us on{" "}
                <a href="tel:+918045025533">08045025533</a>
              </p>

              <Link
                to='/dashboard'
                className="btn btn-primary text-4  mb-3 margin-overview-button1"
                style={{ borderRadius: "5px" }}
              >
                Back to Dashboard
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>

      {/* <Col xl={6}>
        <Card>
          <CardHeader>
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">Order Summary</h5>
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <ProductDetails data={data} />
          </CardBody>
        </Card>
      </Col> */}
    </React.Fragment>
  );
};

export default EcommerceCheckout;
