import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_LIST, GET_STATUS } from "./actionType";



//Include Both Helper File with needed methods
import { AlgoStatusGet, getAlgoList, getAlgoTranstionList } from "../../helpers/fakebackend_helper";
import { AlgoGetListApiResponseError, AlgoGetListSuccess, AlgoGetStatusApiResponseError, AlgoGetStatusSuccess } from "./action";

// Trade Book Cash
function* getAlgoStatu( {payload:data}) {
  try {
    const response = yield call(AlgoStatusGet, data);
    yield put(AlgoGetStatusSuccess(GET_STATUS, response));
  } catch (error) {
    yield put(AlgoGetStatusApiResponseError(GET_STATUS, error));
  }
}

export function* watchGetStatusAlgoSaga() {
  yield takeEvery(GET_STATUS, getAlgoStatu);
}

function* getAlgoStatuAlgoSaga() {
  yield all([
    fork(watchGetStatusAlgoSaga)
  ]);
}

export default getAlgoStatuAlgoSaga;
