import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_PAYMENT_DETAILS } from "./actionType";

import {
  PaymentDetailsApiResponseSuccess,
  PaymentDetailsApiResponseError,
} from "./action";

//Include Both Helper File with needed methods
import { PaymentDetailsApi } from "../../helpers/fakebackend_helper";

function* getPaymentDetails(data) {
    ////console.log(data);
  try {
    const response = yield call(PaymentDetailsApi,data.data);
    yield put(PaymentDetailsApiResponseSuccess(GET_PAYMENT_DETAILS, response));
  } catch (error) {
    yield put(PaymentDetailsApiResponseError(GET_PAYMENT_DETAILS, error));
  }
}

export function* watchGetPaymentDetails() {
  yield takeEvery(GET_PAYMENT_DETAILS, getPaymentDetails);
}

// // add to cart
// function* onAddItemToCart(data) {
//   try {
//       const response = yield call(addItemToCartApi(data.data));
//       yield put(addToCartApiResponseSuccess(response));
//       toast.success("Item Added Successfully", { autoClose: 3000 });
//   } catch (error) {
//       yield put(addToCartApiResponseError(error));
//       toast.error("Item Added Failed", { autoClose: 3000 });
//   }
// }

// export function* watchAddItemToCart() {
//   yield takeEvery(ADD_ITEM_TO_CART, onAddItemToCart);
// }

// // add to cart
// function* onRemoveItemToCart(data) {
//   try {
//       const response = yield call(removeItemToCartApi(data.data));
//       yield put(removeToCartApiResponseSuccess(response));
//       toast.success("Item Remove Successfully", { autoClose: 3000 });
//   } catch (error) {
//       yield put(removeToCartApiResponseError(error));
//       toast.error("Item Remove Failed", { autoClose: 3000 });
//   }
// }

// export function* watchRemoveItemToCart() {
//   yield takeEvery(REMOVE_ITEM_TO_CART, onRemoveItemToCart);
// }

function* PaymentDetailsSaga() {
  yield all([
    fork(watchGetPaymentDetails),
    // fork(watchAddItemToCart),
    // fork(watchRemoveItemToCart)
  ]);
}

export default PaymentDetailsSaga;
