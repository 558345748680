import { VERIFY_OTP_FAIL, VERIFY_OTP_SUCCESS, VERIFY_OTP, VERIFY_OTP_RESET } from "./actionType";

const INIT_STATE = {
  oderDetails: [],
};

const VerifyOtp = (state = INIT_STATE, action) => {
  // //console.log(action.payload);
  // //console.log(action.type);
  switch (action.type) {
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        VerifyOtpStatus: true,
        oderDetails:
          action.payload.data.data !== null
            ? action.payload.data.data
            : [],
      };
    // add to cart
    case VERIFY_OTP_FAIL:
      return {
        ...state,
        oderDetails: [],
      };
      case VERIFY_OTP_RESET: 
      return {
        ...state,
        oderDetails: [],
      }
    default:
      return { ...state };
  }
};

export default VerifyOtp;
