import {
  API_CLIENT_ID_RESPONSE_SUCCESS,
  API_CLIENT_ID_ERROR,
  GET_CLIENT_ID
} from "./actionType";

const INIT_STATE = {
  ClientID: {}
};

const ClientID = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_CLIENT_ID_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CLIENT_ID:
          return {
            ...state,
            ClientID: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_CLIENT_ID_ERROR:
      switch (action.payload.actionType) {
        case GET_CLIENT_ID:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_CLIENT_ID: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default ClientID;
