import {
  GET_MY_FEATURE_SUGGESTED_PLAN,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
  myFeatureSuggestedPlan: [],
};

const MyFeatureSuggestedPlan = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MY_FEATURE_SUGGESTED_PLAN:
          return {
            ...state,
            myFeatureSuggestedPlan: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_MY_FEATURE_SUGGESTED_PLAN:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_MY_FEATURE_SUGGESTED_PLAN: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default MyFeatureSuggestedPlan;
