import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

// Notifications
import Notifications from "./notification/saga";

// E Learning
import ELearning from "./eLearning/saga";

// E Learning Category
import ELearningCategory from "./eLearningCategory/saga";

// Profile Details
import ProfileDetailsSega from "./Profile/saga";

// Bigul Highlights
import HighlightsSega from "./bigulHighlights/saga";

// Dashboard Suggested Algo
import SuggestedAlgoSega from "./dashboardSuggestedAlgos/saga";

// Dashboard Add On
import AddOnSega from "./addOn/saga";

// Dashboard Feature And Plan
import FeatureAndPlanSega from "./featureAndPlan/saga";

// My Plan
import MyPlanSega from "./myPlan/saga";

// My Plan Duration
import MyPlanDurationSega from "./myPlanDuration/saga";

// My Feature Plan
import MyFeaturePlanSega from "./myFeaturePlan/saga";

// My Feature Suggested Plan
import MyFeatureSuggestedPlanSega from "./myFeatureSuggestedPlan/saga";

// Generate Key
import GenerateKeySega from "./generateKey/saga";

//Encrypt Key 
import EncryptKeySega from "./encryptKey/saga";

// Statements
import StatementsSega from "./statements/saga";

// MarginOverview
import MarginOverview from "./marginOverview/saga";

//Investment Summary
import InvestmentSummary from './investedSummary/saga';

//ClientID
import ClientID from './eSignClientId/saga';

// MyInvestedAlgos
import MyInvestedAlgos from './myInvestedAlgo/saga';

// Sa
import Sa from './sa/saga';

// pms
import Pms from './pms/saga';

// Mf
import Mf from './mf/saga';

// Mf
import Collateral from './Collateral/saga';


// Algo Summary Table
import SummaryTable from './algoSummaryTable/saga';

// Cart
import Cart from './cart/saga';

// Payment Details
import PaymentDetailsSaga from "./checkOut/saga";

// Plan History table
import PlanHistorySaga from "./planHistoryTable/saga";

//VERIFY_OTP
import VerifyOtpSaga  from "./VerifyOTP/saga";


//calendar
import calendarSaga from "./calendar/saga";
//chat
import chatSaga from "./chat/saga";
//ecommerce
import ecommerceSaga from "./ecommerce/saga";

//Project
import projectSaga from "./projects/saga";
// Task
import taskSaga from "./tasks/saga";
// Crypto
import cryptoSaga from "./crypto/saga";
//TicketsList
import ticketsSaga from "./tickets/saga";

//crm
import crmSaga from "./crm/saga";
//invoice
import invoiceSaga from "./invoice/saga";
//mailbox
import mailboxSaga from "./mailbox/saga";

// Dashboard Analytics
import dashboardAnalyticsSaga from "./dashboardAnalytics/saga";

// Dashboard CRM
import dashboardCrmSaga from "./dashboardCRM/saga";

// Dashboard Ecommerce
import dashboardEcommerceSaga from "./dashboardEcommerce/saga";

// Dashboard Crypto
import dashboardCryptoSaga from "./dashboardCrypto/saga";

// Dashboard Project
import dashboardProjectSaga from "./dashboardProject/saga";

// Dashboard NFT
import dashboardNFTSaga from "./dashboardNFT/saga";

// Pages > Team
import teamSaga from "./team/saga";

// File Manager
import fileManager from "./fileManager/saga";

// To do
import todos from "./todos/saga"

//Job
import jobSaga from "./job/saga";

//API Key
import APIKeysaga from "./apikey/saga";

// 
import { watchGetTransionSaga } from "./algoGetTranstions/saga";
import { watchGetListAlgoSaga } from "./algoGetList/saga";
// referlink saga
import ReferralLinkSaga from "./ReferralLink/saga";
import TradeReferSummarySaga from "./MyTradeReferSummary/saga";
import GenerateApiKeySaga from "./GenerateApiKey/saga";
import CodeEncryptionSaga from "./CodeEncryption/saga";
import TotalReferredSaga from "./TotalReferred/saga";
import AddReferralNumberSaga from "./AddReferal/saga";
import AllUnconvertedLeadsSaga from "./AllUnconvertedLeads/saga";
import { watchGetStatusAlgoSaga} from "./AlgoStatusCheck/saga";
export default function* rootSaga() {
  yield all([
    //public
    fork(GenerateKeySega),
    fork(EncryptKeySega),
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(cryptoSaga),
    fork(ticketsSaga),
    fork(calendarSaga),
    fork(ecommerceSaga),
    fork(crmSaga),
    fork(invoiceSaga),
    fork(mailboxSaga),
    fork(dashboardAnalyticsSaga),
    fork(dashboardCrmSaga),
    fork(dashboardEcommerceSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardProjectSaga),
    fork(dashboardNFTSaga),
    fork(teamSaga),
    fork(fileManager),
    fork(todos),
    fork(jobSaga),
    fork(APIKeysaga),
    fork(Notifications),
    fork(ELearning),
    fork(ProfileDetailsSega),
    fork(HighlightsSega),
    fork(SuggestedAlgoSega),
    fork(AddOnSega),
    fork(FeatureAndPlanSega),
    fork(MyPlanSega),
    fork(MyPlanDurationSega),
    fork(MyFeaturePlanSega),
    fork(MyFeatureSuggestedPlanSega),
    fork(StatementsSega),
    fork(MarginOverview),
    fork(InvestmentSummary),
    fork(MyInvestedAlgos),
    fork(Sa),
    fork(Pms),
    fork(Cart),
    fork(PaymentDetailsSaga),
    fork(SummaryTable),
    fork(PlanHistorySaga),
    fork(ELearningCategory),
    fork(VerifyOtpSaga),
    fork(Mf),
    fork(Collateral),
    fork(ClientID),
    fork(watchGetTransionSaga),
    fork(watchGetListAlgoSaga),
      //refer and eran
      fork(watchGetListAlgoSaga),
      fork(ReferralLinkSaga),
      fork(TradeReferSummarySaga),
      fork(GenerateApiKeySaga),
      fork(CodeEncryptionSaga),
      fork(TotalReferredSaga),
      fork(AddReferralNumberSaga),
      fork(AllUnconvertedLeadsSaga),
      fork(watchGetStatusAlgoSaga),
  ]);
}
