import React, { useState, useEffect, useMemo } from "react";

import { Label } from "reactstrap";
import dayjs from "dayjs";

import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";

//Import Breadcrumb
import Loader1 from "../../Components/Common/Loader1";
//action
import { getLedger } from "../../store/statements/action";
import { downloadMarginOverviewApi } from "../../helpers/fakebackend_helper";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer5 from "../../Components/CustomComponents/TableContainer5";

const ProductDetails = (props) => {
  const { data } = props;
  const [isDownload, setIsDownload] = useState(false);
  const [file, setFile] = useState([]);
  const { generatedKey, encryptKey } = props;
  const dispatch = useDispatch();
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });

  const { ledger } = useSelector((state) => ({
    ledger: state.Statements.ledger,
  }));
 // //console.log(data);
  const TradeBookCash = [
    {
      name: "Julie Jane",
      email: "jane.julie@mail.com",
      date: "24/01/2023",
      earning: "+300",
    },
    {
      name: "Julie Jane",
      email: "jane.julie@mail.com",
      date: "24/01/2023",
      earning: "+300",
    },
    {
      name: "Julie Jane",
      email: "jane.julie@mail.com",
      date: "24/01/2023",
      earning: "+300",
    },
  ];

  function removeValue(value, index, arr) {
    // //console.log(value)
    // //console.log(index)
    // //console.log(arr)
    // If the value at the current array index matches the specified value (2)
    if (
      value.LNarrat === "Opening Balance       " ||
      value.LNarrat === "Total : "
    ) {
      // Removes the value from the original array
      arr.splice(index, 1);
      return true;
    }
    return false;
  }

  // Pass the removeValue function into the filter function to return the specified value
  const x = ledger.filter(removeValue);
  //  ////console.log(ledger);
  useEffect(() => {
    dispatch(
      getLedger({
        apiKey: generatedKey,
        code: encryptKey,
        startDate: dayjs(selectDate.date[0]).format("YYYY-MM-DD"),
        endDate: dayjs(selectDate.date[1]).format("YYYY-MM-DD"),
      })
    );
  }, [dispatch, selectDate]);

  const downloadHandler = () => {
    downloadMarginOverviewApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(selectDate.date[0]).format("YYYY-MM-DD"),
      endDate: dayjs(selectDate.date[1]).format("YYYY-MM-DD"),
    })
      .then((response) => {
        setFile(response.finResult);
        setIsDownload(true);
        ////console.log(response);
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  const myArray = [];
  for (let i = 0; i < file.length; i += 1) {
    const childArray = file[i];
    const obj = {
      Name: childArray.LNarrat.replace("#", ""),
      Date: childArray.Ldate,
      Amount: childArray.Damount,
      Balance: childArray.Camount,
    };
    myArray.push(obj);
  }

  const userHeader = ["Name", "Date", "Amount", "Balance"];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `Ledger`);
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const AmountFormatter = (data) => {
    switch (true) {
      case data.value > 0:
        return <div className="text-color6">{data.value}</div>;
      case data.value < 0:
        return <div className="text-color11">{data.value}</div>;
      default:
        return <div>{data.value}</div>;
    }
  };

  const downloadPdfHandler = (data) => {
    //console.log(data.value);
    window.open(
      `${data.value}`,
      "_blank"
    );
  };

  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   accessor: "id",
      //   filterable: true,
      // },,
      {
        Header: "Specifics",
        accessor: "plan_name",
        filterable: true,
      },
      {
        Header: "Activation Date",
        accessor: "activation_date",
        filterable: true,
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY")
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        filterable: true,
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY")
        },
      },
      // {
      //   Header: "Amount Paid",
      //   accessor: "email",
      //   filterable: true,
      //   Cell: AmountFormatter,
      // },
      {
        Header: "PDF",
        accessor: "pdfpath",
        filterable: true,
        Cell: (cell) => {
          return (
            <button
              type="button"
              style={{
                border: "none",
                background: "none",
                color: "#5367fc",
                fontSize: "25px",
              }}
              onClick={() => {
                downloadPdfHandler(cell);
              }}
            >
              {" "}
              <i className="bx bxs-file-pdf"></i>
            </button>
          );
        },
      },
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  return (
    <React.Fragment>
      {data.length ? (
        <TableContainer5
          columns={columns}
          data={data || []}
          isGlobalFilter={true}
          isAddUserList={false}
          customPageSize={8}
          divClass="table-responsive table-card mb-5"
          tableClass="align-middle table-nowrap text-center"
          theadClass="table-light text-muted text-uppercase fs-13"
          // handleOrderClick={handleCustomerClicks}
          isOrderFilter={true}
          tableTrClasses="summary-table-tr px-4"
          SearchPlaceholder="Search for order ID, customer, order status or something..."
        />
      ) : (
        <Loader1 />
      )}
    </React.Fragment>
  );
};

export default ProductDetails;
