import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
// TODO Ui and design and functions.
const ConfirmChanges1 = (props) => {
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);

  const modal = {
    CChange1: false,
    CChange2: true,
  };

  const tog_signUpModals = () => {
    props.tog(false);
  };

  const submitHandler = () => {
    ////console.log("submit");
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(modal);
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom">
          E-Sign Required
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <form onSubmit={submitHandler()}>
          <div className="d-flex mb-2">
            <div className="flex-grow-1 ms-2 text-muted text-11 px-3 pt-1">
              *Note: Setting bank preference as Primary requires E-Sign
              Verification.
            </div>
          </div>
          <div className="mb-3 form-check">
            <label
              className="form-check-label text-15 text-color1"
              htmlFor="checkTerms"
            >
              Setting bank preference as Primary requires E-Sign In
              Verification. Setting bank preference as Primary requires E-Sign
              In Verification. Setting bank preference as Primary requires
              E-Sign In Verification. Setting bank preference as Primary
              requires E-Sign In Verification. Setting bank preference as
              Primary requires E-Sign In Verification.
            </label>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary nominee-page-button-save my-3"
              onClick={(e) => onClickHandler(e)}
            >
              Continue to E-Sign
            </button>
          </div>
        </form>
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default ConfirmChanges1;
