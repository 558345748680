import {
  GET_ENCRYPT_KEY,
  API_ENCRYPT_RESPONSE_SUCCESS,
  API_ENCRYPT_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
  encryptKey: '',
};

const EncryptKey = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_ENCRYPT_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ENCRYPT_KEY:
          return {
            ...state,
            encryptKey: action.payload.data.EncryptclcodeResult,
          };
        default:
          return { ...state };
      }
    case API_ENCRYPT_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_ENCRYPT_KEY:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_ENCRYPT_KEY: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default EncryptKey;
