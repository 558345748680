import { API_SUMMARY_RESPONSE_ERROR } from "../algoSummaryTable/actionType";
import { API_ALGO_GET_LIST_RESPONSE_ERROR, API_ALGO_GET_LIST_SUCCESS,API_ALGO_GET_STATUS_RESPONSE_ERROR,API_ALGO_GET_STATUS_SUCCESS,GET_LIST, GET_STATUS } from "./actionType";

  
  // SummaryTable
  export const AlgoGetStatusSuccess = (actionType, data) => ({
    type: API_ALGO_GET_STATUS_SUCCESS,
    payload: { actionType, data },
  });
  // SummaryTable error
  export const AlgoGetStatusApiResponseError = (actionType, error) => ({
    type: API_ALGO_GET_STATUS_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  // SummaryTable
  export const getstatus = (data) => ({
    type: GET_STATUS,
    payload: data,
  });
  