import {
    API_ALGO_GET_LIST_SUCCESS,
    GET_LIST,
    API_ALGO_GET_LIST_RESPONSE_ERROR,
    GET_STATUS,
    API_ALGO_GET_STATUS_RESPONSE_ERROR,
    API_ALGO_GET_STATUS_SUCCESS
  } from "./actionType";
  
  const INIT_STATE = {
      getAlgoStatus : [],
  };
  const GetList = (state = INIT_STATE, action) => {
    // console.log(">>>>>>>check the data >>>>>>>",action.payload.data);
    switch (action.type) {
      case API_ALGO_GET_STATUS_SUCCESS:
        switch (action.payload.actionType) {
          case GET_STATUS:
            return {
              ...state,
              getAlgoStatus: action.payload.data,
            };
          default:
            return { ...state };
        }
      case API_ALGO_GET_STATUS_RESPONSE_ERROR:
        switch (action.payload.actionType) {
          case GET_STATUS:
            return {
              ...state,
              error: action.payload.error,
            };
          default:
            return { ...state };
        }
      case GET_STATUS: {
        return {
          ...state,
        };
      }
      default:
        return { ...state };
    }
  };
  
  export default GetList;
  