import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import Algo from "../../assets/images/logo/Algo.png";
import Algo1 from "../../assets/images/logo/Algo1.png";
import myalgo_card from "../../assets/images/logo/myalgo_card.png";

const SuggestedAlgos = () => {
  const { MyInvestedAlgos } = useSelector((state) => ({
    MyInvestedAlgos: state.MyInvestedAlgos,
  }));
  // //console.log(MyInvestedAlgos);
  // //console.log(!isEmpty(MyInvestedAlgos.myInvestedAlgos));
  return (
    <React.Fragment>
      <Col xxl={3} xl={3}>
        <Card
          className="background-color1 card-height-90 "
          style={{ borderRadius: "12px", height: '448px' }}
        >
          <CardHeader
            className="align-items-center d-flex border-bottom-dashed margin-overview-button1"
            style={{ height: "65px" }}
          >
            <h4 className="card-title text-10 mb-0 flex-grow-1 text-color3 text-center">
              My Algos
            </h4>
          </CardHeader>

          <CardBody
            className="background-color1"
            style={{
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              height: "257px",
            }}
          >
            <SimpleBar data-simplebar className="mx-n3 px-3">
              <div className="vstack gap-3">
                <div className="d-flex my-2">
                  {!isEmpty(MyInvestedAlgos.myInvestedAlgos) && MyInvestedAlgos.myInvestedAlgos.myalgo && (
                    <div className="avatar-sm flex-shrink-0">
                      <span className="text-primary rounded-circle fs-1">
                        <div className="card-invest-div-logo rounded-circle align-middle background-color2">
                          <img
                            className="card-invest-logo align-middle"
                            src={!isEmpty(MyInvestedAlgos.myInvestedAlgos) && MyInvestedAlgos.myInvestedAlgos.image ?  MyInvestedAlgos.myInvestedAlgos.image :
                              Algo}
                            style={{ width: "45px", height: "45px" }}
                          />
                        </div>
                      </span>
                    </div>
                  )}
                  <div className="flex-grow-1">
                    <button className="text-4 mx-1 text-color3 title-button mb-2">
                      {!isEmpty(MyInvestedAlgos.myInvestedAlgos) &&
                        MyInvestedAlgos.myInvestedAlgos.myalgo}
                    </button>
                    <h6 className="main-header-secondary-page mx-1 text-color2">
                      {!isEmpty(MyInvestedAlgos.myInvestedAlgos) &&
                        MyInvestedAlgos.myInvestedAlgos.myalgo_description}
                    </h6>
                  </div>
                  {/* <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1">
                      <button type="button" className="btn btn-sm text-color2 suggest-algo-algo-hover">
                        <i
                          className="ri-arrow-right-s-line align-bottom "
                          style={{ fontSize: "20px" }}
                        ></i>
                      </button>
                    </div>
                  </div> */}
                </div>
                {/* <div className="d-flex align-items-center my-2">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="text-primary rounded-circle fs-1">
                      <div
                        className="card-invest-div-logo rounded-circle align-middle"
                        style={{ backgroundColor: `rgba(255, 255, 255, 0.15)` }}
                      >
                        <img
                          className="card-invest-logo align-middle"
                          src={Algo1}
                          style={{ width: "45px", height: "45px" }}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="flex-grow-1">
                  <button className="text-4 mx-1 text-color3 title-button">Algo Invest Pro</button>
                    <h6 className="main-header-secondary-page mx-1 text-color2">
                      High end return algo
                    </h6>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1">
                      <button type="button" className="btn btn-sm text-color2 suggest-algo-algo-hover">
                        <i
                          className="ri-arrow-right-s-line align-bottom"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="text-primary rounded-circle fs-1">
                      <div className="card-invest-div-logo rounded-circle align-middle background-color2">
                        <img
                          className="card-invest-logo align-middle"
                          src={Algo}
                          style={{ width: "45px", height: "45px" }}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="flex-grow-1">
                  <button className="text-4 mx-1 text-color3 title-button">Algo Invest Pro</button>
                    <h6 className="main-header-secondary-page mx-1 text-color2">
                      High end return algo
                    </h6>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1">
                      <button type="button" className="btn btn-sm text-color2 suggest-algo-algo-hover">
                        <i
                          className="ri-arrow-right-s-line align-bottom"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </SimpleBar>
          </CardBody>
          <div className="card-myAlgo-div-image">
            <img className="card-my-algos-image" src={myalgo_card} />
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SuggestedAlgos;
