import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_GENERATE_KEY } from "./actionType";

import {
  GenerateKeyApiResponseSuccess,
  GenerateKeyApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getGenerateKeyApi } from "../../helpers/fakebackend_helper";

function* getGenerateKey() {
  try {
    const response = yield call(getGenerateKeyApi);
    yield put(GenerateKeyApiResponseSuccess(GET_GENERATE_KEY, response));
  } catch (error) {
    yield put(GenerateKeyApiResponseError(GET_GENERATE_KEY, error));
  }
}

export function* watchGetGenerateKey() {
  yield takeEvery(GET_GENERATE_KEY, getGenerateKey);
}

function* GenerateKeySaga() {
  yield all([fork(watchGetGenerateKey)]);
}

export default GenerateKeySaga;
