import {
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  GET_INVESTMENT_SUMMARY,
} from "./actionType";

// common success
export const InvestmentSummaryApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const InvestmentSummaryApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getInvestmentSummary = (data) => ({
  type: GET_INVESTMENT_SUMMARY,
  payload: data,
});