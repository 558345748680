import {
  API_SUMMARY_RESPONSE_SUCCESS,
  API_SUMMARY_RESPONSE_ERROR,
  GET_SUMMARY
} from "./actionType";

// SummaryTable
export const SummaryTableApiResponseSuccess = (actionType, data) => ({
  type: API_SUMMARY_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// SummaryTable error
export const SummaryTableApiResponseError = (actionType, error) => ({
  type: API_SUMMARY_RESPONSE_ERROR,
  payload: { actionType, error },
});
// SummaryTable
export const getSummaryTable = (data) => ({
  type: GET_SUMMARY,
  payload: data,
});
