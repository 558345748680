import {
  GET_CODE_ENCRYPTION,
  API_CODE_ENCRYPTION_SUCCESS,
  API_CODE_ENCRYPTION_FAIL,
} from "./actionType";

export const codeEncryptionResponseSuccess = (actionType, data) => ({
  type: API_CODE_ENCRYPTION_SUCCESS,
  payload: { actionType, data },
});

export const codeEncryptionResponseError = (actionType, error) => ({
  type: API_CODE_ENCRYPTION_FAIL,
  payload: { actionType, error },
});

export const getEncryptedCode = (data) => ({
  type: GET_CODE_ENCRYPTION,
  payload: data,
});
