import {
  API_REFERRAL_LINK_SUCCESS,
  API_REFERRAL_LINK_FAIL,
  GET_REFERRAL_LINK,
  API_REFERRAL_NUMBER_SUCCESS,
  API_REFERRAL_NUMBER_FAIL,
  GET_REFERRAL_NUMBER,
  RESET_REFERRAL_STATE,
} from "./actionType";

const INIT_STATE = {
  AddReferalNumber: {},

};

const accountTradeEarn = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_REFERRAL_NUMBER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_REFERRAL_NUMBER:
          return {
            ...state,
            AddReferalNumber:
              action?.payload?.data !== null ? action?.payload?.data : 
              {},
          };
        default:
          return { ...state };
      }
    case API_REFERRAL_NUMBER_FAIL:
      switch (action?.payload?.actionType) {
        case GET_REFERRAL_NUMBER:
          return {
            ...state,
            error: action?.payload?.error,
          };
        default:
          return { ...state };
      }
    case GET_REFERRAL_NUMBER: {
      return {
        ...state,
      };
    }
    case RESET_REFERRAL_STATE:
      const newState = INIT_STATE;
      console.log("Resetting Redux State:", newState);
      return newState;

    default:
      return { ...state };
  }
};

export default accountTradeEarn;
