import {
  API_PMS_RESPONSE_SUCCESS,
  API_PMS_ERROR,
  GET_PMS
} from "./actionType";

const INIT_STATE = {
  pms: {}
};

const Pms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_PMS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PMS:
          return {
            ...state,
            pms: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_PMS_ERROR:
      switch (action.payload.actionType) {
        case GET_PMS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_PMS: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default Pms;
