import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
//Import Breadcrumb
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Label,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import { getPledgeApi } from "../../helpers/fakebackend_helper";
import PledgeModelIndex from "../../Components/CustomComponents/Models/pledge/PledgeModelIndex";

const MyPlansHeader = () => {
  // document.title = "Shopping Cart | Velzon - React Admin & Dashboard Template";
  const [pledgeData, setPledgeData] = useState([]);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [inputQ, setInputQ] = useState([]);
  const [isEditable, setEditable] = useState(true);
  const date = new Date();

  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));

  useEffect(() => {
    if (isCheck?.length === pledgeData?.length && isCheck?.length > 0) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [isCheck,isCheckAll]);

  console.log(
    "ischeck",
    isCheck,
    isCheck.some((i) => i.dpid == "12033500")
  );
  useEffect(() => {
    getPledgeApi({
      apiKey: generatedKey,
      code: encryptKey,
      date: dayjs(date).format("DD-MM-YYYY"),
    })
      .then((response) => {
        console.log(response);
        const dd = response.poamargpledResult.map((el, index) => ({
          ...el,
          checked: false,
          QUANTITYL: el.qty,
          haircutval: ((el.qty * el.market) / 100) * el.nhaircut,
        }));
        setPledgeData(dd);
      })
      .catch((err) => {
        ////console.log(err);
      });
  }, []);

  const handleClick = (e, values) => {
    let newValue = {};

    if (!values.total2) {
      newValue = {
        ...values,
        total2: (values.total / values.quantity) * values.quantity,
      };
    } else {
      newValue = { ...values };
    }
    const { checked } = e.target;
    !isEmpty(newValue) && setIsCheck([...isCheck, newValue]);
    if (!checked) {
      setIsCheck(
        isCheck.filter((item) => {
          return item.name !== values.name;
        })
      );
    }
  };

  const handleSelectAll = (e) => {
    //console.log(isCheckAll)
    setIsCheckAll(!isCheckAll);
    //console.log(isCheckAll)
    setIsCheck(pledgeData.map((li) => li));
    if (isCheckAll) {
      pledgeData.map((el) => el.checked === false);
      setIsCheck([]);
    }
  };

  const SelectedPledge =
    inputQ.length > 0 && isCheckAll === false
      ? inputQ.filter((obj1) => {
          return obj1.checked === true;
        })
      : inputQ.length > 0 && isCheckAll === true
      ? inputQ.filter((obj1) => {
          return obj1;
        })
      : [];
  // console.log(pledgeData);
  // console.log(inputQ);
  // console.log(SelectedPledge);
  const totalValue =
    isCheck.length > 0
      ? isCheck.reduce(function (acc, val) {
          return acc + val.haircutval;
        }, 0)
      : 0;
  // console.log(totalValue);
  const sumall =
    isCheck.length > 0
      ? isCheck
          .map((item) => {
            return item.total2;
          })
          .reduce((prev, curr) => prev + curr, 0)
      : 0;

  let emptyPackageRow = {};

  // //console.log(inputQ.find(item => item.checked === true))

  return (
    <React.Fragment>
      <Col xs={12}>
        <div className="table-responsive table-card">
          {pledgeData.length > 0 ? (
            <Formik
              initialValues={{
                packages: pledgeData ?? [emptyPackageRow],
                CheckAll: false,
              }}
              enableReinitialize
              //validationSchema={ValidationSchema}
              // onSubmit={
              //   (values, errors) => {
              //     delete values.customer
              //     values.uuid = bookingUuid;
              //     ////console.log(values);
              //     let sumOfServices = values.services.length + values.packages.length;
              //     if (sumOfServices > 0) {
              //       dispatch(updateBranchBookingData(values))
              //         .then(() => {
              //           setItemError("");
              //           dispatch(sendSuccess("The booking updated successfully"));
              //         })
              //         .catch((err) => {
              //           dispatch(sendFailure(err.message));
              //           setItemError("Error. Unable to update booking");
              //           //console.log(err);
              //         });
              //     } else {
              //       setItemError(
              //         "At least one service should be added while creating any package"
              //       );
              //     }
              //   }

              //   // saveCustomer(values);
              // }
            >
              {({ values, errors, setFieldValue, touched }) => {
                if (isCheckAll === false) {
                  setInputQ([]);
                }
                ////console.log(values.packages);
                //console.log(values.CheckAll);
                setInputQ(values.packages);
                const d = values.packages.find((el) => el.checked === false);

                if (
                  isCheckAll === false &&
                  values.CheckAll === false &&
                  d === undefined
                ) {
                  //console.log(d);
                  setIsCheckAll(true);
                  //setIsCheck(values.packages.map((li) => li));
                  //handleSelectAll();
                }
                // if (
                //   !isCheckAll &&
                //   SelectedPledge.length === values.packages.length
                // ) {
                //   setIsCheckAll(true);
                //   setIsCheck(values.packages)
                // }
                // //console.log(isCheck);
                return (
                  <Form
                    className="form form-label-right"
                    id="createBookingForm"
                  >
                    <table className="table table-nowrap table-striped-columns mb-0">
                      <thead className="pledge-table-header">
                        <tr>
                          <th scope="col">
                            {" "}
                            <Input
                              type="checkbox"
                              name="CheckAll"
                              id="CheckAll"
                              className="check_box p-2 ms-3"
                              onChange={handleSelectAll}
                              checked={isCheckAll}
                              value={values.CheckAll}
                              // onChange={(e) => {
                              //   setFieldValue(`CheckAll`, true);
                              // setIsCheckAll(!isCheckAll);

                              // setIsCheck(pledgeData.map((li) => li));
                              // if (isCheckAll) {
                              //   pledgeData.map((el) => el.checked === false);
                              //   setIsCheck([]);
                              // }
                              // }}
                              // onBlur={handleBlur}
                            />
                          </th>
                          <th scope="col" className="text-center">
                            Scrip Name
                          </th>
                          <th scope="col" className="text-center">
                            Quantity
                          </th>
                          <th scope="col" className="text-center">
                            Margin Benefit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="packages">
                          {({ push, remove }) => (
                            <>
                              {values.packages.map((item, index, el) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Input
                                        name="check_box"
                                        type="checkbox"
                                        className="p-2 mx-3 check_box"
                                        id={"check_box_1"}
                                        placeholder="Old Email"
                                        value={values.packages[index].checked}
                                        //onChange={handleChange}
                                        //onChange={(e) => {
                                        onChange={(e) => {
                                          ////console.log(isCheckAll);
                                          // if (isCheckAll) {
                                          //   setIsCheckAll(!isCheckAll);
                                          // }
                                          // if(SelectedPledge.length === index + 1) {
                                          //   setIsCheckAll(!isCheckAll);
                                          // }
                                          setFieldValue(
                                            `packages.[${index}].checked`,
                                            e.target.checked
                                          );

                                          isCheck.some(
                                            (i) => i.scripcode == item.scripcode
                                          )
                                            ? setIsCheck(
                                                isCheck.filter(
                                                  (i) =>
                                                    i.scripcode !=
                                                    item?.scripcode
                                                )
                                              )
                                            : setIsCheck((prev) => [
                                                ...prev,
                                                values.packages[index],
                                              ]);

                                          // //console.log(values.packages.length);
                                          // //console.log(SelectedPledge.length);
                                          // if (SelectedPledge.length + 1  === values.packages.length) {
                                          //   setIsCheckAll(!isCheckAll)
                                          // }
                                          //handleClick(e, values);
                                        }}
                                        //onBlur={handleBlur}
                                        //checked={isCheck.includes(values.name)}
                                        checked={
                                          isCheck.some(
                                            (i) =>
                                              i.scripcode === item?.scripcode
                                          )
                                            ? true
                                            : false
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        name={el.scripname}
                                        type="text"
                                        className="nominee-form-input text-color1 text-4"
                                        id="name"
                                        //placeholder="Old Email"
                                        value={values.packages[index].scripname}
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        style={{
                                          border: "none",
                                          textAlign: "center",
                                        }}
                                        disabled
                                        error={errors.name && touched.name}
                                        // helperText={
                                        //   touched.old_mobile_number && errors.old_mobile_number
                                        // }
                                      />
                                      {errors.name && (
                                        <label
                                          className="mt-2 mx-4"
                                          style={{ color: "#f44336" }}
                                        >
                                          <h6 className="fs-10 text-danger">
                                            {errors.name}
                                          </h6>
                                        </label>
                                      )}
                                    </td>
                                    <td>
                                      <Input
                                        name="quantity"
                                        type="number"
                                        className="nominee-form-input text-color1 text-4"
                                        id="quantity"
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid #e0dbdb",
                                          borderRadius: "5px",
                                          width: "80px",
                                          margin: "0 auto",
                                        }}
                                        max={10}
                                        // maxLength={+values.packages[index].QUANTITYL}
                                        //placeholder="Old Email"
                                        value={+values.packages[index].qty}
                                        onChange={(e) => {
                                          const dd =
                                            (e.target.value *
                                              values.packages[index].market) /
                                            100;

                                          if (
                                            e.target.value !== 0 &&
                                            e.target.value <=
                                              values.packages[index].QUANTITYL
                                          ) {
                                            setFieldValue(
                                              `packages.[${index}].qty`,
                                              +e.target.value
                                            );
                                            // setFieldValue(
                                            //   `packages.[${index}].haircutval`,
                                            //   (+values.packages[index].haircutval /
                                            //     +values.packages[index].qty) *
                                            //     +e.target.value
                                            // );
                                            setFieldValue(
                                              `packages.[${index}].haircutval`,
                                              ((e.target.value *
                                                values.packages[index].market) /
                                                100) *
                                                values.packages[index].nhaircut
                                            );
                                          }
                                          // setFieldValue(
                                          //   `packages.[${index}].qty`,
                                          //   +e.target.value
                                          // );
                                          // setFieldValue(
                                          //   `packages.[${index}].haircutval`,
                                          //   (+values.packages[index].haircutval /
                                          //     +values.packages[index].qty) *
                                          //     +e.target.value
                                          // );
                                        }}
                                        //onBlur={handleBlur}
                                        //disabled
                                        error={
                                          errors.quantity && touched.quantity
                                        }
                                        // helperText={
                                        //   touched.old_mobile_number && errors.old_mobile_number
                                        // }
                                      />
                                      {errors.quantity && (
                                        <label
                                          className="mt-2 mx-4"
                                          style={{ color: "#f44336" }}
                                        >
                                          <h6 className="fs-10 text-danger">
                                            {errors.quantity}
                                          </h6>
                                        </label>
                                      )}
                                    </td>
                                    <td>
                                      <Input
                                        name="total"
                                        type="number"
                                        className="nominee-form-input text-color1 text-4"
                                        style={{
                                          border: "none",
                                          textAlign: "center",
                                        }}
                                        id="total"
                                        //placeholder="total Email"
                                        // value={
                                        //   (+values.packages[index].haircutval /
                                        //     +values.packages[index].qty) *
                                        //   +values.packages[index].qty
                                        // }
                                        // value={(
                                        //   ((values.packages[index].qty *
                                        //     values.packages[index].market) /
                                        //     100) *
                                        //   values.packages[index].nhaircut
                                        // ).toFixed(2)}

                                        // value={(
                                        //   (values.packages[index].qty *
                                        //     values.packages[index].market *
                                        //     values.packages[index].nhaircut) /
                                        //   100
                                        // ).toFixed(2)}
                                        value={(
                                          ((values.packages[index].qty *
                                            values.packages[index].market) /
                                            100) *
                                          values.packages[index].nhaircut
                                        ).toFixed(2)}
                                        //onChange={handleChange}
                                        //onBlur={handleBlur}
                                        disabled
                                        error={errors.total && touched.total}
                                        // helperText={
                                        //   touched.old_mobile_number && errors.old_mobile_number
                                        // }
                                      />
                                      {errors.total && (
                                        <label
                                          className="mt-2 mx-4"
                                          style={{ color: "#f44336" }}
                                        >
                                          <h6 className="fs-10 text-danger">
                                            {errors.total}
                                          </h6>
                                        </label>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}

                              {/* {role !== "business" && isEditable && (
                    <div className="form-group row">
                      <div className="col-md-5">
                        <button
                          type="button"
                          className="btn font-weight-bold btn-primary"
                          onClick={() => push(emptyPackageRow)}
                        >
                          <i className="flaticon2-add-1 icon-1x"></i>Add Package
                        </button>
                      </div>
                    </div>
                  )} */}
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </table>

                    {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div>
              <h4 className="text-center">No stock free to be pledged</h4>
              <p className="text-center">
                Please check holdings or contact{" "}
                <a href="mailto:support@bigul.co" target="_blank">
                  support@bigul.co
                </a>{" "}
                or call <a href="tel:+918045025533">9090491911</a>
              </p>
              <div className="d-flex justify-content-center">
                <Link
                  type="button"
                  className="text-4 mb-2 btn btn-primary margin-overview-button1"
                  style={{
                    borderRadius: "5px",
                    height: "50%",
                    margin: "0 auto",
                  }}
                  to="/overview"
                >
                  Go Back
                </Link>
              </div>
            </div>
          )}
        </div>
        {pledgeData.length > 0 && (
          <div className="d-flex justify-content-center mt-5">
            <h4 className="me-3 text-6">Total Collateral Value :</h4>
            <h4 className="text-6">{totalValue.toFixed(2)}</h4>
          </div>
        )}
        {pledgeData.length > 0 && (
          <div className="d-flex justify-content-center mt-1">
            {totalValue > 0 ? (
              <PledgeModelIndex data={inputQ} data2={isCheckAll} />
            ) : (
              <button
                type="button"
                className="btn btn-primary modal-content-Button"
                disabled
              >
                Submit
              </button>
            )}
          </div>
        )}
      </Col>
    </React.Fragment>
  );
};

export default MyPlansHeader;
