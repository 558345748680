import backgroundImg from "../assets/images/referandEarn/backgroundImg.svg";
// Header
import logo from "../assets/images/referandEarn/header/logo.svg";
import search from "../assets/images/referandEarn/header/search.svg";
import activeDashboardIcon from "../assets/images/referandEarn/header/dashboardIcon.svg";
import reportIcon from "../assets/images/referandEarn/header/reportIcon.svg";
import exploreIcon from "../assets/images/referandEarn/header/explore.svg";
import algosIcon from "../assets/images/referandEarn/header/myAlgos.svg";
import portfolio from "../assets/images/referandEarn/header/portfolio.svg";
import statementsIcon from "../assets/images/referandEarn/header/statement.svg";
import rewardsIcon from "../assets/images/referandEarn/header/rewardsIcon.svg";
import bellIcon from "../assets/images/referandEarn/header/bellIcon.svg";
import profileIcon from "../assets/images/referandEarn/header/profileIcon.svg";
import moreIcon from "../assets/images/referandEarn/header/moreIcon.svg";
import dashboardActiveIcon from "../assets/images/referandEarn/header/dashboardActiveIcon.svg";
import reportActiveIcon from "../assets/images/referandEarn/header/reportActiveIcon.svg";
import msgTextIcon from "../assets/images/referandEarn/header/msgTextIcon.svg";
import clockBackward from "../assets/images/referandEarn/header/clockBackward.svg";

// Dashboard
import myRefferalIcon from "../assets/images/referandEarn/dashboard/myRefferalIcon.svg";
import rightIcone from "../assets/images/referandEarn/dashboard/rightIcone.svg";
import closeIcon from "../assets/images/referandEarn/dashboard/closeIcon.svg";
import totalEarningIcon from "../assets/images/referandEarn/dashboard/totalEarningIcon.svg";
import amountCreditLedgreIcon from "../assets/images/referandEarn/dashboard/amountCreditLedgreIcon.svg";
import remainingBalanceIcon from "../assets/images/referandEarn/dashboard/remainingBalanceIcon.svg";
import addRefferalIcon from "../assets/images/referandEarn/dashboard/addRefferalIcon.svg";
import gmailIcon from "../assets/images/referandEarn/dashboard/gmailIcon.svg";
import facebookIcon from "../assets/images/referandEarn/dashboard/facebookIcon.svg";
import linkedinIcon from "../assets/images/referandEarn/dashboard/linkedinIcon.svg";
import whatsappIcon from "../assets/images/referandEarn/dashboard/whatsappIcon.svg";
import refferErarnBackground from "../assets/images/referandEarn/dashboard/refferErarnBackground.svg";
import refferErarnImg from "../assets/images/referandEarn/dashboard/refferErarnImg.svg";

// Custom Calander
import calendarIcon from "../assets/images/referandEarn/customCalendar/calendarIcon.svg";

// Explore
import filterIcon from "../assets/images/referandEarn/Explore/filterIcon.svg";
import polygon from "../assets/images/referandEarn/Explore/polygon.svg";
import arrowUp from "../assets/images/referandEarn/Explore/arrowUp.svg";
import arrowDown from "../assets/images/referandEarn/Explore/arrowDown.svg";
import descendingSortingIcon from "../assets/images/referandEarn/Explore/descendingSortingIcon.svg";
import sortDown from "../assets/images/referandEarn/Explore/sortDown.svg";
import sortUp from "../assets/images/referandEarn/Explore/sortUp.svg";

// pagination
import deactiveLeftArrow from "../assets/images/referandEarn/pagination/deactiveLeftArrow.svg";
import activeRightArrow from "../assets/images/referandEarn/pagination/activeRightArrow.svg";
import copy_image from "../assets/images/referandEarn/dashboard/copy_img.png";
import trading_image from "../assets/images/referandEarn/dashboard/trading.svg"

export const ImagePath = {
  copy_image,
  backgroundImg,
  sortUp,
  sortDown,
  logo,
  trading_image,
  search,
  activeDashboardIcon,
  reportIcon,
  exploreIcon,
  algosIcon,
  portfolio,
  statementsIcon,
  rewardsIcon,
  moreIcon,
  bellIcon,
  profileIcon,
  myRefferalIcon,
  totalEarningIcon,
  amountCreditLedgreIcon,
  remainingBalanceIcon,
  addRefferalIcon,
  gmailIcon,
  facebookIcon,
  linkedinIcon,
  whatsappIcon,
  refferErarnImg,
  refferErarnBackground,
  calendarIcon,
  filterIcon,
  polygon,
  deactiveLeftArrow,
  activeRightArrow,
  dashboardActiveIcon,
  arrowDown,
  arrowUp,
  reportActiveIcon,
  descendingSortingIcon,
  closeIcon,
  rightIcone,
  clockBackward,
  msgTextIcon,
};
