import React, { useEffect, useState } from "react";

import { CardBody, Card, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
//redux
import { useSelector, useDispatch } from "react-redux";
//Import actions
import { getFeatureAndPlan } from "../../store/featureAndPlan/action";
import { addToCart } from "../../store/cart/action";
import MyPlan from "../../Components/CustomComponents/Models/myPlan/MyPlan";
import active from "../../assets/images/logo/active.png";
import templateimage from "../../assets/images/logo/Template.png";
import algoimage from "../../assets/images/logo/Ralgo.png";
import tradenew from "../../assets/images/logo/tradenew.png";
import { toast } from "react-toastify";

const SuggestedAlgos1 = () => {
  //  ////console.log(cyptoWidgets);
  const dispatch = useDispatch();

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");

  const { featureAndPlan } = useSelector((state) => ({
    featureAndPlan: state.FeatureAndPlan,
  }));
  //  //console.log(featureAndPlan);
  //  //console.log(new Date(featureAndPlan.from_date));
  useEffect(() => {
    dispatch(
      getFeatureAndPlan({
        client_code: client_code,
        client_token: client_token,
      })
    );
  }, [dispatch, client_token, client_code]);

  const charactersLimit = (data, limit) => {
    if (data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data;
  };

  const addToCartHandler = (data) => {
    ////console.log(data);
    const item = {
      client_code: client_code,
      client_token: client_token,
      feature_or_plan_id: data.feature_ids,
      feature_or_plan: "Feature",
      duration_type: "Monthly",
    };
    dispatch(addToCart(item));
  };

  ////console.log(dayjs(featureAndPlan.activeFeatureAndPlanList.to_date).format("Do MMM, YYYY"))
  return (
    <React.Fragment>
      <Col lg={12} md={12} xs={12}>
        <div className="d-flex align-items-center justify-content-between mb-4 mt-4">
          <div>
            <h4 className="card-title mb-0 flex-grow-1 text-6 text-color1">
              Suggested Algos
            </h4>
          </div>
          {/* <div className="page-title-right">
              <button
                className="btn btn-primary btn-sm text-5 addons-button"
              >
                View More
              </button>
            </div> */}
        </div>
      </Col>
      <Col xl={4} lg={4} md={6} className="mb-4 h-100">
        <Card
          className=""
          style={{
            backgroundColor: "#5367FC",
            margin: 0,
            height: "350px",
          }}
        >
          <CardBody className="px-5 py-4 algo-card-padding">
            <div
              className="suggested-algo-dashboard-img d-flex justify-content-center align-items-center"
              style={{
                margin: "0 auto",
              }}
            >
              <img
                className="card-invest-logo align-middle"
                src={templateimage}
                alt=""
                style={{
                  width: "45px",
                  height: "45px",
                  //borderRadius: "50%",
                }}
              />
            </div>
            <h4
              className="text-6 text-color3 mt-4 text-center"
              style={{ height: "15%" }}
            >
              Tech Algo Platform
            </h4>
            <p
              className="text-color3 text-11 m-0 text-center"
              style={{ height: "35%" }}
            >
              Explore your gateway to cutting-edge solutions. Discover diverse top-tier tech products and unleash the potential of innovation.
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <Link
                //to="/algo/tech"
                // onClick={() => window.open("https://bigul.co/en/index.php/product-category/marketplace/", '_blank')}
                onClick={() => toast.error('This platform does not exits')}
                className="suggested-algo-know-more-btn text-4"
              >
                Know More
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={4} lg={4} md={6} className="mb-4 h-100">
        <Card
          className=""
          style={{
            backgroundColor: "#008490",
            margin: 0,
            height: "350px",
          }}
        >
          <CardBody className="px-5 py-4 algo-card-padding">
            <div
              className="suggested-algo-dashboard-img d-flex justify-content-center align-items-center"
              style={{
                margin: "0 auto",
              }}
            >
              <img
                className="card-invest-logo align-middle"
                src={tradenew}
                alt=""
                style={{
                  width: "45px",
                  height: "45px",
                  //borderRadius: "50%",
                }}
              />
            </div>
            <h4
              className="text-6 text-color3 mt-4 text-center"
              style={{ height: "15%" }}
            >
              Tradetron Strategies
            </h4>
            <p
              className="text-color3 text-11 m-0 text-center"
              style={{ height: "35%" }}
            >
              Unlock the Power of Algo Trading with Bigul's Partnership with Tradetron. Together, in the algo trading landscape in India, we're offering a pioneering, no-code platform that welcomes traders of all levels.
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <Link
                //to="/algo/strategy"
                onClick={() => window.open("https://bigul.co/tradetron-strategy", '_blank')}
                className="suggested-algo-know-more-btn2 text-4"
              >
                Know More
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={4} lg={4} md={6} className="mb-4 h-100" style={{ display: "none" }}>
        <Card
          className=""
          style={{
            backgroundColor: "#008490",
            margin: 0,
            height: "350px",
          }}
        >
          <CardBody className="px-5 py-4 algo-card-padding">
            <div
              className="suggested-algo-dashboard-img d-flex justify-content-center align-items-center"
              style={{
                margin: "0 auto",
              }}
            >
              <img
                className="card-invest-logo align-middle"
                src={active}
                alt=""
                style={{
                  width: "45px",
                  height: "45px",
                }}
              />
            </div>
            <h4
              className="text-6 text-color3 mt-4 text-center"
              style={{ height: "15%" }}
            >
              One Click Strategy
            </h4>
            <p
              className="text-color3 text-11 m-0 text-center"
              style={{ height: "35%" }}
            >
              Easy to invest with one click save profile for later. Easy to
              invest with one click save profile for later. Easy to invest with
              one click save profile for later. Easy to invest with one click
              save profile for later.
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <Link
                //to="/algo/strategy"
                onClick={() => window.open("https://one-click-strategy.bigul.co/", '_blank')}
                className="suggested-algo-know-more-btn2 text-4"
              >
                Know More
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={4} lg={4} md={6} className="mb-4 h-100">
        <Card
          className=""
          style={{
            backgroundColor: "#0A3294",
            margin: 0,
            height: "350px",
          }}
        >
          <CardBody className="px-5 py-4 algo-card-padding">
            <div
              className="suggested-algo-dashboard-img d-flex justify-content-center align-items-center"
              style={{
                margin: "0 auto",
              }}
            >
              <img
                className="card-invest-logo align-middle"
                src={algoimage}
                alt=""
                style={{
                  width: "45px",
                  height: "45px",
                  //borderRadius: "50%",
                }}
              />
            </div>
            <h4
              className="text-6 text-color3 mt-4 text-center"
              style={{ height: "15%" }}
            >
              Execution Algo Templates
            </h4>
            <p
              className="text-color3 text-11 m-0 text-center"
              style={{ height: "35%" }}
            >
              Unlock intelligent, automated trading with Bigul Execution Algos. Streamline strategies by optimizing time, price, and volume parameters. Trade smarter today.
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <Link
                // to="/algo/execution"
                onClick={() => window.open("https://bigul.co/execution_algos", '_blank')}
                className="suggested-algo-know-more-btn3 text-4"
              >
                Know More
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SuggestedAlgos1;
