import React from 'react';

const CustomPagination = ({ currentPage, totalPages, paginate }) => {
  const pageRange = 4; // Number of pages to initially show

  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  const startIdx = Math.max(0, currentPage - Math.floor(pageRange / 2));
  const endIdx = Math.min(totalPages - 1, startIdx + pageRange - 1);

  const visiblePages = pageNumbers.slice(startIdx, endIdx + 1);

  return (
    <div className="table-pagination">
      {/* <span>Page {currentPage} of {totalPages}</span> */}
      <div className="pagination-buttons">
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className={currentPage === 1 ? 'inactive' : 'active'}>
          Previous
        </button>
        {startIdx > 1 && (
          <>
            <button onClick={() => paginate(1)} className={currentPage === 1 ? 'active-page' : 'inactive-page'}>
              1
            </button>
            {startIdx > 2 && <span>...</span>}
          </>
        )}
        {visiblePages.map((page) => (
          <button
            key={page}
            onClick={() => paginate(page)}
            className={currentPage === page ? 'active-page' : 'inactive-page'}
          >
            {page}
          </button>
        ))}
        {endIdx < totalPages - 1 && (
          <>
            {endIdx < totalPages - 2 && <span>...</span>}
            <button onClick={() => paginate(totalPages)} className={currentPage === totalPages ? 'active-page' : 'inactive-page'}>
              {totalPages}
            </button>
          </>
        )}
        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'inactive' : 'active'}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;
