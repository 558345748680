import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";

import InviteFriend from "./InviteFriend";
import InviteFriend2 from "./InviteFriend2";
import Earning from "./Earning";
import EarningTable from "./EarningTable";
import Benefits from "./Benefits";
import Questions from './Questions'
import Footer from './Footer'
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";
import reffandearn from "../../assets/images/logo/reffandearn.png";

const ReferAndEarn = () => {
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <div className="p-0">
            <Row>
              <div
                className="d-flex w-75 mb-4 pb-4"
                style={{ borderBottom: "1px solid #ece0e0", margin: "0 auto" }}
              >
                <InviteFriend />
                <InviteFriend2 />
              </div>
            </Row>
            <Row>
              <div className="w-75 mb-4" style={{ margin: "0 auto" }}>
                <Earning />
                <EarningTable />
              </div>
            </Row>
          </div>
        </Container>
        <div
          className="p-0"
          style={{ backgroundColor: " rgba(255, 200, 75, 0.15)" }}
        >
          <Row>
            <div className="w-75" style={{ margin: "0 auto", padding: '0 5%' }}>
              <Benefits />
            </div>
            
          </Row>
        </div>
        <div
          className="p-0"
          style={{ backgroundColor: " #F15B4D" }}
        >
          <Row>
            <Questions />
          </Row>
        </div>
        <div
          className="p-0"
          style={{ backgroundColor: "rgba(35, 31, 32, 1)" }}
        >
          <Row>
            <Footer />
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReferAndEarn;
