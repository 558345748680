import {
  GET_MY_INVESTED_ALGO_TOP,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  API_MY_INVESTED_ALGO_GRAPH_SUCCESS,
  API_MY_INVESTED_ALGO_GRAPH_ERROR,
  GET_MY_INVESTED_ALGO_GRAPH
} from "./actionType";

// common success
export const MyInvestedAlgosApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const MyInvestedAlgosApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getMyInvestedAlgosTop = (data) => ({
  type: GET_MY_INVESTED_ALGO_TOP,
  data,
});

// common graph success
export const MyInvestedAlgosGraphApiResponseSuccess = (actionType, data) => ({
  type: API_MY_INVESTED_ALGO_GRAPH_SUCCESS,
  payload: { actionType, data },
});
// common graph error
export const MyInvestedAlgosGraphApiResponseError = (actionType, error) => ({
  type: API_MY_INVESTED_ALGO_GRAPH_ERROR,
  payload: { actionType, error },
});

export const getMyInvestedAlgosGraph = (data) => {
  return ({
  type: GET_MY_INVESTED_ALGO_GRAPH,
  data,
})};