import { API_SUMMARY_RESPONSE_ERROR } from "../algoSummaryTable/actionType";
import { API_ALGO_GET_TRANSTIONS_SUCCESS, GET_TRANSTIONS } from "./actionType";

  
  // SummaryTable
  export const AlgoGetTranstionSuccess = (actionType, data) => ({
    type: API_ALGO_GET_TRANSTIONS_SUCCESS,
    payload: { actionType, data },
  });
  // SummaryTable error
  export const AlgoGetTranstionsApiResponseError = (actionType, error) => ({
    type: API_SUMMARY_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  // SummaryTable
  export const getTranstions = (data) => ({
    type: GET_TRANSTIONS,
    payload: data,
  });
  