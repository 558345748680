// Actions TradeBook Cash
export const API_TRADE_BOOK_CASH_RESPONSE_SUCCESS = "API_TRADE_BOOK_CASH_RESPONSE_SUCCESS";
export const API_TRADE_BOOK_CASH_RESPONSE_ERROR = "API_TRADE_BOOK_CASH_RESPONSE_ERROR";
export const GET_TRADE_BOOK_CASH = "GET_TRADE_BOOK_CASH";

// Actions TradeBook Derivative
export const API_TRADE_BOOK_DERIVATIVE_RESPONSE_SUCCESS = "API_TRADE_BOOK_DERIVATIVE_RESPONSE_SUCCESS";
export const API_TRADE_BOOK_DERIVATIVE_RESPONSE_ERROR = "API_TRADE_BOOK_DERIVATIVE_RESPONSE_ERROR";
export const GET_TRADE_BOOK_DERIVATIVE = "GET_TRADE_BOOK_DERIVATIVE";

// Actions Ledger
export const API_LEDGER_RESPONSE_SUCCESS = "API_LEDGER_RESPONSE_SUCCESS";
export const API_LEDGER_RESPONSE_ERROR = "API_LEDGER_RESPONSE_ERROR";
export const GET_LEDGER = "GET_LEDGER";

// Actions P&L Cash
export const API_P_AND_L_CASH_RESPONSE_SUCCESS = "API_P_AND_L_CASH_RESPONSE_SUCCESS";
export const API_P_AND_L_CASH_RESPONSE_ERROR = "API_P_AND_L_CASH_RESPONSE_ERROR";
export const GET_P_AND_L_CASH = "GET_P_AND_L_CASH";

// Actions P&L Derivative
export const API_P_AND_L_DERIVATIVE_RESPONSE_SUCCESS = "API_P_AND_L_DERIVATIVE_RESPONSE_SUCCESS";
export const API_P_AND_L_DERIVATIVE_RESPONSE_ERROR = "API_P_AND_L_DERIVATIVE_RESPONSE_ERROR";
export const GET_P_AND_L_DERIVATIVE = "GET_P_AND_L_DERIVATIVE";

// Actions Holdings
export const API_HOLDING_RESPONSE_SUCCESS = "API_HOLDING_RESPONSE_SUCCESS";
export const API_HOLDING_RESPONSE_ERROR = "API_HOLDING_RESPONSE_ERROR";
export const GET_HOLDING = "GET_HOLDING";

// Actions Tax Report
export const API_TAX_REPORT_RESPONSE_SUCCESS = "API_TAX_REPORT_RESPONSE_SUCCESS";
export const API_TAX_REPORT_RESPONSE_ERROR = "API_TAX_REPORT_RESPONSE_ERROR";
export const GET_TAX_REPORT = "GET_TAX_REPORT";

// Actions Contract Note
export const API_CONTRACT_NOTE_RESPONSE_SUCCESS = "API_CONTRACT_NOTE_RESPONSE_SUCCESS";
export const API_CONTRACT_NOTE_RESPONSE_ERROR = "API_CONTRACT_NOTE_RESPONSE_ERROR";
export const GET_CONTRACT_NOTE = "GET_CONTRACT_NOTE";

// Actions P&L Derivative2
export const API_P_AND_L_DERIVATIVE_2_RESPONSE_SUCCESS = "API_P_AND_L_DERIVATIVE_2_RESPONSE_SUCCESS";
export const API_P_AND_L_DERIVATIVE_2_RESPONSE_ERROR = "API_P_AND_L_DERIVATIVE_2_RESPONSE_ERROR";
export const GET_P_AND_L_DERIVATIVE_2 = "GET_P_AND_L_DERIVATIVE_2";