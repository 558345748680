import React, { useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";

const InviteFriend = () => {

  return (
    <React.Fragment>
      <Col xxl={6} xl={6} lg={6} className="me-3">
      <h4 className="text-23 text-color5 mb-2 pe-1">Invite your friends and family</h4>
      <p className="text-24 text-color1 mb-4 pe-2">Create your personalized Referral link and send it to your friends. All accounts opened using your referral link will be mapped to you.</p>
      <div className="input-group">
                <input
                  type="text"
                  className="form-control text-4 text-color4"
                  //placeholder={!copy && value !== '' ? `${value.link}` : !copy && value === '' ? 'Loading....' : "Copied!"}
                  
                  style={{
                    height: "30px",
                    borderTopLeftRadius: "25px",
                    borderBottomLeftRadius: "25px",
                  }}
                />
                <button
                  className="input-group-text text-5 ref-and-earn-button2 text-uppercase text-center"
                  style={{    marginLeft: '-30px'}}
                  // onClick={() => {
                  //   myFunction("Joey#9278");
                  // }}
                >
                  Generate Link
                </button>
              </div>
      </Col>
    </React.Fragment>
  );
};

export default InviteFriend;
