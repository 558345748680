import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
// TODO Ui and design and functions.
const TransferredSuccessful = (props) => {
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const modalMobile = {
    CUpdate: true,
    otp1: false,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: false,
  };

  function tog_signUpModals() {
    //   ////console.log('submit')
    props.tog(false);
    //setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    //  ////console.log("submit");
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(false);
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4 justify-content-center"
        // toggle={() => {
        //   tog_signUpModals();
        // }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
          Transferred Successful
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <form onSubmit={submitHandler()}>
          <h4
            className="form-check-label text-15 text-color1 px-3 text-center"
            htmlFor="checkTerms"
          >
            Transaction will take up to 48 hours to reflect (depend on the bank
            server and transferring rate)
          </h4>
          <div>
            <h4 className="text-15 text-color1 text-center mt-3">
              We will notify you over email for the same.
            </h4>
          </div>
          <div className="text-center">
              <Link
                //type="submit"
                to="/subscription/plans"
                className="btn btn-primary modal-content-Button my-3"
              //onClick={(e) => onClickHandler(e)}
              >
                Ok
              </Link>
            {/* <button
              type="button"
              className="btn btn-primary modal-content-Button my-3"
              onClick={() => {
                
              }}
            >
              Ok
            </button> */}
          </div>
        </form>
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default TransferredSuccessful;
