import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
// TODO Ui and design and functions.
const ConfirmChanges2 = (props) => {
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const modalMobile = {
    CUpdate: false,
    otp1: true,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: false,
  };

  // function tog_signUpModals() {
  //   // //console.log('submit')
  //   setmodal_signUpModals(!modal_signUpModals);
  // }

  const submitHandler = () => {
    //"submit");
  };
  const onClickHandler = (e) => {
    e.preventDefault();
    props.sendData(false);
  };
  const tog_signUpModals = () => {
    props.tog(false);
  };
  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4 justify-content-center"
        // toggle={() => {
        //   tog_signUpModals();
        // }}
      >
        <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
          After E-Sign
        </h4>
      </ModalHeader>
      <ModalBody className="p-4">
        <form onSubmit={submitHandler()}>
          <h4
            className="form-check-label text-15 text-color1 text-center"
            htmlFor="checkTerms"
          >
            Far far away, behind the word mountains, far from the countries
            Vokalia and Consonantia, there live the blind texts. Separated they
            live in Bookmarksgrove.
          </h4>
          <div>
            <h4 className="text-15 text-color1 mt-3 text-center">
              We will notify you over email for the same.
            </h4>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary modal-content-Button my-3"
              onClick={(e) => onClickHandler(e)}
            >
              Continue
            </button>
          </div>
        </form>
      </ModalBody>
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default ConfirmChanges2;
