import React, { useEffect, useState } from "react";
import FamilyHadder from "./familyHadder";
import FamilyTable from "./familyTable";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

const Family = () => {
  return (
    <div className="page-content pb-0">
      <Container fluid>
        <BreadCrumb
          title="Family Declaration"
          titleMain="Family Declaration"
          //titleSecondary={`Here's what's happening with your account today.`}
          pageTitle="Dashboard"
          to='/dashboard'
        />
        <FamilyHadder>
          <FamilyTable />
        </FamilyHadder>
      </Container>
    </div>
  );
};
export default Family;


