import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import ConfirmChanges1 from './ConfirmChanges1';
import ConfirmChanges2 from './ConfirmChanges2';
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { ResetPhoneState } from "../../../../store/Profile/action";
// TODO Ui and design and functions.
const PledgeModelIndex = (props) => {
  const dispatch = useDispatch();
  const { openState, data, data2 } = props;
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const [modalAdditional, setModelAdditional] = useState({
    CChange1: true,
  });
  //console.log(props.data)
  //console.log(props.data2)

  // const sendData = (data) => {
  //   ////console.log(data);
  //   //e.preventDefault();
  //   setModelAdditional(data)
  // }

  const tog_signUpModalsFinal = (data) => {
    // //console.log('submit')
    setmodal_signUpModals(!modal_signUpModals);
  }
 
  const tog_signUpModals = () => {
    //setModelAdditional(openState)
    setmodal_signUpModals(!modal_signUpModals);
  }

  const submitHandler = () => {
    ////console.log("submit");
  };
  return (
    <React.Fragment>
      <button
        className="btn btn-primary me-3 additional-page-button-save"
        type="button"
        onClick={() => tog_signUpModals()}
      >
        Submit
      </button>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={modal_signUpModals}
        backdropClassName="background-color4"
        toggle={() => {
          tog_signUpModals();
        }}
        centered
      >
        <ConfirmChanges1 tog={tog_signUpModals} data={props.data} data2={props.data2} />
      </Modal>
    </React.Fragment>
  );
};

export default PledgeModelIndex;
