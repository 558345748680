import {
  GET_MY_FEATURE_PLAN,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
  myFeaturePlan: [],
};

const MyFeaturePlan = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MY_FEATURE_PLAN:
          return {
            ...state,
            myFeaturePlan: action.payload.data.data.features,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_MY_FEATURE_PLAN:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_MY_FEATURE_PLAN: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default MyFeaturePlan;
