import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_FEATURE_AND_PLAN } from "./actionType";

import {
  FeatureAndPlanApiResponseSuccess,
  FeatureAndPlanApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getFeatureAndPlanApi } from "../../helpers/fakebackend_helper";

function* getFeatureAndPlan(data) {
  ////console.log(data)
  try {
    const response = yield call(getFeatureAndPlanApi, data.data);
    yield put(FeatureAndPlanApiResponseSuccess(GET_FEATURE_AND_PLAN, response));
  } catch (error) {
    yield put(FeatureAndPlanApiResponseError(GET_FEATURE_AND_PLAN, error));
  }
}

export function* watchGetFeatureAndPlan() {
  yield takeEvery(GET_FEATURE_AND_PLAN, getFeatureAndPlan);
}

function* FeatureAndPlanSaga() {
  yield all([fork(watchGetFeatureAndPlan)]);
}

export default FeatureAndPlanSaga;
