import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
//Import Breadcrumb
import active from "../../assets/images/logo/active.png";
import { Link } from "react-router-dom";
import { getCart, removeToCart, UpdateCart } from "../../store/cart/action";
import { getPaymentDetails } from "../../store/checkOut/action";
import MyPlan from "../../Components/CustomComponents/Models/myPlan/MyPlan";
import MyPlanCart from "../../Components/CustomComponents/Models/myPlanCart/myPlanCart";
import MyFeatureCart from "../../Components/CustomComponents/Models/addOneFeatureCart/myFeatureCart";
import MyFeature from "../../Components/CustomComponents/Models/addOneFeature/myFeature";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";

import gift from "../../assets/images/logo/gift.png";
import Encripted from "../Dashboard/Encripted";

const EcommerceCart = () => {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState("");
  const [productList, setproductList] = useState(shoppingCart);
  const [charge, setCharge] = useState(0);
  const [tax, setTax] = useState(0);
  const [dis, setDis] = useState(0);
  const ValidDate = new Date();
  const ValidDateFeature = new Date();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));

 console.log(Cart,'Cart');

  useEffect(() => {
    getCart({enc:Encripted([])})
  }, [dispatch]);

  // const assigned = productList.map((item) => item.total);
  // let subTotal = 0;
  // for (let i = 0; i < assigned.length; i++) {
  //   subTotal += Math.round(assigned[i]);
  // }

  const values = [];
  Cart.length > 0 &&
    Cart.forEach((element) => {
      if (element.feature_or_plan === "Plan" && element.pre_discount !== "") {
        const value = +element.pre_discount;
        values.push(value);
      } else if (element.amount !== "") {
        const value = +element.amount;
        values.push(value);
      } else {
        const value = +element.amount;
        values.push(value);
      }
      // if (element.amount !== "") {
      //   const value = +element.amount;
      //   values.push(value);
      // } else {
      //   const value = +element.amount;
      //   values.push(value);
      // }
      ////console.log(values);
      //  ////console.log(element);
    });
  ////console.log(values);
  let discountValues = 0;
  Cart.length > 0 &&
    Cart.forEach((element) => {
      console.log('elementelementelement',element)
      if (
        element.feature_or_plan === "Plan" &&
        element.discounted_price !== ""
      ) {
        // const value = +element.discounted_price;
        // console.log('pre_discount',element.pre_discount)
        // console.log('discounted_price',element.discounted_price)
        // const value2 = +element.pre_discount - +element.discounted_price;
        // console.log('discountValuesdiscountValues',discountValues)
        discountValues+= parseFloat(element.pre_discount) - parseFloat(element.discounted_price);
      }
    });

  const subTotal = values.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  console.log(subTotal)
  console.log('discountValues',discountValues);
  const total = subTotal - discountValues;
  ////console.log(total);
  const taxCalculate = total * (18 / 100);
  ////console.log(taxCalculate);
  const finalTotal = total + taxCalculate;
  ////console.log(finalTotal)
  useEffect(() => {
    let dis = 0.15 * subTotal;
    let tax = 0.125 * subTotal;

    if (subTotal !== 0) {
      setCharge(65);
    } else {
      setCharge(0);
    }
    setTax(dis);
    setDis(tax);
  }, [subTotal]);

  function removeCartItem(id) {
    var filtered = productList.filter(function (item) {
      return item.id !== id;
    });

    setproductList(filtered);
  }

  function countUP(id, prev_data_attr, itemPrice) {
    setproductList(
      productList.map((p) =>
        p.id === id
          ? {
              ...p,
              data_attr: prev_data_attr + 1,
              total: (prev_data_attr + 1) * itemPrice,
            }
          : p
      )
    );
  }

  function countDown(id, prev_data_attr, itemPrice) {
    setproductList(
      productList.map((p) =>
        p.id === id && p.data_attr > 0
          ? {
              ...p,
              data_attr: prev_data_attr - 1,
              total: (prev_data_attr - 1) * itemPrice,
            }
          : p
      )
    );
  }
  const removeToCartHandler = (data) => {
    ////console.log(data);
    const item = {
      client_code: client_token,
      client_token: client_token,
      cart_id: data.id,
    };
    dispatch(removeToCart(item));
  };

  const f =
    Cart !== null &&
    Cart.length > 0 &&
    Cart.findIndex((e) => e.feature_or_plan === "Plan");
  const g =
    Cart !== null &&
    Cart.length > 0 &&
    Cart.findIndex((e) => e.feature_or_plan === "Feature");
  const paymentData = {};

  // //console.log(f);
  // //console.log(g);
  if (Cart !== null && Cart.length > 0 && f !== -1) {
    paymentData.client_code = client_code;
    paymentData.client_token = client_token;
    paymentData.master_plan_or_feature_id = Cart[f].feature_or_plan_id;
    paymentData.duration_type = Cart[f].duration_type;
    paymentData.duration = Cart[f].duration;
    paymentData.type = Cart[f].feature_or_plan;
    paymentData.amount = Cart[f].discounted_price;
    ////console.log(paymentData);
  } else if (Cart !== null && Cart.length > 0 && f === -1 ) {
    paymentData.client_code = client_code;
    paymentData.client_token = client_token;
    paymentData.master_plan_or_feature_id = Cart[0].feature_or_plan_id;
    paymentData.duration_type = Cart[0].duration_type;
    paymentData.duration = Cart[0].duration;
    paymentData.type = Cart[0].feature_or_plan;
    paymentData.amount = Cart[0].amount;
    ////console.log(paymentData);
  }
  const cloneCart = Cart !== null && [...Cart];
 // //console.log(cloneCart);
  Cart !== null && f !== -1 ? cloneCart.splice(f, 1) : cloneCart.splice(g, 1);
 // //console.log(cloneCart);
  const addFeature = [];
  cloneCart.forEach((e) => {
    ////console.log(e);
    let fet = {
      id: e.feature_or_plan_id,
      amount: (e.features && e.amount) || (e.plans && e.discounted_price),
      duration: e.duration,
      duration_type: e.duration_type,
    };
    addFeature.push(fet);
  });
  //const t = [];
  paymentData.additional_features = addFeature;
  ////console.log(paymentData);

  const GetPaymentDetailsHandler = () => {
    ////console.log();
    const item = {
      client_code: "Y09018",
      client_token: "Y09018",
      master_plan_or_feature_id: 2,
      amount: "30000.00",
      duration_type: "Monthly",
      duration: 30,
      additional_features: [],
      type: "Feature",
    };
    dispatch(getPaymentDetails(paymentData));
  };

  const updateCartHandler = (e, data) => {
    ////console.log(data.id);
    // //console.log(duration);
    ////console.log(e.target.value);
    setDuration(e.target.value);
    const data1 = {
      client_code: client_code,
      client_token: client_token,
      duration_type: e.target.value,
    };
    ////console.log(data1);
    dispatch(UpdateCart(data1, data.id));
  };

  return (
    <React.Fragment>
      <Col xl={8} className="pb-5 padding-buttom-remove">
        {/* <Row className="align-items-center gy-3 mb-3">
          <div className="col-sm">
            <div>
              <h5 className="text-15 text-color1 mb-0">
                Your Cart ({Cart.length} items)
              </h5>
            </div>
          </div>
          <div className="col-sm-auto">
            <Link
              to="/subscription/plans"
              className="link-primary text-decoration-underline"
            >
              Add more items
            </Link>
          </div>
        </Row> */}
        {Cart.length > 0 ? (
          Cart.map((item, index) => {
           // //console.log(item);
            const dropdown =
              (item.plans && item.plans.plan_durations) ||
              (item.features && item.features.feature_durations);
            ////console.log(dropdown);
            return (
              <React.Fragment key={index}>
                <Card className="product">
                  <CardBody>
                    <Row className="gy-3">
                      <div className="col-sm-auto">
                        <div className="avatar-lg rounded p-1 cart-image-mobile">
                          <img
                            src={
                              (item.features && item.features.logo) ||
                              (item.plans && item.plans.image) ||
                              active
                            }
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </div>
                      <div className="col-sm">
                        <h5 className="text-6 text-color1">
                          <Link
                            //to="/ecommerce-product-detail"
                            className="text-dark"
                          >
                            {(item.features && item.features.title) ||
                              (item.plans && item.plans.plan_name)}
                          </Link>
                        </h5>
                        {/* <p className="text-21 text-color1">
                          {(item.features && item.features.short_description) ||
                            (item.plans && item.plans.description)}
                        </p> */}
                          <p dangerouslySetInnerHTML={{ __html:  (item.features && item.features.short_description) ||
                            (item.plans && item.plans.description) }}></p>
                        <div className="d-flex">
                        <h4
                              className="text-15 m-0 text-color4 width-mobile-25 me-3"
                              style={{
                                padding: "8px 20px",
                                border: "1px solid #80808059",
                                borderRadius: "5px",
                              }}
                            >
                              {
                                item.duration_type
                              }
                              {/* {dropdown.map((el) => {
                                return el.duration_type;
                              })} */}
                            </h4>
                          {/* {dropdown.length > 1 ? (
                            <select
                              name="paymentMode"
                              value={item.duration_type}
                              className="form-select width-mobile-25 me-3 text-15"
                              aria-label="Default select example"
                              style={{ borderRadius: "5px" }}
                              onChange={(e) => {
                                updateCartHandler(e, item);
                              }}
                            >
                              {dropdown.length > 0 &&
                                dropdown.map((el, index) => {
                                 ////console.log(el);
                                  return (
                                    <option
                                      key={index}
                                      value={el.duration_type}
                                    >
                                      {el.duration_type}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            <h4
                              className="text-15 m-0 text-color4 width-mobile-25 me-3"
                              style={{
                                padding: "8px 20px",
                                border: "1px solid #80808059",
                                borderRadius: "5px",
                              }}
                            >
                              {dropdown.map((el) => {
                                return el.duration_type;
                              })}
                            </h4>
                          )} */}
                          {/* <button className="text-11 text-color7 profile-edit-button1">
                          View Details
                        </button> */}
                          {(item.plans && (
                            <MyPlanCart
                              data={item.feature_or_plan_id}
                              data2={item}
                              btnText="View Details"
                              btnClass="text-11 text-color7 profile-edit-button1"
                            />
                          )) ||
                            (item.features && (
                              <MyFeatureCart
                                data={item.feature_or_plan_id}
                                data2={item}
                                btnText="View Details"
                                btnClass="text-11 text-color7 profile-edit-button1"
                              />
                            ))}
                        </div>
                      </div>
                      <div className="col-sm-auto">
                        <div className="cart-valid text-lg-end dashboard-widget-bottom-margin">
                          <p className="text-15 text-color2 mb-1 margin-mobile-row-non">
                            Valid upto
                          </p>
                          <h5 className="text-color1 text-4 margin-mobile-row-non margin-mobile-start">
                            {(item.features &&
                              dayjs(new Date().setDate(new Date().getDate() + 1 + item.duration)).format("DD MMM YYYY")) ||
                              (item.plans &&
                                dayjs(
                                  new Date().setDate(
                                    new Date().getDate() + 1 + item.duration
                                  )
                                ).format("DD MMM YYYY"))}{" "}
                          </h5>
                        </div>
                        {item.plans ? <div className="text-lg-end cart-valid dashboard-widget-bottom-margin">
                          <p className="text-15 text-color2 mb-1 margin-mobile-row-non">
                            Item Price:
                          </p>
                          <h5 className="text-color1 text-4 margin-mobile-row-non margin-mobile-start">
                            ₹{" "}
                            {
                              (item.plans && item.pre_discount)}{" "}
                          </h5>
                        </div> : null}
                        {item.plans ? (
                          <div className="text-lg-end cart-valid">
                            <p className="text-15 text-color2 mb-1 margin-mobile-row-non">
                              Discount:
                            </p>
                            <h5 className="text-color1 text-4 margin-mobile-row-non margin-mobile-start">
                              ₹ {item.plans && (+item.pre_discount - +item.discounted_price).toFixed(2)}
                            </h5>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </CardBody>

                  <div className="card-footer">
                    <div className="d-flex">
                      <div className="col-sm me-auto">
                        <div className="d-flex flex-wrap my-n1">
                          <div>
                            <Link
                              to="#"
                              className="d-block text-body p-1 px-2 me-3 margin-right-33"
                              //onClick={() => removeCartItem(cartItem.id)}
                              onClick={() => {
                                removeToCartHandler(item);
                              }}
                            >
                              <i className="ri-close-line text-muted align-bottom me-1"></i>{" "}
                              Remove
                            </Link>
                          </div>
                          {item.plans && <Link
                              to="/subscription/plans"
                              className="d-block p-1 px-2 change-plan-btn"
                            >
                              Change Plan
                            </Link>}
                        </div>
                      </div>
                      <div className="col-sm-auto margin-top-remove">
                        <div className="d-flex align-items-center gap-2 text-muted">
                          <div className="text-15 text-color2">Total :</div>
                          <h5 className="text-color1 text-4 mb-0">
                            ₹{" "}
                            {(item.features && item.amount) ||
                              (item.plans &&
                                (
                                  +item.discounted_price
                                ).toFixed(2))}{" "}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </React.Fragment>
            );
          })
        ) : (
          <div
            style={{ height: "200px", textAlign: "center", marginTop: "80px" }}
          >
            Add Item To Cart
          </div>
        )}
      </Col>

      <Col xl={4} className="pb-5 padding-buttom-remove2">
        <div className="sticky-side-div">
          <Card>
            <CardHeader className="bg-soft-light border-bottom-dashed">
              <h5 className="text-10 text-color5 mb-0">Order Summary</h5>
            </CardHeader>
            {/* <CardHeader className="bg-soft-light border-bottom-dashed">
              <div className="text-center">
                <h6 className="mb-2 text-19 text-color5">
                  Have a <span className="text-color7">promo</span> code ?
                </h6>
              </div>
              <div className="hstack gap-3 px-3 mx-n3">
                <input
                  className="form-control me-auto"
                  type="text"
                  placeholder="Enter coupon code"
                  aria-label="Add Promo Code here..."
                  style={{ borderRadius: "5px" }}
                />
                <button
                  type="button"
                  className="btn btn-primary w-xs"
                  style={{ borderRadius: "5px" }}
                >
                  Apply
                </button>
              </div>
            </CardHeader> */}
            <CardBody className="pt-2">
              <div className="table-responsive">
                <table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td className="text-color1 text-20">Sub Total</td>
                      <td
                        className="text-end text-color1 text-20"
                        id="cart-subtotal"
                      >
                        ₹ {subTotal.toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid rgb(128 128 128 / 13%)" }}>
                      <td className="text-21 text-color1">
                        Discount (-) <span className="text-muted"></span>{" "}
                      </td>
                      <td
                        className="text-end text-color1 text-20"
                        id="cart-discount"
                      >
                        ₹ {discountValues.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-21 text-color1">
                        Amount <span className="text-muted"></span>{" "}
                      </td>
                      <td
                        className="text-end text-color1 text-20"
                        id="cart-discount"
                      >
                        ₹ {total.toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid #80808042" }}>
                      <td className="text-21 text-color1">GST (+18%) </td>
                      <td
                        className="text-end text-color1 text-20"
                        id="cart-tax"
                      >
                        ₹ {taxCalculate.toFixed(2)}
                      </td>
                    </tr>
                    <tr className="">
                      <th className="text-color7 text-20">Total Amount</th>
                      <td className="text-end text-color7 text-20">
                        <span className="text-color7 text-20" id="cart-total">
                          ₹ {finalTotal.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          {Cart.length > 0 && (
            <div className="d-flex justify-content-between align-items-center">

                <Link
                  to={f !== -1 ? '/subscription/features' : "/subscription/plans"}
                  className="btn margin-overview-button1 btn-primary text-15"
                  style={{borderRadius: '5px', width: '45%'}}
                >
                  Add more items
                </Link>

              <div className="text-end" style={{ width: '45%' }}>
                <Link
                  to="/checkout"
                  className="btn btn-success text-center text-15"
                  style={{ borderRadius: "5px",width: '100%' }}
                  //onClick={GetPaymentDetailsHandler}
                >
                  {/* <i className=" ri-wallet-3-line label-icon align-bottom fs-16 ms-2"></i>{" "} */}
                  Proceed to Pay
                </Link>
              </div>
            </div>
          )}
          {/* <UncontrolledAlert color="primary" className="border-dashed">
            <div className="d-flex align-items-center">
              <img src={gift} style={{ width: "80px", height: "80px" }} />
              <div className="ms-2">
                <h5 className="text-22 text-color7">Bigul Welcome Offer*</h5>
                <p className="text-13 text-color5 mb-1">
                  Apply <span className="text-color7">BIGUL2023</span> to get
                  10% off on your first plan purchase.
                </p>
                <p className="text-11 text-color1">*only for new users</p>
              </div>
            </div>
          </UncontrolledAlert> */}
        </div>
      </Col>
    </React.Fragment>
  );
};

export default EcommerceCart;
