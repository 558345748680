import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumb = ({ title, pageTitle, titleMain, titleSecondary, to }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between page-header-spacing pb-4 mobile-padding">
            <div>
              <h2 className="main-header-page mb-sm-0 mb-lg-1 text-color1 dashboard-top-text ">{titleMain}</h2>
              <h6 className="main-header-secondary-page mb-sm-0 text-color2 dashboard-top-text2">{titleSecondary}</h6>
            </div>

            <div className="page-title-right item-hide">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to={to} className="main-header-secondary-page text-color1 ">{pageTitle}</Link>
                </li>
                <li className="breadcrumb-item active  main-header-secondary-page text-color2">{title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
