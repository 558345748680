import {
  GET_GENERATE_API_KEY,
  API_GENERATE_API_KEY_SUCCESS,
  API_GENERATE_API_KEY_FAIL,
} from "./actionType";

export const generateApiKeyResponseSuccess = (actionType, data) => ({
  type: API_GENERATE_API_KEY_SUCCESS,
  payload: { actionType, data },
});

export const generateApiKeyResponseError = (actionType, error) => ({
  type: API_GENERATE_API_KEY_FAIL,
  payload: { actionType, error },
});

export const generateApiKey = (data) => ({
  type: GET_GENERATE_API_KEY,
  payload: data,
});
