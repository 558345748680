import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_INVESTMENT_SUMMARY } from "./actionType";

import {
  InvestmentSummaryApiResponseSuccess,
  InvestmentSummaryApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getInvestmentSummaryApi } from "../../helpers/fakebackend_helper";

function* getInvestmentSummary({ payload: data }) {
  try {
    const response = yield call(getInvestmentSummaryApi, data);
    yield put(InvestmentSummaryApiResponseSuccess(GET_INVESTMENT_SUMMARY, response));
  } catch (error) {
    yield put(InvestmentSummaryApiResponseError(GET_INVESTMENT_SUMMARY, error));
  }
}

export function* watchGetInvestmentSummary() {
  yield takeEvery(GET_INVESTMENT_SUMMARY, getInvestmentSummary);
}

function* InvestmentSummarySaga() {
  yield all([fork(watchGetInvestmentSummary)]);
}

export default InvestmentSummarySaga;
