import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//import "swiper/css/pagination";
import active from "../../assets/images/logo/active.png";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Autoplay } from "swiper";
import MyFeatureActive from "../../Components/CustomComponents/Models/myFeatureActive/myFeatureActive";

const Features = (props) => {
  const charactersLimit = (data, limit) => {
    //  ////console.log(data);
    var htmlRegexG = /(?<=<div.*?class="some-class".*?>)(.*?)(?=<\/div>)/gi;
    const withoutTags = data.replace(/(<([^>]+)>)/gi, "");
    //  ////console.log(withoutTags);
    if (withoutTags === undefined) {
      return data;
    }
    if (withoutTags !== null || withoutTags !== undefined) {
      if (withoutTags.length > limit) {
        return data.substring(0, limit).concat(" ...");
      }
    }
    return withoutTags;
  };
  const { data } = props;
  //  ////console.log(data);
  const candidateListData = [
    {
      id: 1,
      img: active,
      name: "Algo Invest 1",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 2,
      img: active,
      name: "Algo Invest 2",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 3,
      img: active,
      name: "Algo Invest 3",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 4,
      img: active,
      name: "Algo Invest 4",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 5,
      img: active,
      name: "Algo Invest 5",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 6,
      img: active,
      name: "Algo Invest 6",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
  ];

  return (
    <React.Fragment>
      <Col xxl={11} xl={11} className="mx-auto my-0">
        <Row className="justify-content-center mt-4">
          <Col xl={6} lg={6}>
            <Card
              //className="text-center"
              style={{
                backgroundColor: "#008490",
                margin: 0,
                height: "100%",
              }}
            >
              <CardBody className="p-5 card-height-101">
                <div className="d-flex">
                  <div className="suggested-algo-dashboard-img2 d-flex justify-content-center align-items-center me-4">
                    <img
                      className="card-invest-logo align-middle"
                      src={active}
                      alt=""
                      style={{
                        width: "45px",
                        height: "45px",
                        //borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="me-3" style={{width: '80%'}}>
                    <h4 className="text-7 text-color3 my-3">Onetap Algo</h4>
                    <p
                      className="text-color3 text-11 m-0"
                      style={{ height: "40%" }}
                    >
                      Easy to invest with one click save profile for later. Easy
                      to invest with one click save profile for later. Easy to
                      invest with one click save profile for later. Easy to
                      invest with one click save profile for later.
                    </p>
                  </div>
                  <div className="d-flex justify-content-end align-items-start w-50 my-3">
                    <button
                      //to="/subscription/plans"
                      className="suggested-algo-know-more-btn2 text-4"
                    >
                      Know More
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={6} lg={6}>
            <Card
              //className="text-center"
              style={{
                backgroundColor: "#0A3294",
                margin: 0,
                height: "100%",
              }}
            >
              <CardBody className="p-5 card-height-101">
                <div className="d-flex">
                  <div className="suggested-algo-dashboard-img2 d-flex justify-content-center align-items-center me-3">
                    <img
                      className="card-invest-logo align-middle"
                      src={active}
                      alt=""
                      style={{
                        width: "45px",
                        height: "45px",
                        //borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="" style={{width: '80%'}}>
                    <h4 className="text-7 text-color3 my-3">Onetap Algo</h4>
                    <p
                      className="text-color3 text-11 m-0"
                      style={{ height: "40%" }}
                    >
                      Easy to invest with one click save profile for later. Easy
                      to invest with one click save profile for later. Easy to
                      invest with one click save profile for later. Easy to
                      invest with one click save profile for later.
                    </p>
                  </div>
                  <div className="d-flex justify-content-end align-items-start w-50 my-3">
                    <button
                      //to="/subscription/plans"
                      className="suggested-algo-know-more-btn3 text-4"
                    >
                      Know More
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default Features;
