import { call, put, takeEvery, all, fork, retry } from "redux-saga/effects";

import { GET_REFERRAL_LINK } from "./actionType";

import {
  RefferlLInkApiResponseError,
  RefferlLInkApiResponseSuccess,
} from "./action";
import { postReferralLinkApi } from "../../helpers/fakebackend_helper";


function* ReferralLink(data) {
  try {
    const response = yield call(postReferralLinkApi, data.payload);
    yield put(RefferlLInkApiResponseSuccess(GET_REFERRAL_LINK, response));
  } catch (error) {
    yield put(RefferlLInkApiResponseError(GET_REFERRAL_LINK, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_REFERRAL_LINK, ReferralLink);
}

function* ReferralLinkSaga() {
  yield all([fork(watchGetCart)]);
}

export default ReferralLinkSaga;
