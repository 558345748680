import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Input, Modal, ModalBody, ModalHeader, Label } from "reactstrap";
import { isEmpty } from "lodash";
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import {
  DPSchemeUpdateApiCall,
  DDPIShameESign,
  AfterESignDeduction,
  modification_profile,
  modMailSend
} from "../../../../helpers/fakebackend_helper";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// TODO Ui and design and functions.
const ConfirmChanges2 = (props) => {
  const { ClientID } = useSelector((state) => ({
    ClientID: state.ClientID.ClientID
  }));
  const history = useNavigate();
  const [modStatus, setModStatus] = useState("");
  const [eSign, setESign] = useState(false);
  const [eSignStart, setESignStart] = useState(false);
  const [eSignComplected, setESignComplected] = useState(false);
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const search = useLocation().search;
  const amount = new URLSearchParams(search).get("amount");
  const modalMobile = {
    CUpdate: false,
    otp1: true,
    vNumber1: false,
    otp2: false,
    vNumber2: false,
    done: false,
  };
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));
  const client_code = localStorage.getItem("client_id");
  // function tog_signUpModals() {
  //   // //console.log('submit')
  //   setmodal_signUpModals(!modal_signUpModals);
  // }
  ////console.log(profileDetails);
  const submitHandler = () => {
    ////console.log("submit");
  };

  const eSignfun2 = () => {

    window.location.href = '/profile';

    return false;
    const initialValues ={
      ClientCode: client_code,
      pan_number: profileDetails.userProfile[0].PANGIR,
      IsESign: "Yes",
      IsType : "equity",
      esignType : "dp_scheme",
      full_name : profileDetails.userProfile[0].FIBSACCT,
      email : profileDetails.userProfile[0].EMAIL,
    }

    DDPIShameESign(initialValues)
      .then((response) => {
        ////console.log(response);
        if (
          response.success === true &&
          response.message === "URL Generated Successfully."
        ) {
          // setESignStart2(true);
          // setESign2(false);
          window.open(`${response.data.URLL}`, "_blank");
        } else {
          // setESign2(false);
          // setESignComplected2(true);
          toast.error(`${response.message}`, {
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  function onSubmitHandler(e) {
    ////console.log(props.data);
    // const newValue = {...values, client_code: client_code}
    // //console.log(newValue);
    // const initialValues = {
    //   pan_no: "M9M90Y057",
    //   client_code: 'M9M90Y057',
    //   old_mobile_number: "8097379632",
    //   new_mobile_number: "9821662441",
    // };
    e.preventDefault();
    const value = {
      client_code: client_code,
      pan_no: !isEmpty(profileDetails.userProfile[0])
        ? profileDetails.userProfile[0].PANGIR
        : "",
      old_dp_scheme: !isEmpty(profileDetails.userProfile[0])
        ? profileDetails.userProfile[0].CFEESMAINCODE
        : "",
      new_dp_scheme: props.data,
    };
    // if (props.data.length > 1) {
    //   value.DdpiOption = "All Transaction";
    // } else {
    //   value.DdpiOption = props.data[0];
    // }
    ////console.log(value);
    DPSchemeUpdateApiCall(value)
      .then((response) => {
        ////console.log(response);
        if (response.success === true) {
          setESign(true);
          setModStatus(response.data.modification_id);
        } else {
          //history("/dashboard");
          props.tog(false);
        }
      })
      .catch((err) => {
        ////console.log(err);
      });
    //dispatch(UpdatedPhone(newValue));
  }
  const tog_signUpModals = () => {
    props.tog(false);
  };

  function onSubmitHandler2(e) {
    if(amount == '333'){
      var amount_new  = '333 (Annual)'
    }
    if(amount == '999'){
      var amount_new  = '999 (Life Time)'
    }
    const value = {
    client_code: client_code,
    pan_no: !isEmpty(profileDetails.userProfile[0])
      ? profileDetails.userProfile[0].PANGIR
      : "",
    old_dp_scheme: !isEmpty(profileDetails.userProfile[0])
      ? profileDetails.userProfile[0].CFEESMAINCODE
      : "",
    new_dp_scheme: amount_new,
    };
    console.log('valuevaluevaluevalue',value);
    // if (props.data.length > 1) {
    //   value.DdpiOption = "All Transaction";
    // } else {
    //   value.DdpiOption = props.data[0];
    // }
    ////console.log(value);
    try {
    modification_profile(client_code)
    .then((response) => {
    DPSchemeUpdateApiCall(value)
    .then((response) => {
      ////console.log(response);
      if (response.success === true) {
        const initialValues ={
          ClientCode: client_code,
          pan_number: profileDetails.userProfile[0].PANGIR,
          IsESign: "Yes",
          IsType : "equity",
          esignType : "dp_scheme",
          full_name : profileDetails.userProfile[0].FIBSACCT,
          email : profileDetails.userProfile[0].EMAIL,
        }
    
        DDPIShameESign(initialValues)
          .then((response) => {
            console.log('3');
            if (
              response.success === true &&
              response.message === "URL Generated Successfully."
            ) {
              setESignStart(true);
              setESign(false);
              window.open(`${response.data.URLL}`, "_blank");
            } else {
              setESign(false);
              setESignComplected(null);
              toast.error(`${response.message}`, {
                autoClose: 1500,
              });
            }
          })
          .catch((err) => {
            ////console.log(err);
          });
      }
    })
    .catch((err) => {
      ////console.log(err);
    });
    })
    .catch((err) => {

    console.log('catch ERROR',err);
    });
    }catch(error){

    console.log('ERROR ',error);
    }

    ////console.log(props.data);
    
    //dispatch(UpdatedPhone(newValue));
  }

  useEffect(() => {
    if (eSignStart === true) {
      const initialValues ={
        ClientCode: client_code,
        pan_number: profileDetails.userProfile[0].PANGIR,
        IsESign: "Yes",
        IsType : "equity",
        esignType : "dp_scheme",
        full_name : profileDetails.userProfile[0].FIBSACCT,
        email : profileDetails.userProfile[0].EMAIL,
      }
      let current = setInterval(
        () =>
          DDPIShameESign(initialValues)
            .then((response) => {
              console.log('4');
              if (
                response.success === false &&
                response.message === "ESign Already Done."
              ) {
                setESignStart(false);
                setESignComplected(true);

                var mod_email_mobile = {
                  client_name:profileDetails.userProfile[0].FIBSACCT,
                  mod_type:'dpscheme',
                  mod_mobile:profileDetails.userProfile[0].MOBILE,
                  mod_email:profileDetails.userProfile[0].EMAIL,
                  amount:amount,
                };
                modMailSend(mod_email_mobile)
                .then((response) => {
                  console.log('Mobile sms modification',response);
                })
                .catch((err) => {
                  console.log(err);
                });
               
                AfterESignDeduction({
                  client_code: client_code,
                  pan_no: props.data2.userProfile[0].PANGIR,
                  payment_for: "Update DP Shame",
                  modification_id: modStatus,
                })
                  .then((response) => {})
                  .catch((err) => {
                    ////console.log(err);
                  });
              }
            })
            .catch((err) => {
              ////console.log(err);
            }),
        10000
      );
      if (eSignStart === false) {
        return () => {
          clearInterval(current);
        };
      }
    }
  }, [eSignStart]);

  return (
    <React.Fragment>
      <ModalHeader
        className="px-4 pt-4 justify-content-center"
        toggle={() => {
          tog_signUpModals();
        }}
      >
        {/* {<h4 className="text-2 text-color7 spacing-remove-bottom text-center">
          Confirm Changes
        </h4>} */}
        {eSign === false && eSignComplected === true ? (
              <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
          {/* Proceed For E-Sign */}
          E-sign completed successfully 
        </h4>
            ) : (
              <div>
                <h4 className="text-2 text-color7 spacing-remove-bottom text-center">
                Proceed For E-Sign
                </h4>
              </div>
            )}
      </ModalHeader>
      {eSignStart === false ? (
        <ModalBody className="p-4">
          <form onSubmit={submitHandler()}>
            {/* {eSign === false && eSignComplected === false && (
              <h4
                className="form-check-label text-15 text-color1 text-center"
                htmlFor="checkTerms"
              >
                Updating the DP Scheme will take up to 2 hours to reflect on your profile.
              </h4>
            )} */}
            { eSign === false && eSignComplected === true ? (
              <div>
                <h4 className="text-15 text-color1">
                  {/* E-Sign Complete */}
                  Your DP Scheme may require up to 48 hours for the changes to be reflected in your profile. 
                </h4>
              </div>
            ) : (
              <div>
                <h4 className="text-15 text-color1 mt-2 text-center">
                  {/* Changing DP Scheme requires E-Sign */}
                  To update your DP scheme, it is mandatory to proceed with the e-sign process. 
                </h4>
              </div>
            )}
            <div className="text-center">
              { eSign === false && eSignComplected === true ? (
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={() => eSignfun2()}
                >
                  {" "}
                  Okay
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={(e) => onSubmitHandler2(e)}
                >
                  Continue
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      ) : (
        <ModalBody className="p-4">
          <Loader />
        </ModalBody>
      )}
    </React.Fragment>
  );
};

// ConformUpdate.propTypes = {
//   onCloseClick: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   show: PropTypes.any,
//   sendData: PropTypes.func
// };

export default ConfirmChanges2;
