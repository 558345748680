import {
  API_MF_RESPONSE_SUCCESS,
  API_MF_ERROR,
  GET_MF
} from "./actionType";

const INIT_STATE = {
  mf: {}
};

const Mf = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_MF_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MF:
          return {
            ...state,
            mf: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_MF_ERROR:
      switch (action.payload.actionType) {
        case GET_MF:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_MF: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default Mf;
