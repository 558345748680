import {
  API_CODE_ENCRYPTION_SUCCESS,
  API_CODE_ENCRYPTION_FAIL,
  GET_CODE_ENCRYPTION,
} from "./actionType";

const INIT_STATE = {
  encryptedCode: [],
};

const codeEncryptionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_CODE_ENCRYPTION_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CODE_ENCRYPTION:
          return {
            ...state,
            encryptedCode:
              action.payload.data !== null ? action.payload.data : [],
          };
        default:
          return { ...state };
      }
    case API_CODE_ENCRYPTION_FAIL:
      switch (action.payload.actionType) {
        case GET_CODE_ENCRYPTION:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_CODE_ENCRYPTION: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default codeEncryptionReducer;
