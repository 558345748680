import {
  GET_MY_INVESTED_ALGO_TOP,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  API_MY_INVESTED_ALGO_GRAPH_SUCCESS,
  API_MY_INVESTED_ALGO_GRAPH_ERROR,
  GET_MY_INVESTED_ALGO_GRAPH
} from "./actionType";

const INIT_STATE = {
  myInvestedAlgos: {},
  myInvestedAlgosGraph: {}
};

const MyInvestedAlgos = (state = INIT_STATE, action) => {
  ////console.log(action.payload);
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MY_INVESTED_ALGO_TOP:
          return {
            ...state,
            myInvestedAlgos: action.payload.data,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_MY_INVESTED_ALGO_TOP:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_MY_INVESTED_ALGO_TOP: {
      return {
        ...state,
      };
    }
    //
    case API_MY_INVESTED_ALGO_GRAPH_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MY_INVESTED_ALGO_GRAPH:
          return {
            ...state,
            myInvestedAlgosGraph: action.payload.data,
          };
        default:
          return { ...state };
      }
    case API_MY_INVESTED_ALGO_GRAPH_ERROR:
      switch (action.payload.actionType) {
        case GET_MY_INVESTED_ALGO_GRAPH:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_MY_INVESTED_ALGO_GRAPH: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default MyInvestedAlgos;
