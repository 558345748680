import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_MY_FEATURE_SUGGESTED_PLAN } from "./actionType";

import {
  MyFeatureSuggestedPlanApiResponseSuccess,
  MyFeatureSuggestedPlanApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getMyFeatureSuggestedPlanApi } from "../../helpers/fakebackend_helper";

function* getMyFeatureSuggestedPlan(data) {
  try {
    const response = yield call(getMyFeatureSuggestedPlanApi, data.data);
    yield put(MyFeatureSuggestedPlanApiResponseSuccess(GET_MY_FEATURE_SUGGESTED_PLAN, response));
  } catch (error) {
    yield put(MyFeatureSuggestedPlanApiResponseError(GET_MY_FEATURE_SUGGESTED_PLAN, error));
  }
}

export function* watchGetMyFeatureSuggestedPlan() {
  yield takeEvery(GET_MY_FEATURE_SUGGESTED_PLAN, getMyFeatureSuggestedPlan);
}

function* MyFeatureSuggestedPlanSaga() {
  yield all([fork(watchGetMyFeatureSuggestedPlan)]);
}

export default MyFeatureSuggestedPlanSaga;
