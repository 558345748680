import {
  GET_AVAILABLE_MARGIN,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  API_BANK_DETAIlS_RESPONSE_SUCCESS,
  API_BANK_DETAIlS_RESPONSE_ERROR,
  GET_BANK_DETAIlS,
  WITHDRAW_RESET,
  API_WITHDRAW_OPEN_SUCCESS,
  API_WITHDRAW_CONFORM_SUCCESS,
  API_WITHDRAW_SUCCESS,
  API_WITHDRAW_ERROR,
  GET_WITHDRAW
} from "./actionType";

// available margin common success
export const MarginOverviewApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// available margin common error
export const MarginOverviewApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});
// available margin 
export const getMarginOverview = (data) => ({
  type: GET_AVAILABLE_MARGIN,
  payload: data,
});

// bank details common success
export const BankDetailsApiResponseSuccess = (actionType, data) => ({
  type: API_BANK_DETAIlS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// bank details common error
export const BankDetailsApiResponseError = (actionType, error) => ({
  type: API_BANK_DETAIlS_RESPONSE_ERROR,
  payload: { actionType, error },
});
// bank details 
export const getBankDetails = (data) => ({
  type: GET_BANK_DETAIlS,
  payload: data,
});

// withdraw
export const withdrawReset = () => ({
  type: WITHDRAW_RESET,
});

// withdraw
export const withdrawOpenApi = () => ({
  type: API_WITHDRAW_OPEN_SUCCESS,
});

// withdraw
export const conformOpenApi = () => ({
  type: API_WITHDRAW_CONFORM_SUCCESS,
});

// withdraw
export const withdrawApiResponseSuccess = (actionType, data) => ({
  type: API_WITHDRAW_SUCCESS,
  payload: { actionType, data },
});
// withdraw
export const withdrawApiResponseError = (actionType, error) => ({
  type: API_WITHDRAW_ERROR,
  payload: { actionType, error },
});
// withdraw 
export const withdraw = (data) => ({
  type: GET_WITHDRAW,
  payload: data,
});