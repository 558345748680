import React, { useState } from "react";
import _, { isEmpty } from "lodash";

import { Card, CardBody, Col, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
// action
import { addToCart, addToCartApiResponseSuccess } from "../../store/cart/action";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//import "swiper/css/pagination";
import active from "../../assets/images/logo/active.png";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Autoplay } from "swiper";
import MyFeature from "../../Components/CustomComponents/Models/myFeature/myFeature";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import Encripted from "../Dashboard/Encripted";
import { addItemToCartApi } from "../../helpers/fakebackend_helper";

const AvailableFeatures = (props) => {
  const dispatch = useDispatch();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const symphony_token = localStorage.getItem("symphony_token");
  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));
  const [loading, setLoading] = useState({});

  const charactersLimit = (data, limit) => {
    //  ////console.log(data);
    // const stringWithTags = "<h1>Hello, World!</h1>";
    const withoutTags = data.replace(/(<([^>]+)>)/gi, "");
    //  ////console.log(withoutTags);
    if (withoutTags === undefined) {
      return withoutTags;
    }
    if (data !== null || data !== undefined) {
      if (withoutTags.length > limit) {
        return withoutTags.substring(0, limit).concat(" ...");
      }
    }
    return withoutTags;
  };
  const { data, data2, myPlan } = props;


  const active_features = data2.map((item) => item.feature_id);

  const filteredUsers = Cart.filter((el) => {
    return el.feature_or_plan === "Plan";
  });
  const filteredUsers2 =
    data.length > 0
      ? data.filter((el) => {
        ////console.log(el.feature_durations[0].feature_id )
        ////console.log(myPlan)
        return el.feature_durations[0].feature_id !== data2.feature_id;
      })
      : [];

  const RegisteredEvents = data.filter((obj1) => {
    return !filteredUsers2.some((obj2) => {
      return +obj1.id === +obj2.feature_id;
    });
  });
  const featureDurationIds = Cart?.flatMap(item => item?.features?.feature_durations?.map(duration => duration?.id));

  const addToCartHandler = (data) => {

    const checkids = data.feature_durations.map(duration => duration.id);


    const item = {
      client_code: client_code,
      client_token: client_token,
      feature_or_plan_id: data.id,
      feature_or_plan: "Feature",
      duration_type: data.feature_durations[0].duration_type,
    };
    const allElementsPresent = checkids.every(element => featureDurationIds.includes(element));

    if (allElementsPresent) {
      toast.error("A brokerage plan already exists in the cart", { autoClose: 3000 });
    } else {
      setLoading(prevState => ({ ...prevState, [data.id]: true }));
      addItemToCartApi(item)
        .then((res) => {
          if (res.status == true) {
            dispatch(addToCartApiResponseSuccess(res))
            setLoading(prevState => ({ ...prevState, [data.id]: false }));
          }


        })
        .catch((error) => {
          console.log(error, 'error');

          setLoading(prevState => ({ ...prevState, [data.id]: false }));

          toast.error("Failed to add item to cart");
        })
    }

  };
  const candidateListData = [
    {
      id: 1,
      img: active,
      name: "Algo Invest 1",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 2,
      img: active,
      name: "Algo Invest 2",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 3,
      img: active,
      name: "Algo Invest 3",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 4,
      img: active,
      name: "Algo Invest 4",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 5,
      img: active,
      name: "Algo Invest 5",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 6,
      img: active,
      name: "Algo Invest 6",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
    {
      id: 7,
      img: active,
      name: "Algo Invest 7",
      username: "@tonya",
      designation:
        "Easy to invest with one click save profile for later. Easy to invest with one click save profile for later. ",
      points1: "Easy to invest with one click save profile for later.",
    },
  ];
  // const match = Cart.find((el) => {
  //   return el.feature_or_plan_id === item.feature_or_plan_id;
  // });
  // const getParameters = (item) => {
  //   if (item?.parmeters_name_arr === 'NA') {
  //     return '';
  //   }

  //   const params = item?.parmeters_name_arr?.map(param => {
  //     let value
  //     if (param?.enc_value == 1) {
  //       value = btoa(localStorage.getItem(param?.param_value));

  //     } else {
  //       value = localStorage.getItem(param?.param_value);
  //     }

  //     return param.parameter + '=' + (value !== null ? value : param?.param_value);
  //   }).join('&');

  //   return params;
  // };


  const getParameters = (item) => {
    if (item?.parmeters_name_arr === 'NA') {
      return '';
    }
  
    const params = item?.parmeters_name_arr?.map(param => {
      let value = localStorage.getItem(param?.param_value);
  
      if (param?.enc_value == 1) {
        // Encrypt all values except for 'client_id' and 'terminal_code'
        if (param?.param_value !== 'client_id' && param?.param_value !== 'terminal_code') {
          value = btoa(value);
        }
      }
  
      return param.parameter + '=' + (value !== null ? value : param?.param_value);
    }).join('&');
  
    return params;
  };
  


  const feature_cat_id = Cart?.map((item) => {
    // console.log("gg", item.plans);
    const idsArray = item.plans?.feature_ids
      ?.split(",")
      .map((id) => parseInt(id.trim()));
    return idsArray || [];
  });
  const flatFeatureCatIds = feature_cat_id?.flat() || [];

  const planValArr =
    myPlan?.map((item) => item.features.map((fe) => fe.id)).flat() || [];
  let global_type = btoa(client_token)
  return (
    <React.Fragment>
      <Col xxl={11} xl={11} className="mx-auto my-0">
        <Row className="mt-4 justify-content-center">
          {RegisteredEvents.length > 0 ? (
            RegisteredEvents.map((item, index) => {
              const match = Cart.find((el) => {
                ////console.log(el)
                return (
                  el.feature_or_plan !== "Plan" &&
                  el.feature_or_plan_id === item.id
                );
              });

              const match2 =
                !isEmpty(myPlan) &&
                myPlan.some((plan) => {
                  return plan.features.some((feature) => {
                    return feature.id === item.id;
                  });
                });

              return (
                <React.Fragment key={index}>
                  <Col xl={3} lg={4} md={4} sm={6}>
                    <Card>
                      <CardBody
                        className="pt-3 pb-3 px-3"
                        style={{ height: "260px" }}
                      >
                        <div
                          className="d-flex mb-1 align-items-center"
                          style={{ height: "60px" }}
                        >
                          <div className="avatar-sm flex-shrink-0">
                            <span className="text-primary rounded-circle fs-1">
                              <div className="card-invest-div-logo rounded-circle align-middle background-color2">
                                <img
                                  className="card-invest-logo align-middle"
                                  src={item.logo || candidateListData[0].img}
                                  style={{ width: "45px", height: "45px", contain: 'content' }}
                                />
                              </div>
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="text-28 text-color6 text-end margin-0">
                              {/* Rs{" "}
                              {Number(
                                item.feature_durations[0].amount
                              ).toLocaleString("en-US")}{" "}
                              /- */}
                              {Number(item.feature_durations[0].amount) === 0
                                ? "Free"
                                : `₹ ${Number(
                                  item.feature_durations[0].amount
                                ).toFixed(2)}`}
                            </h6>
                            <h5 className="mt-1 text-1 text-color2 text-end">
                              {Number(item.feature_durations[0].amount) === 0
                                ? " "
                                : item.feature_durations[0].duration_type ||
                                "Per Month"}
                            </h5>
                          </div>
                        </div>
                        <h5
                          className="mt-2 text-6 text-color1"
                          style={{ height: "30px" }}
                        >
                          {charactersLimit(item.title, 18) ||
                            candidateListData[0].name}
                        </h5>
                        <p
                          className="text-color1 text-17 mt-2"
                          style={{ height: "60px" }}
                        >
                          {charactersLimit(item.short_description, 80) ||
                            candidateListData[0].designation}
                        </p>
                        <div className="d-flex justify-content-between">
                          {/* <button className="text-11 text-color7 profile-edit-button1">
                        View Details
                      </button> */}
                          {/* {console.log("flatFeatureCatIdsff",flatFeatureCatIds)} 
                           {console.log("flatFeatureCatIdfffffsff",item?.id)} */}
                          <MyFeature
                            data={item.id}
                            match2={!isEmpty(match2) ? true : false}
                            planexits={planValArr?.includes(item?.id)}
                            cartexist={flatFeatureCatIds.includes(
                              parseInt(item?.id)
                            )}
                          />
                          {/* {console.log('item.token_parameter_nameitem.token_parameter_nameitem.token_parameter_name',item  )} */}
                          {Number(item.feature_durations[0].amount) === 0 ? (
                            item.sso_url ? (
                              <a
                                href={
                                  item.sso_url +
                                  "?" +
                                  getParameters(item)
                                }
                                target="_blank"
                                login_source={item.login_source}
                                className="sub-access-btn"
                              >
                                <button
                                  className="text-4 btn btn-primary addons-feature-button-normal sub-access-btn"
                                  disabled
                                  onClick={() => {
                                    addToCartHandler(item);
                                  }}
                                  style={{
                                    backgroundColor: "#00ffc1",
                                    color: "black",
                                    fontSize: "13px",
                                  }}
                                >
                                  Access Now
                                </button>
                              </a>
                            ) : (
                              <button
                                className="text-4 btn btn-primary addons-feature-button-normal"
                                disabled
                                onClick={() => {
                                  addToCartHandler(item);
                                }}
                              >
                                Subscribed
                              </button>
                            )
                          ) : !isEmpty(match) ||
                            flatFeatureCatIds.includes(parseInt(item?.id)) ? (
                            <button
                              className="text-4 btn btn-primary addons-feature-button-normal"
                              disabled
                              onClick={() => {
                                addToCartHandler(item);
                              }}
                            >
                              <i className="ri-check-line fs-15 align-middle pe-2 text-light"></i>{" "}
                              Added
                            </button>
                          ) : planValArr?.includes(parseInt(item?.id)) ||
                            active_features?.includes(parseInt(item?.id)) ? (
                            item.sso_url ? (
                              <a
                                href={
                                  item.sso_url +
                                  "?" +
                                  getParameters(item)
                                }
                                target="_blank"
                                login_source={item.login_source}
                              >
                                <button
                                  className="text-4 btn btn-primary addons-feature-button-normal"
                                  disabled
                                  onClick={() => {
                                    addToCartHandler(item);
                                  }}
                                  style={{
                                    backgroundColor: "#00ffc1",
                                    color: "black",
                                    fontSize: "13px",
                                  }}
                                >
                                  Access Now
                                </button>
                              </a>
                            ) : (
                              <button
                                className="text-4 btn btn-primary addons-feature-button-normal"
                                disabled
                                onClick={() => {
                                  addToCartHandler(item);
                                }}
                              >
                                Subscribed
                              </button>
                            )
                          ) : (
                            <button
                              className="addons-feature-button-normal11"
                              disabled={loading[item.id]} // Disable button when loading

                              onClick={() => {
                                addToCartHandler(item);
                              }}
                            >
                              Add to Cart
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              );
            })
          ) : (
            <h4 className="text-center">Suggested Feature Not Available!</h4>
          )}
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default AvailableFeatures;
