import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label
} from "reactstrap";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import * as moment from "moment";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader1 from "../../Components/Common/Loader1";
//action
import { getHoldings } from "../../store/statements/action";
//Import Breadcrumb
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const Holdings = (props) => {
  const dispatch = useDispatch();
  const [startDate1, setStartDate1] = useState(new Date().setDate(new Date().getDate() - 7));
  const [endDate1, setEndDate1] = useState(new Date());
  const [isDownload, setIsDownload] = useState(false);
  const startDate = new Date();
  const endDate = new Date() ;
  startDate.setDate(startDate.getDate() - 7);
  
  const [selectDate, setSelectDate] = useState({date: [startDate, endDate]});
  const { generatedKey } = useSelector((state) => ({
    generatedKey: state.GenerateKey.generatedKey,
  }));
  const { encryptKey } = useSelector((state) => ({
    encryptKey: state.EncryptKey.encryptKey,
  }));
  const { Holdings } = useSelector((state) => ({
    Holdings: state.Statements.Holdings,
  }));
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
 // console.log(Holdings);
  const holdingTotal = Holdings.reduce(function (acc, val) {
    return acc + val.PNL;
  }, 0);
  //console.log(holdingTotal)
  useEffect(() => {
    dispatch(getHoldings({apiKey: props.generatedKey, code: props.encryptKey, startDate: dayjs(startDate1).format("YYYY-MM-DD"), endDate: dayjs(endDate1).format("YYYY-MM-DD")}));
  }, [dispatch]);
  
  const DateFilter = () => {
    dispatch(
      getHoldings({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
      })
    );
  };
  const TradeBookCash = [
    {
      ScriptCode : '31 INFOTECH',
      ScriptName : '31 INFOTECH',
      ISINNo: 'INE982B01019',
      Qty : '1',
      NetValue: '00.00'
    },
    {
      ScriptCode : 'AVONMORE CAPITAL',
      ScriptName : 'AVONMORE CAPITAL & M',
      ISINNo: 'INE982B01019',
      Qty : '1',
      NetValue: '00.00'
    },
    {
      ScriptCode : 'AVONMORE CAPITAL',
      ScriptName : 'AVONMORE CAPITAL & M',
      ISINNo: 'INE982B01019',
      Qty : '1',
      NetValue: '00.00'
    },
    {
      ScriptCode : 'AVONMORE CAPITAL',
      ScriptName : 'AVONMORE CAPITAL & M',
      ISINNo: 'INE982B01019',
      Qty : '1',
      NetValue: '00.00'
    },
    {
      ScriptCode : 'AVONMORE CAPITAL',
      ScriptName : 'AVONMORE CAPITAL & M',
      ISINNo: 'INE982B01019',
      Qty : '1',
      NetValue: '00.00'
    },
  ];

  const AmountFormatter = (data) => {
    switch (true) {
      case data.value > 0 :
        return (
          <div className="text-color6 ms-5">
            {data.value.toFixed(2)}
          </div>
        );
      case data.value < 0:
        return (
          <div className="text-color11 ms-5">
            {data.value.toFixed(2)}
          </div>
        );
      default:
        return <div className="ms-5">{data.value.toFixed(2)}</div>;
    }
  };

  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   //TODO
      //   Header: "#",
      //   Cell: (cell) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="productCheckBox form-check-input"
      //         value={cell.row.original._id}
      //         //onClick={() => displayDelete()}
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Script Code",
      //   accessor: "Scripcode",
      //   filterable: true,
      // },
      {
        //Header: "Scrip Name",
        Header: <h4 className="d-flex m-0 fs-13">Scrip Name</h4>,
        accessor: "ScripName",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex" >{cell.value}</span>;
        },
      },
      // {
      //   Header: "ISIN No",
      //   accessor: "Isin",
      //   filterable: true,
      // },
      {
        Header: "Quantity",
        accessor: "Netqty",
        filterable: true,
      },
      {
        //Header: "Buy Average",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Buy Average</h4>,
        accessor: "Netavg",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
      },
      {
        //Header: "Invested Value",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Invested Value</h4>,
        accessor: "Netval",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
      },
      {
        //Header: "Market Rate",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Market Rate</h4>,
        accessor: "Mark",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
      },
      {
        //Header: "Market Value",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Market Value</h4>,
        accessor: "Markval",
        filterable: true,
        Cell: (cell) => {
          return <span className="d-flex justify-content-end" >{cell.value.toFixed(2)}</span>;
        },
      },
      // {
      //   Header: "Amount  (₹)",
      //   accessor: "Net_val",
      //   filterable: true,
      //   //Cell: AmountFormatter
      // },
      {
        //Header: "Profit / loss",
        Header: <h4 className="d-flex justify-content-end m-0 fs-13">Profit / loss</h4>,
        accessor: "PNL",
        filterable: true,
        Cell: (cell) => {
          switch (true) {
            case cell.value > 0:
              return <div className="text-color6 d-flex justify-content-end">{cell.value.toFixed(2)}</div>;
            case cell.value < 0:
              return <div className="text-color11 d-flex justify-content-end">{cell.value.toFixed(2)}</div>;
            default:
              return <div className="d-flex justify-content-end">{cell.value.toFixed(2)}</div>;
          }
        },
        //Cell: AmountFormatter
      }
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  function getCurrentFinancialYear(date) {
    ////console.log(date)
    var fiscalyear = "";
    var today = new Date(date.length > 0 && date[1]);
    ////console.log(today)
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    ////console.log(fiscalyear)
    return fiscalyear
  }
  useEffect(() => {
    getCurrentFinancialYear(selectDate.date)
  },[selectDate]);
  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  const myArray = [];
  for (let i = 0; i < Holdings.length; i += 1) {
    const childArray = Holdings[i];
    const obj = {
      ScripName: childArray.ScripName,
      Netqty: childArray.Netqty,
      Netavg: childArray.Netavg,
      Netval: childArray.Netval,
      Mark: childArray.Mark,
      Markval: childArray.Markval,
      PNL: childArray.PNL
    };
    myArray.push(obj);
  }

  const userHeader = [
    "Scrip Name",
    "Quantity",
    "Buy Average",
    "Invested Value",
    "Market Rate",
    "Market Value",
    "Profit(+)/loss(-)",
  ];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;
    
  const encodedUri = encodeURI(csvContent);
 
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${!isEmpty(profileDetails) ? profileDetails[0].FIBSACCT.trim() : "USER"}_${dayjs(new Date()).format("YYYY-MM-DD")}_Holdings`);
  document.body.appendChild(link);
  useEffect(() => {
		if (isDownload === true) {
			link.click();
		}
    setIsDownload(false);
	}, [isDownload]);

  const downloadHandler = () => {
    setIsDownload(true);
  }

  return (
    <React.Fragment>
       {/* <div>
        <div className="d-flex justify-content-between p-3">
          <div className="input-group input-group-width-25">
            <Flatpickr
              placeholder="Select date"
              id="exampleInputdate"
              className="form-control summary-table-date"
              options={{
                mode: "range",
                maxRange: 1,
                dateFormat: "d M, Y",
                maxDate: new Date(),
              }}
              value={selectDate.date}
              onChange={date => {
                setSelectDate({ date: date });
              }}
            />
            <Label htmlFor="exampleInputdate" className="form-label">
              <span
                className="input-group-text summary-table-date px-2 py-0"
                id="basic-addon1"
              >
                <i className="ri-calendar-2-line fs-4 text-color1"></i>
              </span>
            </Label>
          </div>
          <div></div>

          <div className="d-flex">
            <button
                type="button"
                className="btn btn-success text-4 margin-overview-button2"
                style={{ borderRadius: "5px" }}
                onClick={downloadHandler}
              >
              <i className="ri-download-2-line me-2 align-bottom"></i>
              Download Report
            </button>
          </div>
        </div>
      </div> */}
            <div>
        <div className="d-flex justify-content-between align-items-end holding-download p-3" style={{borderBottom: '1px solid #8080802b'}}>
          <div className="d-flex align-items-end">
            {/* <div className="input-group-width-50 me-4 mb-2">
            <h5 className="text-10"> From </h5>
              <div className="d-flex">
                <Flatpickr
                  placeholder="Select date"
                  id="exampleInputdate"
                  className="form-control summary-table-date"
                  options={{
                    //mode: "range",
                    dateFormat: "d M, Y",
                    maxDate: new Date(),
                  }}
                  value={startDate1}
                  onChange={(date) => {
                    ////console.log(date);
                    setStartDate1(date[0]);
                  }}
                />
                <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label>
              </div>
            </div> */}

            {/* <div className="input-group-width-50 me-4 mb-2">
            <h5 className="text-10"> To</h5>
              <div className="d-flex">
                <Flatpickr
                  placeholder="Select date"
                  id="exampleInputdate"
                  className="form-control summary-table-date"
                  options={{
                    //mode: "range",
                    dateFormat: "d M, Y",
                    maxDate: new Date(),
                  }}
                  value={endDate1}
                  onChange={(date) => {
                    ////console.log(date);
                    setEndDate1(date[0]);
                  }}
                />
                <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label>
              </div>
            </div> */}
            {/* <button
              type="button"
              className="text-4 mb-2 btn btn-primary margin-overview-button1"
              style={{ borderRadius: "5px", height: "50%" }}
              onClick={DateFilter}
            >
              Search
            </button> */}
          </div>
          <div className="text-center mobile-view-cashbal">
          <h5 className="text-10 text-end text-color7 mob-txt-amt"> 
            <span>P&L :</span>
            <span className={`text-10  ${holdingTotal > 0 ? "text-color6" : holdingTotal < 0 ? "text-color11" : "text-color7"}`}> {holdingTotal.toLocaleString("en-IN").toString()}</span>
          </h5>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-success text-4  margin-overview-button2 new_btn"
              style={{ borderRadius: "5px",  height: "50%"  }}
              onClick={downloadHandler}
              disabled={Holdings.length === 0}
            >
              <i className="ri-download-2-line me-2 align-bottom"></i>
              Download Report
            </button>
          </div>
          </div>
        </div>
      </div>
            {Holdings.length ? (
              <TableContainer2
                columns={columns}
                data={Holdings || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={8}
                divClass="table-responsive table-card mb-1"
                tableClass="align-middle table-nowrap text-center"
                theadClass="table-light text-muted text-uppercase fs-13"
                tableTrClasses='summary-table-tr px-4'
               // handleOrderClick={handleCustomerClicks}
                isOrderFilter={true}
                SearchPlaceholder="Search for order ID, customer, order status or something..."
              />
            ) : (
              <Loader1 />
            )}
    </React.Fragment>
  );
};

export default Holdings;
