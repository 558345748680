import React from "react";
import { Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";
import SubscriptionPlanHistoryTable from './SubscriptionPlanHistory';
import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";

const MySubscriptionPlans = () => {
  document.title = "Bigul Admin & Dashboard";
  
  const search = useLocation().search;
  const client_code = localStorage.getItem("client_id");
  // if(client_code == undefined || client_code == 'NULL' || client_code == ""){
    
  //   const client_code = new URLSearchParams(search).get('client_id');
  //   const token = new URLSearchParams(search).get('token');
  //   const symphony_token = new URLSearchParams(search).get('symphony_token');
  //   // console.log(token)
  //   let decodedStringAtoB = atob(client_code);
  //   let decodedStringAtoc = atob(token); // TODO seve to client_token
  //   let decodedStringAtod = atob(symphony_token); // TODO seve to client_token

  //   if(client_code !== null) {
  //     localStorage.setItem('client_id', decodedStringAtoB);
  //     localStorage.setItem('client_token', decodedStringAtoc); // TODO seve to client_token
  //     localStorage.setItem('symphony_token', decodedStringAtod); // TODO seve to client_token
  //   }
  // }
  console.log(client_code)

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="My Statements"
            titleMain="My Statements"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />
          <Row>
            <SubscriptionPlanHistoryTable />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MySubscriptionPlans;
