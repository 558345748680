import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";

//Import Breadcrumb
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";

const MyPlansHeader = () => {
  // document.title = "Shopping Cart | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <Col xs={12} >
        <div
          style={{ width: "100%", backgroundColor: "#5367FC" }}
          className="p-3"
        >
          <h4 className="text-23 text-color3 mb-0 mt-5 mb-5">
          Tech Algo Platform
          </h4>
        </div>
        <div style={{ width: "100%" }} className="px-3 py-5">
        <h6 className="text-4 text-color1 mb-0">
          Here you can check your active subscription plans, and manage which one to activate and deactivate. You can also check for feature expiry date. Here you can check your active subscription plans, and manage which one to activate and deactivate. You can also check for feature expiry date. Here you can check your active subscription plans, and manage which one to activate and deactivate. You can also check for feature expiry date. Here you can check your active subscription plans, and manage which one to activate and deactivate.
          </h6>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default MyPlansHeader;
