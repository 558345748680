import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
//redux
import { useSelector, useDispatch } from "react-redux";
//reuseable component
import BreadCrumb from "../../../Components/CustomComponents/BreadCrumb";

//Dashboard components
import EcommerceCheckout from './EcommerceCheckout';
import { getProfileDetails } from "../../../store/Profile/action";
import {
  DPSchemeUpdateApiCall,
  DDPIShameESign,
  AfterESignDeduction,
} from "../../../helpers/fakebackend_helper";
// IMAGE
//import bigul_bg from "../../assets/images/logo/bigul_bg.png";


const DPSchemeOrderSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const { VerifyOtpData } = useSelector((state) => ({
    VerifyOtpData: state.VerifyOTP.oderDetails,
  }));

  const [modStatus, setModStatus] = useState("");
  const [eSign, setESign] = useState(false);
  const [eSignStart, setESignStart] = useState(false);
  const [eSignComplected, setESignComplected] = useState(false);

  // useEffect(() => {
  //   if (VerifyOtpData.length === 0) {
  //     return navigate("/dashboard");
  //   }
  // },[VerifyOtpData]);
  ////console.log(VerifyOtpData);
  document.title = "Bigul Admin & Dashboard";

  const client_id = new URLSearchParams(search).get("ClientCode");
  const amount = new URLSearchParams(search).get("amount");
  const orderId = new URLSearchParams(search).get("insertId");
  const upiTransactionId = new URLSearchParams(search).get("upiTransactionId");
  const status = new URLSearchParams(search).get("status");
  const scheme = new URLSearchParams(search).get("dpSchemeName");
  console.log(scheme);
  const generatedKey  = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));
  console.log(profileDetails);
  let decodedStringAtoB = atob(status);
  console.log(decodedStringAtoB)
  useEffect(() => {
    if(decodedStringAtoB === "payment") {
      dispatch(getProfileDetails({ client_code: client_id }));
      if (!isEmpty(profileDetails.userProfile[0])) {
        const value = {
          client_code: client_id,
          pan_no: profileDetails.userProfile[0].PANGIR,

          old_dp_scheme: profileDetails.userProfile[0].CFEESMAINCODE,

          new_dp_scheme: scheme,
        };
        // if (props.data.length > 1) {
        //   value.DdpiOption = "All Transaction";
        // } else {
        //   value.DdpiOption = props.data[0];
        // }
        ////console.log(value);
        DPSchemeUpdateApiCall(value)
          .then((response) => {
            ////console.log(response);
            if (response.success === true) {
              setESign(true);
              setModStatus(response.data.modification_id);
            } else {
              //history("/dashboard");
              //props.tog(false);
            }
          })
          .catch((err) => {
            ////console.log(err);
          });
      }
    }

  }, [decodedStringAtoB]);

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Payment Success"
            titleMain="Dp Scheme "
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Profile"
            to='/profile'
          />

          <Row>

            <EcommerceCheckout  generatedKey={generatedKey} encryptKey={encryptKey} data={VerifyOtpData} />
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default DPSchemeOrderSummary;
