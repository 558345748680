import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
//Import actions
import { getMyFeaturePlan } from "../../store/myFeaturePlan/action";
import { getMyFeatureSuggestedPlan } from "../../store/myFeatureSuggestedPlan/action";
import { getMyPlan } from "../../store/myPlan/action";
import { getMyPlanDuration } from "../../store/myPlanDuration/action";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";
import MyPlansHeader from "./MyPlansHeader";
import Features from "./Features";
import AvailableFeatureHeader from "./AvailableFeatureHeader";
import AvailableFeatures from "./AvailableFeatures";
import MyPlansFooter from "./MyPlansFooter";

const Pledge = () => {
  //document.title = "Bigul Admin & Dashboard";

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");

  const dispatch = useDispatch();

  const { myFeaturePlan } = useSelector((state) => ({
    myFeaturePlan: state.MyFeaturePlan.myFeaturePlan,
  }));
  const { myFeatureSuggestedPlan } = useSelector((state) => ({
    myFeatureSuggestedPlan: state,
  }));

  const { myPlan } = useSelector((state) => ({
    myPlan: state.MyPlan.myPlan,
  }));
  ////console.log(myPlan)
  const { myPlanDuration } = useSelector((state) => ({
    myPlanDuration: state.MyPlanDuration.myPlanDuration,
  }));
  ////console.log(myPlanDuration)
  useEffect(() => {
    dispatch(
      getMyPlan({ client_code: client_code, client_token: client_token })
    );
    dispatch(
      getMyPlanDuration({
        client_code: client_code,
        client_token: client_token,
      })
    );
  }, [dispatch, client_code, client_token]);

  ////console.log(myFeaturePlan);
  ////console.log(myFeatureSuggestedPlan.MyFeatureSuggestedPlan.myFeatureSuggestedPlan);
  useEffect(() => {
    dispatch(
      getMyFeaturePlan({ client_code: client_code, client_token: client_token })
    );
    dispatch(
      getMyFeatureSuggestedPlan({
        client_code: client_code,
        client_token: client_token,
      })
    );
  }, [dispatch, client_token, client_code]);

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Pledge"
            titleMain="Pledge"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Margin Overview"
            to="/overview"
          />
          <Row className="mt-5">
            <MyPlansHeader />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pledge;
