import {
  GET_REFERRAL_LINK,
  API_REFERRAL_LINK_SUCCESS,
  API_REFERRAL_LINK_FAIL,
  API_REFERRAL_NUMBER_SUCCESS,
  API_REFERRAL_NUMBER_FAIL,
  GET_REFERRAL_NUMBER,
  RESET_REFERRAL_STATE,
} from "./actionType";

export const AddRefferlApiResponseSuccess = (actionType, data) => ({
  type: API_REFERRAL_NUMBER_SUCCESS,
  payload: { actionType, data },
});

export const AddRefferlApiResponseError = (actionType, error) => ({
  type: API_REFERRAL_NUMBER_FAIL,
  payload: { actionType, error },
});

export const getaddRefferlNumber = (data) => ({
  type: GET_REFERRAL_NUMBER,
  payload: data,
});

export const resetReferralState = () => ({
  type: RESET_REFERRAL_STATE,
});