import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import "../Dashboard/masonry.css";
//action
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import { getCollateral } from "../../store/Collateral/action";
import {
  downloadMarginOverviewApi,
  latestUpdateOnApi,
  ledgerApi,
  marginApi,
  collateralApi,
  SymphonyWithdrawalBalance,
} from "../../helpers/fakebackend_helper";

// image
import WithdrawFunds from "../../Components/CustomComponents/Models/withDraw/WithdrawFunds";
import Encripted from "../Dashboard/Encripted";
import loaderImage from "../../assets/images/Loader.png";

const MarginOverview = (props) => {
  var advancedFormat = require("dayjs/plugin/advancedFormat");
  dayjs.extend(advancedFormat);
  const [isDownload, setIsDownload] = useState(false);
  const [file, setFile] = useState([]);
  const [updateData, setUpdateData] = useState("");
  const { generatedKey, encryptKey } = props;
  const [loading, setLoading] = useState(false);

  const [ledger, setLedger] = useState({});
  const [margin, setMargin] = useState({});
  const [collateral, setCollateral] = useState({});
  const [ledgerBalances, setLedgerBalance] = useState(0);
  const [marginBalances, setMarginBalance] = useState(0);
  const [WithdrawalAmount, setWithdrawalBalance] = useState(0);
  const [TotalValue, setTotalValue] = useState(0);
  const [cashBalance, setcashBalance] = useState(0);
  const [collatral, setcollatral] = useState(0);
  const [marginUsed, setmarginUsed] = useState(0);

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const symphony_token = localStorage.getItem("symphony_token");
  const client_type = localStorage.getItem("client_type");

  const dispatch = useDispatch();
  const startDate = new Date();
  const endDate = new Date();
  endDate.setDate(endDate.getDate() - 30);
  const [selectDate, setSelectDate] = useState({
    date: [endDate.setDate(endDate.getDate() - 30), startDate],
  });

  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getCollateral({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch]);

  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));
  console.log(availableMargin, "ooo");

  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));

  // console.log(availableMargin);
  //console.log(Collateral);
  // console.log(ledger);
  // console.log(margin);

  console.log(WithdrawalAmount, "WithdrawalAmount");

  const addFundHandler = () => {
    window.open(
      `https://payment.bigul.co/?ClientCode=${Encripted([])}`,
      "_blank"
    );
  };

  // const amountFormatter = (data) => {
  //   if (data > 0) {
  //     ////console.log(data);
  //     return `- ₹${data.toLocaleString("en-US").toString()}`;
  //   } else if (data < 0) {
  //     ////console.log(data);
  //     return `₹${data.toLocaleString("en-US").toString().slice(1)}`;
  //   } else {
  //     return `₹${data.toLocaleString("en-US").toString()}`;
  //   }
  //   //  ////console.log(data);
  // };

  const amountFormatter = (data, data2) => {
    let Total = 0;
    if (data > 0) {
      Total = -Math.abs(data) + data2;
      let t = Math.round(Total * 100) / 100;
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      } else if (t == 0) {
        return `₹0`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
      }
      //console.log(Total);
    } else if (data < 0) {
      // console.log(data);
      Total = Math.abs(data) + data2;
      let t = Math.round(Total * 100) / 100;
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      } else if (t == 0) {
        return `₹0`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
      }
      //console.log(Total);
    } else {
      Total = data + data2;
      let t = Math.round(Total * 100) / 100;
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      } else if (t == 0) {
        return `₹0`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
      }
      //console.log(Total);
    }
    //  ////console.log(data);
  };

  const amountFormatter2 = (data) => {
    let Total = data;
    if (data > 0) {
      let t = Math.round(Total * 100) / 100;
      return `₹${t.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      let t = Math.round(Total * 100) / 100;
      return `- ₹${t.toLocaleString("en-IN").toString().slice(1)}`;
    } else {
      let t = Math.round(Total * 100) / 100;
      return `₹${t.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await latestUpdateOnApi({ clientCode: client_code })
        .then((response) => {
          setUpdateData(response.createdAt);
          ////console.log(response);
        })
        .catch((err) => {
          ////console.log(err);
        });
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

 
  function convertValue(value) {
    // Check if the value is negative
    if (value < 0) {
      // If negative, convert it to positive
      return Math.abs(value);
    } else {
      // If positive, convert it to negative
      return -value;
    }
  }
  let convertedValue = convertValue(availableMargin?.availableMargin[0]?.Final1);
  
  useEffect(() => {
    if(client_type !=='Bigul' ){
      setWithdrawalBalance(convertedValue)
    }
    if(client_type == "Bigul"){
    setLoading(true)
    SymphonyWithdrawalBalance({
      client_token: client_token,
      token: symphony_token,
    })
      .then((response) => {
        console.log("SymphonyWithdrawalBalance",response);
        if (!response.balance_arr.withdrawal) {
          setWithdrawalBalance(0);
          setTotalValue(0);
    setLoading(false)

        } else {
          var total_margin_set = response.balance_arr.total_margin;
          setWithdrawalBalance(response.balance_arr.withdrawal);
          setTotalValue(total_margin_set);
    setLoading(false)

        }
      })
      .catch((err) => {
        setWithdrawalBalance(0);
        // console.log(err);
      });

    // ledgerApi({
    //   apiKey: generatedKey,
    //   code: encryptKey,
    //   startDate: dayjs(new Date("1/4/2015")).format("YYYY-MM-DD"),
    //   endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    // })
    // .then((response) => {
    //   //console.log(response);
    //   const d = response.finResult.find((el) => {
    //     return el.LNarrat === "Total : ";
    //   });
    //   setLedger(d);
    //   if (d.Lbal < 0) {
    //     setLedgerBalance(Math.abs(d.Lbal));
    //   } else if (d.Lbal > 0) {
    //     setLedgerBalance(-Math.abs(d.Lbal));
    //   } else {
    //     setLedgerBalance(0);
    //   }
    //   //console.log(d);
    // })
    // .catch((err) => {
    //   ////console.log(err);
    // });

    // marginApi({
    //   apiKey: generatedKey,
    //   code: encryptKey,
    //   startDate: dayjs(new Date("1/4/2015")).format("YYYY-MM-DD"),
    //   endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    // })
    // .then((response) => {
    //     // console.log(response);
    //     const d = response.finResult.find((el) => {
    //       return el.LNarrat === "Total : ";
    //     });
    //     //console.log(d)
    //     if (d.Lbal < 0) {
    //       setMarginBalance(Math.abs(d.Lbal));
    //     } else if (d.Lbal > 0) {
    //       setMarginBalance(-Math.abs(d.Lbal));
    //     } else {
    //       setMarginBalance(0);
    //     }
    //     setMargin(d);
    //     //console.log(d);
    //   })
    // .catch((err) => {
    //     ////console.log(err);
    // });
}
  }, [generatedKey, encryptKey,margin,convertedValue]);

  const openState = {
    CUpdate: true,
    otp1: false,
    done: false,
  };

  const downloadHandler = () => {
    downloadMarginOverviewApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(selectDate.date[0]).format("YYYY-MM-DD"),
      endDate: dayjs(selectDate.date[1]).format("YYYY-MM-DD"),
    })
      .then((response) => {
        setFile(response.finResult);
        setIsDownload(true);
        ////console.log(response);
      })
      .catch((err) => {
        ////console.log(err);
      });
  };

  const myArray = [];
  for (let i = 0; i < file.length; i += 1) {
    const childArray = file[i];
    const obj = {
      Name: childArray.LNarrat.replace("#", ""),
      Date: childArray.Ldate,
      Amount: childArray.Damount,
      Balance: childArray.Camount,
    };
    myArray.push(obj);
  }

  const userHeader = ["Name", "Date", "Amount", "Balance"];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `Ledger`);
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) { 
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const referHandler = () => {
    window.open(
      `https://bigul.co/en/index.php/terms-and-conditions/client-referral-terms-and-conditions/`,
      "_blank"
    );
  };
  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    //console.log(el)
    if (el.CollateralQuantity > 0) {
      // CollateralValues.push((el.CollateralQuantity * el.mktrate * el.Haircut) / 100);
      CollateralValues.push(
        el.CollateralQuantity * el.mktrate -
          (el.CollateralQuantity * el.mktrate * el.Haircut) / 100
      );
    }
    return el.CollateralQuantity > 0;
  });

  // const CollateralNew = Collateral.filter((el) => {
  //   CollateralValues.push(el.BuyAvgPrice + el.CollateralQuantity);
  //   return el;
  // });

  //console.log(Collateral)
  //console.log(CollateralNew)
  //console.log(CollateralValues)
  const CollateralValuessubTotal = CollateralValues.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  //console.log(CollateralValuessubTotal);

  //const TotalValue = availableMargin.availableMargin.length > 0 && +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal;
  //console.log(+availableMargin.availableMargin[0].Final1);

  // let TotalValue = 0;
  // if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 > 0
  // ) {
  //   TotalValue =
  //     -Math.abs(+availableMargin.availableMargin[0].Final1) +
  //     CollateralValuessubTotal;
  // } else if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 < 0
  // ) {
  //   TotalValue =
  //     Math.abs(+availableMargin.availableMargin[0].Final1) +
  //     CollateralValuessubTotal;
  // } else if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 === 0
  // ) {
  //   TotalValue =
  //     +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal;
  // }
  //console.log(ledgerBalances);
  //console.log(marginBalances);
  // const A =
  //   !isEmpty(ledger) && !isEmpty(margin) && Collateral.length > 0
  //     ? ledgerBalances - marginBalances / 2
  //     : 0;
  // //console.log(A);
  // const B =
  //   !isEmpty(ledger) && !isEmpty(margin) && Collateral.length > 0
  //     ? CollateralValuessubTotal - marginBalances / 2
  //     : 0;
  //console.log(B);
  // const WithdrawalAmount = !isEmpty(ledger) && !isEmpty(margin) && Collateral.length > 0 ? (ledger.Lbal - (margin.Lbal/2)) + (CollateralValuessubTotal- (margin.Lbal/2)) : 0;
  // console.log(WithdrawalAmount);
  // let A = 0;
  // let B = 0;

  //   if (!isEmpty(ledger) && ledger.Lbal < 0) {
  //     setLedgerBalance(Math.abs(ledger.Lbal));
  //   } else if (!isEmpty(ledger) && ledger.Lbal > 0) {
  //     setLedgerBalance(-Math.abs(ledger.Lbal));
  //   } else {
  //     setLedgerBalance(0);
  //   }
  //console.log(ledgerBalances)
  // console.log(marginBalances);
  // let WithdrawalAmount = 0;

  // if (
  //   !isEmpty(ledger) &&
  //   !isEmpty(margin) &&
  //   Collateral.length > 0 &&
  //   ledgerBalances <= 0
  // ) {
  //   WithdrawalAmount = 0;
  // } else if (
  //   !isEmpty(ledger) &&
  //   !isEmpty(margin) &&
  //   Collateral.length > 0 &&
  //   ledgerBalances > 0 &&
  //   A < 0
  // ) {
  //   WithdrawalAmount = 0;
  // } else if (
  //   !isEmpty(ledger) &&
  //   !isEmpty(margin) &&
  //   Collateral.length > 0 &&
  //   ledgerBalances > 0 &&
  //   A > 0 &&
  //   B > 0
  // ) {
  //   WithdrawalAmount = A + 0;
  // } else {
  //   WithdrawalAmount = A + B;
  // }
  // console.log(WithdrawalAmount);
  //console.log(availableMargin.availableMargin[0].Final1)

  
  return (
    <Col xxl={5} xl={5} lg={5} md={5}>
      <Row>
        <Col lg={12} md={12}>
          <Card style={{ height: "250px" }}>
            <CardHeader className="border-0 d-flex align-items-center">
              <div className="flex-grow-1">
                <h4 className="text-6 text-color1 mb-1"> Available Margin </h4>{" "}
                <h5 className="text-4 text-color1 mb-0">
                  {" "}
                  (Cash Balances + Collateral - Margin used){" "}
                </h5>{" "}
              </div>{" "}
              {/* <div className="flex-shrink-0">
                            <div className="card-overview-div-logo rounded-circle align-middle">
                              <Link
                                className="card-overview-div-logo-button"
                                //onClick={downloadHandler}
                                to="/statements/3"
                              >
                                <i
                                  className={"align-middle " + "ri-arrow-right-fill"}
                                  style={{ color: "#FFF" }}
                                ></i>
                              </Link>
                            </div>
                          </div> */}{" "}
            </CardHeader>{" "}
            <div className="card-body px-3 py-0">
              <h4
                className={`text-7 text-color5  mb-1 flex-grow-1 ${
                  TotalValue > 0 ? "text-color6" : "text-color11" || convertedValue > 0 ? "text-color6" : "text-color11"
                }`}
              >
               {client_type == 'Bonanza' ?
             availableMargin?.availableMargin.length == 0 ?
             <div style={{ display: 'flex', justifyContent: 'center' }}>
               <img src={loaderImage} className="custom-loader" style={{ height: "20px", }} />
             </div> :
             '₹' + convertedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              :
                loading ?
                <div style={{display:'flex',justifyContent:'center'}}>
                  <img src={loaderImage} className="custom-loader" style={{ height: "20px",}} />
                   </div>
                  : '₹' + TotalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              </h4>{" "}
              <h4 className="text-1 mb-0 flex-grow-1 text-color1">
                {" "}
                {updateData !== "" &&
                  `Last Transaction on ${dayjs(updateData).format(
                    "DD MMM YYYY"
                  )}`}{" "}
              </h4>{" "}
            </div>{" "}
            <div className="card-body p-3">
              <div className="d-flex pt-1 justify-content-between mb-3">
                <button
                  type="button"
                  className="btn btn-success text-4 w-100 me-3 margin-overview-button2"
                  style={{ borderRadius: "5px" }}
                  onClick={addFundHandler}
                >
                  <i
                    className={"align-middle me-2 fs-5 " + "ri-add-circle-line"}
                  ></i>
                  Add Funds{" "}
                </button>{" "}
                {/* <button
                              type="button"
                              className="btn btn-success text-4 w-100 mb-3 margin-overview-button2"
                              style={{ borderRadius: "5px" }}
                            >
                              <i
                                className={"align-middle me-2 fs-5 " + "ri-wallet-line"}
                              ></i>
                              Withdraw
                            </button> */}{" "}
                {/* <WithdrawFunds data={availableMargin} openState={openState} /> */}{" "}
                {/* {availableMargin.availableMargin.length > 0 &&
                            +availableMargin.availableMargin[0].Final1 < 0 ? (
                              <WithdrawFunds data={availableMargin} openState={openState} />
                            ) : (
                              <button
                                type="button"
                                className="btn btn-success text-4 w-100 margin-overview-button2"
                                style={{ borderRadius: "5px", cursor: "notAllowed", backgroundColor: '#5367fc', borderColor: '#5367fc' }}
                                disabled
                              >
                                <i
                                  className={"align-middle me-2 fs-5 " + "ri-wallet-line"}
                                ></i>
                                Withdraw
                              </button>
                            )} */}{" "}
                <Link
                  type="button"
                  className="btn btn-success text-4 w-100 margin-overview-button2 add-Collateral-btn"
                  style={{
                    borderRadius: "5px",
                    cursor: "notAllowed",
                    backgroundColor: "#5367fc",
                    borderColor: "#5367fc",
                  }}
                  to="/pledge"
                >
                  <i
                    onMouseEnter={{ backgroundColor: " #2b44fe !important" }}
                    className={"align-middle me-2 fs-5 " + "ri-add-circle-line"}
                  ></i>
                  Add Collateral{" "}
                </Link>{" "}
              </div>{" "}
              <div className="vstack gap-1">
                {" "}
                {/* <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                <h5 className="text-15  m-1 text-color4">
                                  Opening Balance
                                </h5>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="d-flex align-items-center gap-1 text-color4 text-15 ">
                                  ₹0.00
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                <h5 className="text-15  m-1 text-color4">
                                  Used for Stock, F&O
                                </h5>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="d-flex align-items-center gap-1 text-color4 text-15 ">
                                  ₹0.00
                                </div>
                              </div>
                            </div> */}{" "}
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h5 className="text-4 m-1 text-color5">
                      Withdrawal Amount{" "}
                    </h5>{" "}
                  </div>{" "}
                  <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1 text-color5 text-4">
                      <p
                        className={`m-0 text-color5 ${
                          WithdrawalAmount > 0 ? "text-color6" : "text-color11"
                        }`}
                      >
                        {" "}
                        {WithdrawalAmount > 0 ? "₹"+WithdrawalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '₹0'}
                        {" "}
                      </p>{" "}
                      <p className="m-0 text-21 text-color1 text-end">
                        {" "}
                        {/* [ <Link onClick={addFundHandler}>Withdraw</Link> ] */}{" "}
                        {WithdrawalAmount > 0 ? (
                          <WithdrawFunds
                            data={availableMargin}
                            openState={openState}
                            wAmount={WithdrawalAmount.toFixed(2)}
                          />
                        ) : (
                          // <button
                          //   type="button"
                          //   className="text-4 text-color7 profile-edit-button1"
                          //   style={{
                          //     borderRadius: "5px",
                          //     cursor: "notAllowed",
                          //     // backgroundColor: "#5367fc",
                          //     // borderColor: "#5367fc",
                          //     textDecoration: "none",
                          //   }}
                          //   disabled
                          // >
                          //   [Withdraw]{" "}
                          // </button>
                          <></>
                        )}{" "}
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </Card>{" "}
        </Col>{" "}
        {/* <Col lg={12} md={6}>
                      <Card>
                        <CardBody>
                          <div className="vstack gap-1">
                            <div className="d-flex align-items-center">
                              <div className="profile-round-dot d-block flex-shrink-0 me-3">
                                <span className="avatar-title rounded-circle fs-4 background-color4"></span>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="text-4 m-1 text-color4">Refer & Earn</h5>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="d-flex align-items-center gap-1">
                                  <button
                                    type="button"
                                    className="btn btn-sm text-color2"
                                    onClick={referHandler}
                                  >
                                    <i
                                      className="ri-arrow-right-s-line align-bottom"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="profile-round-dot d-block flex-shrink-0 me-3">
                                <span className="avatar-title rounded-circle fs-4 background-color3"></span>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="text-4 m-1 text-color4">My Plans</h5>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="d-flex align-items-center gap-1">
                                  <Link
                                    className="btn btn-sm text-color2"
                                    to="/subscription/plans"
                                  >
                                    <i
                                      className="ri-arrow-right-s-line align-bottom"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="profile-round-dot d-block flex-shrink-0 me-3">
                                <span className="avatar-title rounded-circle fs-4 background-color7"></span>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="text-4 m-1 text-color4">My Algos</h5>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="d-flex align-items-center gap-1">
                                  <Link className="btn btn-sm text-color2" to="/myalgos">
                                    <i
                                      className="ri-arrow-right-s-line align-bottom"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="profile-round-dot d-block flex-shrink-0 me-3">
                                <span className="avatar-title rounded-circle fs-4 background-color8"></span>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="text-4 m-1 text-color4">Become a BA</h5>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="d-flex align-items-center gap-1">
                                  <button type="button" className="btn btn-sm text-color2">
                                    <i
                                      className="ri-arrow-right-s-line align-bottom"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}{" "}
      </Row>{" "}
    </Col>
  );
};

export default MarginOverview;
