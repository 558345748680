import {
  API_PLAN_HISTORY_RESPONSE_SUCCESS,
  API_PLAN_HISTORY_RESPONSE_ERROR,
  GET_PLAN_HISTORY
} from "./actionType";

// PlanHistory
export const PlanHistoryApiResponseSuccess = (actionType, data) => ({
  type: API_PLAN_HISTORY_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// PlanHistory error
export const PlanHistoryApiResponseError = (actionType, error) => ({
  type: API_PLAN_HISTORY_RESPONSE_ERROR,
  payload: { actionType, error },
});
// PlanHistory
export const getPlanHistory = (data) => ({
  type: GET_PLAN_HISTORY,
  payload: data,
});
