import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_MY_PLAN } from "./actionType";

import {
  MyPlanApiResponseSuccess,
  MyPlanApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getMyPlanApi } from "../../helpers/fakebackend_helper";

function* getMyPlan(data) {
  try {
    const response = yield call(getMyPlanApi, data.data);
    yield put(MyPlanApiResponseSuccess(GET_MY_PLAN, response));
  } catch (error) {
    yield put(MyPlanApiResponseError(GET_MY_PLAN, error));
  }
}

export function* watchGetMyPlan() {
  yield takeEvery(GET_MY_PLAN, getMyPlan);
}

function* MyPlanSaga() {
  yield all([fork(watchGetMyPlan)]);
}

export default MyPlanSaga;
