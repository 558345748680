import {
  API_SA_RESPONSE_SUCCESS,
  API_SA_ERROR,
  GET_SA
} from "./actionType";

const INIT_STATE = {
  sa: {}
};

const Sa = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SA_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SA:
          return {
            ...state,
            sa: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case API_SA_ERROR:
      switch (action.payload.actionType) {
        case GET_SA:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_SA: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default Sa;
