import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_MY_PLAN_DURATION } from "./actionType";

import {
  MyPlanDurationApiResponseSuccess,
  MyPlanDurationApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getMyPlanDurationApi } from "../../helpers/fakebackend_helper";

function* getMyPlanDuration(data) {
  try {
    const response = yield call(getMyPlanDurationApi, data.data);
    yield put(MyPlanDurationApiResponseSuccess(GET_MY_PLAN_DURATION, response));
  } catch (error) {
    yield put(MyPlanDurationApiResponseError(GET_MY_PLAN_DURATION, error));
  }
}

export function* watchGetMyPlanDuration() {
  yield takeEvery(GET_MY_PLAN_DURATION, getMyPlanDuration);
}

function* MyPlanDurationSaga() {
  yield all([fork(watchGetMyPlanDuration)]);
}

export default MyPlanDurationSaga;
