import {
  GET_CATEGORY_ELEARNINGVIDEOS,
  API_CATEGORY_RESPONSE_SUCCESS,
  API_CATEGORY_RESPONSE_ERROR,
} from "./actionType";

// common success
export const ELearningCategoryApiResponseSuccess = (actionType, data) => ({
  type: API_CATEGORY_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const ELearningCategoryApiResponseError = (actionType, error) => ({
  type: API_CATEGORY_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getELearningCategoryList = (data) => ({
  type: GET_CATEGORY_ELEARNINGVIDEOS,
  data: data
});